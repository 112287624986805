import { Component, Input } from '@angular/core';

@Component({
  selector: 'spinner-dialenga',
  templateUrl: './spinner-dialenga.component.html'
})
export class SpinnerDialengaComponent {

  @Input() classes?: string;

  constructor() { }

}
