import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

export const ScopeProvider = {
  deps: ['$injector'],
  provide: '$scope',
  useFactory: (injector: Injector) => injector.get('$rootScope').$new(),
};

@Directive({
  providers: [ScopeProvider],
  selector: 'disabled-button'
})
export class DisabledButtonDirective extends UpgradeComponent {

  @Input() buttonId?: string;
  @Input() classes: string;
  @Input() text?: string;
  @Input() tooltipText?: string;
  @Input() iconClass?: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('disabledButton', elementRef, injector);
  }
}
