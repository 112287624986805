import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { Constants } from '../../constants';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'documents',
    templateUrl: './documents.component.html'
})
export class DocumentsComponent implements OnInit {

    public DOCUMENTS_TABS: any;
    public MODULES_STATES: any;
    public MODULES: any;
    public selectedTab: number;
    private _route: any;
    private userSession: any;
    public isDocumentsModuleActive: boolean;
    public isSignatureTabHidden: boolean;
    public isSignatureProductionMode: boolean;

    constructor(private location: Location, private upgrade: UpgradeModule, private titleService: Title, private translate: TranslateService) { 

        this.DOCUMENTS_TABS = Constants.DOCUMENTS_TABS;
        this.MODULES = Constants.MODULES;
        this.MODULES_STATES = Constants.MODULES_STATES;
        this._route = this.upgrade.$injector.get('$route');
        this.selectedTab = this._route.current.locals.selectedTab;
        this.userSession = window['getUserSession']();

        this.isDocumentsModuleActive = this.userSession.activeModules[this.MODULES.DOCUMENTS].state === this.MODULES_STATES.ENABLED;
        this.isSignatureProductionMode = this.userSession.activeModules[this.MODULES.DOCUMENTS].productionMode;
        this.isSignatureTabHidden = this.userSession.activeModules[this.MODULES.DOCUMENTS].backofficeHidden;
    }


    ngOnInit() {
       
    }

    navigateTo(url: string) {
        this.location.go(url);
    }

    loadTabContent( tab: number) {
        if (this.selectedTab !== tab) {
            this.selectedTab = tab;
            if (tab === this.DOCUMENTS_TABS.ALL_DOCUMENTS) {
                this.titleService.setTitle(this.translate.instant('documents.title'));
                this._route.updateParams({ tabName: '' });
            } else if (tab === this.DOCUMENTS_TABS.REMOTE_SIGNATURE) {
                this.titleService.setTitle(this.translate.instant('documents.remoteSignatureShipments'));
                this._route.updateParams({ tabName: 'remote-signature'});
            }
        }
    }
}