<div class="main-container__content margin-bottom-40">
  <div class="main-container--full-width">

    <div class="flex align-items-start justify-space-between">
      <div class="group-form group-form--label-100">
        <div class="group-form__label">
          <span class="group-form__label__text font-size-16" [translate]="'model.schemes.employees'"></span>
        </div>
        <div class="group-form__data ">
          <div class="blue-counter">
            <span class="blue-counter__label" [textContent]="companyEmployeesDatagrid?.total"></span>
          </div>
        </div>
      </div>
      <!-- filter by username, internal id, email and document name -->
      <div class="search-filter-container align-items-center margin-bottom-5">
        <input type="text" class="input input--search" maxlength="1024" [placeholder]="'documents.list.filters.searchPlaceholder' | translate" [(ngModel)]="gridFilteringOps.name"
          (input)="listItemsSearch()" ngModelOptions="{ debounce: 200 }" [ngClass]="gridFilteringOps.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'">
          <div class="icon icon-remove" (click)="clearSearchInput()"></div>
        </div>
      </div>

      <!-- Company employees grid -->
      @if (companyEmployeesDatagrid?.data.length > 0) {
        <kendo-grid #companyEmployeesGrid [data]="companyEmployeesDatagrid" class="master-grid"
          [sortable]="true" [sort]="gridSort" (sortChange)="onSortChange($event)" [resizable]="false" [selectable]="false" [navigable]="true" [loading]="isSyncing"
          [pageable]="gridPagerSettings" (pageChange)="onPageChange($event)" [skip]="gridPagingOps.skip" [pageSize]="gridPagingOps.size">
          <kendo-grid-messages
            [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate" [pagerOf]="'global.lists.pagerOf' | translate"
            [pagerItems]="'global.lists.pagerItems' | translate">
          </kendo-grid-messages>
          <!-- Employee avatar -->
          <kendo-grid-column width="76" class="cursor-pointer" [headerClass]="'padding-0'" [sortable]="false">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="flex align-items-center">
                <avatar [name]="dataItem.name" [surname]="dataItem.surname" [small]="false"
                  [thumbnails]="{thumbnail_M: dataItem.avatarThumbnailMUrl || dataItem.avatarUrl, thumbnail_S: dataItem.avatarThumbnailSUrl || dataItem.avatarThumbnailMUrl}">
                </avatar>
              </div>
            </ng-template>
          </kendo-grid-column>
          <!-- Employee name and surname -->
          <kendo-grid-column class="cursor-default" sortable="false" [headerStyle]="{'pointer-events': 'none'}">
            <ng-template kendoGridHeaderTemplate>
              <div class="flex align-items-center cursor-pointer user-select-none">
                <span [textContent]="'users.name' | translate"></span>
              </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="flex align-items-center">
                <span class="truncate" [textContent]="dataItem.name + ' ' + dataItem.surname"></span>
              </div>
            </ng-template>
          </kendo-grid-column>
          <!-- Employee username -->
          <kendo-grid-column field="username" class="cursor-pointer"[sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <div class="flex align-items-center padding-left-8 cursor-pointer user-select-none">
                <span [textContent]="'users.username' | translate"></span>
              </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="flex align-items-center">
                <span class="truncate" [textContent]="dataItem.username"></span>
              </div>
            </ng-template>
          </kendo-grid-column>
          <!-- CTA buttons column -->
          <kendo-grid-command-column title="" [style]="{ 'text-align': 'end' }" class="cursor-pointer">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
              @if (selectedRows === 0) {
                <div class="td-on-hover">
                  <a id="delete-user-btn" class="k-button k-master-button" (click)="deleteEmployee(dataItem)">
                    <span class="icon icon-delete"></span>
                  </a>
                </div>
              }
            </ng-template>
          </kendo-grid-command-column>

          <!-- Custom Pager -->
          <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <article class="dui-grid-pager">
              <kendo-pager-info></kendo-pager-info>
              @if (companyEmployeesDatagrid.total > 10) {
                <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                  <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                  <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                  <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                </article>
                <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
              }
            </article>
          </ng-template>
        </kendo-grid>
      }

      <!-- Skeleton company employees grid -->
      @if (companyEmployeesDatagrid?.data.length == 0 && isSyncing) {
        <div>
          <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid" [rowClass]="skeletonRowCallback">
            <kendo-grid-column width="4%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <kendo-skeleton shape="circle" animation="pulse" [width]="40" [height]="40"></kendo-skeleton>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="25%" [headerStyle]="{'padding': '0.5em 0.6em'}">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center">
                  <span class="padding-left-10" [textContent]="'users.name' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <kendo-skeleton shape="text" animation="pulse" [width]="200" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="24%">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center">
                  <span class="padding-left-8" [textContent]="'users.username' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-skeleton shape="text" animation="pulse" [width]="250" [height]="20" style="border-radius: 4px"></kendo-skeleton>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-command-column title="" width="25%"></kendo-grid-command-column>
          </kendo-grid>
        </div>
      }
      @if (companyEmployeesDatagrid?.data.length == 0 && !gridFilteringOps.name && !isSyncing) {
        <no-results [imageTitle]="'schemes.employees.noResultsTitle'"
          [description]="'schemes.employees.noResultsText'">
        </no-results>
      }
      @if (companyEmployeesDatagrid?.data.length == 0 && gridFilteringOps.name && !isSyncing) {
        <no-search-results></no-search-results>
      }
    </div>
  </div>