import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare var moment: any;

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    private URL_REGEXP = /^(?:(?:(?:https?|ftp):)?\/\/){0,1}(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
    private EMAIL_REGEXP = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    private CLEAN_HTML_REGEXP = /<[^>]*>/g;
    private SIZE_UNITS = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb'];

    constructor(private translate: TranslateService) { }

    private removeHash(color) {
        return (color.charAt(0) === '#') ? color.substring(1, 7) : color;
    }

    decimalToHex(color: any) {
        if (!color) {
            return '';
        }
        const hexLength = 6;
        const hexNumber = '00000' + Number(color).toString(16);
        return '#' + hexNumber.substr(hexNumber.length - hexLength);
    }

    hexToRGBString(color) {
        return parseInt((this.removeHash(color)).substring(0, 2), 16) + ', ' +
            parseInt((this.removeHash(color)).substring(2, 4), 16) + ', ' +
            parseInt((this.removeHash(color)).substring(4, 6), 16);
    }

    increaseBrightness(decimalColor: number, percent: number) {
        let hex = this.decimalToHex(decimalColor);
        // Remove the leading # char
        hex = hex.replace(/^\s*#|\s*$/g, '');

        // Convert 3 char codes to 6. E.g. 'E0F' -> 'EE00FF'
        if (hex.length === 3) {
            hex = hex.replace(/(.)/g, '$1$1');
        }

        const r = parseInt(hex.substr(0, 2), 16),
            g = parseInt(hex.substr(2, 2), 16),
            b = parseInt(hex.substr(4, 2), 16);

        return '#' +
            ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
            ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
            ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
    }

    decreaseBrightness(decimalColor: number, percent: number) {
        const hex = this.decimalToHex(decimalColor);
        const r = parseInt(hex.substr(1, 2), 16),
            g = parseInt(hex.substr(3, 2), 16),
            b = parseInt(hex.substr(5, 2), 16);

        return '#' +
            ((0 | (1 << 8) + r * (100 - percent) / 100).toString(16)).substr(1) +
            ((0 | (1 << 8) + g * (100 - percent) / 100).toString(16)).substr(1) +
            ((0 | (1 << 8) + b * (100 - percent) / 100).toString(16)).substr(1);
    }

    getCategoryColor(category: any): string {
        let categoryColor: string ='';

        if (!!category) {
            categoryColor = category.color;

            if (category.hierarchy && category.hierarchy.length > 0) {
                const level: number = category.hierarchy.length - 1;
                const brightnessPercentage = level < 4 ? level * 20 : 80;
                categoryColor = this.increaseBrightness(category.color, brightnessPercentage);
            } 
        }
        return categoryColor; 
    }

    getShortLanguageKey(language: string): string {
        if (language === 'ca_ES') {
            return 'ca';
        } else if (language === 'eu_ES') {
            return 'eu';
        } else {
            return language;
        }
    }

    formatDateToString(date: Date): string {
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        const year = date.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    getTimeElapsedFrom(startDate: Date): string {
        let elapsedFrom = moment(startDate).fromNow();
        return elapsedFrom.charAt(0).toUpperCase() + elapsedFrom.slice(1);
    }

    getIconFromMimeType(mime: string): string {
        if (mime.startsWith('image/') && mime !== 'image/svg+xml') {
            mime = 'image/png';
        }
        let iconFile: string = '';
        switch (mime) {
            case 'application/pdf':
                // Full path duplicated for each icon for gulp's cache busting task
                iconFile = '/ng1/assets/img/mime_pdf.svg';
                break;
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                iconFile = '/ng1/assets/img/mime_word.svg';
                break;
            case 'application/vnd.ms-excel':
            case 'text/csv':
                iconFile = '/ng1/assets/img/mime_excel.svg';
                break;
            case 'application/vnd.ms-powerpoint':
                iconFile = '/ng1/assets/img/mime_ppt.svg';
                break;
            case 'text/plain':
            case 'text/css':
            case 'text/html':
            case 'application/rtf':
                iconFile = '/ng1/assets/img/mime_text.svg';
                break;
            case 'image/gif':
            case 'image/x-icon':
            case 'image/jpeg':
            case 'image/tiff':
            case 'image/webp':
            case 'image/png':
            case 'image/tga':
                iconFile = '/ng1/assets/img/mime_image.svg';
                break;
            case 'image/svg+xml':
            case 'application/x-figma':
                iconFile = '/ng1/assets/img/mime_vector.svg';
                break;
            case 'audio/ogg':
            case 'audio/x-wav':
            case 'audio/webm':
            case 'audio/3gpp':
            case 'audio/aac':
            case 'audio/midi':
            case 'audio/mpeg':
                iconFile = '/ng1/assets/img/mime_audio.svg';
                break;
            case 'application/x-rar-compressed':
            case 'application/x-zip-compressed':
            case 'application/x-7z-compressed':
            case 'application/zip':
            case 'application/x-bzip':
            case 'application/x-bzip2':
                iconFile = '/ng1/assets/img/mime_compressed.svg';
                break;
            default:
                iconFile = '/ng1/assets/img/mime_unknown.svg';
                break;
        }
        return iconFile;
    }

    getResourceSize(size: number): string {
        if (!size || isNaN(size) || size === 0) return '0 bytes';

        const i = Math.floor(Math.log(size) / Math.log(1024));
        return `${parseFloat((size / Math.pow(1024, i)).toFixed(2))} ${this.SIZE_UNITS[i]}`;
    }

    validateURL(url: string) :boolean {
        if (url && url.startsWith('tel:') && url.length > 4) {
            return true;
        }
        return this.URL_REGEXP.test(url);
    };

    concatURLPrefixIfNotExists(url: string) :string {
        if (url && url.startsWith('mailto:') || url.startsWith('tel:')) {
            return url;
        }
        return url.indexOf("http") == 0 ? url : "http://" + url;
    };

    validateEmail(email: string) :boolean {
        return email? this.EMAIL_REGEXP.test(email) : true;
    };

    showUrlLink = function (url: string) :void {
        if (url) window.open(url, "_blank");
    };

    replaceSpecialCharsSpanish(text: string): string {
        text = text.toLowerCase();
        text = text
            .replaceAll('&aacute;', 'á')
            .replaceAll('&eacute;', 'é')
            .replaceAll('&iacute;', 'í')
            .replaceAll('&oacute;', 'ó')
            .replaceAll('&uacute;', 'ú')
            .replaceAll('&ntilde;', 'ñ')
            .replaceAll('&uuml;', 'ü')
            .replaceAll('&nbsp;', ' ')
            .replaceAll('&ndash;', '-')
            .replaceAll('&iquest;', '¿')
            .replaceAll('&iexcl;', '¡');
        return text;
    }

    syllablesCounterSpanish(word: string) {
        // Replace special characters and convert to lowercase
        word = this.replaceSpecialCharsSpanish(word);
        // Count the vowels in the word
        const wordVowels = word.match(/[aeiouáéíóú]/g);
        // If there are no vowels or it's a single vowel, we assume a single syllable
        if (!wordVowels || wordVowels.length <= 1) {
          return 1;
        }
        
        const vocals = ['a','e','i','o','u','á','é','í','ó','ú'];
        let counter = 0;
        for (let i = 0; i < word.length; i++) {
            if(vocals.indexOf(word[i]) !== -1) {
                if(i === 0) {
                    counter++;
                } else if(vocals.indexOf(word[i-1]) === -1) {
                    counter++;
                }
            }
        }
        return counter;
    }

    getPlainTextFromString(data: string): string {
        data = data.trim();
        // strip html tags from data
        data = data.replace(this.CLEAN_HTML_REGEXP, '');
        // clean for special characters e.g. &aacute, &ntilde
        data = this.replaceSpecialCharsSpanish(data);
        return data;
    }

    getSentencesNumberFromText(text: string): number {
        // must receive plain text to work properly
        let textSentences = text.split(/\.|\;|\:|\!/);
        return textSentences[textSentences.length-1].length < 1 ? textSentences.length-1 : textSentences.length; 
    }

    calculateReadabilityIndex(data: any): number {
        let index = 0;
        switch (data.indexKind) {
            case 'FHUERTA':
                let syllablesByHundredWords = data.syllables*100 / data.words.length;
                let sentencesByHundredWords = data.sentences*100 / data.words.length;
                index = 206.84 - (0.60 * syllablesByHundredWords) - (1.02 * sentencesByHundredWords);
                break;
            case 'INFLESZ':
                index = 206.835 - ((62.3 * data.syllables)/data.words.length) - (data.words.length/data.sentences);   //INFLESZ
                break;
            default:
                break;
        }
        // round index to 3 decimals
        index = Math.round(index);

        return index < 0 ? 0 : index > 100 ? 100 : index;
    }

    getReadabilityIndexImageFile(index: number): string {
        if (index === null) {
            return 'ng1/assets/img/index-level-none.svg';
        }
        var image = 'ng1/assets/img/';
        switch (true) {
            case index >= 0 && index < 35:
                image += 'index-level-1.svg';
                break;
            case index >= 35 && index < 50:
                image += 'index-level-2.svg';
                break;
            case index >= 50 && index < 65:
                image += 'index-level-3.svg';
                break;
            case index >= 65 && index < 80:
                image += 'index-level-4.svg';
                break;
            case index >= 80 && index <= 100:
                image += 'index-level-5.svg';
                break;
            default:
                image += 'index-level-none.svg';
                break;
        }
        return image;
    }

    getReadabilityIndexText(index: number): string {
        var text = '';
        switch (true) {
            case index >= 0 && index < 35:
                text = this.translate.instant('message.readability.veryLow');
                break;
            case index >= 35 && index < 50:
                text = this.translate.instant('message.readability.low');
                break;
            case index >= 50 && index < 65:
                text = this.translate.instant('message.readability.normal');
                break;
            case index >= 65 && index < 80:
                text = this.translate.instant('message.readability.high');
                break;
            case index >= 80 && index <= 100:
                text = this.translate.instant('message.readability.veryHigh');
                break;
            default:
                break;
        }
        return text;
    }

    getVideoId(url: string): string {
        let videoId = url.split('?v=')[1];
        if (videoId && videoId.length > 1) {
            // Regular links
            let ampersandPosition = videoId.indexOf('&');
            if (ampersandPosition !== -1) {
                videoId = videoId.substring(0, ampersandPosition);
            }
        } else {
            // Shortened links
            videoId = url.split('youtu.be/')[1];
            if (videoId && videoId.length > 1) {
                let paramsPosition = videoId.indexOf('?');
                if (paramsPosition !== -1) {
                    videoId = videoId.substring(0, paramsPosition);
                }
            } else {
                // Shorts links
                videoId = url.split('/shorts/')[1];
                if (!videoId || videoId.length === 0) {
                    // Embeded links
                    videoId = url.split('/embed/')[1];
                    if (!videoId || videoId.length === 0) {
                        // Invalid links
                        videoId = '';
                    }
                }
            }
        }
        return videoId;
    }
 }
