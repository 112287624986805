import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-slim-button',
  templateUrl: './slim-button.component.html'
})
export class SlimButtonComponent {

  @Input() icon: string;
  @Input() text: string;
  @Input() isHidden = false;
  @Output() submitAction: EventEmitter<any> = new EventEmitter();

  constructor() { }

  performAction(): void {
    this.submitAction.emit(null);
  }

}
