import { Component } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: 'dui-notifications',
  templateUrl: './dui-notifications.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          opacity: 0,
        }),
        style({
          opacity: 1,
        })
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translateY(40px)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false
})

export class DuiNotificationsComponent extends Toast {
  constructor( protected toastrService: ToastrService, public toastPackage: ToastPackage ) {
    super(toastrService, toastPackage);
  }
  
  getIconClass() :string {
    let iconClass :string = 'toast-icon icon ';

    let toastClasses: Array<string> = this.options.toastClass.split(' ');
    switch (toastClasses[toastClasses.length-1]) {
        case 'toast-success':
            iconClass += 'icon-ok';
            break;
        case 'toast-error':
            iconClass += 'icon-error';
            break;
        case 'toast-info':
            iconClass += 'icon-info';
            break;
        case 'toast-warning':
            iconClass += 'icon-alert';
            break;
        default:
            break;
    }
    return iconClass;
  }
  
  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
