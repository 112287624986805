import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UsersSegmentsService {

    public apiUrl: string;
    public PAGE_SIZE: number;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl + '/segments';
        this.PAGE_SIZE = Constants.PAGE_SIZE;
    }

    findAll(options: any): Observable<any> {
        let httpOptions = {
            params: {
                sort: options.sort || 'name',
                active: options.active
            }
        }
        if (options.name) { httpOptions.params['name'] = options.name };
        return this.httpClient.get<any>(this.apiUrl, httpOptions);
    }

    findAllSegmentedUsers(options: any) {
        let httpOptions = {
            params: options
        };
        httpOptions.params.page= httpOptions.params.page || 0;
        httpOptions.params.size= httpOptions.params.size || this.PAGE_SIZE;
        httpOptions.params.sort= httpOptions.params.sort || '';
        return this.httpClient.get<any>(`${Constants.apiUrl}/users/segmented-users`, httpOptions);
    }

    getSegmentUsers(segmentId: number, options: any) {
        let httpOptions = {
            params: options
        };
        httpOptions.params.page= httpOptions.params.page || 0;
        httpOptions.params.size= httpOptions.params.size || this.PAGE_SIZE;
        httpOptions.params.sort= httpOptions.params.sort || '';
        return this.httpClient.get<any>(`${this.apiUrl}/${segmentId}/users`, httpOptions);
    }

    getUserCountBySegmentationKind(population: number, segments: Array<any>): any {
        let recipients = {
            population: population,
            segments: segments
        }
        return this.httpClient.post<any>(`${Constants.apiUrl}/users/recipients-count`, recipients);
    }
}