import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    public apiUrl: string;
    public PAGE_SIZE: number;
    public USER_STATES_FILTER: any;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
        this.PAGE_SIZE = Constants.PAGE_SIZE;
        this.USER_STATES_FILTER = Constants.USER_STATES_FILTER;
    }

    findOne(id: number): Observable<any> {
        return this.httpClient.get<any>(this.apiUrl + '/users/' + id);
    };

    getEmployees(employeeFilter?: string): Observable<any> {
        let url = this.apiUrl + '/users/employees?';
        if (!!employeeFilter) {
            url += 'name=' + employeeFilter.toLowerCase() + '&';
        }
        url += 'state=' + this.USER_STATES_FILTER.REGISTERED + '&state=' + this.USER_STATES_FILTER.PENDING_ACTIVATION + '&state=' + this.USER_STATES_FILTER.LOCKED;

        return this.httpClient.get<any>(url).pipe(
            map(response => {
                if (response.content.length > 0) {
                    response.content.forEach(user => {
                        user.fullName = user.name + ' ' + user.surname;
                    });
                }
                return response;
            })
        );
    }

    getCountryCodes(): Observable<any> {
        return this.httpClient.get<any>(this.apiUrl + '/country-codes');
    }

    updatePhoneNumber(userId, countryCode, phoneNumber): Observable<any> {
        return this.httpClient.put<any>(`${this.apiUrl}/users/${userId}/phone`, { code: countryCode, number: phoneNumber });
    }
}
