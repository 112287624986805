<div class="boxes-user-segments-container">
  <div class="group">
    @if (!availableSegments || availableSegments.length > 0) {
      <ul class="box-groups provider relative-to-view" id="available-users-container">
        @for (segment of availableSegments; track segment) {
          <li class="user-item available-user non-selectable text-left" attr.data-name="{{segment.name}}"
            [ngClass]="{'added': segment.added, 'selected': segment.selected }" (click)="selectSegmentFromAvailables(segment, $event)" (dblclick)="doubleClickedSegment(segment)">
            <span class="icon icon-24 icon-user-groups-filled"></span>
            <span class="name" [textContent]="segment.name"></span>
          </li>
        }
      </ul>
    }
    @if (availableSegments?.length > 0) {
      <div class="flex justify-space-between direction-reverse align-items-center group-footer">
        @if (availableSegments?.length > 0) {
          <a id="add-all-available" [routerLink]="" queryParamsHandling="preserve" [translate]="'segments.addAll'" (click)="addAllFilteredAvailableSegments()">{{'segments.addAll' | translate}} ({{availableSegments.length}})</a>
        }
        @if (selectedSegments?.length > 0) {
          <div class="flex align-items-center">
            <span class="number margin-left-0" [textContent]="selectedSegments.length"></span>
            <span class="margin-left-10" [translate]="'segments.selectedGroups'" [translateParams]="{'count': selectedSegments.length}"></span>
          </div>
        }
      </div>
    }
    @if (availableSegments?.length == 0) {
      <div class="box-groups flex justify-center align-items-center relative-to-view">
        @if (!filterAvailable && isSyncing === false) {
          <no-results [imageTitle]="'model.segment.noResultsTitle'"></no-results>
        }
        @if (filterAvailable && isSyncing === false) {
          <no-search-results></no-search-results>
        }
      </div>
    }
  </div>

  <div id="add-selected-items-button" class="icon icon-arrow-fill" [ngClass]="{'active': selectedSegments.length > 0 }" (click)="addSelectedSegments()"></div>

  <div class="group">
    @if (filteredAddedSegments?.length > 0) {
      <ul id="added-users-container" class="box-groups relative-to-view">
        @for (addedSegment of filteredAddedSegments; track trackByFn($index, addedSegment)) {
          <li class="user-item text-left">
            <span class="icon icon-24 icon-user-groups-filled"></span>
            <span class="name" [textContent]="addedSegment.name"></span>
            <div class="icon icon-remove" (click)="removeAddedItemFromList(addedSegment)"></div>
          </li>
        }
      </ul>
    }
    @if (addedSegments?.length > 0) {
      <div class="flex justify-space-between direction-reverse align-items-center group-footer">
        <a id="remove-all-added" [routerLink]="" queryParamsHandling="preserve" [translate]="'segments.removeAll'" (click)="removeAllFilteredAddedSegments()"></a>
        <div class="flex align-items-center">
          <span class="number margin-left-0" [textContent]="addedSegments.length"></span>
          <span class="margin-left-10" [translate]="'segments.addedGroups'" [translateParams]="{'count': addedSegments.length}"></span>
        </div>
      </div>
    }
    @if (filteredAddedSegments?.length == 0) {
      <div class="box-groups flex justify-center align-items-center relative-to-view">
        @if (!filterAdded && isSyncing === false) {
          <no-results [imageTitle]="'global.form.noDataTitleMessage'" [description]="'global.form.noDataTextMessage'"></no-results>
        }
        @if (filterAdded && isSyncing === false) {
          <no-search-results></no-search-results>
        }
      </div>
    }
  </div>
</div>