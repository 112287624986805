import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/ng2/app/constants';
import { UtilsService } from '../../services/utils.service';

declare const mobiscroll: any;
declare const moment: any;

interface Filters {
  id: number,
  title: string,
  dates: {
    firstDate: any,
    lastDate: any
  },
  isQuick?: boolean,
  isShortcut: boolean
}

@Component({
  selector: 'date-range-filter',
  templateUrl: './date-range-filter.component.html'
})

export class DateRangeFilterComponent implements OnInit {
  @Input() hideShortcuts?: boolean;
  @Input() anchor?: string;
  @Input() dateRangeId?: string;
  @Input() isDownload?: string;
  @Input() monthlyFilters?: string;
  @Input() initialSelectedButton?: number;
  @Output() onDateSet: EventEmitter<any> = new EventEmitter();
  @Output() onCancel: EventEmitter<any> = new EventEmitter();

  private calendarPopup: HTMLElement;
  private shortcutHistory: number[];
  private shortcutList: HTMLElement;
  private top: string;
  public DATE_FILTER: any;
  public filterTypes: Filters[];
  public filterTypesLenght: number;
  public inst: { setVal: (arg0: string[]) => void; show: () => void; };
  public selectedButton: number;
  public selectedLink: number;

  constructor(
    private translate: TranslateService,
    private utilsService: UtilsService,
  ) {
    this.translate = translate;

    this.DATE_FILTER = {
      THIS_WEEK: 0,
      LAST_WEEK: 1,
      THIS_MONTH: 2,
      LAST_MONTH: 3,
      LAST_30_DAYS: 4,
      LAST_60_DAYS: 5,
      LAST_90_DAYS: 6,
      CUSTOM: 7
    };

    this.filterTypes = [
      {
        id: this.DATE_FILTER.THIS_WEEK,
        title: "global.form.timeInterval.thisWeek",
        dates: {
          firstDate: moment().startOf("isoWeek").format("YYYY-MM-DD"),
          lastDate: moment().format("YYYY-MM-DD")
        },
        isShortcut: true
      },
      {
        id: this.DATE_FILTER.LAST_WEEK,
        title: "global.form.timeInterval.lastWeek",
        dates: {
          firstDate: moment().subtract(1, "weeks").startOf("isoWeek").format("YYYY-MM-DD"),
          lastDate: moment().subtract(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD")
        },
        isQuick: true,
        isShortcut: true
      },
      {
        id: this.DATE_FILTER.THIS_MONTH,
        title: "global.form.timeInterval.thisMonth",
        dates: {
          firstDate: moment().startOf("month").format("YYYY-MM-DD"),
          lastDate: moment().format("YYYY-MM-DD")
        },
        isShortcut: true
      },
      {
        id: this.DATE_FILTER.LAST_MONTH,
        title: "global.form.timeInterval.lastMonth",
        dates: {
          firstDate: moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
          lastDate: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
        },
        isQuick: true,
        isShortcut: true
      },
      {
        id: this.DATE_FILTER.LAST_30_DAYS,
        title: "remoteSignature.batch.dateFilterLabel.last30",
        dates: {
          firstDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
          lastDate: moment().format("YYYY-MM-DD")
        },
        isShortcut: true
      },
      {
        id: this.DATE_FILTER.LAST_60_DAYS,
        title: "global.form.timeInterval.lastSixtyDays",
        dates: {
          firstDate: moment().subtract(60, "days").format("YYYY-MM-DD"),
          lastDate: moment().format("YYYY-MM-DD")
        },
        isShortcut: true
      },
      {
        id: this.DATE_FILTER.LAST_90_DAYS,
        title: "global.form.timeInterval.lastNinetyDays",
        dates: {
          firstDate: moment().subtract(90, "days").format("YYYY-MM-DD"),
          lastDate: moment().format("YYYY-MM-DD")
        },
        isQuick: true,
        isShortcut: true
      },
      {
        id: this.DATE_FILTER.CUSTOM,
        title: "global.form.custom",
        dates: {
          firstDate: moment().format("YYYY-MM-DD"),
          lastDate: moment().format("YYYY-MM-DD")
        },
        isQuick: true,
        isShortcut: true
      }
    ];

    this.filterTypesLenght = this.filterTypes.length;

    this.selectedButton = this.DATE_FILTER.LAST_90_DAYS;
    this.selectedLink = this.DATE_FILTER.LAST_90_DAYS;

    this.shortcutHistory = [this.selectedLink];
  }

  ngOnInit() {
    const self = this;
    const userSession = window['getUserSession']();
    let language = userSession && userSession.language ? userSession.language : Constants.defaultLanguage;
    language = this.utilsService.getShortLanguageKey(language);

    this.shortcutList = document.querySelector('#filters-shortcuts');

    if (this.initialSelectedButton) {
      this.selectedButton = this.initialSelectedButton;
      this.selectedLink = this.initialSelectedButton;
    }

    this.inst = mobiscroll.range('#date-range-filter-calendar', {
      theme: 'dialenga',
      display: 'bubble',
      anchor: this.hideShortcuts ? '#' + this.anchor : '#custom',
      cssClass: this.dateRangeId ? 'date-range-filter__calendar ' + this.dateRangeId : 'date-range-filter__calendar',
      showOverlay: false,
      showOnTap: false,
      scrollLock: false,
      layout: 'fixed',
      showSelector: true,
      max: moment().format('YYYY-MM-DD'),
      months: 2,
      returnFormat: 'dd/mm/yyyy',
      lang: language,
      firstDay: 1,
      fromText: this.translate.instant('timeoff.exportCsv.modal.from'),
      toText: this.translate.instant('timeoff.exportCsv.modal.to'),
      defaultValue: [moment().subtract(90, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      buttons: ['cancel', 'set'],
      setText: this.isDownload ? this.translate.instant('global.modal.download') : this.translate.instant('global.modal.accept'),
      onShow: function (event, inst): void {
        self.shortcutsColumn();
      },
      onDayChange: function (event, inst): void {
        self.selectedLink = self.filterTypesLenght - 1;
        self.selectedButton = self.selectedLink;
        self.shortcutHistory.push(self.selectedLink);
      },
      onSet: function (event, inst): void {
        const range = inst.getVal();
        if (!range || range.length !== 2) { return; }

        const startDate = moment(range[0], 'DD/MM/YYYY', true).format('YYYY-MM-DD');
        const endDate = moment(range[1], 'DD/MM/YYYY', true).format('YYYY-MM-DD');

        self.onDateSet.emit({
          startDate: startDate,
          endDate: endDate
        });

        self.shortcutHistory = [self.selectedLink];

        if (self.filterTypes[self.selectedLink].isQuick) {
          self.selectedButton = self.selectedLink;
        }
      },
      onCancel: function (event, inst): void {
        self.selectedLink = self.shortcutHistory[0];
        if (self.selectedLink % 2 === 0) {
          self.selectedButton = self.filterTypesLenght - 1;
        } else {
          self.selectedButton = self.selectedLink;
        }

        self.shortcutHistory = [self.selectedLink];

        inst.setVal([self.filterTypes[self.selectedLink].dates.firstDate, self.filterTypes[self.selectedLink].dates.lastDate]);

        self.onCancel.emit({cancel: true});
      }
    });

    if (this.hideShortcuts) {
      this.filterTypes.map((filter) => {
        return filter.isQuick = false;
      });

      this.getCustomDates();
    }

    if (this.monthlyFilters) {
      const responsiveMonthlyFilter = () => {
        this.filterTypes.forEach(filter => {
          if (filter.id === this.DATE_FILTER.LAST_90_DAYS) {
            filter.isQuick = window.innerWidth >= 1300;
          }
        });
      };

      this.filterTypes.forEach(filter => {
        if ([this.DATE_FILTER.LAST_30_DAYS, this.DATE_FILTER.LAST_60_DAYS, this.DATE_FILTER.LAST_90_DAYS, this.DATE_FILTER.CUSTOM].includes(filter.id)) {
            filter.isQuick = true;
        } else {
          filter.isQuick = false;
        }
      });

      responsiveMonthlyFilter();      
  
      window.addEventListener('resize', responsiveMonthlyFilter);
    }
     
  }

  setDates(_id: number, _firstDate: string, _lastDate: string, _isShortcut: boolean = false): void {
    this.inst.setVal([_firstDate, _lastDate]);
    this.selectedButton = _id;
    this.selectedLink = _id;

    if (_isShortcut === false) {
      this.shortcutHistory = [this.selectedButton];

      this.onDateSet.emit({
        startDate: _firstDate,
        endDate: _lastDate
      });

    } else {
      this.selectedLink = _id;

      const nonQuickFilterIds = this.filterTypes
        .filter(filter => !filter.hasOwnProperty('isQuick') || filter.isQuick === false)
        .map(filter => filter.id);

      if (nonQuickFilterIds.includes(_id)) {
        this.selectedButton = this.filterTypesLenght - 1;
      }

      this.shortcutHistory.push(this.selectedLink);
    }
  }

  getCustomDates(): void {
    this.selectedButton = this.DATE_FILTER.CUSTOM;
    this.inst.show();
  }

  shortcutsColumn(): void {
    this.calendarPopup = document.querySelector('.date-range-filter__calendar .mbsc-fr-popup');
    this.top = getComputedStyle(this.calendarPopup).top;

    this.shortcutList.remove();
    this.calendarPopup.appendChild(this.shortcutList);
    this.calendarPopup.style.top = parseInt(this.top, 10) + 6 + "px";
    this.shortcutList.style.display = 'flex';
  }
}
