<div class="group-form group-form--label-220">
  <div class="group-form__label">
    <span class="group-form__label__text" [translate]="'model.message.relatedlink'"></span>
  </div>
  <div class="group-form__data">
    <toggle-input id="toggle-related-link" [inputId]="'relatedLinkEnabled'" [(inputModel)]="isRelatedLinkEnabled"
      (inputChanged)="relatedLinkValueChanged()"
    (checkboxDeactivationFunction)="confirmRelatedLinkCheckboxDeactivation()"></toggle-input>
  </div>
</div>
@if (isRelatedLinkEnabled) {
  <div>
    <div class="group-form group-form--label-220">
      <div class="group-form__label group-form__label--end flex direction-column align-items-end">
        <span class="group-form__label__text margin-right-5" [translate]="'model.message.relatedlinkTitle'"></span>
        @if (selectedLanguage) {
          <span class="group-form__label__text group-form__label__text--sm"
          [translate]="'global.languageBrackets.' + selectedLanguage"></span>
        }
      </div>
      <div class="group-form__data">
        <input class="input width-100-percent" [(ngModel)]="message.contents[selectedLanguage].moreInfoLabel"
          id="messageURLTitle" (ngModelChange)="modelValueChanged()" maxlength="64"
          placeholder="{{'message.preview.moreInfo' | translate}}" />
        </div>
      </div>
      <div class="group-form group-form--label-220">
        <div class="group-form__label group-form__label--end flex direction-column align-items-end">
          <span class="group-form__label__text margin-right-5" [translate]="'model.message.relatedLinkURL'"></span>
          @if (selectedLanguage) {
            <span class="group-form__label__text group-form__label__text--sm"
            [translate]="'global.languageBrackets.' + selectedLanguage"></span>
          }
        </div>
        <div class="group-form__data group-form__data--inline flex">
          <input class="input  width-100-percent" [(ngModel)]="message.contents[selectedLanguage].externalURL"
            id="messageURL" (ngModelChange)="modelValueChanged()" maxlength="256" placeholder="URL" />
            <div class="flex align-items-center">
              <button class="button button--only-icon button--link" id="test-url-button"
                [disabled]="!message.contents[selectedLanguage].externalURL || !isValidUrl" (click)="showUrlLink()">
                <dialenga-tooltip classes="button__icon button__icon--lg icon icon-external-link" [tooltipText]="testButtonTooltip"></dialenga-tooltip>
              </button>
            </div>
          </div>
        </div>
      </div>
    }