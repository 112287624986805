import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { ImageBankComponent } from '../../components/image-bank/image-bank.component';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';
import { InternalLinkModalComponent } from '../components/internal-link/internal-link-modal.component';

@Injectable({
    providedIn: 'root'
})

export class DialogService {

    constructor( public dialogView: MatDialog, private imageBankModal: MatDialog, private translate: TranslateService) { }

    openConfirmationDialog(dialogValues: DialogValueOptions, settings?: any): any {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '40px'};
        dialogSettings.panelClass = ['animated-500','slideInDown'];

        dialogSettings.data = {
            title: dialogValues.title,
            message: dialogValues.message,
            messageParam: dialogValues.messageParam,
            acceptButtonText: dialogValues.acceptButtonText || 'global.modal.accept',
            cancelButtonText: dialogValues.cancelButtonText || 'global.modal.cancel',
            acceptButtonTextClass: dialogValues.acceptButtonTextClass || 'button--secondary',
            hideCancelButton: dialogValues.hideCancelButton || false,
            skipModalAlias: dialogValues.skipModalAlias
        };

        return this.dialogView.open(ConfirmationDialogComponent, dialogSettings).afterClosed();
    }

    /**
     * Open the image bank component in a new modal. The parameters are sent in the "data" object of the modal window.
     * @param {Object} cropOptions Constant that define the Cropper options
     * @param {boolean} roundedCanvas Define if the Cropper canvas is normal or rounded.
     * @param {boolean} gifsAllowed Indicates if the component allows search for gifs. If it's false, the buttons for image or gif selection are not shown
     * @param {boolean} picsAllowed Indicates if the component allows search for pictures. If it's false, the buttons for image or gif selection are not shown
     * @param {boolean} uploadAllowed Indicates if the component allows upload image file. If true, show buttons for upload or search. Only used in the text editor, where there is no upload button 
     * @param {boolean} insertInEditor Indicates where will insert the image. If true, the image is sent to backend first for obtain the url and return it. Else, a src image has returned
     * @returns An object, with values for image cropped, gif url, picture url, etc
     */
    openImageBank(cropOptions: any, roundedCanvas: boolean, gifsAllowed: boolean, picsAllowed: boolean, uploadAllowed: boolean, insertInEditor: boolean): any {
        const modalSettings = new MatDialogConfig();
            modalSettings.data = {
              title: this.translate.instant('imageBank.modal.title'),
              acceptButtonText: 'global.modal.accept',
              cancelButtonText: 'global.modal.cancel',
              cropOptions: cropOptions,
              roundedCanvas: roundedCanvas,
              gifsAllowed: gifsAllowed,
              picsAllowed: picsAllowed,
              uploadAllowed: uploadAllowed,
              insertInEditor: insertInEditor || false
            };
            modalSettings.width = '1200px';
            modalSettings.height = '85vh';
            const imageBankModalRef = this.imageBankModal.open(ImageBankComponent, modalSettings).afterClosed();
            return from(imageBankModalRef);
    }

    shouldModalBeDisplayed(skipModalAlias: string) {
        let skipModalValue = localStorage.getItem('skipModal');
        let parsedSkipModalValue = !!skipModalValue ? JSON.parse(skipModalValue) : false;
        return !parsedSkipModalValue || parsedSkipModalValue[skipModalAlias] !== true;
    };

    openInternalLinkModalSelection(): any {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.width = '560px';
        dialogSettings.maxHeight = '95vh';
        dialogSettings.position = { top: '6vh'};
        dialogSettings.panelClass = ['animated','slideInDown','scroll7'];

        return this.dialogView.open(InternalLinkModalComponent, dialogSettings).afterClosed()
    }

}

export interface DialogValueOptions {
    title: string;
    message: string;
    messageParam?: {};
    acceptButtonText?: string;
    cancelButtonText?: string;
    acceptButtonTextClass?: string;
    hideCancelButton?: boolean;
    skipModalAlias?: string;
}

