@if (isMassiveBatchView) {
  <div class="main-container__header">
    <div class="flex justify-space-between align-items-center width-100-percent">
      <div class="flex align-items-center">
        <loading-button [buttonId]="'remote-signature-creation-back-button'"
          [classes]="'button button--small button--only-icon'" [iconClass]="'icon-back'"
          (action)="navigateTo('/documents/remote-signature')" [isValid]="true">
        </loading-button>
        <h3 class="margin-left-15 font-size-22 min-width-fit-content"
          [textContent]="'remoteSignature.detail.massiveBatchTitle' | translate: { kind: massiveBatch.fileKind?.name}">
        </h3>
        @if (massiveBatch.cancellationState == SIGNATURE_MASSIVE_BATCH_STATES.CANCELLED) {
          <div class="margin-left-20 flex align-items-center signature-state signature-state--cancelled">
            <span class="signature-state__label"
              [textContent]="'remoteSignature.batch.stateLabel.cancelled' | translate"></span>
          </div>
        }
      </div>
      <div class="flex align-items-center" kendoTooltip filter="button" position="top" offset="5">
        @if (massiveBatch.cancellable) {
          <button id="cancel-batch" class="button button--cancel button--small" (click)="cancelMassiveBatch()"
            [title]="'remoteSignature.tooltips.cancelMassiveBatch' | translate">
            <span class="button__icon icon icon-18 icon-clear-fill-s"></span>
            <span class="button__label" [textContent]="'global.form.cancel' | translate"></span>
          </button>
        }
        @if (massiveBatch.cancellationState === SIGNATURE_MASSIVE_BATCH_STATES.CANCELLING) {
          <button class="button button--cancel button--small disabled-light" (click)="true" disabled>
            <spinner-dialenga></spinner-dialenga>
            <span class="button__label"
              [textContent]="('remoteSignature.batch.stateLabel.cancelling' | translate) + ' ...'"></span>
          </button>
        }
      </div>
    </div>
  </div>
}
<div class="main-container__content">
  <div class="main-container--full-width">
    <!-- Remote signature notice -->
    @if (!isSignatureProductionMode) {
      <div class="flex direction-column align-items-center" style="min-width: 891px;">
        <div class="flex direction-row justify-space-between width-75-percent margin-top-20"
          style="padding: 10px 40px; background-color: #E7E08C; border-radius: 4px; max-width: 1024px;">
          <div class="flex direction-column width-80-percent">
            <span class="bold" [innerHTML]="'remoteSignature.advice.title' | translate"></span>
            <span class="font-small" [innerHTML]="'remoteSignature.advice.line1' | translate"></span>
            <span class="font-small" [innerHTML]="'remoteSignature.advice.line2' | translate"></span>
          </div>
          <div class="flex align-items-center">
            <button class="button button--small" type="button" (click)="showNewHireInfo()">
              <span class="button__label" [translate]="'activeFeatures.signature.howHiring'"></span>
            </button>
          </div>
        </div>
      </div>
    }
    <!-- Summary batchs and filters state -->
    <div class="flex justify-space-between" [ngClass]="{'margin-bottom-20': isMassiveBatchView}">
      <div class="batch-summary-container" style="min-width: 891px;" kendoTooltip filter="div.batch-summary-tag"
        position="top" offset="10">
        @for (stateBatch of filterStateBatchOptions; track stateBatch) {
          <div class="batch-summary-tag"
            [ngClass]="{'selected': stateBatch.state === datagridFilteringOptions.state && !datagridFilteringOptions.hasRejectedFiles}"
            (click)="onSelectStateBatchFilter(stateBatch, false)"
            [title]="stateBatch.state === SIGNATURE_BATCH_STATES.FINISHED ? ('remoteSignature.batch.stateLabel.completedTooltip' | translate) : ''">
            <span class="batch-summary-tag__label" [translate]="stateBatch.text"></span>
            <span class="batch-summary-tag__counter" [textContent]="stateBatch?.value"></span>
          </div>
        }

        <div class="batch-summary-tag--spacer"></div>

        <div class="batch-summary-tag" [ngClass]="{'selected': datagridFilteringOptions.hasRejectedFiles}"
          (click)="onSelectStateBatchFilter(filterStateBatchRejected, true)">
          <span class="batch-summary-tag__label" [translate]="filterStateBatchRejected.text"></span>
          <span class="batch-summary-tag__counter batch-summary-tag__counter--rejected"
            [textContent]="filterStateBatchRejected?.value"></span>
        </div>
      </div>
      <!-- Input search by keyword -->
      @if (isMassiveBatchView) {
        <div class="search-filter-container justify-self-end">
          <div class="search-filter-container align-items-center margin-bottom-5">
            <input type="text" class="input input--search" maxlength="1024"
              [placeholder]="'documents.list.filters.searchPlaceholder' | translate"
              [(ngModel)]="datagridFilteringOptions.name" (input)="signatureBatchsSearchInput()"
              ngModelOptions="{ debounce: 200 }"
              [ngClass]="datagridFilteringOptions.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'">
            <div class="icon icon-remove" (click)="clearSearchInput()"></div>
          </div>
        </div>
      }
    </div>
    <!-- Batchs filters -->
    @if (!isMassiveBatchView) {
      <div class="flex justify-space-between filter-container">
        <!-- Shipping date filter and batch kind (single or massive)-->
        <div class="flex align-items-center">
          <date-range-filter [monthlyFilters]="true" [initialSelectedButton]="DATE_FILTER.LAST_60_DAYS"
            (onDateSet)="onDateSet($event)"></date-range-filter>
          <kendo-dropdownlist class="margin-left-20 kendo-angular-dropdown min-width-fit-content"
            [data]="batchKindOptions" [(ngModel)]="datagridFilteringOptions.batchKind" valuePrimitive="true"
            [textField]="'name'" valueField="value" (valueChange)="onSelectFilters()">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span
                [textContent]="'remoteSignature.batch.batchKindFilterLabel.selectedOption' | translate: {option: dataItem?.name}"></span>
            </ng-template>
          </kendo-dropdownlist>
        </div>
        <!-- Input search by keyword -->
        <div class="search-filter-container justify-self-end">
          <div class="search-filter-container align-items-center margin-bottom-5">
            <input type="text" class="input input--search" maxlength="1024"
              [placeholder]="'documents.list.filters.searchPlaceholder' | translate"
              [(ngModel)]="datagridFilteringOptions.name" (input)="signatureBatchsSearchInput()"
              ngModelOptions="{ debounce: 200 }"
              [ngClass]="datagridFilteringOptions.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'">
            <div class="icon icon-remove" (click)="clearSearchInput()"></div>
          </div>
        </div>
      </div>
    }

    <!-- Master grid for remote signature batchs -->
    @if (signatureBatchsDatagrid?.data.length > 0) {
      <kendo-grid #masterDocumentsGrid [data]="signatureBatchsDatagrid" class="master-grid"
        (cellClick)="onCellClick($event)" [pageable]="gridPagerSettings" (pageChange)="onPageChange($event)"
        [skip]="datagridPagingOptions.skip" [pageSize]="datagridPagingOptions.size" [navigable]="true" [resizable]="false"
        [selectable]="false" [rowClass]="rowCallback" [loading]="isSyncing" (detailExpand)="onExpandOrCollapse($event)"
        (detailCollapse)="onExpandOrCollapse($event)">
        <kendo-grid-messages [detailExpand]="'Mostrar detalle'" [detailCollapse]="'Ocultar detalle'"
          [pagerItemsPerPage]="'items por página'" [pagerOf]="'de'" [pagerItems]="'ítems'"></kendo-grid-messages>
        <!-- Employee data -->
        <kendo-grid-column field="employee" [title]="'remoteSignature.grid.employee' | translate" width="330">
          <ng-template kendoGridCellTemplate let-dataItem>
            @if (!dataItem.massiveBatch) {
              <div class="flex align-items-center">
                <avatar [name]="dataItem.employee.name" [surname]="dataItem.employee.surname" [small]="true"
                  [thumbnails]="{thumbnail_M: dataItem.employee.avatarThumbnailMUrl || dataItem.employee.avatarUrl, thumbnail_S: dataItem.employee.avatarThumbnailSUrl || dataItem.employee.avatarThumbnailMUrl}">
                </avatar>
                <span class="margin-left-10" [textContent]="dataItem.employee.name"></span>
                <span class="margin-left-5" [textContent]="dataItem.employee.surname"></span>
              </div>
            } @else {
              <div class="conflicts-avatar-list--avatar-small margin-right-10" kendoTooltip filter="div.avatar"
                position="top">
                @for (employee of dataItem.employees | slice:0:4; track employee; let i = $index) {
                  <avatar [name]="employee.name" [surname]="employee.surname" [title]="employee.name + ' ' + employee.surname"
                    [small]="true"
                    [thumbnails]="{thumbnail_M: employee.avatarThumbnailMUrl || employee.avatarUrl, thumbnail_S: employee.avatarThumbnailSUrl || employee.avatarThumbnailMUrl}">
                  </avatar>
                }
                @if (dataItem.documentsCount > 0) {
                  <span class="margin-left-5 bold"
                    [textContent]="'remoteSignature.totalUsers' | translate: {total: dataItem.documentsCount}"></span>
                }
              </div>
            }
          </ng-template>
        </kendo-grid-column>
        <!-- Documents number -->
        <kendo-grid-column field="documents" title=" ">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="flex align-items-center" (click)="showDetailRow(rowIndex, dataItem.massiveBatch)"
              style="cursor: pointer;">
              <span class="icon icon-24 icon-multidocument black-45"></span>
              @if (dataItem.documentsCount > 0) {
                <span class="margin-left-5 bold truncate"
                  [textContent]="'remoteSignature.totalDocuments' | translate: {total: dataItem.documentsCount}"></span>
                }
              @if (dataItem.documentsCount == 0) {
                <span class="margin-left-5 bold truncate" [translate]="'remoteSignature.noDocuments'"></span>
                }
              @if (dataItem.massiveBatch) {
                <span class="icon margin-right-10 margin-left-10"
                  [ngStyle]="{'color': !!dataItem.fileKind?.id && getDocumentTagHexColor(dataItem.fileKind.color)}"
                  [ngClass]="!!dataItem.fileKind?.id ? 'icon-tag-fill-s' : 'icon-tag-s black-45'"></span>
                <span class="truncate"
                  [textContent]="!!dataItem.fileKind?.id ? dataItem.fileKind.name : 'documents.documentsKind.filterTagNames.other' | translate"></span>
              }
            </div>
          </ng-template>
        </kendo-grid-column>
        <!-- Shipment progress label -->
        <kendo-grid-column [title]="'remoteSignature.grid.progress' | translate" width="180"
          [style]="{'padding-left': '5px'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            @if (!dataItem.massiveBatch) {
              <div class="flex align-items-center">
                <div class="flex align-items-center signature-state" [ngClass]="getBatchStateColor(dataItem.state)"
                  kendoTooltip [tooltipTemplate]="tooltipDates" filter="span" position="top">
                  <span class="signature-state__label" [textContent]="getBatchStateTextLabel(dataItem.state)"></span>
                </div>
                <!-- With warnings -->
                @if (dataItem.withWarnings) {
                <div class="flex margin-left-20" kendoTooltip filter="span" position="top">
                  <span class="icon icon-warning-square-fill icon-20 danger-soft-color"
                    [title]="'remoteSignature.warningTooltip.rejectedDoc' | translate: {count: dataItem.rejectedCount}"></span>
                </div>
                }
              </div>
            }
            @if (dataItem.massiveBatch) {
              <div class="flex align-items-center">
                <div class="flex align-items-center signature-state"
                  [ngClass]="{'signature-state--cancelled': dataItem.cancellationState == SIGNATURE_MASSIVE_BATCH_STATES.CANCELLED, 'signature-state--cancelling': dataItem.cancellationState == SIGNATURE_MASSIVE_BATCH_STATES.CANCELLING}">
                  @if (dataItem.cancellationState == SIGNATURE_MASSIVE_BATCH_STATES.CANCELLING) {
                    <span class="signature-state__label"
                      [textContent]="'remoteSignature.batch.stateLabel.cancelling' | translate"></span>
                  }
                  @if (dataItem.cancellationState == SIGNATURE_MASSIVE_BATCH_STATES.CANCELLED) {
                    <span class="signature-state__label"
                      [textContent]="'remoteSignature.batch.stateLabel.cancelled' | translate"></span>
                  }
                </div>
                <!-- Cancel massive batch in progress -->
                @if (dataItem.massiveBatch && dataItem.cancellationState == SIGNATURE_MASSIVE_BATCH_STATES.CANCELLING) {
                  <div class="flex margin-left-20" kendoTooltip filter="spinner-dialenga" position="top">
                    <spinner-dialenga [title]="'remoteSignature.batch.cancelInProgress' | translate"></spinner-dialenga>
                  </div>
                }
              </div>
            }
          </ng-template>
        </kendo-grid-column>
        <!-- Shipment settings -->
        <kendo-grid-column title="" width="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="flex align-items-center" kendoTooltip filter="span" position="top" offset="10">
              @if (dataItem.expiration?.expirationEnabled) {
                <span class="icon icon-timer-s black45 margin-right-20"
                  [title]="('remoteSignature.batchExpirationOn' | translate:{expiration: (dataItem.expiration.expirationDate | date: 'dd/MM/yyyy')})"></span>
              }
              @if (dataItem.reminder?.reminderEnabled) {
                <span class="icon"
                  [ngClass]="dataItem.reminderSent ? 'icon-reminder-tick-s approved-color' : 'icon-reminder-s black45'"
                  [title]="dataItem.reminderSent ? ('remoteSignature.reminderSentOn' | translate:{date: (dataItem.reminder.reminderDate| date: 'dd/MM/yyyy')}) : ('remoteSignature.reminderOn' | translate:{amount: dataItem.reminder.reminderAmount, date: (dataItem.reminder.reminderDate| date: 'dd/MM/yyyy')})"></span>
              }
            </div>
          </ng-template>
        </kendo-grid-column>
        <!-- CTA buttons column -->
        <kendo-grid-command-column title="" width="150">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div class="td-on-hover flex justify-content-end" kendoTooltip filter="a" position="top">
              @if (!dataItem.massiveBatch) {
                <a class="k-button k-master-button" (click)="detailRemoteSignatureBatch(dataItem)"
                  [title]="'documents.history.showDetailTooltip' | translate">
                  <span class="icon icon-eye-opened"></span>
                </a>
                <!-- Remove when delete option has been added -->
                @if ((!dataItem.allDocumentsDisabledByFileKind && !dataItem.employee.confidentialProfile) ||
                userLoggedHasAdminRole) {
                  <a class="k-button k-master-button"
                    (click)="infoRowData = {dataItem: dataItem}; onSelectSubmenuOption({ action: 'download' })"
                    [title]="'global.modal.download' | translate">
                    <span class="icon icon-download"></span>
                  </a>
                }
              }
              @if (dataItem.massiveBatch) {
                <a class="k-button k-master-button" (click)="detailRemoteSignatureBatch(dataItem)"
                  [title]="'documents.history.showDetailMassiveBatchTooltip' | translate">
                  <span class="icon icon-list"></span>
                </a>
              }
            </div>
          </ng-template>
        </kendo-grid-command-column>
        <!-- Grid details template -->
        <div *kendoGridDetailTemplate="let dataItem" class="width-100-percent">
          @if (dataItem.documents.length > 0) {
            <kendo-grid #detailDocumentsGrid [data]="dataItem.documents" class="master-grid master-grid--details-grid"
              scrollable="none">
              <!-- Padding column -->
              <kendo-grid-column title=" " width="370"></kendo-grid-column>
              <!-- Icon and document name -->
              <kendo-grid-column title=" ">
                <ng-template kendoGridCellTemplate let-document>
                  <div class="flex align-items-center" kendoTooltip filter="div" position="top">
                    <div class="batch-document-icon"
                      [ngStyle]="{'background-color': getDocumentTagHexColor(document.fileKind?.color)}"
                      [ngClass]="{'batch-document-icon--empty': !document.fileKind}"
                      [title]="document.fileKind ? document.fileKind.name : 'documents.documentsKind.filterTagNames.other' | translate">
                      <span class="icon icon-24 icon-doc-signed" [ngClass]="{'white-color': document.fileKind}"></span>
                    </div>
                    <span class="text-sm black-85 truncate margin-left-10"
                      [textContent]="document.resource.nameWithExtension"></span>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <!-- Document state label -->
              <kendo-grid-column title=" " width="270">
                <ng-template kendoGridCellTemplate let-document>
                  <div class="flex align-items-center" kendoTooltip filter="span" position="top">
                    <div class="document-state" [ngClass]="getDocumentStateBgColor(document.state)">
                      <span class="document-state__text" [textContent]="getDocumentLabelText(document.state)"></span>
                    </div>
                    @if (document.state == DOCUMENT_STATES.REJECTED) {
                      <span class="icon icon-16 icon-comment-fill margin-left-10 advice-color"
                        [title]="document.rejectionReason? ('conversations.motiveLabel' | translate) + ' ' + document.rejectionReason : ''">
                      </span>
                    }
                  </div>
                </ng-template>
              </kendo-grid-column>
              <!-- CTA buttons -->
              <kendo-grid-command-column title=" " width="150">
                <ng-template kendoGridCellTemplate let-document>
                  @if ((!document.disabledByFileKind && !dataItem.employee.confidentialProfile) || userLoggedHasAdminRole) {
                    <div class="td-on-hover flex justify-content-end padding-right-10" kendoTooltip filter="a" position="top">
                      <a class="k-button k-detail-button" (click)="actionViewDocument(document)"
                        [title]="'global.form.view' | translate">
                        <span class="icon icon-eye-opened"></span>
                      </a>
                      <a class="k-button k-detail-button" (click)="actionDownloadDocument(document)"
                        [title]="'global.modal.download' | translate">
                        <span class="icon icon-download"></span>
                      </a>
                      <!-- <a *ngIf="document.state == DOCUMENT_STATES.UNSIGNED" class="k-button k-detail-button">
                      <span class="icon icon-delete"></span>
                    </a> -->
                      @if (document.state == DOCUMENT_STATES.SIGNED) {
                      <a class="k-button k-detail-button" [title]="'remoteSignature.evidenceReport' | translate"
                        (click)="actionDownloadEvidenceReport(document)">
                        <span class="icon icon-stamped-doc"></span>
                      </a>
                      }
                    </div>
                  }
                </ng-template>
              </kendo-grid-command-column>
            </kendo-grid>
          }
          @if (dataItem.documents.length === 0) {
            <div class="flex align-items-center" style="padding-left: 380px;">
              <span [textContent]="'remoteSignature.batch.noDocumentsResults' | translate"></span>
            </div>
          }
        </div>
        <!-- Custom Pager -->
        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
          <article class="dui-grid-pager">
            <kendo-pager-info></kendo-pager-info>
            @if (signatureBatchsDatagrid.total > 10) {
              <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
              </article>
              <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
            }
          </article>
        </ng-template>
      </kendo-grid>
    }

    <!-- Skeleton remote signature batchs grid -->
    @if (signatureBatchsDatagrid?.data.length == 0 && isSyncing) {
    <div>
      <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid" [rowClass]="skeletonRowCallback">
        <kendo-grid-column title="" width="25">
        </kendo-grid-column>
        <kendo-grid-column width="330">
          <ng-template kendoGridHeaderTemplate>
            <div class="flex align-items-center">
              <span [textContent]="'remoteSignature.grid.employee' | translate"></span>
            </div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="flex align-items-center">
              <kendo-skeleton shape="circle" animation="pulse" [width]="28" [height]="28"></kendo-skeleton>
              <kendo-skeleton shape="text" animation="pulse" [width]="250" [height]="20" style="border-radius: 4px"
                class="margin-left-10"></kendo-skeleton>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [headerStyle]="{'padding': '0.5em 0.6em'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="flex align-items-center">
              <kendo-skeleton shape="rectangle" animation="pulse" [width]="20" [height]="25" style="border-radius: 4px"
                class="margin-right-10"></kendo-skeleton>
              <kendo-skeleton shape="text" animation="pulse" [width]="250" [height]="20"
                style="border-radius: 4px"></kendo-skeleton>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="" width="60"></kendo-grid-column>
        <kendo-grid-column width="180" [style]="{'padding-left': '5px'}">
          <ng-template kendoGridHeaderTemplate>
            <div class="flex align-items-center">
              <span [textContent]="'remoteSignature.grid.progress' | translate"></span>
            </div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-skeleton shape="text" animation="pulse" [width]="150" [height]="20"
              style="border-radius: 4px"></kendo-skeleton>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="" width="100"></kendo-grid-column>
        <kendo-grid-command-column title="" width="150"></kendo-grid-command-column>
      </kendo-grid>
    </div>
    }

    @if (signatureBatchsDatagrid?.data.length == 0 && !datagridFilteringOptions.name && !isSyncing) {
      <no-results [imageTitle]="'remoteSignature.noResults.title'"></no-results>
    }

    @if (signatureBatchsDatagrid?.data.length == 0 && datagridFilteringOptions.name && !isSyncing) {
      <no-search-results></no-search-results>
    }
    
  </div>
</div>