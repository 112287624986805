import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-naming-convention-modal',
    templateUrl: './naming-convention-modal.component.html',
})
export class NamingConventionModalComponent {
    constructor(
        public dialogRef: MatDialogRef<NamingConventionModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    cancel() {
        this.dialogRef.close();
    }
}