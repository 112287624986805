import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { Constants } from '../../constants';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { ChatroomAdminsModalComponent } from '../chatroom/chatroom-admins-modal.component';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { CompaniesService } from '../companies/companies.service';

@Component({
    selector: 'company',
    templateUrl: './company.component.html'
})
export class CompanyComponent implements OnInit {

    public COMPANY_TABS: any;
    
    public selectedTab: number;
    private _route: any;
    private companyId: number;
    
    public companyForm: any = {};
    @ViewChild('companyName') companyNameRef: ElementRef;

    constructor (
        private location: Location, private upgrade: UpgradeModule, private titleService: Title, private companiesService: CompaniesService, 
        private translate: TranslateService, public dialogService: DialogService, public formUtilsService: FormUtilsService, private Dialog: MatDialog,
        private notificationService: DuiNotificationsService

    ) { 
        this.COMPANY_TABS = Constants.COMPANY_TABS;
        this._route = this.upgrade.$injector.get('$route');
        this.companyId = this._route.current.params.id;

        this.selectedTab = this._route.current.locals.selectedTab;
    }

    ngOnInit() {
        this.companiesService.findOne(this.companyId).subscribe(
            (response: any) => {
                this.companyForm = response;
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    private checkCompanyNameAndSave(newName: string) :void {
        if(!newName) {
            this.companyNameRef.nativeElement.textContent = this.companyForm.name;
            return;
        } else if( newName !== this.companyForm.name) {
            this.companyForm.name = this.companyNameRef.nativeElement.textContent;
            this.companyFormSave();
        }
    }

    private companyFormSave() :void {
        this.companiesService.update(this.companyId, this.companyForm).subscribe(
            (response: any) => {
                this.companyForm = response;

                const notificationOptions: NotificationOptions = {
                    kind: 'success',
                    message: this.translate.instant('global.messages.changesUpdated.success')
                };
                this.notificationService.showNotification(notificationOptions);
            },
            (respError: any) => {
                
            }
        );
    }

    onInputCompanyNameEvent(event: any) {
        if(event.type === 'keydown') {
            event.preventDefault();
            if (event.code === 'Escape') {
                this.companyNameRef.nativeElement.textContent = this.companyForm.name;
            }
            this.companyNameRef.nativeElement.blur();
        }
        this.checkCompanyNameAndSave(this.companyNameRef.nativeElement.textContent);

    }
    
    onInputCompanyNamePaste(event: any) :void {
        event.preventDefault();

        var selectedText = window.getSelection().toString();
        if (selectedText) {
            this.companyNameRef.nativeElement.textContent = this.companyNameRef.nativeElement.textContent.replace(selectedText, event.clipboardData.getData('text'));
        } else {
            this.companyNameRef.nativeElement.textContent += event.clipboardData.getData('text');
        }
        this.checkCompanyNameAndSave(this.companyNameRef.nativeElement.textContent);
    }

    navigateTo(url: string): void {
        this.location.go(url);
    }

    loadTabContent( tab: number): void {
        if (this.selectedTab !== tab) {
            this.selectedTab = tab;
            if (tab === this.COMPANY_TABS.GENERAL) {
                this.titleService.setTitle(this.translate.instant('company.title'));
                this._route.updateParams({ tabName: 'general' });
            } else if (tab === this.COMPANY_TABS.USERS) {
                this._route.updateParams({ tabName: 'users'});
            }
        }
    }

    archiveOrDelete(): void {
        // const dialogValues: DialogValueOptions = {
        //     title: 'chatrooms.modal.delete.title',
        //     message: 'chatrooms.modal.delete.message',
        //     messageParam: { chatroom: this.companyForm.name }
        // };
        // this.dialogService.openConfirmationDialog(dialogValues).subscribe(result => {
        //     if(result?.accept) {
        //         this.companiesService.deleteChat(this.companyForm.id).subscribe(
        //             (onSuccess) => {
        //                 const notificationOptions: NotificationOptions = {
        //                     kind: 'success',
        //                     message: this.translate.instant('chatrooms.modal.delete.success', { chatroom: this.companyForm.name })
        //                 }
        //                 this.notificationService.showNotification(notificationOptions);
        //                 // Go back to chatrooms list
        //                 this.navigateTo('/settings/chatrooms');
        //             },
        //             (onError) => {
        //                 const notificationOptions: NotificationOptions = {
        //                     kind: 'error',
        //                     message: this.translate.instant('global.messages.error.unknown')
        //                 }
        //                 this.notificationService.showNotification(notificationOptions);
        //             }
        //         )
        //     }
        // });
    }

    openAddUsersModal(): void {
        // get all the employees from company for pre-charge modal
        this.companiesService.findAllEmployees(this.companyId, {page: 0, size: 20000}, null).subscribe(
            (response) => {
                this.formUtilsService.finishSubmitAction();

                const dialogSettings = new MatDialogConfig();
                dialogSettings.data = {
                    title: this.translate.instant('company.employees.modal.title'),
                    users: response.content
                    };
                dialogSettings.width = '65vw';
                dialogSettings.position = { top: '4vh'};
                dialogSettings.panelClass = ['animated','slideInDown'];

                this.Dialog.open(ChatroomAdminsModalComponent, dialogSettings).afterClosed().subscribe(result => {
                    this.formUtilsService.finishSubmitAction();

                    if (result?.arePendingChanges && result?.addedUsers) {
                        this.companyForm.employees = result.addedUsers;

                        const notificationOptions: NotificationOptions = {
                            kind: 'success',
                            message: this.translate.instant('global.messages.changesUpdated.success')
                        };
                        this.notificationService.showNotification(notificationOptions);
                    }
                });
                },
            (error: any) => {
                // Show notification error
            }
        );
    }
}
