import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";
import { ContextMenuComponent } from "@progress/kendo-angular-menu";
import { TranslateService } from '@ngx-translate/core';
import { FileSaverService } from 'ngx-filesaver';
import { DocumentsService } from '../documents/documents.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DocumentPreviewModalComponent } from './document-preview-modal.component';
import { BehaviorSubject } from 'rxjs';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { UtilsService } from '../../shared/services/utils.service';

@Component({
    selector: 'documents-list',
    templateUrl: './documents-list.component.html',
    styleUrls: [ './_documents-list.component.scss' ]
})
export class DocumentsListComponent implements OnInit {

    public DOCUMENT_KIND_ALL = '';
    public DOCUMENT_KIND_EMPTY = -1;
    
    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [5,10,20,100],
    };
    public isSyncing: boolean = false;
    public documentsDatagrid: GridDataResult = {
        data: [],
        total: 0
    };
    
    public datagridPagingOptions: any = {
        page: 0,
        size: 10,
        skip: 0,
        sort: 'privateDocumentDate,desc'
    };
    public datagridFilteringOptions: any = {
        kind: this.DOCUMENT_KIND_ALL,
        name: '',
        year: '', 
        month: ''
    };
    public submenuAvailableOptions: any[] = [];
    public infoRowData: any;
    public dataSkeleton = [{},{},{}];

    public documentsKindOptions: any[] = [];

    public selectedRows: number = 0;
    public toggleAllItemsSelect: boolean = false;
    public confirmationDialogRef = new BehaviorSubject<any>({}).asObservable();

    public years = [
        { name: new Date().getFullYear().toString(), value: new Date().getFullYear(), selected: true, disabled: false},
        { name: (new Date().getFullYear()-1).toString(), value: (new Date().getFullYear()-1), selected: true, disabled: false},
        { name: (new Date().getFullYear()-2).toString(), value: (new Date().getFullYear()-2), selected: false, disabled: false},
        { name: (new Date().getFullYear()-3).toString(), value: (new Date().getFullYear()-3), selected: false, disabled: false},
        { name: (new Date().getFullYear()-4).toString(), value: (new Date().getFullYear()-4), selected: false, disabled: false},
    ];
    public selectedYears: any;
    public itemAllYears = {name: this.translate.instant('global.messages.stateFilters.all'), value: ''};

    public months: Array<any> = [];
    public itemAllMonths = {value: '', name: this.translate.instant('global.messages.stateFilters.all')};
    public selectedMonths: any;

    public adjustedColumnIndex: number;

    @ViewChild('documentsGrid') documentsGrid: GridComponent;
    @ViewChild('gridDropdownSubmenu') public gridContextMenu: ContextMenuComponent;

    constructor(
        private translate: TranslateService, private documentsService: DocumentsService, public dialogService: DialogService, 
        public documentViewModal: MatDialog, private fileSaverService: FileSaverService, private formUtilsService: FormUtilsService, 
        private duiNotificationService: DuiNotificationsService, private utilsService: UtilsService
    ){}

    ngOnInit() {
        this.updateColumnIndex(window.innerWidth);
        if (window['getLocalizedMonthsNames']) {
            this.months = window['getLocalizedMonthsNames']();
        }
        this.documentsService.getDocumentKinds().subscribe(
            (response) => {
                this.documentsKindOptions = response;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
        this.loadDocumentsGridData(this.datagridPagingOptions, this.datagridFilteringOptions);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.updateColumnIndex(event.target.innerWidth);
    }

    rowCallback(row: RowClassArgs) {
        return {
            selected: row.dataItem.submenuOpened
        };
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2 
        }
    }

    updateColumnIndex(innerWidth: number) {
        this.adjustedColumnIndex = innerWidth >= 1240 ? 5 : 4;
    }
    
    public onCellClick(e: any): void {
        if (e.columnIndex === this.adjustedColumnIndex) {
            const originalEvent = e.originalEvent;
            originalEvent.preventDefault();
            if (originalEvent.target.parentNode.id === 'menuMore') {
                this.infoRowData = {
                    dataItem: e.dataItem,
                    rowIndex: e.rowIndex
                }
                e.dataItem.submenuOpened = true;

                setTimeout(() :void =>  {
                    this.gridContextMenu.show({
                        left: originalEvent.pageX - 100,
                        top: originalEvent.pageY + 15,
                    });
                }, 100)
            }
        } else {
            this.selectRow(e.dataItem)
        }
    }

    getSubmenuOptions() {
        this.submenuAvailableOptions = [
            // { text: 'documents.list.actions.rename', icon: 'icon icon-rename', action: 'rename'},
            { text: 'global.modal.download', icon: 'icon-download', action: 'download'},
            { text: 'global.form.delete', icon: 'icon-delete', action: 'delete'}
        ];
        return this.submenuAvailableOptions;
    }

    onSubmenuClose() {
        let data: any = this.documentsGrid.data['data'];
        for (const item of data) {
            item.submenuOpened = false
        }
        this.submenuAvailableOptions = [];
    }

    onSelectSubmenuOption(optionSelected) {
        switch (optionSelected.action) {
            case 'rename':
                break;
            case 'download':
                this.documentsService.downloadDocument(this.infoRowData.dataItem.resource.url).subscribe(
                (response) => {
                    this.fileSaverService.save(response.body, this.infoRowData.dataItem.resource.nameWithExtension);
                },
                (error: any) => {

                });
                break;
            case 'delete':
                const dialogValues: DialogValueOptions = {
                    title: 'users.documents.modal.delete.title',
                    message: 'users.documents.modal.delete.message',
                };
                this.confirmationDialogRef = this.dialogService.openConfirmationDialog(dialogValues).subscribe(result => {
                    if(result?.accept) {
                        let deleteDocsIds: Array<[]> = [];
                        deleteDocsIds.push(this.infoRowData.dataItem.id);
                        this.deleteDocumentsByIds(deleteDocsIds);
                    }
                });
                break;
            default:
                break;
        }
    }

    loadDocumentsGridData(pagingOptions: any, filterOptions: any) {
        this.isSyncing = true;

        this.documentsService.findAll(pagingOptions, filterOptions).subscribe(
            (response) => {
                this.documentsDatagrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    deleteDocumentsByIds(deleteDocsIds: Array<[]>) {
        this.isSyncing = true;
        this.documentsService.deleteDocuments(deleteDocsIds).subscribe(
            (response) => {
                this.loadDocumentsGridData(this.datagridPagingOptions, this.datagridFilteringOptions);
                const notificationOptions: NotificationOptions = {
                    kind: 'success',
                    message: deleteDocsIds.length > 1 ? this.translate.instant('documents.notifications.batchDeleted') : this.translate.instant('documents.notifications.deleted')
                }
                this.duiNotificationService.showNotification(notificationOptions);
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    onPageChange(event: PageChangeEvent): void {
        this.datagridPagingOptions.page = (event.skip / this.datagridPagingOptions.size);
        this.datagridPagingOptions.skip = event.skip;
        this.datagridPagingOptions.size = event.take;

        this.loadDocumentsGridData(this.datagridPagingOptions, this.datagridFilteringOptions);
        this.toggleAllItemsSelect = false;
        this.selectedRows = 0;
    }

    getDocumentTagHexColor(color: any): string {
        return this.utilsService.decimalToHex(color);
    }

    allRowsToggleSelectedProperty(): void {
        for (const item of this.documentsGrid.data['data']) {
            item.selectedItem = !this.toggleAllItemsSelect;
        }
        this.updateRowsSelected()
    }

    deselectAllRows(): void {
        for (const item of this.documentsGrid.data['data']) {
            item.selectedItem = false;
        }
        this.updateRowsSelected()
    }
    
    selectRow(item: any): void {
        item.selectedItem = !item.selectedItem;
        this.updateRowsSelected()
    }

    updateRowsSelected(): void {
        let counterRows = [];
        counterRows = this.documentsGrid.data['data'].filter(item => {return item.selectedItem });
        this.selectedRows = counterRows.length;
    }

    actionViewDocument(dataItem: any) {
        // TO DO: Service for initialize basic dialog modal options
        dataItem.iconMimeType = this.iconFromMime(dataItem.resource.mimetype);
        const modalSettings = new MatDialogConfig();
        modalSettings.data = dataItem;
        modalSettings.width = '1200px';
        modalSettings.height = '90vh';

        const documentViewModalRef = this.documentViewModal.open(DocumentPreviewModalComponent, modalSettings);

        // documentViewModalRef.afterClosed().subscribe(result => {
        // });
    }

    relabelSelectedDocs(): void {
        // Make the process and show toast
        this.deselectAllRows();
        this.toggleAllItemsSelect = false;
    }

    downloadSelectedDocs(): void {
        // Make the process and show toast
        this.deselectAllRows();
        this.toggleAllItemsSelect = false;
    }

    deleteSelectedDocs(): void {
        const dialogValues: DialogValueOptions = {
            title: 'users.documents.modal.delete.title',
            message: 'users.documents.modal.delete.message'
        };
        this.confirmationDialogRef = this.dialogService.openConfirmationDialog(dialogValues).subscribe(result => {
            if(result?.accept) {
                let deleteDocsIds: Array<[]> = [];
                deleteDocsIds = this.documentsGrid.data['data']
                    .filter(item => { return item.selectedItem })
                    .map(item => { return item.id });

                this.deleteDocumentsByIds(deleteDocsIds);
                this.deselectAllRows();
                this.toggleAllItemsSelect = false;
            }
            this.formUtilsService.finishSubmitAction();
        });
    }

    iconFromMime(mimeType: string): string {
        return this.utilsService.getIconFromMimeType(mimeType);
    }

    listItemsSearch() {
        if (this.datagridFilteringOptions.name.length >= 3 || this.datagridFilteringOptions.name.length === 0) {
            this.loadDocumentsGridData(this.datagridPagingOptions, this.datagridFilteringOptions);
        }
    }

    clearSearchInput() {
        this.datagridFilteringOptions.name = '';
        this.loadDocumentsGridData(this.datagridPagingOptions, this.datagridFilteringOptions);
    }

    onSelectDocumentKindFilter(optionKind) :void {
        this.datagridFilteringOptions.kind = optionKind.id;
        this.datagridPagingOptions.page = 0;
        this.datagridPagingOptions.skip = 0;
        this.loadDocumentsGridData(this.datagridPagingOptions, this.datagridFilteringOptions);
    }

    onChangeYearFilter(yearSelected: any) {
        this.datagridFilteringOptions.year = yearSelected;
        this.datagridPagingOptions.page = 0;
        this.datagridPagingOptions.skip = 0;
        this.loadDocumentsGridData(this.datagridPagingOptions, this.datagridFilteringOptions);
    }

    onChangeMonthFilter(monthSelected: any) {
        this.datagridFilteringOptions.month = monthSelected;
        this.datagridPagingOptions.page = 0;
        this.datagridPagingOptions.skip = 0;
        this.loadDocumentsGridData(this.datagridPagingOptions, this.datagridFilteringOptions);
    }

    resetDateFilters() {
        this.selectedMonths = '';
        this.selectedYears = '';
        this.datagridFilteringOptions.month = this.itemAllMonths.value;
        this.datagridFilteringOptions.year = this.itemAllYears.value;
        this.datagridPagingOptions.page = 0;
        this.datagridPagingOptions.skip = 0;
        this.loadDocumentsGridData(this.datagridPagingOptions, this.datagridFilteringOptions);
    }
}
