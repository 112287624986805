import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../constants';

@Injectable({
    providedIn: 'root'
})
export class InternalLinkService {

    private apiUrl: string;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
    }

    findContents(searchText: string, contentKind: string) {
        const httpOptions = {
            params: {
                search: searchText
            },
            reportProgress: true
        };
        if (contentKind) { httpOptions.params['kind'] = contentKind }
        
        return this.httpClient.get<any>(`${this.apiUrl}/linkable-publications`, httpOptions);
    }

}