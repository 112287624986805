import { Component, Input, OnInit } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { Constants } from '../../../constants';

@Component({
    selector: 'show-rating-icon',
    templateUrl: './show-rating-icon.component.html'
})

export class ShowRatingIconComponent implements OnInit {

    public RATING_KIND: any;
    public ratingKind: number;
    public ratingValue: number;
    public starsClasses: Array<any>;

    private _rootScope: any;

    @Input() value: number;
    @Input() showTitle: boolean;

    constructor( private upgrade: UpgradeModule ) 

    { 
        this._rootScope = this.upgrade.$injector.get('$rootScope');
        this.ratingKind = this._rootScope.account.ratingKind;
        this.RATING_KIND = Constants.RATING_KIND;
    }

    ngOnInit(): void {
        this.ratingValue = Math.round(this.value);

        if (this._rootScope.account && this._rootScope.account.ratingKind === this.RATING_KIND.STAR) {
            var classes = [];
            for (var index = 1; index <= 5; index++) {
                if (this.ratingValue >= index) {
                    classes.push('icon icon-star-fill');
                } else {
                    classes.push('icon icon-star');
                }
            }
            this.starsClasses = classes;
        }
    }

}
