<div style="position: relative;" (mouseleave)="!onlyOnClick && hidePopover()">

  <div (mouseenter)="!onlyOnClick && showPopover()" (click)="onlyOnClick && togglePopover()">
    <ng-content select="[popoverItem]"></ng-content>
  </div>
  
  <div class="dui-popover" [class.dui-popover--active]="isActive">
    <ng-content></ng-content>
  </div>

</div>
