import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class WorkplacesService {

    private apiUrl: string;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
    }

    findAll(pagerOptions: any, filterOptions: any) {
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10,
                active: filterOptions.active
            },
            reportProgress: true
        };
        if (pagerOptions?.sort) { httpOptions.params['sort'] = pagerOptions.sort; }
        if (filterOptions?.name) { httpOptions.params['name'] = filterOptions.name; }
        
        return this.httpClient.get<any>(`${this.apiUrl}/headquarters`, httpOptions);
    }

    create(data: any) {
        return this.httpClient.post(`${this.apiUrl}/headquarters`, data);
    }

    delete(id: number) {
        return this.httpClient.delete<any>(`${this.apiUrl}/headquarters/${id}`);
    }

    archive(id: number) {
        return this.httpClient.put(`${this.apiUrl}/headquarters/${id}/archive`, null);
    }
    
    activate(id: number) {
        return this.httpClient.put(`${this.apiUrl}/headquarters/${id}/activate`, null);
    }
}