import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class RequestsService {

    private apiUrl: string;
    private requestsApiUrl: string;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
        this.requestsApiUrl = this.apiUrl + '/conversations';
    }

    private getPublicationAsFormData (publication: string, publicationAlias: string, resources: Array<any>): any {
        let fd = new FormData();
        let content = typeof publication !== 'string' ? JSON.stringify(publication) : publication;
        
        fd.append(publicationAlias, new Blob([content], { type: "application/json" }));
        resources.forEach((resource: any) => {
            fd.append('resources', resource);
        })
        return fd;
    };

    findAll(pagerOptions: any, filterOptions: any) {
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10,
                state: filterOptions.state,
                validationState: filterOptions.validationState
            },
            reportProgress: true
        };
        if (pagerOptions?.sort) { httpOptions.params['sort'] = pagerOptions.sort; }
        if (filterOptions.tray) { httpOptions.params['tray.id'] = filterOptions.tray; }
        if (filterOptions.motive) { httpOptions.params['motive.id'] = filterOptions.motive; }
        if (filterOptions?.name) { httpOptions.params['omniSearch'] = filterOptions.name; }
        if (filterOptions?.unread) { httpOptions.params['unread'] = filterOptions.unread; }
        if (filterOptions?.withoutResponse) { httpOptions.params['withoutResponse'] = filterOptions.withoutResponse; }
        if (filterOptions?.hasAttachments) { httpOptions.params['hasAttachments'] = filterOptions.hasAttachments; }
        if (filterOptions?.hasNotes) { httpOptions.params['hasNotes'] = filterOptions.hasNotes; }
        
        return this.httpClient.get<any>(`${this.requestsApiUrl}/responsible`, httpOptions);
    }

    createRequestWithEmployee(request: any) {
        var fd = this.getPublicationAsFormData(request.form, 'conversation', request.resources);
        return this.httpClient.post<any>(`${this.requestsApiUrl}/responsible`, fd);
    }

    closeRequest(id: number) {
        return this.httpClient.put(`${this.requestsApiUrl}/${id}/close`, null);
    }

    reopenRequest(id: number) {
        return this.httpClient.put(`${this.requestsApiUrl}/${id}/reopen`, null);
    }

    changeTray(id: number, trayId: number) {
        return this.httpClient.put(`${this.requestsApiUrl}/${id}/change-tray`, trayId);
    };

    changeMotive(id: number, motiveId: number) {
        return this.httpClient.put(`${this.requestsApiUrl}/${id}/change-motive`, motiveId);
    };

    validateRequest(requestId: number, validationForm: any, attachments: Array<File>) {
        var fd = this.getPublicationAsFormData(validationForm, 'validation', attachments);
        return this.httpClient.post<any>(`${this.requestsApiUrl}/${requestId}/validate`, fd);
    }

    discardRequestValidation(requestId: number) {
        return this.httpClient.put(`${this.requestsApiUrl}/${requestId}/discard-validation`, null);
    }

    getRequestsStatistics() {
        return this.httpClient.get(`${this.requestsApiUrl}/statistics`);
    }

    getAllTrays() {
        const httpOptions = {
            params: {
                page: 0,
                size: 20000,
                archived: false,
                sort: ['lastModifiedDate,asc']
            },
            reportProgress: true
        };
        return this.httpClient.get(`${this.apiUrl}/feedback-trays`, httpOptions);
    }

    getAllTraysForResponsible(includeAnonymous?: boolean) {
        let url = includeAnonymous ? '/feedback-trays/all-responsible' : '/feedback-trays/all-responsible/public';
        return this.httpClient.get(`${this.apiUrl}${url}`);
    }

    getAllMotives() {
        const httpOptions = {
            params: {
                page: 0,
                size: 20000,
                archived: false,
                sort: 'contents.motive,asc'
            },
            reportProgress: true
        };
        return this.httpClient.get(`${this.apiUrl}/feedback-motives`, httpOptions);
    }

    getAllMotivesForResponsible() {
        return this.httpClient.get(`${this.apiUrl}/feedback-motives/all-responsible`);
    }

    getRequestResources(requestId: number) {
        return this.httpClient.get(`${this.requestsApiUrl}/${requestId}/resources`);
    }

    getResponsibleConversation(requestId: number) {
        return this.httpClient.get(`${this.requestsApiUrl}/responsible/${requestId}`);
    }

    getResponsibleRequestNotes(requestId: number) {
        return this.httpClient.get(`${this.requestsApiUrl}/${requestId}/notes`);
    }

    downloadResource(url: string): any {
        return this.httpClient.get(url, { withCredentials: true, observe: 'response', responseType: 'blob'});
    }

    addMessageToRequestAsResponsible(requestId: number, message: any, resources: Array<any>) {
        let fd = new FormData();
        
        fd.append('conversationMessage', new Blob([JSON.stringify(message)], { type: "application/json" }));
        resources.forEach((resource: any) => {
            fd.append('resources', resource);
        })
        return this.httpClient.post<any>(`${this.requestsApiUrl}/responsible/${requestId}/messages`, fd);
    }

    addNoteToRequestAsResponsible(requestId: number, note: string, resources: Array<any>) {
        var fd = this.getPublicationAsFormData(note, 'note', resources);

        return this.httpClient.post<any>(`${this.requestsApiUrl}/${requestId}/notes`, fd);
    }

    exportRequests(trayId: number): any {
        let httpOptions: any = {
            withCredentials: true, 
            observe: 'response', 
            responseType: 'blob'
        }
        if ( trayId ) { httpOptions.params = {trayId: trayId} }
        return this.httpClient.get(`${this.requestsApiUrl}/export-csv`, httpOptions);
    }
}