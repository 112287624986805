<div class="main-container" [ngClass]="{'main-container--with-preview' : selectedTab ===  MY_COMPANY_TABS.EXTERNAL_LINKS}">
  <div class="main-container__header" style="min-width: 891px;">
    <div class="flex direction-column width-100-percent" spellcheck="false">
      <ul class="breadcrumb">
        <li>
          <a href="/global-settings" [textContent]="'global.navbar.globalSettings' | translate"></a>
        </li>
      </ul>
      <h2 [translate]="'myCompany.title'"></h2>
    </div>

    <div class="flex justify-self-end">

      @if (selectedTab === MY_COMPANY_TABS.COMPANIES) {
        <button id="create-company-button" class="button button--secondary button--small"
          (click)="createCompany()" data-cy="create-company">
          <span class="button__icon icon icon-add"></span>
          <span class="button__label" [translate]="'companies.newCompany'"></span>
        </button>
      }
      @if (selectedTab === MY_COMPANY_TABS.WORKPLACES) {
        <button id="create-workplace-button" class="button button--secondary button--small"
          (click)="createWorkplace()" data-cy="create-workplace">
          <span class="button__icon icon icon-add"></span>
          <span class="button__label" [translate]="'workplaces.newWorkplace'"></span>
        </button>
      }
      @if (selectedTab === MY_COMPANY_TABS.DEPARTMENTS) {
        <button id="create-department-button" class="button button--secondary button--small"
          (click)="createDepartment()" data-cy="create-department">
          <span class="button__icon icon icon-add"></span>
          <span class="button__label" [translate]="'departments.newDepartment'"></span>
        </button>
      }
      @if (selectedTab === MY_COMPANY_TABS.TEAMS) {
        <button id="create-team-button" class="button button--secondary button--small" (click)="createTeam()"
          data-cy="create-team">
          <span class="button__icon icon icon-add"></span>
          <span class="button__label" [translate]="'timeoff.teams.newTeam'"></span>
        </button>
      }
      @if (selectedTab === MY_COMPANY_TABS.EXTERNAL_LINKS) {
        <button id="create-ext-link" class="button button--secondary button--small"
          (click)="createExternalLink()" data-cy="create-ext-link">
          <span class="button__icon icon icon-add"></span>
          <span class="button__label" [translate]="'externalLinks.newExternalLink'"></span>
        </button>
      }
      @if (!isPreviewVisible && selectedTab === MY_COMPANY_TABS.EXTERNAL_LINKS) {
        <button class="button button--small preview-launcher" (click)="isPreviewVisibleFn(true)">
          <span class="button__icon icon icon-eye-opened"></span>
          <span class="button__label" [textContent]="'message.preview.preview' | translate"></span>
        </button>
      }
    </div>
  </div>
  <div class="tabs-language-container" style="min-width: 891px;">
    <div class="tab-nav tab-nav--inline" style="width:100%">
      @if (userLoggedHasAdminRole) {
        <!-- Global tab -->
        <span id="global-tab" data-cy="global-tab" class="tab-nav__item"
          [ngClass]="{'tab-nav__item--selected': selectedTab === MY_COMPANY_TABS.GLOBAL}"
          [translate]="'myCompany.tabs.global'" (click)="loadTabContent(MY_COMPANY_TABS.GLOBAL)">
        </span>
        <!-- Companies tab -->
        <span id="companies-tab" data-cy="companies-tab" class="tab-nav__item"
          [ngClass]="{'tab-nav__item--selected': selectedTab === MY_COMPANY_TABS.COMPANIES}"
          [translate]="'myCompany.tabs.companies'" (click)="loadTabContent(MY_COMPANY_TABS.COMPANIES)">
        </span>
      }
      <!-- Workplaces tab -->
      <span id="workplaces-tab" data-cy="workplaces-tab" class="tab-nav__item"
        [ngClass]="{'tab-nav__item--selected': selectedTab === MY_COMPANY_TABS.WORKPLACES}"
        [translate]="'myCompany.tabs.workplaces'" (click)="loadTabContent(MY_COMPANY_TABS.WORKPLACES)">
      </span>
      <!-- Departments tab -->
      @if (userLoggedHasAdminRole) {
        <span id="departments-tab" data-cy="departments-tab" class="tab-nav__item"
          [ngClass]="{'tab-nav__item--selected': selectedTab === MY_COMPANY_TABS.DEPARTMENTS}"
          [translate]="'myCompany.tabs.departments'" (click)="loadTabContent(MY_COMPANY_TABS.DEPARTMENTS)">
        </span>
      }
      <!-- Teams tab -->
      <span id="teams-tab" data-cy="teams-tab" class="tab-nav__item"
        [ngClass]="{'tab-nav__item--selected': selectedTab === MY_COMPANY_TABS.TEAMS}"
        [translate]="'myCompany.tabs.teams'" (click)="loadTabContent(MY_COMPANY_TABS.TEAMS)">
      </span>
      <!-- External Links tab -->
      <span id="external-links-tab" data-cy="external-links-tab" class="tab-nav__item"
        [ngClass]="{'tab-nav__item--selected': selectedTab === MY_COMPANY_TABS.EXTERNAL_LINKS}"
        [translate]="'myCompany.tabs.externalLinks'" (click)="loadTabContent(MY_COMPANY_TABS.EXTERNAL_LINKS)">
      </span>
    </div>
  </div>
  @if (selectedTab === MY_COMPANY_TABS.GLOBAL) {
    <div class="margin-top-20" style="min-width: 891px;">
      <my-company-global [userSession]="userSession"></my-company-global>
    </div>
  }
  @if (selectedTab === MY_COMPANY_TABS.COMPANIES) {
    <div class="margin-top-20" style="min-width: 891px;">
      <companies></companies>
    </div>
  }
  @if (selectedTab === MY_COMPANY_TABS.WORKPLACES) {
    <div class="margin-top-20" style="min-width: 891px;">
      <workplaces></workplaces>
    </div>
  }
  @if (selectedTab === MY_COMPANY_TABS.DEPARTMENTS) {
    <div class="margin-top-20" style="min-width: 891px;">
      <departments></departments>
    </div>
  }
  @if (selectedTab === MY_COMPANY_TABS.TEAMS) {
    <div class="margin-top-20" style="min-width: 891px;">
      <teams></teams>
    </div>
  }
  @if (selectedTab === MY_COMPANY_TABS.EXTERNAL_LINKS) {
    <div class="margin-top-20" style="min-width: 891px;">
      <external-links [(isPreviewVisible)]="isPreviewVisible"></external-links>
    </div>
  }
</div>