<div class="main-container" [ngClass]="{'main-container--with-preview' : selectedTab === CHATROOM_TABS.GENERAL}">
  <div class="main-container__header">
    <div class="flex align-items-end justify-space-between margin-bottom-20 width-100-percent">
      <div class="flex direction-column" spellcheck="false">
        <ul class="breadcrumb">
          <li>
            <a href="/global-settings" [textContent]="'global.navbar.globalSettings' | translate"></a>
          </li>
          <li>
            <a id="go-chatrooms" href="/settings/chatrooms" [textContent]="'chatrooms.title' | translate"></a>
          </li>
        </ul>
        <h2 [textContent]="'chatrooms.update.title' | translate"></h2>
      </div>
      <div class="flex">
        @if (!isPreviewVisible && selectedTab !== CHATROOM_TABS.USERS) {
          <button class="button button--small preview-launcher" (click)="isPreviewVisibleFn(true)"
            >
            <span class="button__icon icon icon-eye-opened"></span>
            <span class="button__label" [textContent]="'message.preview.preview' | translate"></span>
          </button>
        }
        <div kendoTooltip filter="button" position="top">
          <!-- Add participants to chatroom -->
          @if (selectedTab === CHATROOM_TABS.USERS && chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.MANUAL) {
            <loading-button [buttonId]="'add-users-modal'" [classes]="'button button--small button--secondary margin-top-24'" [iconClass]="'icon-add'"
              [text]="'segments.addUsers'" (action)="openAddUsersModal()" [isValid]="true">
            </loading-button>
          }
          @if (selectedTab === CHATROOM_TABS.USERS && chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.SEGMENTS) {
            <loading-button [buttonId]="'add-segments-modal'" [classes]="'button button--small button--secondary margin-top-24'" [iconClass]="'icon-add'"
              [text]="'chatrooms.update.synchroBtnText'" (action)="openAddSegmentsModal()" [isValid]="true">
            </loading-button>
          }
          <!-- Archive chatroom -->
          <!-- <ng-container *ngIf="(!chatroomForm.deletable || chatroomForm.state === CHATROOM_STATES.ARCHIVED) && selectedTab !== CHATROOM_TABS.USERS">
          <button id="archive-chat-btn" class="button button--small button--only-icon margin-left-10" (click)="archiveOrDelete()" [title]="(chatroomForm.state !== CHATROOM_STATES.ARCHIVED ? 'global.form.archive' : 'global.form.activate') | translate">
            <span class="button__icon icon" [ngClass]="chatroomForm.state !== CHATROOM_STATES.ARCHIVED ? 'icon-archive' : 'icon-unarchive'"></span>
          </button>
        </ng-container> -->
        <!-- Delete chatroom -->
        @if ((chatroomForm.deletable && chatroomForm.state !== CHATROOM_STATES.ARCHIVED) && selectedTab !== CHATROOM_TABS.USERS) {
          <button id="delete-chat-btn" class="button button--small button--only-icon margin-left-10" (click)="archiveOrDelete()" [title]="'global.form.delete' | translate">
            <span class="button__icon icon icon-delete"></span>
          </button>
        }
      </div>
    </div>
  </div>
</div>
<div class="main-container__content">
  <div class="main-container--full-width">
    <div class="tabs-language-container margin-bottom-20">
      <div class="tab-nav tab-nav--inline" style="width:100%">
        <span id="general-tab" class="tab-nav__item" [ngClass]="{'tab-nav__item--selected': selectedTab === CHATROOM_TABS.GENERAL}"
          [textContent]="'chatrooms.tabs.general' | translate" (click)="loadTabContent(CHATROOM_TABS.GENERAL)">
        </span>
        <span id="users-tab" class="tab-nav__item" [ngClass]="{'tab-nav__item--selected': selectedTab === CHATROOM_TABS.USERS}"
        [textContent]="'chatrooms.tabs.participants' | translate" (click)="loadTabContent(CHATROOM_TABS.USERS)"></span>
      </div>
    </div>
  </div>

  <!-- Chatroom preview -->
  @if (selectedTab === CHATROOM_TABS.GENERAL) {
    <div class="mobile-preview-container padding-top-40" [ngClass]="{'base-preview--visible' : isPreviewVisible}">
      <div class="mobile-preview-container__close icon icon-close" (click)="isPreviewVisibleFn(false)"></div>
      <div class="text-center">
        <div class="tab-nav tab-nav--inline margin-top-20">
          <span class="tab-nav__item ng-scope tab-nav__item--selected" [textContent]="'message.preview.preview' | translate"></span>
        </div>
      </div>
      <div class="step-views-container">
        @if (currentPreview === CHATROOM_PREVIEW.DIRECTORY) {
          <p class="title-view" [textContent]="'chatrooms.previews.directoryTitle' | translate"
          ></p>
        }
        @if (currentPreview === CHATROOM_PREVIEW.DETAIL) {
          <p class="title-view" [textContent]="'chatrooms.previews.detailTitle' | translate"
          ></p>
        }
        <ul class="number">
          <li [ngClass]="{'selected': currentPreview === CHATROOM_PREVIEW.DIRECTORY}"
          (click)="currentPreview = CHATROOM_PREVIEW.DIRECTORY" [textContent]="CHATROOM_PREVIEW.DIRECTORY"></li>
          <li [ngClass]="{'selected': currentPreview === CHATROOM_PREVIEW.DETAIL}"
          (click)="currentPreview = CHATROOM_PREVIEW.DETAIL" [textContent]="CHATROOM_PREVIEW.DETAIL"></li>
        </ul>
      </div>
      <div class="mobile-container">
        <div class="device-container">
          <div class="device-details-container">
            <div class="preview-header">
              <div class="preview-header__first-row">
                <span class="preview-header__icon icon icon-wifi"></span>
                <div class="flex">
                  <span class="preview-header__label margin-right-5">74%</span>
                  <span class="preview-header__icon icon icon-battery"></span>
                </div>
              </div>
            </div>
            @if (!isSyncing) {
              <div class="header-device"
                [ngClass]="(currentPreview === CHATROOM_PREVIEW.DIRECTORY && chatroomForm.exclusive) ? 'justify-content-center': 'justify-start'">
                @if (!(currentPreview === CHATROOM_PREVIEW.DIRECTORY && chatroomForm.exclusive)) {
                  <span class="icon icon-back"></span>
                }
                @if (currentPreview === CHATROOM_PREVIEW.DIRECTORY) {
                  <span class="chatroom-preview chatroom-preview__title" [ngClass]="{'margin-left-0': chatroomForm.exclusive}"
                    [translate]="chatroomForm.exclusive ? 'chatrooms.myChats' : 'chatrooms.directory'">
                  </span>
                }
              </div>
            }
            @if (!isSyncing) {
              <div class="scroll-device">
                <!-- Preview directory -->
                @if (currentPreview === CHATROOM_PREVIEW.DIRECTORY && chatroomForm) {
                  <div class="flex margin-top-20">
                    <div class="margin-left-20">
                      <avatar [name]="''" [surname]="''" [chatroom]="true"
                        [thumbnails]="{thumbnail_M: chatroomForm.croppedImageSrc, thumbnail_S: chatroomForm.croppedImageSrc}">
                      </avatar>
                    </div>
                    <div class="flex direction-column margin-left-10 width-70-percent justify-content-center">
                      @if (chatroomForm.name) {
                        <p class="chatroom-preview chatroom-preview__name truncate" [textContent]="chatroomForm.name"></p>
                      }
                    </div>
                  </div>
                  <div class="flex margin-top-20 opacity-50">
                    <div class="margin-left-20">
                      <kendo-skeleton shape="circle" [animation]="false" [width]="40" [height]="40"></kendo-skeleton>
                    </div>
                    <div class="flex direction-column margin-left-10">
                      <kendo-skeleton shape="text" [animation]="false" [width]="150" [height]="22" style="border-radius: 4px"></kendo-skeleton>
                      <div class="flex">
                        <kendo-skeleton shape="text" [animation]="false" [width]="15" [height]="18" style="border-radius: 4px"></kendo-skeleton>
                        <kendo-skeleton shape="text" [animation]="false" [width]="80" [height]="18" style="margin-left: 5px; border-radius: 4px"></kendo-skeleton>
                      </div>
                    </div>
                  </div>
                  <div class="flex margin-top-20 opacity-30">
                    <div class="margin-left-20">
                      <kendo-skeleton shape="circle" [animation]="false" [width]="40" [height]="40"></kendo-skeleton>
                    </div>
                    <div class="flex direction-column margin-left-10">
                      <kendo-skeleton shape="text" [animation]="false" [width]="150" [height]="22" style="border-radius: 4px"></kendo-skeleton>
                      <div class="flex">
                        <kendo-skeleton shape="text" [animation]="false" [width]="15" [height]="18" style="border-radius: 4px"></kendo-skeleton>
                        <kendo-skeleton shape="text" [animation]="false" [width]="80" [height]="18" style="margin-left: 5px; border-radius: 4px"></kendo-skeleton>
                      </div>
                    </div>
                  </div>
                }
                <!-- Preview detail -->
                @if (currentPreview === CHATROOM_PREVIEW.DETAIL && chatroomForm) {
                  <div class="flex direction-column align-items-center margin-top-10">
                    @if (chatroomForm.croppedImageSrc) {
                      <img class="chatroom-preview__detail-img" [src]="chatroomForm.croppedImageSrc" alt="">
                    }
                    @if (!chatroomForm.croppedImageSrc) {
                      <span class="chatroom-preview__detail-img chatroom-preview__detail-img--none icon icon-group"></span>
                    }
                    @if (chatroomForm.name) {
                      <p class="chatroom-preview chatroom-preview__name--detail margin-top-20 truncate text-center width-90-percent"
                        [textContent]="chatroomForm.name">
                      </p>
                    }
                    <p class="chatroom-preview chatroom-preview__name black-85 padding-5 text-center" [textContent]="chatroomForm.description"></p>
                    @if (!chatroomForm.exclusive) {
                      <button class="button button--cta margin-top-10" type="button">
                        <span class="button__label" [textContent]="'chatrooms.previews.buttonCta' | translate"></span>
                      </button>
                    }
                    <div class="flex margin-top-20 opacity-50">
                      <div class="margin-left-20">
                        <kendo-skeleton shape="circle" [animation]="false" [width]="40" [height]="40"></kendo-skeleton>
                      </div>
                      <div class="flex direction-column margin-left-10">
                        <kendo-skeleton shape="text" [animation]="false" [width]="150" [height]="22" style="border-radius: 4px"></kendo-skeleton>
                        <div class="flex">
                          <kendo-skeleton shape="text" [animation]="false" [width]="15" [height]="18" style="border-radius: 4px"></kendo-skeleton>
                          <kendo-skeleton shape="text" [animation]="false" [width]="80" [height]="18" style="margin-left: 5px; border-radius: 4px"></kendo-skeleton>
                        </div>
                      </div>
                    </div>
                    <div class="flex margin-top-20 opacity-30">
                      <div class="margin-left-20">
                        <kendo-skeleton shape="circle" [animation]="false" [width]="40" [height]="40"></kendo-skeleton>
                      </div>
                      <div class="flex direction-column margin-left-10">
                        <kendo-skeleton shape="text" [animation]="false" [width]="150" [height]="22" style="border-radius: 4px"></kendo-skeleton>
                        <div class="flex">
                          <kendo-skeleton shape="text" [animation]="false" [width]="15" [height]="18" style="border-radius: 4px"></kendo-skeleton>
                          <kendo-skeleton shape="text" [animation]="false" [width]="80" [height]="18" style="margin-left: 5px; border-radius: 4px"></kendo-skeleton>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  }
</div>
@if (selectedTab === CHATROOM_TABS.GENERAL) {
  <chatroom-general [chatId]="chatroomId" [(chatroomForm)]="chatroomForm" [initialDataForm]="initialDataForm"></chatroom-general>
}
@if (selectedTab === CHATROOM_TABS.USERS) {
  <chatroom-users [chatId]="chatroomId" [chatroomForm]="chatroomForm" [(participants)]="chatroomForm.participants" [(segments)]="chatroomForm.segments" ></chatroom-users>
}
</div>