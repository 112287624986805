<div class="modal-header">
  <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
    <span class="button__icon icon icon-close"></span>
  </button>
  <h3 id="new-request-title" class="modal-title" [translate]="'conversations.newConversation'"></h3>
</div>

<div class="modal-body scroll7" style="max-height: 72vh; overflow-y: auto;">
  <!-- Target employee -->
  <div class="group-form group-form--label-200">
    <div class="group-form__label">
      <span id="label-employee" class="group-form__label__text" [translate]="'timeoffRequests.employee'"></span>
    </div>
    <div class="group-form__data max-width-350">
      <div #targetUser id="target-user" class="width-100-percent">
        <user-dropdown [(inputModel)]="requestForm.employee" (onSelection)="onChange()"></user-dropdown>
      </div>
    </div>
  </div>
  <!-- motive -->
  <div class="group-form group-form--label-200">
    <div class="group-form__label">
      <span class="group-form__label__text" [translate]="'timeoffRequests.modal.motive'"></span>
    </div>
    <div class="group-form__data">
      <div class="min-width-250">
        <kendo-dropdownlist data-cy="motive-dropdown" [defaultItem]="''" [data]="availablesMotives" [(ngModel)]="requestForm.motive" [disabled]="!requestForm.employee"
          class="dui-dropdown margin-right-10" [valuePrimitive]="true" (valueChange)="onChange()" [textField]="'motive'" valueField="id"
          [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">

          <ng-template kendoDropDownListValueTemplate let-dataItem>
            @if (dataItem?.id) {
              <span class="icon icon-20 icon-tag-fill-s margin-right-10" [ngStyle]="{'color': getMotiveTagHexColor(dataItem.color)}"></span>
            }
            <span class="truncate" [textContent]="dataItem?.motive"></span>
          </ng-template>
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <div class="flex align-items-center min-height-28">
              @if (dataItem?.id) {
                <span class="icon icon-20 icon-tag-fill-s margin-right-10" [ngStyle]="{'color': getMotiveTagHexColor(dataItem.color)}"></span>
              }
              <span class="truncate" [textContent]="dataItem?.motive"></span>
            </div>
          </ng-template>
        </kendo-dropdownlist>

      </div>
    </div>
  </div>
  <!-- feedbackTray -->
  <div class="group-form group-form--label-200">
    <div class="group-form__label">
      <span class="group-form__label__text" [translate]="'feedbackTray.app.preview.nameInApp'"></span>
    </div>
    <div class="group-form__data">
      <div class="min-width-250">
        <kendo-dropdownlist data-cy="tray-dropdown" [defaultItem]="''" [data]="availablesTrays" [(ngModel)]="requestForm.tray" [disabled]="!requestForm.employee"
          class="dui-dropdown margin-right-10" [valuePrimitive]="true" (valueChange)="onChange()" textField="tray" valueField="id"
          [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">

          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span class="truncate" [textContent]="dataItem?.tray"></span>
          </ng-template>
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <div class="flex align-items-center min-height-28">
              <span class="truncate padding-3" [textContent]="dataItem?.tray"></span>
            </div>
          </ng-template>
        </kendo-dropdownlist>
      </div>
    </div>
  </div>
  <!-- Comment -->
  <div class="group-form group-form--label-200">
    <div class="group-form__label">
      <span class="group-form__label__text" [translate]="'model.message.text'"></span>
    </div>
    <div class="group-form__data">
      <dialenga-textarea class="width-100-percent new-rich-editor" textareaId="short-description" [isDisabled]="!requestForm.employee"
        [(inputModel)]="requestForm.message" (inputChanged)="onChange()" [showLinkBtns]="true"
        [placeholder]="'model.message.text'" [isRichFormat]="true">
      </dialenga-textarea>
    </div>
  </div>
  <!-- Attachments -->
  <div class="group-form group-form--label-200">
    <div class="group-form__label">
      <span class="group-form__label__text" [translate]="'conversations.modal.attachFile'"></span>
    </div>
    <div class="group-form__data">
      <div class="flex direction-column">
        <div class="flex flex-wrap">
          @for (attachment of attachedResources; track attachment) {
            <div class="flex align-items-baseline margin-bottom-10 margin-right-10">
              <div class="attachment hover-disabled">
                <span class="attachment__icon icon icon-attachment"></span>
                <span class="attachment__filename" [textContent]="attachment.name | lowercase"></span>
              </div>
              <span class="attachment__icon attachment__icon--remove icon nucleo icon-remove-fill margin-left-5"
              (click)="removeAttachedFile($index)"></span>
            </div>
          }
        </div>
        <div class="flex align-items-center">
          <button class="button button--small" (click)="attacthResource()" [disabled]="!requestForm.employee">
            <span class="button__label" [translate]="'documents.import.attachFiles'"></span>
          </button>
          <input #attachFile data-cy="attach-file" type="file" title class="upload" style="display: none;"
            (change)="onFileSelected($event.target.files)" />
        </div>
      </div>
    </div>
  </div>
  <!-- Related internal link -->
  <div class="group-form group-form--label-200">
    <div class="group-form__label">
      <span class="group-form__label__text" [translate]="'internalLinks.title'"></span>
    </div>
    <div class="group-form__data">
      <div class="flex direction-column">

        @for (link of requestForm.linkedPublications; track link) {
          <div class="link-publication">
            @if (link.imageURL) {
              <figure class="link-publication__img"
                [ngClass]="{'link-publication__img--gallery': link.gallerySize !== 0, 'link-publication__img--survey': link.kind === CONTENT_KIND.SURVEY}"
                [ngStyle]="{'background-image': 'url(' + link.imageURL + ')'}">
                @if (link.gallerySize !== 0) {
                  <figcaption class="icon-gallery-number" [textContent]="'+' + (link.gallerySize - 1)"></figcaption>
                }                
                @if (link.kind === CONTENT_KIND.SURVEY) {
                  <figcaption class="icon-gallery-number icon icon-questionnaire icon-16"></figcaption>
                }
              </figure>
            }
            @if (!link.imageURL) {
              @if (!!link.youtubeURL) {
                <figure class="link-publication__img link-publication__img--video"
                  [ngStyle]="{'background-image': 'url(' + getVideoImgPreview(link.youtubeURL) + ')'}">
                  <img src="/ng1/assets/img/youtube-icon-red.svg" alt="Youtube Icon">
                </figure>
              }
              @if (!link.youtubeURL) {
                <div class="link-publication__img link-publication__img--category"
                  [ngStyle]="{'background-image': 'url(' + (link.category.imageURL || '/ng1/assets/img/background-test.png') + ')'}">
                  <span class="overlay-color"
                  [ngStyle]="{'background-color': utilsService.getCategoryColor(link.category)}"></span>
                  @if (link.kind === CONTENT_KIND.SURVEY) {
                    <figcaption class="icon-gallery-number icon icon-questionnaire icon-16"></figcaption>
                  }
                </div>
              }
            }
            <div class="link-publication__info">
              <span class="link-publication__title" [textContent]="link.title"></span>
              <span class="link-publication__category truncate" [textContent]="link.category.name"
              [ngStyle]="{'background-color': utilsService.getCategoryColor(link.category)}"></span>
              <span class="icon icon-heisenberg anonymous-badge anonymous-badge--small-card" *ngIf="link.kind === CONTENT_KIND.SURVEY && link.anonymousSurvey"></span>
            </div>
            <span class="link-publication__icon-remove icon nucleo icon-remove-fill margin-left-5"
            (click)="removeLink($index)"></span>
          </div>
        }

        <div class="flex align-items-center">
          <button class="button button--small" (click)="internalLinkSelection()" [disabled]="!requestForm.employee">
            <span class="button__label" [translate]="'documents.import.attachFiles'"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer flex justify-end">
  <loading-button [buttonId]="'send-conversation'" [classes]="'button button--secondary margin-right-10'"
  (action)="onAccept()" [isValid]="isFormValid" [text]="'global.form.send'"></loading-button>
  <button type="button" class="button" (click)="onCancel()">
    <span class="button__label" [translate]="'global.modal.cancel'"></span>
  </button>
</div>