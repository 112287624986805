<div class="main-container__content">
  <div class="main-container--full-width">
    <div class="info-advice filter-container">
      <p [translate]="'companies.advice'"></p>
    </div>
    <div class="filter-container">
      <div class="flex">
        <div class="flex direction-column justify-content-end">
          <!-- Companies Actives / Archived button bar -->
          <div class="button-bar">
            <button class="button button--small" [ngClass]="{'button--selected' : dataFilterOpts.active}" (click)="getCompaniesByIsActive(true)">
              <span class="button__label" [translate]="'global.listFilters.activeSelection'" [translateParams]="{gender: 'female', number: 'plural'}"></span>
            </button>
            <button class="button button--small" [ngClass]="{'button--selected' : !dataFilterOpts.active}" (click)="getCompaniesByIsActive(false)">
              <span class="button__label" [translate]="'global.listFilters.archivedSelection'" [translateParams]="{gender: 'female', number: 'plural'}"></span>
            </button>
          </div>
        </div>
      </div>
      <div class="search-filter-container align-items-center">
        <input type="text" class="input input--search" maxlength="1024" [placeholder]="'companies.searchPlaceholder' | translate" [(ngModel)]="dataFilterOpts.name"
          (input)="listItemsSearch()" ngModelOptions="{ debounce: 200 }" [ngClass]="dataFilterOpts.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'">
          <div class="icon icon-remove" (click)="clearSearchInput()"></div>
        </div>
      </div>

      @if (companiesDatagrid?.data.length > 0) {
        <kendo-grid #companiesGrid [data]="companiesDatagrid" class="master-grid user-select-none margin-bottom-40 org-grid"
          [sortable]="true" [sort]="gridSort" (sortChange)="onSortChange($event)" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()"
          [pageable]="gridPagerSettings" (pageChange)="onPageChange($event)" [skip]="dataPagingOpts.skip" [pageSize]="dataPagingOpts.size"
          [navigable]="true" [resizable]="false" [selectable]="false" [loading]="isSyncing">
          <kendo-grid-messages
            [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate" [pagerOf]="'global.lists.pagerOf' | translate"
            [pagerItems]="'global.lists.pagerItems' | translate">
          </kendo-grid-messages>
          <!-- Company name -->
          <kendo-grid-column field="name">
            <ng-template kendoGridHeaderTemplate>
              <div class="flex align-items-center cursor-pointer">
                <span class="truncate" [textContent]="'companies.grid.companyName' | translate"></span>
              </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="flex align-items-center">
                <span class="icon nucleo icon-headquarters icon-24 black-60 margin-right-8"></span>
                <span class="truncate cursor-default" [textContent]="dataItem.name"></span>
                @if (dataItem.parent) {
                  <span class="cia-parent-label margin-left-8 font-size-13" [translate]="'company.parent'"></span>
                }
              </div>
            </ng-template>
          </kendo-grid-column>
          <!-- Company CIF -->
          <kendo-grid-column field="cif">
            <ng-template kendoGridHeaderTemplate>
              <div class="flex align-items-center cursor-pointer">
                <span class="truncate" [textContent]="'companies.grid.cif' | translate"></span>
              </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="flex align-items-center">
                <span class="truncate cursor-default" [textContent]="dataItem.cif"></span>
              </div>
            </ng-template>
          </kendo-grid-column>
          <!-- Company director -->
          <kendo-grid-column [sortable]="false" [headerStyle]="{'cursor': 'default !important', 'pointer-events': 'none'}">
            <ng-template kendoGridHeaderTemplate>
              <div class="flex align-items-center">
                <span [textContent]="'companies.grid.director' | translate"></span>
              </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              @if (dataItem.director) {
                <div class="flex align-items-center">
                  <avatar [name]="dataItem.director.name" [surname]="dataItem.director.surname" [small]="true"
                    [thumbnails]="{thumbnail_M: dataItem.director.avatarThumbnailMUrl || dataItem.director.avatarUrl, thumbnail_S: dataItem.director.avatarThumbnailSUrl || dataItem.director.avatarThumbnailMUrl}">
                  </avatar>
                  <span class="margin-left-10" [textContent]="dataItem.director.name"></span>
                  <span class="margin-left-5 truncate" [textContent]="dataItem.director.surname"></span>
                </div>
              }
            </ng-template>
          </kendo-grid-column>
          <!-- Company employees count -->
          <kendo-grid-column field="employeeCount" width="64" [sortable]="false" [headerStyle]="{'cursor': 'default !important', 'pointer-events': 'none'}">
            <ng-template kendoGridHeaderTemplate>
              <div class="flex align-items-center justify-content-center cursor-pointer" [style.marginInline]="'auto'">
                <!-- <span [textContent]="'model.schemes.employees' | translate"></span> -->
                <span class="icon nucleo icon-user-groups-filled icon-22"></span>
              </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="flex align-items-center justify-content-center">
                <span class="document-label__text cursor-default" [textContent]="dataItem.employeeCount"></span>
              </div>
            </ng-template>
          </kendo-grid-column>
          <!-- CTA buttons column -->
          <kendo-grid-command-column title="" width="136" [style]="{ 'text-align': 'end' }">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
              <div class="flex justify-end td-on-hover" kendoTooltip filter="a.k-master-button" offset="0">
                <a class="k-button k-master-button" (click)="updateCompany(dataItem)" [title]="'global.form.edit' | translate">
                  <span class="icon icon-edit"></span>
                </a>
                @if (!dataItem.parent) {
                  @if (!dataItem.deletable || !dataItem.active) {
                    <a class="k-button k-master-button" (click)="archiveOrActivateCompany(dataItem)"
                      [title]="(dataItem.active ? 'global.form.archive' : 'global.form.activate') | translate">
                      <span class="icon" [ngClass]="dataItem.active ? 'icon-archive' : 'icon-unarchive'"></span>
                    </a>
                  }
                  @if (dataItem.deletable && dataItem.active) {
                    <a class="k-button k-master-button"
                      (click)="deleteCompany(dataItem)" [title]="'global.form.delete' | translate">
                      <span class="icon icon-delete"></span>
                    </a>
                  }
                }
              </div>
            </ng-template>
          </kendo-grid-command-column>
          <ng-template kendoGridNoRecordsTemplate></ng-template>
          <!-- Custom Pager -->
          <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <article class="dui-grid-pager">
              <kendo-pager-info></kendo-pager-info>
              @if (companiesDatagrid.total > 10) {
                <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                  <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                  <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                  <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                </article>
                <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
              }
            </article>
          </ng-template>
        </kendo-grid>
      }

      <!-- Skeleton companies grid -->
      @if (companiesDatagrid?.total == 0 && isSyncing) {
        <div>
          <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid org-grid" [rowClass]="skeletonRowCallback">
            <!-- Company name -->
            <kendo-grid-column>
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center">
                  <span [textContent]="'companies.grid.companyName' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <kendo-skeleton shape="text" animation="pulse" [width]="300" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- Company CIF -->
            <kendo-grid-column>
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center">
                  <span [textContent]="'companies.grid.cif' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <kendo-skeleton shape="text" animation="pulse" [width]="100" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- Company Director -->
            <kendo-grid-column>
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center">
                  <span [textContent]="'companies.grid.director' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <kendo-skeleton class="margin-right-10" shape="circle" animation="pulse" [width]="28" [height]="28"></kendo-skeleton>
                  <kendo-skeleton shape="text" animation="pulse" [width]="200" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- Company employees -->
            <kendo-grid-column width="64" media="(min-width: 1240px)">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center justify-content-center">
                  <span class="icon nucleo icon-user-groups-filled icon-22"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center justify-content-center">
                  <kendo-skeleton shape="text" animation="pulse" [width]="20" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- CTA column -->
            <kendo-grid-command-column width="136"></kendo-grid-command-column>
          </kendo-grid>
        </div>
      }
      <!-- No data information -->
      @if (companiesDatagrid.total == 0 && !dataFilterOpts.name && !isSyncing) {
        <no-results
          [imageTitle]="'companies.noResultsTitle'" [description]="dataFilterOpts.active ? 'companies.noResultsText' : 'companies.noResultsArchiveText'">
        </no-results>
      }
      @if (companiesDatagrid.total == 0 && dataFilterOpts.name && !isSyncing) {
        <no-search-results></no-search-results>
      }
    </div>
  </div>