<div class="main-container__content margin-bottom-40">
  <div class="main-container--full-width">

    <div class="flex margin-bottom-5 justify-end">
      <!-- filter by username, internal id, email and document name -->
      <div class="search-filter-container align-items-center margin-bottom-5">
        <input type="text" class="input input--search" maxlength="1024" [placeholder]="'documents.list.filters.searchPlaceholder' | translate" [(ngModel)]="gridFilteringOps.name"
          (input)="listItemsSearch()" ngModelOptions="{ debounce: 200 }" [ngClass]="gridFilteringOps.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'">
          <div class="icon icon-remove" (click)="clearSearchInput()"></div>
        </div>
      </div>
      <!-- Chat participants grid -->
      @if (chatUsersDatagrid?.data.length > 0) {
        <kendo-grid #chatUsersGrid [data]="chatUsersDatagrid" class="master-grid" (cellClick)="onCellClick($event)" [ngClass]="{'row-noHover': chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.SEGMENTS}"
          [sortable]="true" [sort]="gridSort" (sortChange)="onSortChange($event)" [resizable]="false" [selectable]="false" [navigable]="true" [loading]="isSyncing"
          [pageable]="gridPagerSettings" (pageChange)="onPageChange($event)" [skip]="gridPagingOps.skip" [pageSize]="gridPagingOps.size">
          <kendo-grid-messages
            [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate" [pagerOf]="'global.lists.pagerOf' | translate"
            [pagerItems]="'global.lists.pagerItems' | translate">
          </kendo-grid-messages>
          <!-- Checkbox for select row-->
          <kendo-grid-column width="62" [sortable]="false">
            <ng-template kendoGridHeaderTemplate>
              <label class="checkbox__container padding-left-1"
                        [ngClass]="{'disabled': chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.SEGMENTS, 
                                    'cursor-pointer': chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.MANUAL}">
                <span class="checkbox icon icon-24" [ngClass]="toggleAllItemsSelect ? 'checkbox--on icon-checkbox-label-active': 'icon-checkbox-label'" ></span>
                <input class="checkbox__input" type="checkbox" id="selectAllCheckboxId" [(ngModel)]="toggleAllItemsSelect" (click)="allRowsToggleSelectedProperty()"/>
              </label>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <label class="checkbox__container"
                        [ngClass]="{'disabled': chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.SEGMENTS || dataItem.admin, 
                                    'cursor-pointer': chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.MANUAL && !dataItem.admin}">
                <span class="checkbox icon icon-24" [ngClass]="dataItem.selectedItem && !dataItem.admin ? 'checkbox--on icon-checkbox-label-active': 'icon-checkbox-label'" ></span>
                @if (!dataItem.admin) {
                  <input class="checkbox__input" type="checkbox" id="selectCheckbox" [(ngModel)]="dataItem.selectedItem" disabled/>
                }
              </label>
            </ng-template>
          </kendo-grid-column>
          <!-- Participant avatar -->
          <kendo-grid-column width="76" [ngClass]="{'cursor-pointer': chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.MANUAL}" [headerClass]="'padding-0'" [sortable]="false">
            <ng-template kendoGridHeaderTemplate>
              @if (selectedRows > 0) {
                <div class="flex align-items-center padding-left-10" kendoTooltip filter="label" position="top" offset="8">
                  <label for="delete-users" class="margin-left-10" [title]="'global.form.delete' | translate">
                    <loading-button [buttonId]="'delete-users'" [iconClass]="'icon-delete'" name="delete-users"
                      [classes]="'button button--small button--only-icon'" (action)="deleteMultipleUsers()" [isValid]="true">
                    </loading-button>
                  </label>
                </div>
              }
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="flex align-items-center cursor-default">
                <avatar [name]="dataItem.name" [surname]="dataItem.surname" [small]="false"
                  [thumbnails]="{thumbnail_M: dataItem.avatarThumbnailMUrl || dataItem.avatarUrl, thumbnail_S: dataItem.avatarThumbnailSUrl || dataItem.avatarThumbnailMUrl}">
                </avatar>
              </div>
            </ng-template>
          </kendo-grid-column>
          <!-- Participant name -->
          <kendo-grid-column field="name" [ngClass]="{'cursor-pointer': chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.MANUAL}" sortable="true" [headerStyle]="selectedRows > 0 && {'pointer-events': 'none'}">
            <ng-template kendoGridHeaderTemplate>
              @if (selectedRows === 0) {
                <div class="flex align-items-center cursor-pointer user-select-none">
                  <span [textContent]="'users.name' | translate"></span>
                </div>
              }
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="flex align-items-center cursor-default">
                <span class="truncate min-width-33-percent" [textContent]="dataItem.name"></span>
                @if (dataItem.admin) {
                  <div class="chat-admin-state margin-left-10">
                    <span class="chat-admin-state__label" [translate]="'chatrooms.participants.admin'"></span>
                  </div>
                }
              </div>
            </ng-template>
          </kendo-grid-column>
          <!-- Participant surname -->
          <kendo-grid-column field="surname" [ngClass]="{'cursor-pointer': chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.MANUAL}" [sortable]="true" [headerStyle]="selectedRows > 0 && {'pointer-events': 'none'}">
            <ng-template kendoGridHeaderTemplate>
              @if (selectedRows === 0) {
                <div class="flex align-items-center cursor-pointer user-select-none">
                  <span [textContent]="'users.surname' | translate"></span>
                </div>
              }
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="flex align-items-center cursor-default">
                <span class="truncate" [textContent]="dataItem.surname"></span>
              </div>
            </ng-template>
          </kendo-grid-column>
          <!-- Participant username -->
          <kendo-grid-column field="username" [ngClass]="{'cursor-pointer': chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.MANUAL}" [sortable]="true" [headerStyle]="selectedRows > 0 && {'pointer-events': 'none'}">
            <ng-template kendoGridHeaderTemplate>
              @if (selectedRows === 0) {
                <div class="flex align-items-center padding-left-8 cursor-pointer user-select-none">
                  <span class="truncate" [textContent]="'users.username' | translate"></span>
                </div>
              }
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="flex align-items-center cursor-default">
                <span class="truncate" [textContent]="dataItem.username"></span>
              </div>
            </ng-template>
          </kendo-grid-column>
          <!-- CTA buttons column -->
          <kendo-grid-command-column title="" [style]="{ 'text-align': 'end' }" [ngClass]="{'cursor-pointer': chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.MANUAL}">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
              @if (selectedRows === 0 && chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.MANUAL && !dataItem.admin) {
                <div class="td-on-hover">
                  <a id="delete-user-btn" class="k-button k-master-button" (click)="deleteSingleUser(dataItem)">
                    <span class="icon icon-delete"></span>
                  </a>
                </div>
              }
            </ng-template>
          </kendo-grid-command-column>
          <!-- Custom Pager -->
          <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <article class="dui-grid-pager">
              <kendo-pager-info></kendo-pager-info>
              @if (chatUsersDatagrid.total > 10) {
                <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                  <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                  <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                  <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                </article>
                <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
              }
            </article>
          </ng-template>
        </kendo-grid>
      }

      <!-- Skeleton chat users grid -->
      @if (chatUsersDatagrid?.data.length == 0 && isSyncing) {
        <div>
          <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid" [rowClass]="skeletonRowCallback">
            <kendo-grid-column width="5">
              <ng-template kendoGridHeaderTemplate>
                <label class="checkbox__container padding-left-1">
                  <span class="checkbox icon icon-24 icon-checkbox-label"></span>
                </label>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <label class="checkbox__container padding-left-3">
                  <kendo-skeleton shape="rectangle" animation="pulse" [width]="18" [height]="18" style="border-radius: 4px"></kendo-skeleton>
                </label>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="5%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <kendo-skeleton shape="circle" animation="pulse" [width]="40" [height]="40"></kendo-skeleton>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="20%" [headerStyle]="{'padding': '0.5em 0.6em'}">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center">
                  <span class="padding-left-10" [textContent]="'users.name' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <kendo-skeleton shape="text" animation="pulse" [width]="200" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="25%">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center">
                  <span class="padding-left-5" [textContent]="'users.surname' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <kendo-skeleton shape="text" animation="pulse" [width]="250" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="22%">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center">
                  <span class="padding-left-8" [textContent]="'users.username' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-skeleton shape="text" animation="pulse" [width]="250" [height]="20" style="border-radius: 4px"></kendo-skeleton>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-command-column title="" width="25%"></kendo-grid-command-column>
          </kendo-grid>
        </div>
      }
      @if (chatUsersDatagrid?.data.length == 0 && !gridFilteringOps.name && !isSyncing) {
        <no-results [imageTitle]="'chatrooms.participants.grid.noResultsTitle'"
          [description]="'chatrooms.participants.grid.noResultsText'">
        </no-results>
      }
      @if (chatUsersDatagrid?.data.length == 0 && gridFilteringOps.name && !isSyncing) {
        <no-search-results></no-search-results>
      }
    </div>
  </div>