import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UtilsService } from '../../services/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'creation-link-modal',
    templateUrl: './creation-link-modal.html',
  })
  export class CreationLinkModalComponent implements OnInit {

    public isFormValid: boolean = false;
    public createLinkForm: any = {
        url: '',
        text: ''
    };

    constructor(
        public dialogRef: MatDialogRef<CreationLinkModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private utilsService: UtilsService, private translate: TranslateService
    ) {}
  
    ngOnInit() {
        this.createLinkForm.url = this.data.url.trim();
        this.createLinkForm.text = this.data.text.trim();
    }

    onChange(): void {
        this.isFormValid = this.utilsService.validateURL(this.createLinkForm.url);
        if(this.isFormValid) {
            this.createLinkForm.url = this.utilsService.concatURLPrefixIfNotExists(this.createLinkForm.url);
        }
    }

    getPlaceholder(): string {
        return !this.createLinkForm.url ? this.translate.instant('model.message.title') : this.createLinkForm.url;
    };

    onCancel(): void {
        this.dialogRef.close({cancel: true});
    }

    onAccept() {
        let linkTitle = !!this.createLinkForm.text ? this.createLinkForm.text : this.createLinkForm.url;
        const generatedLink = '<a href="' + this.createLinkForm.url + '" target="_blank">' + linkTitle + '</a>';
        this.dialogRef.close({accept: true, generatedLink: generatedLink});
    }
}