<section class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 id="internal-link-title" class="modal-title" [translate]="'internalLinks.title'"></h3>
</section>

<section class="modal-body">
    <!-- Input for search contents -->
    <section class="search-content">
        <p class="black-85 margin-bottom-10" [translate]="'internalLinks.description'"></p>
        <article class="relative">
            <input #contentSearch id="contentSearch" type="text" class="input" [(ngModel)]="contentSearchText" (input)="onSearch()"
            [placeholder]="'segments.searchPlaceholder' | translate" autocomplete="off" [ngModelOptions]="{ debounce: 1000 }" />
            <span class="icon icon-remove" (click)="onClearSearchInput()"></span>
        </article>
        <p *ngIf="contentSearchText.length < 3" class="margin-top-4 margin-bottom-0 font-size-13 black-45" [translate]="'internalLinks.searchInfoLabel'"></p>
    </section>

    <!-- Total results of search and filter content buttons -->
    <section class="search-content__filter margin-top-32" *ngIf="showFilters">
        <p class="black-85 margin-bottom-16" [translate]="'global.form.nResults'" [translateParams]="{number: internalLinks.totalElements ? internalLinks.totalElements : 0}"></p>
        <div class="flex align-items-center">
            <ng-container *ngFor="let filterBtn of filterButtons">
                <button [id]="filterBtn.id" class="button button--small" (click)="onSelectFilter(filterBtn)"
                    [ngClass]="{'button--selected': filterBtn.value === selectedFilter.value}">
                    <span class="button__label font-size-12" [textContent]="filterBtn.text"></span>
                </button>
            </ng-container>
        </div>
    </section>

    <ng-container *ngIf="internalLinks.totalElements > 0 && contentSearchText.length >= 3">
        <!-- Result list for select content -->
        <section class="search-content__container scroll7 margin-top-16">
            <ng-container *ngFor="let content of internalLinks.content">
                <article class="search-content__item" (click)="onSelectContent(content)" [ngClass]="{'selected': content.selected}">
                    <span class="icon icon-24" [ngClass]="getContentIconClass(content.kind, !!content.gallerySize)"></span>
                    <div class="search-content__data">
                        <span class="search-content__title margin-bottom-6" [innerHTML]="content.title"></span>
                        <span class="search-content__category truncate" [textContent]="content.category.name" 
                            [ngStyle]="{'background-color': utilsService.getCategoryColor(content.category)}"></span>
                        <span class="search-content__date margin-top-6" [textContent]="getTimeElapsedFrom(content.date)"></span>
                    </div>
                    <ng-container *ngIf="!!content.imageURL">
                        <figure class="search-content__img" [ngClass]="{'search-content__img--gallery': content.gallerySize !== 0, 'search-content__img--survey': content.kind === CONTENT_KIND.SURVEY }" [ngStyle]="{'background-image': 'url(' + content.imageURL + ')'}">
                            <span class="icon icon-heisenberg anonymous-badge anonymous-badge--md" *ngIf="content.kind === CONTENT_KIND.SURVEY && content.anonymousSurvey"></span>
                            <figcaption class="icon-gallery-number" [textContent]="'+' + (content.gallerySize - 1)" *ngIf="content.gallerySize !== 0"></figcaption>
                            <figcaption class="icon-gallery-number icon icon-questionnaire icon-24" *ngIf="content.kind === CONTENT_KIND.SURVEY"></figcaption>
                        </figure>
                    </ng-container>
                    <ng-container *ngIf="!content.imageURL">
                        <figure class="search-content__img search-content__img--video" [ngStyle]="{'background-image': 'url(' + getVideoImgPreview(content.youtubeURL) + ')'}"  *ngIf="!!content.youtubeURL">
                            <img src="/ng1/assets/img/youtube-icon-red.svg" alt="Youtube Icon">
                        </figure>
                        <div *ngIf="!content.youtubeURL" class="search-content__img search-content__img--category"
                            [ngStyle]="{'background-image': 'url(' + (content.category.imageURL || '/ng1/assets/img/background-test.png') + ')'}">
                            <span class="icon icon-heisenberg anonymous-badge anonymous-badge--md" *ngIf="content.kind === CONTENT_KIND.SURVEY && content.anonymousSurvey"></span>
                            <span class="overlay-color" [ngStyle]="{'background-color': utilsService.getCategoryColor(content.category)}"></span>
                            <figcaption class="icon-gallery-number icon icon-questionnaire icon-24" *ngIf="content.kind === CONTENT_KIND.SURVEY"></figcaption>
                        </div>
                    </ng-container>
                </article>
            </ng-container>
        </section>
    </ng-container>

    <ng-container *ngIf="internalLinks.totalElements === 0 && contentSearchText.length >= 3">
        <section class="search-content__no-matches margin-top-16">
            <div class="text-center">
                <img src="/ng1/assets/img/dialenguin-no-matches.svg">
                <p class="margin-top-16" [translate]="'internalLinks.noMatchesTitle'"></p>
                <p class="margin-top-8" [translate]="'internalLinks.noMatchesText'"></p>
            </div>
        </section>
    </ng-container>
</section>

<section class="modal-footer flex justify-end" style="border-top: none">
    <loading-button [buttonId]="'validate-conversation-button'" [classes]="'button button--secondary margin-right-10'"
        (action)="onAccept()" [isValid]="isFormValid" [text]="'global.modal.accept'" ></loading-button>
    <button type="button" class="button" (click)="onCancel()">
        <span class="button__label" [translate]="'global.modal.cancel'"></span>
    </button>
</section>