<div class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [textContent]="'departments.newDepartment' | translate"></h3>
</div>

<div class="modal-body">
    <!-- Department name -->
    <div class="group-form group-form--label-200 margin-bottom-15">
        <div class="group-form__label">
            <span class="group-form__label__text" [textContent]="'model.segment.name' | translate"></span>
        </div>
        <div class="group-form__data">
            <input #departmentName
                type="text"
                name="departmentName"
                id="departmentName"
                class="input"
                maxlength="64"
                data-cy="department-name"
                [(ngModel)]="departmentForm.name"
                (ngModelChange)="onChange()"
                (keyup.enter)="onEnterPressed()"
                [placeholder]="'departments.namePlaceholder' | translate"
            >
        </div>
    </div>

    <!-- Department manager -->
    <div class="group-form group-form--label-200 margin-bottom-0">
        <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'timeoff.teams.grid.teamLeader'"></span>
            <small class="group-form__label__text width-100-percent">({{'global.form.optional' | translate}})</small>
        </div>
        <div class="group-form__data">
            <user-dropdown class="min-width-100-percent" [(inputModel)]="departmentForm.responsible" (onSelection)="onChange()"></user-dropdown>
        </div>
    </div>

</div>

<div class="modal-footer flex justify-end">
    <loading-button
        [buttonId]="'department-create'"
        [classes]="'button--secondary margin-right-10'"[text]="'departments.modal.createDepartment'" 
        (action)="onAccept()" [isValid]="isFormValid">
    </loading-button>
    <button id="department-cancel" data-cy="department-cancel" type="button" class="button" (click)="onCancel()">
        <span class="button__label" [textContent]="'global.modal.cancel' | translate"></span>
    </button>
</div>