<div class="user-avatar">
  <avatar [small]="small" [name]="user.name" [surname]="user.surname" [medium]="medium"
    [thumbnails]="{thumbnail_M: user.avatarThumbnailMUrl || user.avatarUrl, thumbnail_S: user.avatarThumbnailSUrl || user.avatarThumbnailMUrl }"
    [state]="user.state" [access]="user.lastAccessDate" [anonymous]="anonymous">
  </avatar>
  <div class="avatar-data">
    <span class="margin-left-10 truncate" [textContent]="user.name + ' ' + user.surname" [ngClass]="{'avatar-data-name': showUsername}"></span>
    @if (showUsername) {
      <span class="avatar-data-username margin-left-10 truncate" [textContent]="user.username"></span>
    }
  </div>
</div>