import { Component, Input } from '@angular/core';

@Component({
  selector: 'no-results',
  templateUrl: './no-results.component.html'
})
export class NoResultsComponent {

  @Input() imageTitle: string;
  @Input() description?: string;
  @Input() classes?: string;

  constructor() { }

}
