@if (isCategoryVisible()) {
  <div class="category-tree__group" [ngClass]="'level-' + treeLevel" (click)="selectCategory($event)" (dblclick)="categoryDoubleClick($event)"
    >
    <div class="category-tree__item"
      [ngClass]="{'category-tree__item--editing': category.isEditionActive, 'category-tree__item--selected': category.isSelected && !isEditable}">
      <span class="category-tree__icon icon" [ngStyle]="{'color': category.categoryColor}"
        [ngClass]="category.active ? 'icon-folder' : 'icon-folder-outline'" (click)="toggleCategoryOpenedState($event)">
        <span class="category-tree__icon__state icon"
          [ngStyle]="{'color': category.categoryColor}"
        [ngClass]="(category.children && category.children.length > 0) ? (!category.isOpened ? 'icon-arrow-down' : 'icon-arrow-top') : ''"></span>
      </span>
      @if (!category.isEditionActive) {
        <span class="category-tree__text non-selectable"
          [textContent]="isEditable && category.contents[selectedLanguage] ? category.contents[selectedLanguage].name : category.name"
          [ngClass]="{'category-tree__text--pending': isEditable && (!category.contents[selectedLanguage] || !category.contents[selectedLanguage].name)}"
        [attr.id]="categoryIdAttr + '-name'"></span>
      }
      @if (!isEditable && (category.contentsCount === 0 || category.knowledgeCenterContentsCount === 0) && category.subfoldersCount === 0) {
        <span
        class="category-tree__label non-selectable" [translate]="'model.category.contentsEmpty'"></span>
      }
      @if (category.isEditionActive) {
        <input class="category-tree__input" type="text" maxlength="36"
          [attr.id]="categoryIdAttr" [(ngModel)]="category.contents[selectedLanguage].name" (blur)="inputCategoryOnBlur()"
          (keyup.enter)="inputCategoryOnBlur()" />
      }
      @if (!category.isEditionActive && this.category.active && treeLevel < maxSubcategoriesLevels) {
        <button class="button button--small button--only-icon button__category-tree" (click)="createFolder($event)"
          >
          <span class="button__icon icon icon-add-folder"></span>
        </button>
      }
      @if (isEditable && treeLevel === 1 && category.isOpened) {
        <div class="category-tree__languages">
          @for (language of languages; track language) {
            <span [translate]="'global.language.' + language" class="category-tree__language non-selectable"
              [ngClass]="{'category-tree__language--selected': language === selectedLanguage}"
            (click)="selectLanguage(language)"></span>
          }
        </div>
      }
      @if (isEditable && treeLevel > 1) {
        <div class="category-tree__options">
          <span class="category-tree__option non-selectable" [translate]="'global.form.edit'" (click)="editCategory($event)"></span>
          @if (isActivationLinkVisible()) {
            <span class="category-tree__option non-selectable" [translate]="'global.form.activate'"
            (click)="activateCategory($event)"></span>
          }
          @if (category.active && !category.deletable) {
            <span class="category-tree__option non-selectable" [translate]="'global.form.archive'"
            (click)="confirmCategoryArchivation($event)"></span>
          }
          @if (category.deletable) {
            <span class="category-tree__option non-selectable" [translate]="'global.form.delete'" (click)="deleteCategory($event)"
            ></span>
          }
        </div>
      }
    </div>
    @if (category.isOpened && category.children && category.children.length > 0) {
      <div>
        @for (subcategory of category.children | sort:'name'; track subcategory) {
          <categories-tree-item
            [isEditable]="isEditable" [category]="subcategory" [treeLevel]="treeLevel" [color]="color" [selectedLanguage]="selectedLanguage">
          </categories-tree-item>
        }
      </div>
    }
  </div>
}