<div class="flex direction-column" style="height: 85vh; overflow: hidden;">
  <!-- Header -->
  <div class="dialog-modal-header ">
    <div class="flex align-items-center">
      <h3 [textContent]="data.title"></h3>
    </div>
    <button type="button" class="button button--only-icon dialog-modal__button-close" (click)="onCancel()">
      <span class="button__icon icon icon-close"></span>
    </button>
  </div>
  <!-- Body -->
  <div class="dialog-modal-body flex direction-column relative" style="height: 82vh; overflow: hidden; padding-bottom: 0px;">

    <div class="flex relative margin-bottom-20 margin-right-20" [ngClass]="data.uploadAllowed ? 'justify-content-end' : 'justify-content-center'">
      <div class="flex padding-left-20" style="left: 0px; position: absolute;">
        @if (data.uploadAllowed) {
          <div class="button-bar margin-right-20">
            <button id="source-upload" class="button button--small" [ngClass]="{'button--secondary': selectedSource === SOURCE_IMAGE.UPLOAD}" (click)="selectedSource = SOURCE_IMAGE.UPLOAD">
              <span class="button__label" [textContent]="'imageBank.uploadButtonText' | translate"></span>
            </button>
            <button id="source-search" class="button button--small" [ngClass]="{'button--secondary': selectedSource === SOURCE_IMAGE.SEARCH}" (click)="selectedSource = SOURCE_IMAGE.SEARCH">
              <span class="button__label" [textContent]="'imageBank.searchButtonText' | translate"></span>
            </button>
          </div>
        }
        <!-- Button bar if both images and Gifs are allowed-->
        @if ((data.gifsAllowed && data.picsAllowed) && selectedSource !== SOURCE_IMAGE.UPLOAD) {
          <div class="button-bar">
            <button class="button button--small" [ngClass]="{'button--secondary': selectedSourceKind === SOURCE_KIND.PICTURE}" (click)="selectedSourceKind = SOURCE_KIND.PICTURE">
              <span class="button__label" [textContent]="'model.message.image' | translate"></span>
            </button>
            <button class="button button--small" [ngClass]="{'button--secondary': selectedSourceKind === SOURCE_KIND.GIF}" (click)="selectedSourceKind = SOURCE_KIND.GIF">
              <span class="button__label" [textContent]="'imageBank.gifButtonText' | translate"></span>
            </button>
          </div>
        }
      </div>
      <!-- Search inputs -->
      @if (selectedSource === SOURCE_IMAGE.SEARCH && selectedSourceKind === SOURCE_KIND.PICTURE) {
        <div class="flex align-items-center search-filter-container width-40-percent">
          <input type="text" class="input input--search" maxlength="1024"
            [placeholder]="'imageBank.placeholder' | translate" [(ngModel)]="userQueryText"
            (keyup.enter)="onInputQueryText()" ngModelOptions="{ debounce: 200 }"
            [ngClass]="userQueryText.length > 0 ? 'showClearBtn' : 'hideClearBtn'">
            <div class="icon icon-remove" (click)="clearInputQueryText()"></div>
          </div>
        }
        @if (selectedSource === SOURCE_IMAGE.SEARCH && selectedSourceKind === SOURCE_KIND.GIF) {
          <div class="flex align-items-center search-filter-container width-40-percent">
            <input type="text" class="input input--search" maxlength="1024"
              [placeholder]="'imageBank.gifPlaceholder' | translate" [(ngModel)]="userGifQueryText"
              (keyup.enter)="onGifQueryText()" ngModelOptions="{ debounce: 200 }"
              [ngClass]="userGifQueryText.length > 0 ? 'showClearBtn' : 'hideClearBtn'">
              <div class="icon icon-remove" (click)="clearGifQueryText()"></div>
            </div>
          }
          <!-- Info tooltip for search images in Pexels -->
          <div class="flex align-items-center margin-left-10" [ngStyle]="{'visibility': selectedSource === SOURCE_IMAGE.SEARCH && selectedSourceKind === SOURCE_KIND.PICTURE ? 'visible' : 'hidden'}"
            kendoTooltip [tooltipTemplate]="tooltipQuery" filter="span" position="bottom">
            <span class="group-form__label__text group-form__label__help icon icon-help-fill icon-help-fill-18" kendo-tooltip></span>
          </div>
          <ng-template #tooltipQuery>
            <p [translate]="'imageBank.modal.tooltip.title'"></p>
            <ul>
              <li [translate]="'imageBank.modal.tooltip.lineOne'"></li>
              <li [translate]="'imageBank.modal.tooltip.lineTwo'"></li>
            </ul>
          </ng-template>
        </div>

        <!-- Source image is upload from local -->
        @if (selectedSource === SOURCE_IMAGE.UPLOAD) {
          @if (selectImageMode) {
            <div class="flex justify-content-center align-items-center" style="min-height: 85%">
              <documents-upload class="min-width-100-percent padding-20 height-100-percent" [imageUpload]="true" (addDocuments)="uploadImages($event)"></documents-upload>
            </div>
          }
          @if (!selectImageMode) {
            <div class="dialog-modal-body flex direction-column padding-top-20" style="height: 70vh; overflow: hidden;">
              <div class="dui-cropper-container" [ngClass]="{'cropper-ready': isCropperReady}" style="height: 90%">
                <image-cropper
                  [imageChangedEvent]="selectedImageURL"
                  [maintainAspectRatio]="false"
                  [aspectRatio]="1/1"
                  [roundCropper]="false"
                  [imageURL]="selectedImageURL"
                  format="png"
                  (imageCropped)="imageCropped($event)"
                  (cropperReady)="isCropperReady = true">
                </image-cropper>
              </div>
            </div>
          }
        }
        <!-- Source image is static image (Pexels) -->
        @if (selectedSource === SOURCE_IMAGE.SEARCH && selectedSourceKind === SOURCE_KIND.PICTURE) {
          @if (selectImageMode) {
            <div class="flex align-items-center direction-column">
              @if (responseData.total_results > 0) {
                <div class="scroll7 flex justify-content-center animated animated-500" style="height: 75vh; overflow-y: scroll; overflow-x: hidden;" infiniteScroll
                  [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="150" [scrollWindow]="false" (scrolled)="onScroll()" [infiniteScrollDisabled]="isSyncing || (currentPage*10) >= responseData.total_results"
                  [ngClass]="isSyncing && currentPage == 1 ? 'fadeOut' : 'fadeIn'">
                  <kendo-gridlayout [rows]="[210,210,210,210]" [cols]="[210,210,210,210,210]" [gap]="10" style="padding-right: 8px">
                    @for (photo of responseData?.photos; track photo; let i = $index) {
                      <kendo-gridlayout-item (click)="selectImage(photo)"
                        [row]="getItemRow(i)" [col]="getItemCol(i)" [rowSpan]="getItemRowSpan(i)" [colSpan]="getItemColSpan(i)" [ngClass]="['box', 'griditem', 'animated', 'animated-500', 'fadeInUp']" [style.--gridItemNumber]="secondsCounter(i)">
                        <div class="img-select-button">
                          <loading-button [button-id]="'select-image'" [classes]="'button button--small button--secondary'" [text]="'imageBank.modal.selectBtnText'"
                            (action)="selectImage(photo)" [isValid]="true">
                          </loading-button>
                        </div>
                        <div class="image" style="background-image: url({{photo.src.large}})"></div>
                      </kendo-gridlayout-item>
                    }
                  </kendo-gridlayout>
                </div>
              }
            </div>
            <div class="margin-top-100">
              @if (responseData.total_results == 0) {
                <no-search-results class="margin-top-100"></no-search-results>
              }
            </div>
          }
          @if (!selectImageMode) {
            <div class="dialog-modal-body flex direction-column padding-top-0" style="height: 70vh; overflow: hidden;">
              <div class="info-advice margin-top-0 margin-bottom-10">
                <p [textContent]="'message.imageModalSubtitle' | translate"></p>
              </div>
              <div class="dui-cropper-container" [ngClass]="{'cropper-ready': isCropperReady}" style="height: 90%">
                <image-cropper
                  [imageChangedEvent]="selectedImageURL"
                  [maintainAspectRatio]="data.cropOptions.ASPECT_RATIO !== 'none'"
                  [aspectRatio]="data.cropOptions.ASPECT_RATIO"
                  [cropperMinWidth]="data.cropOptions.MINIMUM_WIDTH"
                  [roundCropper]="data.roundedCanvas || false"
                  [imageURL]="selectedImageURL"
                  format="png"
                  (imageCropped)="imageCropped($event)"
                  (cropperReady)="isCropperReady = true">
                </image-cropper>
              </div>
            </div>
          }
        }

        <!-- Source image is GIF (Giphy) -->
        @if (selectedSource === SOURCE_IMAGE.SEARCH && selectedSourceKind === SOURCE_KIND.GIF) {
          <div class="flex align-items-center direction-column">
            @if (responseGifData.pagination?.total_count > 0) {
              <div class="scroll7 flex justify-content-center animated animated-500" style="height: 75vh; overflow-y: scroll; overflow-x: hidden;" infiniteScroll
                [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="150" [scrollWindow]="false" (scrolled)="onScroll()" [infiniteScrollDisabled]="isSyncing || (currentPage*10) >= responseGifData.pagination.total_count"
                [ngClass]="isSyncing && currentPage == 1 ? 'fadeOut' : 'fadeIn'">
                <kendo-gridlayout [rows]="[210,210,210,210]" [cols]="[210,210,210,210,210]" [gap]="10" style="padding-right: 8px">
                  @for (gif of responseGifData?.data; track gif; let i = $index) {
                    <kendo-gridlayout-item (click)="selectGif(gif)"
                      [row]="getItemRow(i)" [col]="getItemCol(i)" [rowSpan]="getItemRowSpan(i)" [colSpan]="getItemColSpan(i)" [ngClass]="['box', 'griditem', 'animated', 'animated-500', 'fadeInUp']" [style.--gridItemNumber]="secondsCounter(i)">
                      <div class="img-select-button">
                        <loading-button [button-id]="'select-image'" [classes]="'button button--small button--secondary'" [text]="'imageBank.modal.selectBtnText'"
                          (action)="selectGif(gif)" [isValid]="true">
                        </loading-button>
                      </div>
                      <div class="image" style="background-image: url({{gif.images.original.webp}})"></div>
                    </kendo-gridlayout-item>
                  }
                </kendo-gridlayout>
              </div>
            }
          </div>
          <div class="margin-top-100">
            @if (responseGifData.pagination?.total_count == 0) {
              <no-search-results class="margin-top-100"></no-search-results>
            }
          </div>
        }
        @if (selectedSource === SOURCE_IMAGE.SEARCH && selectedSourceKind === SOURCE_KIND.GIF) {
          <div>
            <span class="flex justify-content-center aling-items-center bg-black black-15 font-size-12 opacity-70"
              style="position: absolute; right: 0px; bottom: 0px; width: fit-content; padding: 5px 10px" [textContent]="'imageBank.poweredGiphy' | translate">
            </span>
          </div>
        }
      </div>

      <!-- Footer -->
      @if (!selectImageMode && selectedSourceKind === SOURCE_KIND.PICTURE) {
        <div class="modal-footer flex justify-end" style="margin: 0;">
          <button id="confirmation-modal-accept-button" class="button button--secondary" (click)="onAccept()" cdkFocusInitial>
            <span class="button__label" [textContent]="data.acceptButtonText | translate"></span>
          </button>
          <button id="confirmation-modal-cancel-button" class="button margin-left-10" (click)="onCancel()">
            <span class="button__label" [textContent]="data.cancelButtonText | translate"></span>
          </button>
        </div>
      }
    </div>