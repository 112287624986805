<div class="dialenga-textarea__container dui__container">

  @if (!isRichFormat) {
    <textarea [id]="textareaId" class="dialenga-textarea" [(ngModel)]="inputModel" (ngModelChange)="modelValueIsChanging()" [placeholder]="(placeholder | translate)"
      [disabled]="isDisabled" [readonly]="isReadOnly" (focus)="modelValueOnFocus()" (focusout)="modelValueOnFocusOut()" (keydown.esc)="onKeyPressed($event)"
    minlength="{{minLength?.amount}}" maxlength="{{maxLength?.amount}}" rows="2" autocomplete="off"></textarea>
  }
  @if (isRichFormat) {
    <kendo-editor #editor [id]="textareaId" class="dialenga-textarea--editor" [value]="inputModel" [placeholder]="(placeholder | translate)"
      [disabled]="isDisabled" [readonly]="isReadOnly" [resizable]="false" [iframe]="false" [pasteCleanupSettings]="pasteCleanupSettings"
      (focus)="modelValueOnFocus()" (blur)="modelValueOnFocusOut()" (valueChange)="editorValueChange($event, editor)" (keydown.esc)="onKeyPressed($event)">
      <kendo-toolbar>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
          @if (showListsBtns) {
            <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
          }
          @if (showLinkBtns) {
            <kendo-toolbar-button icon="link-horizontal" (click)="createLink(editor)" [title]="'global.editor.addLink' | translate"></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
          }
        </kendo-toolbar-buttongroup>
      </kendo-toolbar>
      <kendo-editor-messages
        [bold]="('global.editor.bold' | translate)"
        [italic]="('global.editor.italic' | translate)"
        [underline]="('global.editor.underline' | translate)"
        [unlink]="('global.editor.removeLink' | translate)"
        [createLink]="('global.editor.addLink' | translate)"
        [linkText]="'model.message.relatedlinkTitle' | translate"
        [linkWebAddress]="'model.message.relatedLinkURL' | translate"
        [dialogInsert]="('global.form.add' | translate)"
        [dialogCancel]="('global.form.cancel' | translate)"
      ></kendo-editor-messages>
    </kendo-editor>
  }

  @if (isReadOnly) {
    <span class="dui__icon dui__icon--read-only icon icon-lock-fill-s"></span>
  }
  <div class="dui__actions-container" [ngClass]="{'dui__actions-container--with-header': isRichFormat}">
    <div  class="dui__message" [ngClass]="{'dui__message--error': !isValid}">
      @if (isWarningMessageActive && isValid && inputOnFocus) {
        <span class="dui__message__text dui__message__text--align-right"
          [ngClass]="{'warning' : colorWarningMessage == this.MESSAGE_COLOR.WARNING, 'danger' : colorWarningMessage == this.MESSAGE_COLOR.DANGER}"
        [textContent]="warningMessage"></span>
      }
      @if (!isValid) {
        <span class="dui__message__text" class="warning" [textContent]="errorMessage"></span>
      }
    </div>
    @if (inputOnFocus && !isReadOnly && !isDisabled && !hideActionsBtns) {
      <div class="dui__action-buttons">
        <div [id]="textareaId + '-dialenga-accept-button'" class="dui__action-button" (mousedown)="modelValueAccept($event)">
          <span class="dui__action-button__icon icon icon-accept"></span>
        </div>
        <div [id]="textareaId + '-dialenga-cancel-button'" class="dui__action-button" (mousedown)="modelValueCancel()">
          <span class="dui__action-button__icon icon icon-revert"></span>
        </div>
      </div>
    }
  </div>
</div>