import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Constants } from '../../../constants';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../services/utils.service';

declare var mobiscroll: any;

@Component({
    selector: 'date-selector',
    template: `<div style="display: none"></div>`
})
export class DateSelectorComponent implements OnInit, OnChanges {

    @Input() instance: any;
    @Input() inputId: string;
    @Input() defaultValue: string;
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() showToday?: boolean;
    @Output() instanceChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() errorFunction?: EventEmitter<any> = new EventEmitter<any>();
    @Output() dateSetted: EventEmitter<any> = new EventEmitter<any>();

    constructor(private translate: TranslateService, private utilsService: UtilsService) {
        this.translate = translate;
    }
    private hook: any;
    private anchor = '#';

    ngOnInit() {
        this.hook = this.inputId.split('-');
        for (let idx = 0; idx < this.hook.length - 1; idx++) {
            this.anchor += idx === 0 ? this.hook[idx] : '-' + this.hook[idx];
        }

        if (this.inputId) {
            this.initializeRangeComponent(this.inputId, this.dateSetted, this.anchor);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.maxDate || changes.minDate) {
            if (!!this.instance) {
                this.initializeRangeComponent(this.inputId, this.dateSetted, this.anchor);
            }
        }
    }

    initializeRangeComponent(id: string, dateSetted: any, anchor: string) {
        const userSession = window['getUserSession']();
        let language = userSession && userSession.language ? userSession.language : Constants.defaultLanguage;
        language = this.utilsService.getShortLanguageKey(language);
        mobiscroll.settings = {
            theme: 'dialenga'
        };
        this.instance = mobiscroll.calendar('#' + this.inputId, {
            display: 'bubble',
            anchor: anchor,
            showOverlay: false,
            showOnTap: false,
            // showOnFocus: true,
            setOnDayTap: true,
            focusOnClose: false,
            defaultValue: this.defaultValue,
            buttons: this.showToday ? ['now'] : [],
            nowText: this.showToday ? this.translate.instant('timeoffRequests.validation.today') : '',
            months: 1,
            returnFormat: 'jsdate',
            dateFormat: 'yyyy-mm-dd',
            lang: language,
            min: this.minDate,
            max: this.maxDate,
            onSet: function (event, inst) {
                dateSetted.emit();
            }
        });
        this.instanceChange.emit(this.instance);
    }

}
