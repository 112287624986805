import { Component, OnInit } from '@angular/core';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { Constants } from '../../constants';
import { TimeTrackingService } from '../time-tracking/time-tracking.service';

@Component({
    selector: 'time-tracking-notifications',
    templateUrl: 'time-tracking-notifications.component.html'
})

export class TimeTrackingNotificationsComponent implements OnInit {

    public timeTrackingNotificationsForm: timeTrackingNotificationsForm;
    public closedPeriodNotificationDisabled: boolean = false;
    private SHIFT_EDITION_PERIOD: any;

    constructor(
        private notificationService: DuiNotificationsService,
        private timeTrackingService: TimeTrackingService,
    ) {
        this.SHIFT_EDITION_PERIOD = Constants.SHIFT_EDITION_PERIOD;
        
        this.timeTrackingNotificationsForm = {
            pendingRecordNotification: false,
            periodClosingNotification: false,
            shiftEditionPeriod: 1
        };
    }

    ngOnInit() {
        this.getDayRecordNotificationsConfig();
    }

    private getDayRecordNotificationsConfig(): void {
        this.timeTrackingService.getNotificationsConfig().subscribe(
            (response): void => {
                this.timeTrackingNotificationsForm = response;
                this.closedPeriodNotificationDisabled = this.timeTrackingNotificationsForm.shiftEditionPeriod === this.SHIFT_EDITION_PERIOD.ONGOING_WORKDAY || 
                                                        this.timeTrackingNotificationsForm.shiftEditionPeriod === this.SHIFT_EDITION_PERIOD.CUSTOMIZED ||
                                                        this.timeTrackingNotificationsForm.shiftEditionPeriod === this.SHIFT_EDITION_PERIOD.DISABLED
            },
            (error: any): void => {
                console.error(error);
            }
        );
    }

    setPendingRecordNotification() {
        this.timeTrackingService.togglePendingRecordNotification(this.timeTrackingNotificationsForm.pendingRecordNotification).subscribe(
            (response): void => {
                this.notificationService.showSuccessNotification();
            },
            (error): void => {
                this.notificationService.showErrorNotification();
            }
        );
    }

    setPeriodClosingNotification() {
        this.timeTrackingService.togglePeriodClosingNotification(this.timeTrackingNotificationsForm.periodClosingNotification).subscribe(
            (response): void => {
                this.notificationService.showSuccessNotification();
            },
            (error): void => {
                this.notificationService.showErrorNotification();
            }
        );
    }

}

type timeTrackingNotificationsForm = {
    pendingRecordNotification: boolean,
    periodClosingNotification: boolean,
    shiftEditionPeriod: number
};
