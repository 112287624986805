import { Component, OnInit } from '@angular/core';
import { DocumentSettingsService } from '../document-settings/document-settings.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { Constants } from '../../constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'document-settings-global',
    templateUrl: './document-settings-global.component.html'
})
export class DocumentSettingsGlobalComponent implements OnInit {

    public docSettingsGlobalForm: any = {
        documentOpener: null,
        allowDownloading: false
    };

    public DOCUMENT_OPENER = Constants.DOCUMENT_OPENER;

    constructor(
        private documentSettingsService: DocumentSettingsService, 
        private notificationService: DuiNotificationsService, 
        private translate: TranslateService) 
    { 

    }

    ngOnInit() {
        this.getDocumentSettings();
    }

    getDocumentSettings() {
        this.documentSettingsService.getDocumentSettings().subscribe(
            (response: any) => {
                this.docSettingsGlobalForm = response;
            },
            (error: any) => {
                // Show notification error
            }
        )
    }

    showSuccessNotification() {
        const notificationOptions: NotificationOptions = {
            kind: 'success',
            message: this.translate.instant('global.messages.changesUpdated.success')
        };
        this.notificationService.showNotification(notificationOptions);
    }

    showErrorNotification() {
        const notificationOptions: NotificationOptions = {
            kind: 'error',
            message: this.translate.instant('global.messages.error.unknown')
        }
        this.notificationService.showNotification(notificationOptions);
    }

    setDocumentSettings() {
        if (this.docSettingsGlobalForm.documentOpener === this.DOCUMENT_OPENER.USER_DESIRED_APP) {
            this.docSettingsGlobalForm.allowDownloading = true;
        }
        this.documentSettingsService.updateDocumentSettings(this.docSettingsGlobalForm).subscribe(
            (response) => {
                this.showSuccessNotification();
            },
            (error) => {
                this.showErrorNotification();
            }
        )
    } 

    changeDocumentOpener(kind: number) {
        if (this.docSettingsGlobalForm.documentOpener !== kind) {
            this.docSettingsGlobalForm.documentOpener = kind;
            this.setDocumentSettings();
        }
    }
    
}