import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

export const ScopeProvider = {
  deps: ['$injector'],
  provide: '$scope',
  useFactory: (injector: Injector) => injector.get('$rootScope').$new(),
};

@Directive({
  providers: [ScopeProvider],
  selector: 'dialenga-tooltip'
})
export class DialengaTooltipDirective extends UpgradeComponent {

  @Input() tooltipText: any;
  @Input() classes?: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('dialengaTooltip', elementRef, injector);
  }
}
