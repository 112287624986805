<div class="modal-header">
  <h3 class="modal-title" [textContent]="data.title"></h3>
  <button type="button" class="button button--only-icon modal__buton-close" (click)="goBack()">
    <span class="button__icon icon icon-close"></span>
  </button>
</div>

<div class="modal-body">
  <p [translate]="'externalLinks.paramsModal.paragraphOne'"></p>

  <article>
    <h4 [translate]="'externalLinks.paramsModal.variableListTitle'"></h4>
    <ul class="scroll7">
      @for (param of paramsList; track param) {
        <li>
          <div class="params params__name">
            <span class="bold" [textContent]="param.name"></span>
            <span class="icon icon-16 icon-copy black-45" (click)="copyToClipboard(param, $event)">
              <span class="copied-toast" [translate]="'externalLinks.paramsModal.copiedToClipboard'"></span>
            </span>
          </div>
          <div class="params">
            <span [textContent]="param.description"></span>
          </div>
        </li>
      }
    </ul>
  </article>

  <h4 [translate]="'externalLinks.paramsModal.combinedParamsTitle'"></h4>
  <p class="margin-top-8" [innerHTML]="'externalLinks.paramsModal.paragraphTwo' | translate"></p>

  <div class="info-advice filter-container">
    <span [textContent]="exampleParams"></span>
  </div>
</div>

<div class="modal-footer flex justify-end">
  <button type="button" class="button" (click)="goBack()">
    <span class="button__label" [textContent]="'global.modal.close' | translate"></span>
  </button>
</div>