<article class="date-range-filter">
  <section class="button-bar date-range-filter__buttons">
    @for (filter of this.filterTypes; track filter) {
      @if (filter.isQuick) {
        <button class="button button--small" [class]="{ 'button--selected' : selectedButton === filter.id }" [id]="(filter.id === (this.filterTypes.length - 1) ? 'custom' : '')" (click)="filter.id !== (this.filterTypes.length - 1) ? setDates(filter.id, filter.dates.firstDate, filter.dates.lastDate) : getCustomDates()">
          <span class="button__label" [textContent]="filter.title | translate"></span>
        </button>
      }
    }
  </section>
  <section id="date-range-filter-calendar">
    <ul id="filters-shortcuts" class="date-range-filter__list" style="display: none">
      @for (filter of this.filterTypes; track filter) {
        <li class="data-range-filter__list-element">
          <button class="button" [class]="{'button--active' : selectedLink === filter.id}" (click)="setDates(filter.id, filter.dates.firstDate, filter.dates.lastDate, filter.isShortcut)">
            <span class="button__label" [textContent]="filter.title | translate"></span>
          </button>
        </li>
      }
    </ul>
  </section>
</article>
