<div class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [textContent]="'workplaces.newWorkplace' | translate"></h3>
</div>
<div class="modal-body">
    <!-- Workplace name -->
    <div class="group-form group-form--label-150">
        <div class="group-form__label">
            <span class="group-form__label__text" [textContent]="'model.segment.name' | translate"></span>
        </div>
        <div class="group-form__data">
            <input #workplaceName id="workplaceName" class="input" data-cy="workplace-name"
                [(ngModel)]="workplaceForm.name" (ngModelChange)="onChange()" (keyup.enter)="onEnterPressed()"
                maxlength="64" [placeholder]="'workplaces.placeholderTitle' | translate" />
        </div>
    </div>
    <!-- Workplace director -->
    <div class="group-form group-form--label-150 margin-bottom-0">
        <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'timeoff.teams.grid.teamLeader'"></span>
            <small class="group-form__label__text">({{'global.form.optional' | translate}})</small>
        </div>
        <div class="group-form__data">
            <user-dropdown class="min-width-100-percent" [(inputModel)]="workplaceForm.director"></user-dropdown>
        </div>
    </div>
</div>
<div class="modal-footer flex justify-end">
    <loading-button [buttonId]="'workplace-create'" [classes]="'button--secondary margin-right-10'" [text]="'model.workplaces.createWorkplace'" 
        (action)="onAccept()" [isValid]="isFormValid">
    </loading-button>
    <button id="workplace-cancel" data-cy="workplace-cancel" type="button" class="button" (click)="onCancel()">
        <span class="button__label" [textContent]="'global.modal.cancel' | translate"></span>
    </button>
</div>