import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToDecimal'
})
export class NumberToDecimal  implements PipeTransform {
  transform(field: number, decimals: number): string {
    decimals = decimals || 2;
    return !!field ? parseFloat(field.toFixed(decimals)).toString().replace('.', ',') : '';
  }
}