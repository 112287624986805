<div class="main-container">
  <div class="main-container__header">
    <div class="flex align-items-start justify-space-between width-100-percent">
      <div class="flex direction-column width-100-percent" spellcheck="false">
        <ul class="breadcrumb margin-bottom-0">
          <li>
            <a href="/global-settings" [textContent]="'global.navbar.globalSettings' | translate"></a>
          </li>
        </ul>
        <h2 [textContent]="'chatrooms.title' | translate"></h2>
      </div>
      <div class="flex align-items-end">
        <button id="create-chatroom" class="button button--secondary button--small" (click)="createChatroom()">
          <span class="button__icon icon icon-add"></span>
          <span class="button__label" [textContent]="'chatrooms.newChatroom' | translate"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="main-container__content">
    <div class="main-container--full-width">
      <div class="flex justify-space-between margin-bottom-20">
        <div class="filters-buttons-bar-container flex vertical-center">
          <div class="label-filters">
            <span [ngClass]="{'selected': !dataFilterOpts.archived}" (click)="getChatroomsByIsArchived(false)" [textContent]="'chatrooms.titleAvailables' | translate"></span>
            <span [ngClass]="{'selected': dataFilterOpts.archived}" (click)="getChatroomsByIsArchived(true)" [textContent]="'chatrooms.titleArchived' | translate"></span>
          </div>
        </div>
        <!-- filter by chatroom name, description and admins-->
        <div class="search-filter-container align-items-center margin-bottom-5">
          <input type="text" class="input input--search" maxlength="1024" [placeholder]="'documents.list.filters.searchPlaceholder' | translate" [(ngModel)]="dataFilterOpts.name"
            (input)="listItemsSearch()" ngModelOptions="{ debounce: 200 }" [ngClass]="dataFilterOpts.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'">
            <div class="icon icon-remove" (click)="clearSearchInput()"></div>
          </div>
        </div>

        @if (chatroomsDatagrid?.data.length > 0) {
          <kendo-grid #chatroomsGrid [data]="chatroomsDatagrid" class="master-grid user-select-none" [sortable]="true" [sort]="gridSort" (sortChange)="onSortChange($event)"
            [pageable]="gridPagerSettings" (pageChange)="onPageChange($event)" [skip]="dataPagingOpts.skip" [pageSize]="dataPagingOpts.size"
            [navigable]="true" [resizable]="false" [selectable]="false" [loading]="isSyncing" (cellClick)="onCellClick($event)" (dblclick)="onDblClick($event)">
            <kendo-grid-messages
              [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate" [pagerOf]="'global.lists.pagerOf' | translate"
              [pagerItems]="'global.lists.pagerItems' | translate">
            </kendo-grid-messages>
            <!-- Toggle open/close chatroom -->
            <kendo-grid-column width="76" [hidden]="dataFilterOpts.archived && !isSyncing">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="min-height-20" kendoTooltip filter="span" offset="10">
                  <label class="toggle__container padding-top-5 justify-content-center">
                    <div class="toggle toggle--small margin-right-0" [ngClass]="{'toggle--active': dataItem.state === CHATROOM_STATES.ACTIVE}" >
                      <span class="toggle__indicator"
                        [title]="(dataItem.state === CHATROOM_STATES.ACTIVE ? 'global.form.active_female' : 'global.form.inactive_female') | translate">
                      </span>
                    </div>
                    <input class="toggle__input" type="checkbox" (value)="dataItem.state" (click)="toggleActivateChat(dataItem)" />
                  </label>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- Chatroom name -->
            <kendo-grid-column field="name">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center cursor-pointer">
                  <span class="truncate" [textContent]="'chatrooms.grid.headers.name' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <avatar class="margin-right-10" [thumbnails]="{thumbnail_M: dataItem.avatarThumbnailMUrl, thumbnail_S: dataItem.avatarThumbnailSUrl}"
                    [chatroom]="true" [ngStyle]="dataItem.state === CHATROOM_STATES.INACTIVE && {opacity: '0.5'}">
                  </avatar>
                  <span class="truncate cursor-default" [textContent]="dataItem.name"></span>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- Chatroom admins -->
            <kendo-grid-column>
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center padding-left-10">
                  <span [textContent]="'chatrooms.grid.headers.admins' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                @if (dataItem.admins?.length < 2) {
                  @if (dataItem.admins?.length > 0 && (!!dataItem.admins[0].name && !!dataItem.admins[0].surname)) {
                    <div class="flex align-items-center">
                      <avatar [name]="dataItem.admins[0].name" [surname]="dataItem.admins[0].surname" [small]="true"
                        [thumbnails]="{thumbnail_M: dataItem.admins[0].avatarThumbnailMUrl || dataItem.admins[0].avatarUrl, thumbnail_S: dataItem.admins[0].avatarThumbnailSUrl || dataItem.admins[0].avatarThumbnailMUrl}">
                      </avatar>
                      <span class="margin-left-10" [textContent]="dataItem.admins[0].name"></span>
                      <span class="margin-left-5 truncate" [textContent]="dataItem.admins[0].surname"></span>
                    </div>
                  }
                } @else {
                  <div class="conflicts-avatar-list--avatar-small margin-right-10" kendoTooltip filter="div.avatar" position="top">
                    @for (admin of dataItem.admins | slice:0:4; track admin; let i = $index) {
                      <avatar [name]="admin.name" [surname]="admin.surname" [title]="admin.name + ' ' + admin.surname" [small]="true"
                        [thumbnails]="{thumbnail_M: admin.avatarThumbnailMUrl || admin.avatarUrl, thumbnail_S: admin.avatarThumbnailSUrl || admin.avatarThumbnailMUrl}">
                      </avatar>
                    }
                    @if (dataItem.admins?.length > 4) {
                      <span class="avatar avatar--small conflicts-avatar-list__more">
                        <span class="avatar__label">+{{dataItem.admins.length - 4}}</span>
                      </span>
                    }
                  </div>
                }
              </ng-template>
            </kendo-grid-column>
            <!-- Chatroom participants count -->
            <kendo-grid-column field="participantCount">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center justify-content-center cursor-pointer" [style.marginInline]="'auto'">
                  <span [textContent]="'chatrooms.grid.headers.participants' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center justify-content-center">
                  <span class="document-label__text cursor-default" [textContent]="dataItem.participantCount"></span>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- Chatroom last activity date -->
            <kendo-grid-column field="lastActivityDate" media="(min-width: 1360px)" [sortable]="false">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center padding-left-8">
                  <span [textContent]="'chatrooms.grid.headers.lastActivity' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <span class="truncate cursor-default" style="text-transform: lowercase;" [textContent]="dataItem.lastActivityDate | date: 'dd/MM/yyyy HH:mm'"></span>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- CTA buttons column -->
            <kendo-grid-command-column title="" width="120" [style]="{ 'text-align': 'end' }">
              <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <div class="flex justify-end td-on-hover" kendoTooltip filter="a.k-master-button" offset="0">
                  <a class="k-button k-master-button" (click)="updateChatroom(dataItem)" [title]="'global.form.edit' | translate">
                    <span class="icon icon-edit"></span>
                  </a>
                  @if (!dataItem.deletable || dataItem.state === CHATROOM_STATES.ARCHIVED) {
                    <a class="k-button k-master-button" (click)="archiveOrReactivateChat(dataItem)"
                      [title]="(dataItem.state !== CHATROOM_STATES.ARCHIVED ? 'global.form.archive' : 'global.form.activate') | translate">
                      <span class="icon" [ngClass]="dataItem.state !== CHATROOM_STATES.ARCHIVED ? 'icon-archive' : 'icon-unarchive'"></span>
                    </a>
                  }
                  @if (dataItem.deletable && dataItem.state !== CHATROOM_STATES.ARCHIVED) {
                    <a class="k-button k-master-button"  (click)="deleteChat(dataItem)" [title]="'global.form.delete' | translate">
                      <span class="icon icon-delete"></span>
                    </a>
                  }
                </div>
              </ng-template>
            </kendo-grid-command-column>
            <ng-template kendoGridNoRecordsTemplate></ng-template>
            <!-- Custom Pager -->
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
              <article class="dui-grid-pager">
                <kendo-pager-info></kendo-pager-info>
                @if (chatroomsDatagrid.total > 10) {
                  <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                    <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                    <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                    <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                  </article>
                  <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                }
              </article>
            </ng-template>
          </kendo-grid>
        }
        <ng-template kendoGridLoadingTemplate>
          <div class="k-loading-color"></div>
          <div class="loading-container">
            <kendo-loader type="converging-spinner" size="large"></kendo-loader>
          </div>
        </ng-template>

        <!-- Skeleton chatrooms grid -->
        @if (chatroomsDatagrid?.total == 0 && isSyncing) {
          <div>
            <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid" [rowClass]="skeletonRowCallback">
              <!-- Toggle -->
              <kendo-grid-column width="5%"></kendo-grid-column>
              <!-- Chatroom name -->
              <kendo-grid-column width="22%" [headerStyle]="{'padding': '0.5em 0.6em'}">
                <ng-template kendoGridHeaderTemplate>
                  <div class="flex align-items-center">
                    <span class="padding-left-10" [textContent]="'chatrooms.grid.headers.name' | translate"></span>
                  </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="flex align-items-center">
                    <kendo-skeleton class="margin-right-10" shape="circle" animation="pulse" [width]="40" [height]="40" style="min-width: 40px"></kendo-skeleton>
                    <kendo-skeleton shape="text" animation="pulse" [width]="200" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <!-- Chatroom admins -->
              <kendo-grid-column width="22%">
                <ng-template kendoGridHeaderTemplate>
                  <div class="flex align-items-center padding-left-10">
                    <span [textContent]="'chatrooms.grid.headers.admins' | translate"></span>
                  </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="flex align-items-center">
                    <kendo-skeleton shape="circle" animation="pulse" [width]="28" [height]="28"></kendo-skeleton>
                    <kendo-skeleton shape="text" animation="pulse" [width]="150" [height]="20" style="border-radius: 4px" class="margin-left-10"></kendo-skeleton>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <!-- Chatroom total users -->
              <kendo-grid-column width="22%">
                <ng-template kendoGridHeaderTemplate>
                  <div class="flex align-items-center justify-content-center padding-left-5">
                    <span [textContent]="'chatrooms.grid.headers.participants' | translate"></span>
                  </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="flex align-items-center justify-content-center">
                    <kendo-skeleton shape="text" animation="pulse" [width]="20" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <!-- Chatroom last activity date -->
              <kendo-grid-column width="20%" media="(min-width: 1360px)">
                <ng-template kendoGridHeaderTemplate>
                  <div class="flex align-items-center padding-left-8 cursor-pointer">
                    <span [textContent]="'chatrooms.grid.headers.lastActivity' | translate"></span>
                  </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-skeleton shape="text" animation="pulse" [width]="100" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                </ng-template>
              </kendo-grid-column>
              <!-- CTA column -->
              <kendo-grid-command-column title="" width="10%"></kendo-grid-command-column>
            </kendo-grid>
          </div>
        }
        <!-- No data information -->
        @if ((chatroomsDatagrid.total == 0 && !dataFilterOpts.name && !isSyncing) && !dataFilterOpts.archived) {
          <no-results
            [imageTitle]="'chatrooms.noResultsTitle'" [description]="'chatrooms.noResultsText'">
          </no-results>
        }
        @if ((chatroomsDatagrid.total == 0 && !dataFilterOpts.name && !isSyncing) && dataFilterOpts.archived) {
          <no-results
            [imageTitle]="'chatrooms.noResultsTitle'" [description]="'chatrooms.noResultsArchiveText'">
          </no-results>
        }
        @if (chatroomsDatagrid.total == 0 && dataFilterOpts.name && !isSyncing) {
          <no-search-results></no-search-results>
        }
      </div>
    </div>
  </div>