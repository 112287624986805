<div class="boxes-user-categories-container">
  <div class="group">
    @if (availableCategories?.length > 0) {
      <a [routerLink]="" queryParamsHandling="preserve" class="remove-all" [translate]="'users.editorUser.categories.addAll'"
      (click)="addAllFilteredAvailableCategories()"></a>
    }
    @if (!availableCategories || availableCategories.length > 0) {
      <ul class="box-groups provider relative-to-view" id="available-categories-container">
        @for (category of availableCategories; track category) {
          <li class="category-item non-selectable text-left" attr.data-name="{{category.name}}"
            [ngClass]="{'added': category.added, 'selected': category.selected }" (click)="selectCategoryFromAvailables(category, $event)" (dblclick)="doubleClickedCategory(category)">
            @if (category.added) {
              <span class="icon icon-sm icon-accept-1 black"></span>
            }
            <span class="category-path__base" [ngStyle]="{'background-color': categoryColor(category.color)}" [textContent]="category.name"></span>
          </li>
        }
      </ul>
    }
    @if (availableCategories?.length == 0) {
      <div class="box-groups flex justify-center align-items-center relative-to-view">
        @if (!filterAvailable && isSyncing === false) {
          <no-results [imageTitle]="'model.segment.noResultsTitle'"></no-results>
        }
        @if (filterAvailable && isSyncing === false) {
          <no-search-results></no-search-results>
        }
      </div>
    }
  </div>

  <div id="add-selected-items-button" class="icon icon-arrow-fill" [ngClass]="{'active': selectedCategories.length > 0 }" (click)="addSelectedCategories()"></div>

  <div class="group">
    @if (addedCategories?.length > 0) {
      <a [routerLink]="" queryParamsHandling="preserve" class="remove-all" [translate]="'users.editorUser.categories.removeAll'"
      (click)="removeAllFilteredAddedCategories()"></a>
    }
    @if (filteredAddedCategories?.length > 0) {
      <ul id="added-categories-container" class="box-groups relative-to-view">
        @for (addedCategory of filteredAddedCategories; track trackByFn($index, addedCategory)) {
          <li class="category-item text-left">
            <span class="category-path__base" [ngStyle]="{'background-color': categoryColor(addedCategory.color)}" [textContent]="addedCategory.name"></span>
            <div class="icon icon-remove" (click)="removeAddedItemFromList(addedCategory)"></div>
          </li>
        }
      </ul>
    }
    @if (filteredAddedCategories?.length == 0) {
      <div class="box-groups flex justify-center align-items-center relative-to-view">
        @if (!filterAdded && isSyncing === false) {
          <no-results  [description]="'Selecciona las categorías disponibles para el usuario.'"
            >
          </no-results>
        }
        @if (filterAdded && isSyncing === false) {
          <no-search-results></no-search-results>
        }
      </div>
    }
  </div>
</div>