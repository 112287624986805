import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { ImageTransform } from 'ngx-image-cropper';

@Component({
    selector: 'dialenga-image-cropper',
    templateUrl: './dialenga-image-cropper.component.html'
})
export class DialengaImageCropperComponent implements OnChanges, AfterContentChecked {

    @Input() sourceImage: string;
    @Input() mimeType?: string;
    @Input() cropperOptions: any;
    @Input() targetImage: string;
    @Input() roundedCropper?: boolean;
    @Input() transformOptions: any;
    @Output() targetImageChange: EventEmitter<string> = new EventEmitter<string>();

    constructor(private cdRef: ChangeDetectorRef) {}

    public showCropper: boolean = false;
    public isCropperReady: boolean = false;
    public transform: ImageTransform = {
        scale: 1
    }
    public resizeToWidth: number = 0;
    public resizeToHeight: number = 0;

    ngAfterContentChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.transformOptions && !changes.transformOptions.firstChange) {
            this.transform = {...this.transform, scale: this.transformOptions};
        }
    }

    imageCropped(event: any) {
        this.targetImageChange.emit(event.base64);
    }

    imageLoaded() {
        this.resizeToWidth = this.cropperOptions.MAX_WIDTH;
        this.resizeToHeight = this.cropperOptions.MAX_HEIGHT;
        this.showCropper = true;
    }
    
    mustMaintainAspectRatio(): boolean {
        return this.cropperOptions.ASPECT_RATIO !== 'none';
    }
}
