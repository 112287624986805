import { Component, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";
import { SortDescriptor} from "@progress/kendo-data-query";
import { CompaniesService } from '../companies/companies.service';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';

@Component({
    selector: 'company-employees',
    templateUrl: './company-employees.component.html'
})
export class CompanyEmployeesComponent implements OnChanges {
    @Input() employees: Array<any>;
    @Input() companyId: number;

    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [5,10,20,100],
    };
    public isSyncing: boolean = false;
    public companyEmployeesDatagrid: GridDataResult = {
        data: [],
        total: 0
    };
    
    public gridPagingOps: any = {
        page: 0,
        size: 10,
        skip: 0,
        sort: 'name,asc'
    };
    public gridFilteringOps: any = {
        name: ''
    };
    public gridSort: SortDescriptor[];
    public totalPages: number = 0;

    public dataSkeleton = [{},{},{}];
    public selectedRows: number = 0;
    public toggleAllItemsSelect: boolean = false;

    @ViewChild('companyEmployeesGrid') companyEmployeesGrid: GridComponent;

    constructor(
        private companiesService: CompaniesService, private formUtilsService: FormUtilsService,
        private notificationService: DuiNotificationsService, private translate: TranslateService,
        public dialogService: DialogService
        ) { 
            
        }
        
    ngOnChanges(changes: SimpleChanges): void {
        if ( changes.employees && changes.employees.previousValue == undefined && changes.employees.firstChange ) {
            this.loadCompanyEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
        }
        if ( changes.employees && !changes.employees.firstChange ) {
            this.updateEmployees(changes.employees.currentValue);
        }
    }

    loadCompanyEmployeesGridData(pagingOptions: any, filterOptions: any) :void {
        this.isSyncing = true;

        this.companiesService.findAllEmployees(this.companyId, pagingOptions, filterOptions).subscribe(
            (response) => {
                this.companyEmployeesDatagrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.totalPages = response.totalPages;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    updateEmployees(users: Array<any>) {
        this.isSyncing = true;

        this.companiesService.updateEmployees(this.companyId, users).subscribe(
            () => {
                this.loadCompanyEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        )
    }

    deleteEmployee(data: any): void {
        const dialogValues: DialogValueOptions = {
            title: 'company.employees.delete.title',
            message: 'company.employees.delete.message',
            messageParam: {fullName: data.name + ' ' + data.surname}
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe(result => {
            if(result?.accept) {
                this.isSyncing = true;
                this.companiesService.deleteEmployee(this.companyId, data.id).subscribe(
                    (response) => {
                        this.loadCompanyEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
                        const notificationOptions: NotificationOptions = {
                            kind: 'success',
                            message: this.translate.instant('company.employees.delete.confirmation', {fullName: data.name + ' ' + data.surname})
                        }
                        this.notificationService.showNotification(notificationOptions);
                    },
                    (error: any) => {
                        // Show notification error
                    }
                );
            }
        })
    }

    listItemsSearch() {
        if (this.gridFilteringOps.name.length >= 3 || this.gridFilteringOps.name.length === 0) {
            this.loadCompanyEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
        }
    }

    clearSearchInput() {
        this.gridFilteringOps.name = '';
        this.loadCompanyEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2 
        }
    }

    public onSortChange(sort: SortDescriptor[]): void {
        this.gridSort = sort;        
        this.gridPagingOps.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : 'name,asc';
        this.loadCompanyEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
    }

    public onPageChange(event: PageChangeEvent): void {
        this.gridPagingOps.page = (event.skip / this.gridPagingOps.size);
        this.gridPagingOps.skip = event.skip;
        this.gridPagingOps.size = event.take;

        this.loadCompanyEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
        this.toggleAllItemsSelect = false;
        this.selectedRows = 0;
    }
}
