<dui-popover class="dui-popover-avatar">
  <ng-content popoverItem></ng-content>
  <div class="dui-popover-container">
    <div class="dui-popover-container__item">
      <div class="dui-popover-container__item__label">
        <span class="margin-right-5" [translate]="'users.dni'"></span>
      </div>
      <div class="dui-popover-container__item__value" (mouseenter)="showCopyIcon('nationalId')" (mouseleave)="hideCopyIcon('nationalId')">
        @if (nationalId) {
          <span class="dui-popover-container__item__value__text" [textContent]="nationalId"></span>
        }
        @if (nationalId && isCopyIconVisible['nationalId']) {
          <span class="dui-popover-container__item__value__icon icon nucleo icon-copy"
          (click)="copyToClipboard(nationalId)"></span>
        }
        @if (!nationalId) {
          <span class="dui-popover-container__item__value__text--empty" [translate]="'global.form.notSet'"></span>
        }
      </div>
    </div>
    <div class="dui-popover-container__item margin-top-15">
      <div class="dui-popover-container__item__label">
        <span class="margin-right-5" [translate]="'users.userId'"></span>
      </div>
      <div class="dui-popover-container__item__value" (mouseenter)="showCopyIcon('username')" (mouseleave)="hideCopyIcon('username')">
        @if (username) {
          <span class="dui-popover-container__item__value__text" [textContent]="username"></span>
        }
        @if (username && isCopyIconVisible['username']) {
          <span class="dui-popover-container__item__value__icon icon nucleo icon-copy"
          (click)="copyToClipboard(username)"></span>
        }
        @if (!username) {
          <span class="dui-popover-container__item__value__text--empty" [translate]="'global.form.notSet'"></span>
        }
      </div>
    </div>
    <div class="dui-popover-container__item margin-top-15">
      <div class="dui-popover-container__item__label">
        <span class="margin-right-5" [translate]="'users.internalId'"></span>
      </div>
      <div class="dui-popover-container__item__value" (mouseenter)="showCopyIcon('internalId')" (mouseleave)="hideCopyIcon('internalId')">
        @if (internalId) {
          <span class="dui-popover-container__item__value__text" [textContent]="internalId"></span>
        }
        @if (internalId && isCopyIconVisible['internalId']) {
          <span class="dui-popover-container__item__value__icon icon nucleo icon-copy"
          (click)="copyToClipboard(internalId)"></span>
        }
        @if (!internalId) {
          <span class="dui-popover-container__item__value__text--empty" [translate]="'global.form.notSet'"></span>
        }
      </div>
    </div>
  </div>
</dui-popover>