import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'image-cropper-modal',
    templateUrl: './image-cropper-modal.component.html',
  })
  export class ImageCropperModalComponent {
    public croppedImage: any;
    public scale: number = 1;
    private ZOOM_100 = 5;
    public zoomImage: number = 5;

    constructor(
        public dialogRef: MatDialogRef<ImageCropperModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}
  
    onTransformImage(): void {
        this.scale = this.zoomImage / this.ZOOM_100;
    }

    onAccept(): void {
        this.dialogRef.close( {accepted: true, image: this.croppedImage});
    }

    onClose(): void {
        this.dialogRef.close();
    }
}