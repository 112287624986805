<div class="main-container">
  <div class="main-container__header" style="min-width: 891px;">
    <div class="flex align-items-end justify-space-between width-100-percent">
      <div class="flex direction-column width-100-percent" spellcheck="false">
        <ul class="breadcrumb">
          <li>
            <a href="/global-settings" [textContent]="'global.navbar.globalSettings' | translate"></a>
          </li>
          <li>
            <a id="go-schedules" href="/settings/time-tracking/schedules" [textContent]="'global.navbar.timeTracking' | translate"></a>
          </li>
        </ul>
        <div class="editable-wrapper">
          <h2 #scheduleParentName class="icon icon-edit contenteditable editable-title width-50-percent"
            [textContent]="scheduleForm?.name" ([ngModel])="scheduleForm?.name" [contentEditable]="true" prevent-event="dragover drop"
            (blur)="onInputScheduleNameEvent($event)" (keydown.enter)="onInputScheduleNameEvent($event)" (keydown.esc)="onInputScheduleNameEvent($event)"
          maxlength="64" (paste)="onInputScheduleNamePaste($event)"></h2>
          <span class="bottom-line"></span>
        </div>
      </div>
      <div class="flex align-items-center" kendoTooltip filter="button" position="top">
        <!-- Add employees to schedule -->
        @if (selectedTab === SCHEDULE_TABS.USERS) {
          <loading-button [buttonId]="'add-users-modal'" [classes]="'button button--secondary button--small'" [iconClass]="'icon-add'"
            [text]="'schemes.employees.addEmployees'" (action)="addUsersToSchedule()" [isValid]="true">
          </loading-button>
        }
        <!-- Archive/restore schedule -->
        <!-- <ng-container *ngIf="!scheduleForm.deletable && selectedTab !== SCHEDULE_TABS.USERS">
        <button id="archive-unarchive-btn" class="button button--small button--only-icon margin-left-10" (click)="archiveOrRestore()"
          [title]="(scheduleForm.active ? 'global.form.archive' : 'global.form.activate') | translate">
          <span class="button__icon icon" [ngClass]="scheduleForm.active ? 'icon-archive' : 'icon-unarchive'"></span>
        </button>
      </ng-container> -->
      <!-- Delete schedule -->
      @if ((scheduleForm.deletable && scheduleForm.active) && selectedTab !== SCHEDULE_TABS.USERS) {
        <button id="delete-btn" class="button button--small button--only-icon margin-left-10" (click)="deleteSchedule()"
          [title]="'global.form.delete' | translate">
          <span class="button__icon icon icon-delete"></span>
        </button>
      }
    </div>
  </div>
</div>
<div class="main-container__content">
  <div class="main-container--full-width">
    <div class="tabs-language-container margin-bottom-20">
      <div class="tab-nav tab-nav--inline" style="width:100%">
        <span id="general-tab" class="tab-nav__item" [ngClass]="{'tab-nav__item--selected': selectedTab === SCHEDULE_TABS.GENERAL}"
          [translate]="'timeTracking.schedule.tabs.general'" (click)="loadTabContent(SCHEDULE_TABS.GENERAL)">
        </span>
        <span id="kinds-tab" class="tab-nav__item" [ngClass]="{'tab-nav__item--selected': selectedTab === SCHEDULE_TABS.RECORD_KINDS}"
          [translate]="'timeTracking.schedule.tabs.recordKinds'" (click)="loadTabContent(SCHEDULE_TABS.RECORD_KINDS)">
        </span>
        <span id="users-tab" class="tab-nav__item" [ngClass]="{'tab-nav__item--selected': selectedTab === SCHEDULE_TABS.USERS}"
        [translate]="'timeTracking.schedule.tabs.employees'" (click)="loadTabContent(SCHEDULE_TABS.USERS)"></span>
      </div>
    </div>
  </div>
</div>
@if (selectedTab === SCHEDULE_TABS.GENERAL) {
  <schedule-general [scheduleId]="scheduleId" [(scheduleForm)]="scheduleForm"></schedule-general>
}
@if (selectedTab === SCHEDULE_TABS.RECORD_KINDS) {
  <schedule-record-kinds [scheduleId]="scheduleId"></schedule-record-kinds>
}
@if (selectedTab === SCHEDULE_TABS.USERS) {
  <schedule-employees [scheduleId]="scheduleId" [(employees)]="scheduleForm.employees"></schedule-employees>
}
</div>