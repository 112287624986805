import { Component, Input } from '@angular/core';

@Component({
    selector: 'user-avatar',
    templateUrl: './user-avatar.component.html'
})
export class UserAvatarComponent {

    @Input() user: any;
    @Input() small: boolean;
    @Input() medium: boolean;
    @Input() anonymous?: boolean;
    @Input() showUsername: boolean;

    constructor() { }

}
