<div class="main-container__content">
  <div class="main-container--medium-width">

    <p class="margin-bottom-32" [translate]="'documents.nameConvention.advice'"></p>

    @if (conventionsDatagrid?.data.length > 0) {
      <kendo-grid #conventionsGrid [data]="conventionsDatagrid" class="master-grid avoid-row-effect"
        [sortable]="false" [pageable]="false" [navigable]="true" [resizable]="false" [selectable]="false" [loading]="isSyncing">
        <!-- Document kind icon -->
        <kendo-grid-column width="60">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="document-icon" [ngStyle]="{'background-color': getDocumentTagHexColor(dataItem.kind?.color)}" [ngClass]="{'batch-document-icon--empty': !dataItem.kind}"
              [title]="dataItem.kind ? dataItem.kind.name : 'documents.documentsKind.filterTagNames.other' | translate">
              <span class="icon icon-24 white-color" [ngClass]="getDocumentIcon(dataItem.kind?.id)"></span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <!-- Document kind name -->
        <kendo-grid-column>
          <ng-template kendoGridHeaderTemplate>
            <div class="flex align-items-center">
              <span class="truncate" [textContent]="'documents.import.docType' | translate"></span>
            </div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="flex align-items-center">
              <span class="truncate cursor-default" [textContent]="dataItem.kind?.name"></span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <!-- Document convention -->
        <kendo-grid-column>
          <ng-template kendoGridHeaderTemplate>
            <div class="flex align-items-center padding-left-5">
              <span [textContent]="'documents.nameConvention.conventionColumnTitle' | translate"></span>
            </div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="flex align-items-center" kendoTooltip filter="span" offset="0">
              <span #description class="truncate cursor-default bold" [textContent]="dataItem.name"
                [title]="checkElementOverflow(description) ? dataItem.name : ''">
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <!-- Convention example -->
        <kendo-grid-column>
          <ng-template kendoGridHeaderTemplate>
            <div class="flex align-items-center padding-left-8">
              <span [textContent]="'documents.nameConvention.exampleColumnTitle' | translate"></span>
            </div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="flex align-items-center">
              <span class="truncate cursor-default" [textContent]="dataItem.example"></span>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    }
    <!-- Skeleton chatrooms grid -->
    @if (conventionsDatagrid?.total == 0 && isSyncing) {
      <div>
        <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid" [rowClass]="skeletonRowCallback">
          <!-- Document kind icon -->
          <kendo-grid-column width="60">
            <ng-template kendoGridCellTemplate>
              <div class="flex align-items-center">
                <kendo-skeleton shape="text" animation="pulse" [width]="28" [height]="40" style="border-radius: 4px"></kendo-skeleton>
              </div>
            </ng-template>
          </kendo-grid-column>
          <!-- Document kind name -->
          <kendo-grid-column>
            <ng-template kendoGridHeaderTemplate>
              <div class="flex align-items-center">
                <div class="flex align-items-center">
                  <span class="truncate" [textContent]="'documents.import.docType' | translate"></span>
                </div>
              </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="flex align-items-center">
                <kendo-skeleton shape="text" animation="pulse" [width]="200" [height]="20" style="border-radius: 4px"></kendo-skeleton>
              </div>
            </ng-template>
          </kendo-grid-column>
          <!-- Document convention -->
          <kendo-grid-column>
            <ng-template kendoGridHeaderTemplate>
              <div class="flex align-items-center padding-left-5">
                <span [textContent]="'documents.nameConvention.conventionColumnTitle' | translate"></span>
              </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-skeleton shape="text" animation="pulse" [width]="250" [height]="20" style="border-radius: 4px"></kendo-skeleton>
            </ng-template>
          </kendo-grid-column>
          <!-- Convention example -->
          <kendo-grid-column>
            <ng-template kendoGridHeaderTemplate>
              <div class="flex align-items-center padding-left-8">
                <span [textContent]="'documents.nameConvention.exampleColumnTitle' | translate"></span>
              </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="flex align-items-center">
                <kendo-skeleton shape="text" animation="pulse" [width]="250" [height]="20" style="border-radius: 4px"></kendo-skeleton>
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    }
    <!-- No data information -->
    @if ((conventionsDatagrid.total == 0 && !isSyncing)) {
      <no-results
        [imageTitle]="'documents.nameConvention.errorTitle'">
      </no-results>
    }
  </div>
</div>