import { ViewEncapsulation } from '@angular/core';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'dui-select-multiple-soft',
    templateUrl: './dui-select-multiple-soft.component.html',
    styleUrls: ['./_dui-select-multiple-soft.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DuiSelectMultipleSoftComponent implements OnInit {

    @Input() availableOptions: Array<any>;
    @Input() inputModel: any;
    @Input() textFieldName: string;
    @Input() valueFieldName: string;
    @Input() showClearBtn?: boolean;
    @Input() popupClass?: string;
    @Input() placeholder: string;
    @Input() summaryTagFrom?: number;
    @Input() isReadOnly?: boolean;
    @Output() inputModelChange: EventEmitter<any> = new EventEmitter();
    @Output() inputChanged: EventEmitter<any> = new EventEmitter();

    constructor() {
        
    }

    onChangeSelectedValue (): void {
        this.inputModelChange.emit(this.inputModel);
        this.inputChanged.emit();
    }

    ngOnInit () {
        
    }
}
