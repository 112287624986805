<div class="time-record-detail" [ngClass]="{'time-record-detail--visible': selectedRow.length > 0}">
  <div class="overlay" [ngStyle]="isSyncing && {'visibility': 'visible'}"></div>
  <section class="time-record-detail__header-top">
    <span class="icon icon-16 icon-close" (click)="onCloseDetail()"></span>
  </section>
  <!-- Title and day status or day info tags -->
  <section class="time-record-detail__header-title">
    <h3 [translate]="'timeTracking.detail.title'"></h3>

    @if (!dayDetailForm.fullDayAbsence && !dayDetailForm.fullDayHoliday && !dayDetailForm.publicHoliday) {
      <!-- Complete day registration -->
      @if (dayDetailForm.workday && dayDetailForm.balanceTime >= 0) {
        <div class="time-record-detail__label-state time-record-detail__label-state--complete">
          <span [translate]="'timeTracking.list.grid.completeTag'"></span>
        </div>
      }
      <!-- Incomplete day registration -->
      @if (dayDetailForm.workday && dayDetailForm.balanceTime < 0 && dayDetailForm.workedTime) {
        <div class="time-record-detail__label-state time-record-detail__label-state--incomplete">
          <span [translate]="'timeTracking.list.grid.incompleteTag'"></span>
        </div>
      }
      <!-- Pending day registration -->
      @if (dayDetailForm.workday && dayDetailForm.balanceTime < 0 && !dayDetailForm.workedTime) {
        <div class="time-record-detail__label-state time-record-detail__label-state--pending">
          <span [translate]="'timeTracking.list.grid.pendingTag'"></span>
        </div>
      }
      <!-- Not working day (p.e. week end) -->
      @if (!dayDetailForm.workday) {
        <div class="time-record-detail__label-state time-record-detail__label-state--noWorkday">
          <span [translate]="'timeTracking.list.grid.noWorkdayTag'"></span>
        </div>
      }
    }
    <!-- Day is holiday (full day), has absence (full day) or public Holiday (by calendar) -->
    @if (dayDetailForm.fullDayAbsence || dayDetailForm.fullDayHoliday || dayDetailForm.publicHoliday) {
      @if (!!dayDetailForm.publicHoliday) {
        <div class="time-record-detail__label-state time-record-detail__label-state--publicHoliday">
          <span [textContent]="dayDetailForm.publicHoliday"></span>
        </div>
      }
      @if (dayDetailForm.fullDayHoliday) {
        <div class="time-record-detail__label-state time-record-detail__label-state--holiday">
          <span [translate]="'timeTracking.list.grid.holidayTag'"></span>
        </div>
      }
      @if (dayDetailForm.fullDayAbsence) {
        <div class="time-record-detail__label-state time-record-detail__label-state--absence">
          <span [translate]="'timeTracking.list.grid.absenceTag'"></span>
        </div>
      }
    }

  </section>
  <!-- Info day -->
  <section class="time-record-detail__container scroll7">
    <div class="time-record-detail__info-item" kendoTooltip filter="a">
      <span [translate]="'timeoffRequests.employee'"></span>
      <div class="flex align-items-center">
        <avatar-popover class="cursor-default" [nationalId]="user?.nationalIdNumber" [username]="user?.username" [internalId]="user?.companyInternalId || ''">
          <avatar [name]="user?.name" [surname]="user?.surname" [small]="true" class="margin-horizontal-10"
            [thumbnails]="{thumbnail_M: user?.avatarThumbnailMUrl || user?.avatarUrl, thumbnail_S: user?.avatarThumbnailSUrl || user?.avatarThumbnailMUrl}">
          </avatar>
        </avatar-popover>
        <a class="info-color text-decoration-none cursor-pointer non-selectable margin-left-8" [title]="'users.linkToUser' | translate"
        [textContent]="user?.fullName" [href]="'/user/personaldata/update/'+ user?.id"></a>
      </div>
    </div>
    <div class="time-record-detail__info-item margin-top-4">
      <span [translate]="'model.users.documents.documentDate'"></span>
      <span [textContent]="getFormattedDate()"></span>
    </div>
    @if (!dayDetailForm?.fullDayHoliday && !dayDetailForm?.fullDayAbsence && !dayDetailForm.publicHoliday && dayDetailForm?.workday) {
      <div class="time-record-detail__info-item margin-top-24">
        <span [translate]="'timeTracking.detail.scheduledHours'"></span>
        <span [textContent]="dayDetailForm?.workdayTime | hoursMinutes:{inputKind: 'seconds', showSign: false, large: true}"></span>
      </div>
    }
    @if (dayDetailForm?.workedTime ||
      (!dayDetailForm?.workedTime && (!dayDetailForm?.fullDayHoliday && !dayDetailForm?.fullDayAbsence && !dayDetailForm.publicHoliday && dayDetailForm?.workday))) {
      <div class="time-record-detail__info-item margin-top-24">
        <span [translate]="'timeTracking.detail.workedHours'"></span>
        <span [textContent]="dayDetailForm?.workedTime | hoursMinutes:{inputKind: 'seconds', showSign: false, large: true}"></span>
      </div>
    }
    @if (dayDetailForm?.balanceTime !== 0) {
      <div class="time-record-detail__info-item margin-top-24">
        <span [translate]="'timeTracking.list.grid.balance'"></span>
        <span [textContent]="dayDetailForm?.balanceTime | hoursMinutes:{inputKind: 'seconds', showSign: true, large: true}"></span>
      </div>
    }
    <span class="spacer"></span>
    <!-- Info for partial holidays or absences -->
    @if (dayDetailForm.partialHoliday || dayDetailForm.partialAbsence) {
      <div class="time-record-detail__info-item">
        <span [innerHTML]="'timeTracking.detail.timeoffHours' | translate"></span>
        <div class="flex direction-column" style="gap: 8px;">
          @if (dayDetailForm.partialAbsence) {
            <div class="label-kind time-record-detail__label-state--absence">
              <span class="label-kind__text"
              [textContent]="('timeTracking.list.grid.absenceTag' | translate) + ' • ' + (dayDetailForm?.absenceTime | hoursMinutes:{inputKind: 'seconds', showSign: false, large: true})"></span>
            </div>
          }
          @if (dayDetailForm.partialHoliday) {
            <div class="label-kind time-record-detail__label-state--holiday">
              <span class="label-kind__text" [translate]="'timeTracking.detail.partialHoliday'"></span>
            </div>
          }
        </div>
      </div>
      <span class="spacer"></span>
    }

    <!-- Records -->
    @if (dayDetailForm.records?.length > 0) {
      <h3 class="font-size-16 margin-bottom-16" [translate]="'timeTracking.detail.records'"></h3>
    }

    <section class="time-record-detail__records-container" [ngClass]="{'margin-bottom-16': dayDetailForm.records?.length > 0}">
      @for (record of dayDetailForm?.records; track record; let index = $index) {
        <div class="time-record-detail__record-item relative">
          <div class="time-record-detail__cta-btns">
            <span class="action-btn icon icon-16 icon-edit" (click)="editRecord(record)"></span>
            <span class="action-btn icon icon-16 icon-delete" (click)="deleteRecord(record)"></span>
          </div>
          <span [translate]="'model.users.documents.kind'"></span>
          <div class="label-kind" [ngStyle]="{'background-color': decimalToHex(record.kind.color)}">
            <span class="label-kind__text truncate" [textContent]="record.kind.name"></span>
          </div>
          <span [translate]="'timeTracking.detail.departure'"></span>
          <span [textContent]="record.endTime"></span>
          <span [translate]="'timeTracking.detail.entry'"></span>
          <span [textContent]="record.startTime"></span>
        </div>
      }
    </section>

    <div class="info-color text-decoration-none cursor-pointer non-selectable flex align-items-center justify-content-end" (click)="addRecords()">
      <span class="icon icon-16 icon-add margin-right-8"></span>
      <span [translate]="'timeTracking.detail.addInterval'"></span>
    </div>
  </section>
</div>