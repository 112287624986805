import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UsersSegmentsService } from '../../shared/services/users-segments.service'
import { Constants } from '../../constants';
import { isEqual } from 'lodash';

@Component({
    selector: 'chatroom-admins-modal',
    templateUrl: './chatroom-admins-modal.component.html'
})
export class ChatroomAdminsModalComponent implements OnInit {

    public isSyncing: boolean = false;
    public userSegments: Array<any> = [];
    public selectedUsers: Array<any> = [];
    public totalAvailableItems: number = 0;
    public originGroup: number;
    public filter: any = {
        availableUsers: '',
        addedUsers: ''
    };
    public availableUsers: Array<any> = [];
    public addedUsersIds: Array<any> = [];
    public arePendingChanges: boolean = false;
    private initialAddedUsersIds: Array<any> = [];
    public addedUsers: Array<any> = [ ...this.data.users ];
    public globalSearchOpened: boolean = false;
    public groupSearchOpened: boolean = false;
        
    @ViewChild('globalSearch') globalSearchRef: ElementRef;
    @ViewChild('groupSearch') groupSearchRef: ElementRef;

    constructor( 
        public dialogRef: MatDialogRef<ChatroomAdminsModalComponent>, private SegmentsService: UsersSegmentsService, 
        @Inject(MAT_DIALOG_DATA) public data: any,

    )  { 
        this.SegmentsService.findAll({ sort: 'name', active: true}).subscribe(
            (response) => {
                this.userSegments = response.content;
            }
        )
    }

    ngOnInit() {
        this.loadAvailableSegmentedUsers();
        this.initialAddedUsersIds = this.getAddedUsersIds();
    }

    loadAvailableSegmentedUsers() {
        let options = {
            page: 0,
            size: 20000
        };
        const availableUsersStates = [
            Constants.USER_STATES_FILTER.ANONYMOUS,
            Constants.USER_STATES_FILTER.PENDING_ACTIVATION,
            Constants.USER_STATES_FILTER.REGISTERED,
            Constants.USER_STATES_FILTER.EXPIRED_CREDENTIALS,
            Constants.USER_STATES_FILTER.LOCKED
        ];
        if (!!this.originGroup) {
            options['user.name'] = this.filter.availableUsers;
            options['user.state'] = availableUsersStates;
            this.getSegmentAvailableUsers(this.originGroup, options);
        } else {
            options['name'] = this.filter.availableUsers;
            options['state'] = availableUsersStates;
            this.getAllAvailableUsers(options);
        }
    }

    setAvailableUsers(data: any) {
        this.availableUsers = data.content;
        this.totalAvailableItems = data.totalElements;
    }

    searchAvailableUsers(clearInput: boolean) {
        if(clearInput) { this.filter.availableUsers = ''; this.globalSearchRef.nativeElement.focus();}
       
        if(this.filter.availableUsers.length === 0) { this.selectedUsers = []; }

        if (this.filter.availableUsers.length >= 3 || this.filter.availableUsers.length === 0) {
            this.loadAvailableSegmentedUsers();
        }
    }

    getAllAvailableUsers(options: any) {
        this.isSyncing = true;
        this.SegmentsService.findAllSegmentedUsers(options).subscribe(
            (response) => {
                this.isSyncing = false;
                if (options.name === this.filter.availableUsers) {
                    this.setAvailableUsers(response);
                }
            });
    }

    getSegmentAvailableUsers(segmentId: number, options: any) {
        this.isSyncing = true;
        this.SegmentsService.getSegmentUsers(segmentId, options).subscribe(
            (response) => {
                this.isSyncing = false;
                if (options['user.name'] === this.filter.availableUsers) {
                    this.setAvailableUsers(response);
                }
        });
    }

    getAddedUsersIds() {
        return this.addedUsers.map(function (user) {
            return user.user;
        });
    }

    originGroupChange (event:any) {
        this.loadAvailableSegmentedUsers();
        this.selectedUsers = [];
    }

    globalSearchOpen(): void {
        this.globalSearchOpened = !this.globalSearchOpened;
        setTimeout(() => {
            this.globalSearchRef.nativeElement.focus();
        }, 500);
    }

    groupSearchOpen(): void {
        this.groupSearchOpened = !this.groupSearchOpened;
        setTimeout(() => {
            this.groupSearchRef.nativeElement.focus();
        }, 100);
    }

    clearGroupSearch(): void {
        this.filter.addedUsers = '';
        this.groupSearchRef.nativeElement.focus();
    }

    onAccept() {
        this.arePendingChanges = !isEqual(this.initialAddedUsersIds, this.addedUsersIds);
        let dataReturnedOnAccept: any = {
            arePendingChanges: this.arePendingChanges,
            addedUsers: this.arePendingChanges ? this.addedUsers :  null,
            addedUsersIds: this.arePendingChanges ? this.addedUsersIds : null
        };
        this.dialogRef.close(dataReturnedOnAccept);
    }
   
    onCancel() {
        this.dialogRef.close({ cancelled: true });
    }
    
}