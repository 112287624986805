import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { NormalizeUnicodePipe } from '../../pipes/normalize-unicode.pipe';

@Component({
    selector: 'items-selection-boxes',
    templateUrl: './items-selection-boxes.component.html'
})
export class ItemsSelectionBoxesComponent implements OnChanges {

    @Input() availableUsers: Array<any> = [];
    @Input() addedUsers: Array<any> = [];
    @Input() filterAvailable = '';
    @Input() filterAdded = '';
    @Input() isSyncing: boolean;
    @Input() addedUsersIds: Array<number>;
    @Input() remoteSignatureErrors?: boolean;

    @Output() addedUsersIdsChange: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();
    @Output() checkingUsersData: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

    private singleClickTimer: any;
    private preventSingleClick = false;
    public selectedUsers = [];
    public filteredAddedUsers = [];
    public sortAvailableUsersField = {name: 'name', reverse: false};
    public sortAddedUsersField = {name: 'name', reverse: false};

    constructor(private normalizeUnicodePipe: NormalizeUnicodePipe) { }

    ngOnChanges(changes: any) {
        if (changes.availableUsers && changes.availableUsers.currentValue &&
            changes.availableUsers.currentValue !== changes.availableUsers.previousValue) {
            this.unselectAvailableUsers();
            this.toggleAddedPropertyInAvailableUsers();
            this.sortAvailableUsers();
            this.selectedUsers = [];
        }
        if (changes.addedUsers && changes.addedUsers.currentValue &&
            (changes.addedUsers.currentValue !== changes.addedUsers.previousValue || changes.addedUsers.currentValue.length > 0)) {
            this.searchAddedUsers();
            this.addedUsersIds = this.getAddedUsersIds();
            this.addedUsersIdsChange.emit(this.addedUsersIds);
            this.sortFilteredAddedUsers();
        }
        if (changes.filterAdded && changes.filterAdded.currentValue !== changes.filterAdded.previousValue) {
            this.searchAddedUsers();
        }
        if (changes.filterAvailable && changes.filterAvailable.currentValue !== changes.filterAvailable.previousValue) {
            this.selectedUsers = [];
        }
    }

    trackByFn(index: number, item: any): number {
        return index;
    }

    private getIndexOfItemInUsers(users: Array<any>, userId: number): number {
        const usersLength = users.length;
        for (let index = 0; index < usersLength; index++) {
            const currentUserId = users[index].hasOwnProperty('id') ? users[index]['id'] : users[index]['user'];
            if (currentUserId === userId) {
                return index;
            }
        }
        return -1;
    }

    private getIndexOfItemInAvailableUsers(userId: number): number {
        const index = this.availableUsers.findIndex(availableUser => {
            return availableUser.id === userId;
        });
        return index;
    }

    private addAvailableUser(user: any): void {
        user['selected'] = false;

        const userId = user.hasOwnProperty('id') ? user['id'] : user['user'];

        if (this.addedUsersIds.indexOf(userId) === -1) {
            // If user is not already added, add it
            this.addedUsers.push(user);
        }

        const selectedUserIndex = this.getIndexOfItemInUsers(this.selectedUsers, userId);
        if (selectedUserIndex !== -1) {
            // If user was selected, remove from selectedUsers array
            this.selectedUsers.splice(selectedUserIndex, 1);
        }
    }

    private getAddedUsersIds(): Array<number> {
        const userIds = this.addedUsers.length > 0 ? this.addedUsers.map(function (user) {
            return user.hasOwnProperty('id') ? user['id'] : user['user'];
        }) : [];
        return userIds;
    }

    private toggleAddedPropertyInAvailableUsers(): void {
        const numAvailableUsers = this.availableUsers.length;
        for (let index = 0; index < numAvailableUsers; index++) {
            const userId = this.availableUsers[index].hasOwnProperty('id') ? this.availableUsers[index]['id'] : this.availableUsers[index]['user'];
            this.availableUsers[index]['added'] = this.addedUsersIds.indexOf(userId) !== -1;
        }
    }

    private searchAddedUsers(): void {
        const self = this;
        this.filteredAddedUsers = this.addedUsers.filter(function (user) {
            const searchString = self.normalizeUnicodePipe.transform(user['name'] + ' ' + user['surname'] + ' ' + user['email']);
            const inputText = self.normalizeUnicodePipe.transform(self.filterAdded);
            return inputText.length > 2 ? searchString.toLowerCase().indexOf(inputText.toLowerCase()) > -1 : true;
        });
    }

    private syncAddedUsers(): void {
        this.searchAddedUsers();
        this.addedUsersIds = this.getAddedUsersIds();
        this.addedUsersIdsChange.emit(this.addedUsersIds);
        
        // This code checks the user's data and emits it to the checkingUsersData event.
        this.checkingUsersData.emit(this.addedUsers);
        
        this.toggleAddedPropertyInAvailableUsers();
        this.sortAvailableUsers();
        this.sortFilteredAddedUsers();
    }

    doubleClickedUser(user: any): void {
        clearTimeout(this.singleClickTimer);
        this.preventSingleClick = true;
        if (!user.added) {
            this.addAvailableUser(user);
            this.syncAddedUsers();
        }
    }

    addAllFilteredAvailableUsers(): void {
        const numFilteredAvailableUsers = this.availableUsers.length;
        for (let index = 0; index < numFilteredAvailableUsers; index++) {
            this.addAvailableUser(this.availableUsers[index]);
        }
        this.syncAddedUsers();
    }

    private unselectAvailableUsers(): void {
        const numAvailableUsers = this.availableUsers.length;
        for (let index = 0; index < numAvailableUsers; index++) {
            this.availableUsers[index]['selected'] = false;
        }
    }

    addSelectedUsers(): void {
        if (document.querySelectorAll('.selected').length > 0) {
            Array.prototype.push.apply(this.addedUsers, this.selectedUsers);
            this.selectedUsers = [];
            this.syncAddedUsers();

            this.unselectAvailableUsers();
        }
    }

    private toggleUserInSelectedUsers(user: any, index: number): void {
        if (index === -1) {
            this.selectedUsers.push(user);
        } else {
            this.selectedUsers.splice(index, 1);
        }
    }

    selectUserFromAvailables(user: any, $event: any): void {
        const self = this;
        this.singleClickTimer = setTimeout(function () {
            if (!self.preventSingleClick) {
                if (!user.added) {
                    if ($event.shiftKey) {
                        const userIndex = self.getIndexOfItemInAvailableUsers(user.id);
                        for (let index = userIndex; index >= 0; index--) {
                            if (self.availableUsers[index]['selected']) {
                                break;
                            } else {
                                self.availableUsers[index]['selected'] = !self.availableUsers[index]['selected'];

                                const userId = self.availableUsers[index].hasOwnProperty('id') ? self.availableUsers[index]['id'] : self.availableUsers[index]['user'];
                                const userIndex = self.getIndexOfItemInUsers(self.selectedUsers, userId);
                                self.toggleUserInSelectedUsers(self.availableUsers[index], userIndex);
                            }
                        }
                    } else {
                        user.selected = !user.selected;

                        const userId = user.hasOwnProperty('id') ? user['id'] : user['user'];
                        const userIndex = self.getIndexOfItemInUsers(self.selectedUsers, userId);
                        self.toggleUserInSelectedUsers(user, userIndex);
                    }
                }
            }
            self.preventSingleClick = false;
        }, 200);
    }

    private removeAddedUser(user: any): void {
        user.added = false;

        const userId = user.hasOwnProperty('id') ? user['id'] : user['user'];
        const userIndex = this.getIndexOfItemInUsers(this.addedUsers, userId);
        if (userIndex !== -1) {
            this.addedUsers.splice(userIndex, 1);
        }
    }

    removeAddedItemFromList(user: any): void {
        this.removeAddedUser(user);
        this.syncAddedUsers();
    }

    removeAllFilteredAddedUsers(): void {
        if (this.filteredAddedUsers.length > 0) {
            const numFilteredAddedUsers = this.filteredAddedUsers.length;
            for (let index = 0; index < numFilteredAddedUsers; index++) {
                this.removeAddedUser(this.filteredAddedUsers[index]);
            }
        }
        this.syncAddedUsers();
    }

    private sortAscending(a: Array<any>, b: Array<any>, field: string): number {
        if (!a[field]) {
            return !b[field] ? 0 : -1;
        } else if (!b[field]) {
            return 1;
        }
        return a[field].toString().toUpperCase() > b[field].toString().toUpperCase() ? 1 : -1;
    }

    private sortDescending(a: Array<any>, b: Array<any>, field: string): number {
        if (!a[field]) {
            return !b[field] ? 0 : 1;
        } else if (!b[field]) {
            return -1;
        }
        return a[field].toString().toUpperCase() > b[field].toString().toUpperCase() ? -1 : 1;
    }

    private sortSingle(a: Array<any>, b: Array<any>, field: any): number {
        let result = 0;
        if (!field.reverse) {
            result = this.sortAscending(a, b, field.name);
        } else {
            result = this.sortDescending(a, b, field.name);
        }
        return result;
    }

    private sortFilteredAddedUsers(): void {
        if (!!this.filteredAddedUsers) {
            this.filteredAddedUsers.sort((a, b) => this.sortSingle(a, b, this.sortAddedUsersField));
        }
    }

    private sortMultiple(a: Array<any>, b: Array<any>, fields: Array<any>): number {
        let index = 0, result = 0;
        while (index < fields.length && result === 0) {
            result = this.sortSingle(a, b, fields[index]);
            index++;
        }
        return result;
    }

    private sortAddedPropertyAndMultiple(a: Array<any>, b: Array<any>, fields: Array<any>): number {
        if (!a['added']) {
            a['added'] = false;
        }
        if (!b['added']) {
            b['added'] = false;
        }
        if (a['added'] === b['added']) {
            return this.sortMultiple(a, b, fields);
        }
        return a['added'] - b['added'];
    }

    private sortAvailableUsers(): void {
        const sortFields = [{field: 'added', reverse: true}, this.sortAvailableUsersField];
        if (!!this.availableUsers) {
            this.availableUsers.sort((a, b) => this.sortAddedPropertyAndMultiple(a, b, sortFields));
        }
    }

}
