<div class="flex rating-icon-container" [title]="showTitle ? value : ''">
  @if (ratingKind === RATING_KIND.EMOJI) {
    <span class="rating-icon rating-image" [ngClass]="'emoji-' +ratingValue"></span>
  }
  @if (ratingKind === RATING_KIND.STAR) {
    <div class="flex stars">
      @for (starClass of starsClasses; track starClass) {
        <span [ngClass]="starClass"></span>
      }
    </div>
  }
</div>