import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MyCompanyService } from '../my-company/my-company.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { Constants } from '../../constants';
import { TranslateService } from '@ngx-translate/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

@Component({
    selector: 'my-company-global',
    templateUrl: './my-company-global.component.html'
})
export class MyCompanyGlobalComponent implements OnInit {
    // @Input() availableLanguages: Array<any>;
    @Input() userSession: any;

    @ViewChild('timezoneSelect') public timezoneSelect: ComboBoxComponent;
    public SUPPORTED_LANGUAGES: Array<any> = [];
    public supportedLangsToggle: boolean = false;

    public globalForm: any = {
        timeZone: {},
        defaultLanguage: '',
        availableLanguages: [],
        commentsDefault: null,
        sharingDefault: null,
        ratingDefault: null,
        ratingKind: null
    };
    public timezonesList: Array<any> = [];
    private timezoneSearch: string = '';
    private lastTimezone = {};
    public commentsAllowedToggle: boolean = false;
    public sharingAllowedToggle: boolean = false;
    public ratingAllowedToggle: boolean = false;

    public GENERAL_CONFIG_STATES = Constants.GENERAL_CONFIG_STATES;
    public RATING_KIND = Constants.RATING_KIND;

    constructor(
        private myCompanyService: MyCompanyService, 
        private notificationService: DuiNotificationsService, 
        private translate: TranslateService) 
    { 
        this.SUPPORTED_LANGUAGES = Constants.LANGUAGES
            .map((language: string) => ({ key: language, name: this.translate.instant('global.language.' + language), active: false }));
        this.SUPPORTED_LANGUAGES.sort((a,b) => { return a.name > b.name ? 1 : b.name > a.name ? -1 : 0});
    }

    ngOnInit() {
        this.getTimezonesAvailables(this.timezoneSearch);
        this.getGlobalSettings();
    }

    getTimezonesAvailables(textToSearch: string) {
        this.myCompanyService.getTimezones(textToSearch).subscribe(
            (response: any) => {
                this.timezonesList = response.content;
            },
            (error: any) => {
                // Show notification error
            }
        )
    }

    getAvailablesLanguages(): Array<any> {
        return this.SUPPORTED_LANGUAGES.filter((language: any) => language.active );
    }
    
    getGlobalSettings() {
        this.myCompanyService.myCompanyGlobalSettings().subscribe(
            (response: any) => {
                this.globalForm = response;
                this.commentsAllowedToggle = this.globalForm.commentsDefault !== this.GENERAL_CONFIG_STATES.FORCE_DISABLED; 
                this.sharingAllowedToggle = this.globalForm.sharingDefault !== this.GENERAL_CONFIG_STATES.FORCE_DISABLED; 
                this.ratingAllowedToggle = this.globalForm.ratingDefault !== this.GENERAL_CONFIG_STATES.FORCE_DISABLED; 
                this.lastTimezone = this.globalForm.timeZone;
                this.checkActiveLanguages(this.globalForm.availableLanguages, this.SUPPORTED_LANGUAGES);
            },
            (error: any) => {
                // Show notification error
            }
        )
    }

    checkActiveLanguages(availableLanguages, supportedLanguages): void {
        for (const language of availableLanguages) {
            const foundLanguage = supportedLanguages.find((lang) => lang.key === language);
            if (foundLanguage) {
                foundLanguage.active = true;
            }
        }
    }

    showSuccessNotification() {
        const notificationOptions: NotificationOptions = {
            kind: 'success',
            message: this.translate.instant('global.messages.changesUpdated.success')
        };
        this.notificationService.showNotification(notificationOptions);
    }

    showErrorNotification() {
        const notificationOptions: NotificationOptions = {
            kind: 'error',
            message: this.translate.instant('global.messages.error.unknown')
        }
        this.notificationService.showNotification(notificationOptions);
    }

    setAvailableLanguages() {
        this.myCompanyService.setAvailableLanguages(this.globalForm.availableLanguages).subscribe(
            (response) => {
                this.userSession.companyLanguages = this.globalForm.availableLanguages;
                this.showSuccessNotification();
            },
            (error) => {
                this.showErrorNotification();
            }
        );
    }

    setCommentsSettings() {
        this.myCompanyService.setCommentsState(this.globalForm.commentsDefault).subscribe(
            (response) => {
                this.userSession.commentsConfig = this.globalForm.commentsDefault;
                this.showSuccessNotification();
            },
            (error) => {
                this.showErrorNotification();
            }
        )
    } 

    setSharingSettings() {
        this.myCompanyService.setSharingState(this.globalForm.sharingDefault).subscribe(
            (response) => {
                this.userSession.shareConfig = this.globalForm.sharingDefault;
                this.showSuccessNotification();
            },
            (error) => {
                this.showErrorNotification();
            }
        )
    }

    setRatingSettings() {
        this.myCompanyService.setRatingState(this.globalForm.ratingDefault).subscribe(
            (response) => {
                this.userSession.ratingConfig = this.globalForm.ratingDefault;
                this.showSuccessNotification();
            },
            (error) => {
                this.showErrorNotification();
            }
        )
    }  

    setRatingKind() {
        this.myCompanyService.setRatingKind(this.globalForm.ratingKind).subscribe(
            (response) => {
                this.userSession.ratingKind = this.globalForm.ratingKind;
                this.showSuccessNotification();
            },
            (error) => {
                this.showErrorNotification();
            }
        )
    }    

    filterTimezones(filterValue?: string): void {
        this.timezoneSearch = filterValue;
        if (filterValue && filterValue.length >= 3) {
            this.getTimezonesAvailables(this.timezoneSearch);
        } else {
            this.timezoneSelect.toggle(false);
        }
    }

    enableDisableComments(event: any) {
        this.globalForm.commentsDefault = event.target.checked ? this.GENERAL_CONFIG_STATES.ENABLED : this.GENERAL_CONFIG_STATES.FORCE_DISABLED;
        this.setCommentsSettings();
    }

    changeCommentsSettings(state: any) {
        if (this.globalForm.commentsDefault !== state) {
            this.globalForm.commentsDefault = state;
            this.setCommentsSettings();
        }
    }

    enableDisableSharing(event: any) {
        this.globalForm.sharingDefault = event.target.checked ? this.GENERAL_CONFIG_STATES.ENABLED : this.GENERAL_CONFIG_STATES.FORCE_DISABLED;
        this.setSharingSettings();
    }

    changeSharingSettings(state: any) {
        if (this.globalForm.sharingDefault !== state) {
            this.globalForm.sharingDefault = state;
            this.setSharingSettings();
        }
    }

    enableDisableRating(event: any) {
        this.globalForm.ratingDefault = event.target.checked ? this.GENERAL_CONFIG_STATES.ENABLED : this.GENERAL_CONFIG_STATES.FORCE_DISABLED;
        this.setRatingSettings();
    }

    changeRatingSettings(state: any) {
        if (this.globalForm.ratingDefault !== state) {
            this.globalForm.ratingDefault = state;
            this.setRatingSettings();
        }
    }

    changeRatingKind(kind: any) {
        if (this.globalForm.ratingKind != kind) {
            this.globalForm.ratingKind = kind;
            this.setRatingKind();
        }
    }

    onChangeValueLanguage() {
        this.myCompanyService.setDefaultLanguage(this.globalForm.defaultLanguage).subscribe(
            (response) => {
                this.userSession.companyDefaultLanguage = this.globalForm.defaultLanguage;
                this.showSuccessNotification();
            },
            (error) => {
                this.showErrorNotification();
            }
        )
    }

    onChangeValueTimezone(value: any) {
        if (this.globalForm.timeZone && this.globalForm.timeZone !== this.lastTimezone) {
            this.lastTimezone = this.globalForm.timeZone;
            this.timezoneSelect.blur();
            this.myCompanyService.setTimezone(this.globalForm.timeZone).subscribe(
                (response) => {
                    this.userSession.companyTimeZone = this.globalForm.timeZone.timezone;
                    this.showSuccessNotification();
                },
                (error) => {
                    this.showErrorNotification();
                }
            )
        }
    }

    onBlurTimezone() {
        if (typeof this.globalForm.timeZone === 'undefined') {
            this.globalForm.timeZone = this.lastTimezone;
            this.timezoneSelect.value = this.lastTimezone
        }
    }

    updateAvailableLanguages(language: any): void {
        language.active = !language.active;

        if (language.active) {
            if (!this.globalForm.availableLanguages.includes(language.key)) {
                this.globalForm.availableLanguages.push(language.key);
            }
        } else {
            const index = this.globalForm.availableLanguages.indexOf(language.key);

            if (index !== -1) {
                this.globalForm.availableLanguages.splice(index, 1);
            }
        }
        this.supportedLangsToggle = false;
        this.setAvailableLanguages();
    }

    closeSupportedLanguages():void {
        this.supportedLangsToggle = false;
    }

    toggleSupportedLanguages(event):void {
        this.supportedLangsToggle = !this.supportedLangsToggle;
        // event.currentTarget.parentElement.focus();
    }
}