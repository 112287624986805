import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { NormalizeUnicodePipe } from '../../pipes/normalize-unicode.pipe';

@Component({
    selector: 'categories-selection-boxes',
    templateUrl: './categories-selection-boxes.component.html'
})
export class CategoriesSelectionBoxesComponent implements OnChanges {

    @Input() availableCategories: Array<any> = [];
    @Input() addedCategories: Array<any> = [];
    @Input() filterAvailable = '';
    @Input() filterAdded = '';
    @Input() isSyncing: boolean;
    @Input() addedCategoriesIds: Array<number>;
    @Output() addedCategoriesIdsChange: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

    private singleClickTimer: any;
    private preventSingleClick = false;
    public selectedCategories = [];
    public filteredAddedCategories = [];

    constructor(private normalizeUnicodePipe: NormalizeUnicodePipe) { }

    ngOnChanges(changes: any) {
        if (changes.availableCategories && changes.availableCategories.currentValue &&
            changes.availableCategories.currentValue !== changes.availableCategories.previousValue) {
            this.unselectAvailableCategories();
            this.toggleAddedPropertyInAvailableCategories();
        }

        if (changes.addedCategories && changes.addedCategories.currentValue &&
            (changes.addedCategories.currentValue !== changes.addedCategories.previousValue || changes.addedCategories.currentValue.length > 0)) {
            this.searchAddedCategories();
            this.addedCategoriesIds = this.getAddedCategoriesIds();
            this.addedCategoriesIdsChange.emit(this.addedCategoriesIds);
        }
        if (changes.filterAdded && changes.filterAdded.currentValue !== changes.filterAdded.previousValue) {
            this.searchAddedCategories();
        }
    }

    trackByFn(index: number, item: any): number {
        return index;
    }

    private getIndexOfItemInCategories(categories: Array<any>, categoryId: number): number {
        const categoriesLength = categories.length;
        for (let index = 0; index < categoriesLength; index++) {
            if (categories[index].id === categoryId) {
                return index;
            }
        }
        return -1;
    }

    private addAvailableCategory(category: any): void {
        category['selected'] = false;

        if (this.addedCategoriesIds.indexOf(category.id) === -1) {
            // If category is not already added, add it
            this.addedCategories.push(category);
        }

        const selectedCategoryIndex = this.getIndexOfItemInCategories(this.selectedCategories, category.id);
        if (selectedCategoryIndex !== -1) {
            // If category was selected, remove from selectedCategories array
            this.selectedCategories.splice(selectedCategoryIndex, 1);
        }
    }

    private getAddedCategoriesIds(): Array<number> {
        return this.addedCategories.length > 0 ? this.addedCategories.map(function (category) { return category.id; }) : [];
    }

    private toggleAddedPropertyInAvailableCategories(): void {
        const numAvailableCategories = this.availableCategories.length;
        for (let index = 0; index < numAvailableCategories; index++) {
            this.availableCategories[index]['added'] = this.addedCategoriesIds.indexOf(this.availableCategories[index].id) !== -1;
        }
    }

    private searchAddedCategories(): void {
        const self = this;
        this.filteredAddedCategories = this.addedCategories.filter(function (category) {
            const searchString = self.normalizeUnicodePipe.transform(category['name']);
            const inputText = self.normalizeUnicodePipe.transform(self.filterAdded);
            return inputText.length > 2 ? searchString.toLowerCase().indexOf(inputText.toLowerCase()) > -1 : true;
        });
    }

    private syncAddedCategories(): void {
        this.searchAddedCategories();
        this.addedCategoriesIds = this.getAddedCategoriesIds();
        this.addedCategoriesIdsChange.emit(this.addedCategoriesIds);
        this.toggleAddedPropertyInAvailableCategories();
    }

    doubleClickedCategory(category: any): void {
        clearTimeout(this.singleClickTimer);
        this.preventSingleClick = true;
        if (!category.added) {
            this.addAvailableCategory(category);
            this.syncAddedCategories();
        }
    }

    addAllFilteredAvailableCategories(): void {
        const numFilteredAvailableCategories = this.availableCategories.length;
        for (let index = 0; index < numFilteredAvailableCategories; index++) {
            this.addAvailableCategory(this.availableCategories[index]);
        }
        this.syncAddedCategories();
    }

    private unselectAvailableCategories(): void {
        const numAvailableCategories = this.availableCategories.length;
        for (let index = 0; index < numAvailableCategories; index++) {
            this.availableCategories[index]['selected'] = false;
        }
    }

    addSelectedCategories(): void {
        if (document.querySelectorAll('.selected').length > 0) {
            Array.prototype.push.apply(this.addedCategories, this.selectedCategories);
            this.selectedCategories = [];
            this.syncAddedCategories();

            this.unselectAvailableCategories();
        }
    }

    private toggleCategoryInSelectedCategories(category: any, index: number): void {
        if (index === -1) {
            this.selectedCategories.push(category);
        } else {
            this.selectedCategories.splice(index, 1);
        }
    }

    selectCategoryFromAvailables(category: any, $event: any): void {
        const self = this;
        this.singleClickTimer = setTimeout(function () {
            if (!self.preventSingleClick) {
                if (!category.added) {
                    category.selected = !category.selected;

                    const categoryIndex = self.getIndexOfItemInCategories(self.selectedCategories, category.Id);
                    self.toggleCategoryInSelectedCategories(category, categoryIndex);
                }
            }
            self.preventSingleClick = false;
        }, 200);
    }

    private removeAddedCategory(category: any): void {
        category.added = false;

        const categoryIndex = this.getIndexOfItemInCategories(this.addedCategories, category.id);
        if (categoryIndex !== -1) {
            this.addedCategories.splice(categoryIndex, 1);
        }
    }

    removeAddedItemFromList(category: any): void {
        this.removeAddedCategory(category);
        this.syncAddedCategories();
    }

    removeAllFilteredAddedCategories(): void {
        if (this.filteredAddedCategories.length > 0) {
            const numFilteredAddedCategories = this.filteredAddedCategories.length;
            for (let index = 0; index < numFilteredAddedCategories; index++) {
                this.removeAddedCategory(this.filteredAddedCategories[index]);
            }
        }
        this.syncAddedCategories();
    }

    categoryColor(color:number): string {
        return typeof color === 'number' ? '#' + ('00000' + (color | 0).toString(16)).substr(-6) : '';
    }
}
