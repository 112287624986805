import { Component, Input, HostListener } from '@angular/core';
import { CdkDragDrop, moveItemInArray, CdkDragStart, CdkDragEnd } from '@angular/cdk/drag-drop';

@Component({
    selector: 'sortable-question-list',
    templateUrl: './sortable-question-list.component.html'
})
export class SortableQuestionListComponent {

    @Input() questionnaireId: any;
    @Input() questions: any;
    @Input() linkedItems: any;
    @Input() isTray: boolean;
    @Input() hasCover: boolean;
    @Input() questionnaireKind: any;
    @Input() showEndingElement?: boolean;

    private isDragCancelled = false;

    constructor() { }

    trackByFn(index, item) {
        return index;
    }

    drop(event: CdkDragDrop<string[]>) {
        if (this.isDragCancelled === true) {
            this.isDragCancelled = false;
        } else if (event.previousIndex !== event.currentIndex && event.item && event.item.data) {
            moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
            window['reorderQuestion'](this.questionnaireId, event.item.data.id, event.currentIndex + 1);
        }
    }

    dragStarted(event: CdkDragStart) {
        document.body.style.cursor = 'move';
    }

    dragEnded(event: CdkDragEnd) {
        document.body.style.cursor = 'initial';
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.isDragCancelled = true;
        document.dispatchEvent(new Event('mouseup'));
    }

}
