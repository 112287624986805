import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../../constants';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'userOriginText'
})
export class UserOriginTextPipe  implements PipeTransform {
  constructor( private translate: TranslateService) {}
  transform(origin: any): string {
    var text = '';
        origin = parseInt(origin);
        if (origin === Constants.SEGMENTED_USER_ORIGIN.DIALOGUE) {
            text =  this.translate.instant('segments.segmentedUsers.origin.dialogue');
        } else if (origin === Constants.SEGMENTED_USER_ORIGIN.SYNCHRONIZED) {
            text =  this.translate.instant('segments.segmentedUsers.origin.synchronized');
        }
        return text;
  }
}