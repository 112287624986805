import { Component } from '@angular/core';

@Component({
  selector: 'drag-selector',
  template: `<div class="img-drag">
      <img src="ng1/assets/img/drag.svg" alt="" cdkDragHandle />
    </div>`
})
export class DragSelectorComponent {

  constructor() { }
}
