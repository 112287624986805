<div class="dui-date-picker__container dui__container" [ngClass]="{'dui-date-picker__container--focus' : isInputFocused, 'dui-date-picker__container--read-only' : isReadOnly, 'dui-date-picker__container--weekDay': showWeekDay}">
  <input [id]="inputId + '-hide'" style="display: none;" [(ngModel)]="hideInputDate" />
  <input [id]="inputId" class="dui-date-picker__input {{classes}}"  [ngClass]="{'dui-date-picker__input--focus' : showingCalendar, 'empty' : !inputModel }" type="date" [disabled]="isReadOnly || isDisabled" [(ngModel)]="date" novalidate="true" (focus)="modelValueOnFocus()" (focusout)="modelValueOnFocusOut($event)" placeholder="dd/mm/yyyy"/>

  <date-selector [inputId]="inputId + '-hide'" [(instance)]="calendarInstance" [defaultValue]="inputModel" (dateSetted)="settingDateFromCalendar()"
  [minDate]="minDate" [maxDate]="maxDate" [showToday]="showTodayBtn"></date-selector>

  @if (!isInputFocused && !isReadOnly) {
    <span id="inputId + '-calendar'" class="dui-date-picker__icon icon icon-calendar" (click)="openCalendar()"></span>
  }
  @if (showWeekDay) {
    <span id="inputId + '-weekDay'" class="dui-date-picker__weekDay" [textContent]="'global.form.weekDaysName' | translate: {day: getWeekDay()}"></span>
  }
  <span [id]="inputId + '-delete'" class="dui-date-picker__icon dui-date-picker__icon--remove icon icon-remove" (mousedown)="deleteDate()"></span>
  @if (isReadOnly) {
    <span class="dui__icon dui__icon--read-only icon icon-lock-fill-s"></span>
  }
  <div class="dui__actions-container">
    @if (isInputFocused && !isReadOnly && !isDisabled) {
      <div class="dui__action-buttons dui__action-buttons--right">
        <div [id]="inputId + '-dialenga-accept-button'" class="dui__action-button" visibility="hidden" (mousedown)="modelValueAccept($event)" >
          <span class="icon icon-accept"></span>
        </div>
        <div [id]="inputId + '-dialenga-cancel-button'" class="dui__action-button" visibility="hidden" (mousedown)="modelValueCancel($event)" >
          <span class="icon icon-revert"></span>
        </div>
      </div>
    }
  </div>
</div>