import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
    selector: 'params-modal',
    templateUrl: 'params-modal.component.html',
    styleUrls: ['./_params-modal.component.scss']
})

export class ParamsModalComponent implements OnInit {
    public paramsList: Array<any>; 
    public exampleParams: string = 'https://www.formacion.externa.com/home?token={{jwt_token}}&company={{company_id}}&employee={{employee_id}}';

    constructor(
        public dialogRef: MatDialogRef<ParamsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private clipboard: Clipboard, private translate: TranslateService
    ) 
    { 
        this.paramsList = [
            {name: 'jwt_token', description: translate.instant('externalLinks.paramsModal.descriptions.token'), value:'{{jwt_token}}'},
            {name: 'user_id', description: translate.instant('externalLinks.paramsModal.descriptions.userId'), value:'{{user_id}}'},
            {name: 'name', description: translate.instant('externalLinks.paramsModal.descriptions.name'), value:'{{name}}'},
            {name: 'surname', description: translate.instant('externalLinks.paramsModal.descriptions.surname'), value:'{{surname}}'},
            {name: 'username', description: translate.instant('externalLinks.paramsModal.descriptions.username'), value:'{{username}}'},
            {name: 'email', description: translate.instant('externalLinks.paramsModal.descriptions.email'), value:'{{email}}'},
            {name: 'phone', description: translate.instant('externalLinks.paramsModal.descriptions.phone'), value:'{{phone}}'},
            {name: 'country', description: translate.instant('externalLinks.paramsModal.descriptions.country'), value:'{{country}}'},
            {name: 'language', description: translate.instant('externalLinks.paramsModal.descriptions.language'), value:'{{language}}'},
            {name: 'employee_id', description: translate.instant('externalLinks.paramsModal.descriptions.employeeId'), value:'{{employee_id}}'},
            {name: 'position', description: translate.instant('externalLinks.paramsModal.descriptions.position'), value:'{{position}}'},
            {name: 'national_id', description: translate.instant('externalLinks.paramsModal.descriptions.nationalId'), value:'{{national_id}}'},
            {name: 'company_id', description: translate.instant('externalLinks.paramsModal.descriptions.companyId'), value:'{{company_id}}'},
        ];
    }

    ngOnInit() { }

    copyToClipboard(param: any, _event: any): void {
        const button = _event.currentTarget;
        button.classList.add('open');
        
        this.clipboard.copy(param.value);
        setTimeout(()=> {
            button.classList.remove('open');
        }, 1200)
    }

    goBack(): void {
        this.dialogRef.close( {closed: true} );
    }
}