<div class="main-container__content">
  <div class="main-container--small-width margin-top-10">
    <!-- Company identification -->
    <h3 class="margin-bottom-40" [textContent]="'company.groupTitle.generalInfo' | translate"></h3>

    <!-- Company CIF -->
    <div class="group-form group-form--label-250">
      <div class="group-form__label">
        <span class="group-form__label__text" [textContent]="'companies.grid.cif' | translate"></span>
        <!-- <span class="group-form__label__text group-form__label__text--optional" [translate]="'global.form.optional'"></span> -->
      </div>
      <div class="group-form__data direction-column align-items-start">
        <input #CompanyCif id="companyCif" class="input width-50-percent" data-cy="company-cif" [(ngModel)]="companyForm.cif"
          (ngModelChange)="onChange()" maxlength="12" [placeholder]="'companies.modal.cifPlaceholder' | translate" />
        </div>
      </div>
      <!-- Company managing director -->
      <div class="group-form group-form--label-250">
        <div class="group-form__label">
          <span class="group-form__label__text" [translate]="'companies.grid.director'"></span>
          <!-- <span class="group-form__label__text group-form__label__text--optional" [translate]="'global.form.optional'"></span> -->
        </div>
        <div class="group-form__data">
          <user-dropdown class="min-width-100-percent" [(inputModel)]="companyForm.director" (onSelection)="onChange()"></user-dropdown>
        </div>
      </div>
      <!-- Company logo -->
      <div class="group-form group-form--label-250">
        <div class="group-form__label" kendoTooltip filter="span.icon-help-fill" [tooltipTemplate]="logoFormat" position="top">
          <span class="group-form__label__text" [translate]="'company.logo'"></span>
          <span class="group-form__label__help icon icon-help-fill margin-left-8 black-30"></span>
        </div>
        <ng-template #logoFormat>
          <span [innerHTML]="'company.logoTooltip' | translate"></span>
        </ng-template>
        <div class="group-form__data">
          <image-uploader class="width-100-percent company-image" [(image)]="companyImage" [(imageUrl)]="companyForm.imageUrl" [(croppedImageSrc)]="companyForm.croppedImageSrc"
            [cropOptions]="IMAGE_CROPPER_COMPANY" [roundedCanvas]="false" [gifsAllowed]="false" [picsAllowed]="true" [textLabel]="'company.dragYourLogo'" [canvasHeight]="'164px'"
            [uploadAllowed]="false" [insertInEditor]="false" [acceptedFormats]="'image/jpeg, image/jpg, image/png, image/webp'" [hideSearchBtn]="true" [showTransformImage]="true"
            [modalTitle]="'company.imageModal.title'" [modalAdviceText]="'company.imageModal.advice'" [modalWidth]="'650px'" (onChanges)="onChange()">
          </image-uploader>
        </div>
      </div>
      <!-- Company short description -->
      <div class="group-form group-form--label-250">
        <div class="group-form__label" kendoTooltip filter="span.icon-help-fill" position="top">
          <span class="group-form__label__text" [translate]="'company.shortDescription'"></span>
          <span class="group-form__label__help icon icon-help-fill margin-left-8 black-30" [title]="('company.descriptionTooltip' | translate)"></span>
        </div>
        <div class="group-form__data group-form__data--column">
          <dialenga-textarea class="width-100-percent new-rich-editor" textareaId="short-description"
            [(inputModel)]="companyForm.description" (inputChanged)="onChange()"
            placeholder="message.captionPlaceholder" [isRichFormat]="true" [maxLength]="{ amount: 240, warningAmount: 220 }">
          </dialenga-textarea>
          <span class="padding-top-5" [translate]="'global.validation.maxlengthChars'" [translateParams]="{amount: 240}"></span>
        </div>
      </div>

      <!-- Company identification -->
      <h3 class="margin-top-40 margin-bottom-40" [textContent]="'contactInfo.title' | translate"></h3>

      <!-- Company website -->
      <div class="group-form group-form--label-250">
        <div class="group-form__label">
          <span class="group-form__label__text" [translate]="'contactInfo.labels.website'"></span>
        </div>
        <div class="group-form__data group-form__data--column relative">
          <input id="company-website" class="input width-100-percent padding-right-40" [(ngModel)]="companyForm.website"
            (ngModelChange)="onChangeUrl(['website'])" maxlength="256" placeholder="{{'contactInfo.placeHolders.website' | translate}}" autocomplete="off"
            [ngClass]="{'input--error': !isValidURLWebsite && !!companyForm.website}" />

            <div class="input-icon-right" kendoTooltip filter="span.icon-external-link">
              <button id="test-url-button" class="button button--only-icon button--link"
                [disabled]="!companyForm.website || !isValidURLWebsite" (click)="testUrlLink(companyForm.website)">
                <span class="button__icon button__icon--lg icon icon-external-link" [title]="'message.urlTest' | translate"></span>
              </button>
            </div>
            @if (!isValidURLWebsite && !!companyForm.website) {
              <div class="margin-top-10">
                <label class="danger" [translate]="'global.form.urlWrong'"></label>
              </div>
            }
          </div>
        </div>
        <!-- Company email -->
        <div class="group-form group-form--label-250">
          <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'users.email'"></span>
          </div>
          <div class="group-form__data group-form__data--column">
            <input id="email" data-cy="e-mail" class="input" [(ngModel)]="companyForm.email" email autocomplete="off" maxlength="255"
              [ngClass]="{'input--error': !isValidEmail && !!companyForm.email}" (ngModelChange)="onChangeEmail()"
              [placeholder]="'users.email' | translate" />

              @if (!isValidEmail && !!companyForm.email) {
                <div class="margin-top-10">
                  <label class="danger" [translate]="'users.validation.emailformat'"></label>
                </div>
              }
            </div>
          </div>
          <!-- Phonenumber -->
          <div class="group-form group-form--label-250">
            <div class="group-form__label">
              <span class="group-form__label__text" [translate]="'users.phone'"></span>
            </div>
            <div class="group-form__data group-form__data--inline flex">
              <input id="phoneNumber" data-cy="phone-number" class="input width-50-percent" [(ngModel)]="companyForm.phoneNumber" (ngModelChange)="onChange()"
                maxlength="20" placeholder="(+34) 915 555 555" autocomplete="off" />
              </div>
            </div>
            <!-- Addresses -->
            <div class="group-form group-form--label-250">
              <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'contactInfo.labels.addresses'"></span>
              </div>
              <div class="group-form__data group-form__data--column">
                <dialenga-textarea class="width-100-percent new-rich-editor" textareaId="company-addresses" [(inputModel)]="companyForm.address" (inputChanged)="onChange()"
                placeholder="message.captionPlaceholder" [isRichFormat]="true" [maxLength]="{ amount: 500, warningAmount: 480 }"></dialenga-textarea>
              </div>
            </div>
            <!-- Company map -->
            <div class="group-form group-form--label-250 padding-top-20">
              <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'contactInfo.labels.mainOffice'"></span>
              </div>
              <div class="group-form__data group-form__data--column">
                <div class="flex align-items-center justify-space-between width-100-percent">
                  <div class="flex align-items-center">
                    <span class="group-form__label__text margin-right-10" [translate]="'contactInfo.labels.latitud'"></span>
                    <input id="latitude" class="input max-width-150" type="number" step="any"
                      [(ngModel)]="companyForm.latitude" (ngModelChange)="onChangeMapCoord()"
                      maxlength="20" placeholder="{{'contactInfo.placeHolders.latitude' | translate}}" autocomplete="off" />
                    </div>
                    <div class="flex align-items-center">
                      <span class="group-form__label__text margin-right-10" [translate]="'contactInfo.labels.longitud'"></span>
                      <input id="latitude" class="input max-width-150" type="number" step="any"
                        [(ngModel)]="companyForm.longitude" (ngModelChange)="onChangeMapCoord()"
                        maxlength="20" placeholder="{{'contactInfo.placeHolders.longitude' | translate}}" autocomplete="off" />
                      </div>
                    </div>
                    <div #staticMap id="staticMap" class="margin-top-10 width-100-percent"
                      style="height: 300px; border: 1px solid lightgrey">
                    </div>
                  </div>
                </div>
                <!-- Business hours -->
                <div class="group-form group-form--label-250">
                  <div class="group-form__label">
                    <span class="group-form__label__text" [translate]="'contactInfo.labels.businessHours'"></span>
                  </div>
                  <div class="group-form__data group-form__data--column">
                    <dialenga-textarea class="width-100-percent new-rich-editor" textareaId="company-businessHours" [(inputModel)]="companyForm.businessHours" (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder" [isRichFormat]="true" [maxLength]="{ amount: 500, warningAmount: 480 }"></dialenga-textarea>
                  </div>
                </div>

                <!-- Company means of transport -->
                <h3 class="margin-top-60 margin-bottom-40" [textContent]="'contactInfo.meansOfTransport' | translate"></h3>

                <!-- Underground stations -->
                <div class="group-form group-form--label-250 margin-top-40">
                  <div class="group-form__label">
                    <span class="group-form__label__text" [translate]="'contactInfo.labels.undergrounds'"></span>
                  </div>
                  <div class="group-form__data group-form__data--column">
                    <dialenga-textarea class="width-100-percent new-rich-editor" textareaId="company-undergrounds" [(inputModel)]="companyForm.undergrounds" (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder" [isRichFormat]="true" [maxLength]="{ amount: 500, warningAmount: 480 }"></dialenga-textarea>
                  </div>
                </div>
                <!-- Bus stations -->
                <div class="group-form group-form--label-250 margin-top-40">
                  <div class="group-form__label">
                    <span class="group-form__label__text" [translate]="'contactInfo.labels.busStations'"></span>
                  </div>
                  <div class="group-form__data group-form__data--column">
                    <dialenga-textarea class="width-100-percent new-rich-editor" textareaId="company-buses" [(inputModel)]="companyForm.buses" (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder" [isRichFormat]="true" [maxLength]="{ amount: 500, warningAmount: 480 }"></dialenga-textarea>
                  </div>
                </div>
                <!-- Train stations -->
                <div class="group-form group-form--label-250 margin-top-40">
                  <div class="group-form__label">
                    <span class="group-form__label__text" [translate]="'contactInfo.labels.trainStations'"></span>
                  </div>
                  <div class="group-form__data group-form__data--column">
                    <dialenga-textarea class="width-100-percent new-rich-editor" textareaId="company-trains" [(inputModel)]="companyForm.trains" (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder" [isRichFormat]="true" [maxLength]="{ amount: 500, warningAmount: 480 }"></dialenga-textarea>
                  </div>
                </div>
                <!-- Parkins -->
                <div class="group-form group-form--label-250 margin-top-40">
                  <div class="group-form__label">
                    <span class="group-form__label__text" [translate]="'contactInfo.labels.parkings'"></span>
                  </div>
                  <div class="group-form__data group-form__data--column">
                    <dialenga-textarea class="width-100-percent new-rich-editor" textareaId="company-parkings" [(inputModel)]="companyForm.parkings" (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder" [isRichFormat]="true" [maxLength]="{ amount: 500, warningAmount: 480 }"></dialenga-textarea>
                  </div>
                </div>
                <!-- Electric Spots -->
                <div class="group-form group-form--label-250 margin-top-40">
                  <div class="group-form__label">
                    <span class="group-form__label__text" [translate]="'contactInfo.labels.electricSpots'"></span>
                  </div>
                  <div class="group-form__data group-form__data--column">
                    <dialenga-textarea class="width-100-percent new-rich-editor" textareaId="company-electricSpots" [(inputModel)]="companyForm.electricSpots" (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder" [isRichFormat]="true" [maxLength]="{ amount: 500, warningAmount: 480 }"></dialenga-textarea>
                  </div>
                </div>

                <!-- Company social networks -->
                <h3 class="margin-top-60 margin-bottom-40" [textContent]="'contactInfo.socialNetworks' | translate"></h3>

                <!-- Facebook -->
                <div class="group-form group-form--label-250">
                  <div class="group-form__label">
                    <span class="group-form__label__text" [translate]="'contactInfo.labels.facebook'"></span>
                  </div>
                  <div class="group-form__data group-form__data--column relative">
                    <input id="facebook" class="input width-100-percent padding-right-40" [(ngModel)]="companyForm.facebook"
                      (ngModelChange)="onChangeUrl(['facebook'])" maxlength="256" placeholder="{{'contactInfo.placeHolders.facebook' | translate}}" autocomplete="off"
                      [ngClass]="{'input--error': !isValidURLFacebook && !!companyForm.facebook}" />

                      <div class="input-icon-right" kendoTooltip filter="span.icon-external-link">
                        <button id="test-url-button" class="button button--only-icon button--link"
                          [disabled]="!companyForm.facebook || !isValidURLFacebook" (click)="testUrlLink(companyForm.facebook)">
                          <span class="button__icon button__icon--lg icon icon-external-link" [title]="'message.urlTest' | translate"></span>
                        </button>
                      </div>
                      @if (!isValidURLFacebook && !!companyForm.facebook) {
                        <div class="margin-top-10">
                          <label class="danger" [translate]="'global.form.urlWrong'"></label>
                        </div>
                      }
                    </div>
                  </div>
                  <!-- Youtube -->
                  <div class="group-form group-form--label-250">
                    <div class="group-form__label">
                      <span class="group-form__label__text" [translate]="'contactInfo.labels.youtube'"></span>
                    </div>
                    <div class="group-form__data group-form__data--column relative">
                      <input id="youtube" class="input width-100-percent padding-right-40" [(ngModel)]="companyForm.youtube"
                        (ngModelChange)="onChangeUrl(['youtube'])" maxlength="256" placeholder="{{'contactInfo.placeHolders.youtube' | translate}}" autocomplete="off"
                        [ngClass]="{'input--error': !isValidURLYoutube && !!companyForm.youtube}" />

                        <div class="input-icon-right" kendoTooltip filter="span.icon-external-link">
                          <button id="test-url-button" class="button button--only-icon button--link"
                            [disabled]="!companyForm.youtube || !isValidURLYoutube" (click)="testUrlLink(companyForm.youtube)">
                            <span class="button__icon button__icon--lg icon icon-external-link" [title]="'message.urlTest' | translate"></span>
                          </button>
                        </div>
                        @if (!isValidURLYoutube && !!companyForm.youtube) {
                          <div class="margin-top-10">
                            <label class="danger" [translate]="'global.form.urlWrong'"></label>
                          </div>
                        }
                      </div>
                    </div>
                    <!-- Twitter -->
                    <div class="group-form group-form--label-250">
                      <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'contactInfo.labels.twitter'"></span>
                      </div>
                      <div class="group-form__data group-form__data--column relative">
                        <input id="twitter" class="input width-100-percent padding-right-40" [(ngModel)]="companyForm.twitter"
                          (ngModelChange)="onChangeUrl(['twitter'])" maxlength="256" placeholder="{{'contactInfo.placeHolders.twitter' | translate}}" autocomplete="off"
                          [ngClass]="{'input--error': !isValidURLTwitter && !!companyForm.twitter}" />

                          <div class="input-icon-right" kendoTooltip filter="span.icon-external-link">
                            <button id="test-url-button" class="button button--only-icon button--link"
                              [disabled]="!companyForm.twitter || !isValidURLTwitter" (click)="testUrlLink(companyForm.twitter)">
                              <span class="button__icon button__icon--lg icon icon-external-link" [title]="'message.urlTest' | translate"></span>
                            </button>
                          </div>
                          @if (!isValidURLTwitter && !!companyForm.twitter) {
                            <div class="margin-top-10">
                              <label class="danger" [translate]="'global.form.urlWrong'"></label>
                            </div>
                          }
                        </div>
                      </div>
                      <!-- Linkedin -->
                      <div class="group-form group-form--label-250">
                        <div class="group-form__label">
                          <span class="group-form__label__text" [translate]="'contactInfo.labels.linkedin'"></span>
                        </div>
                        <div class="group-form__data group-form__data--column relative">
                          <input id="linkedin" class="input width-100-percent padding-right-40" [(ngModel)]="companyForm.linkedin"
                            (ngModelChange)="onChangeUrl(['linkedin'])" maxlength="256" placeholder="{{'contactInfo.placeHolders.linkedin' | translate}}" autocomplete="off"
                            [ngClass]="{'input--error': !isValidURLLinkedin && !!companyForm.linkedin}" />

                            <div class="input-icon-right" kendoTooltip filter="span.icon-external-link">
                              <button id="test-url-button" class="button button--only-icon button--link"
                                [disabled]="!companyForm.linkedin || !isValidURLLinkedin" (click)="testUrlLink(companyForm.linkedin)">
                                <span class="button__icon button__icon--lg icon icon-external-link" [title]="'message.urlTest' | translate"></span>
                              </button>
                            </div>
                            @if (!isValidURLLinkedin && !!companyForm.linkedin) {
                              <div class="margin-top-10">
                                <label class="danger" [translate]="'global.form.urlWrong'"></label>
                              </div>
                            }
                          </div>
                        </div>
                        <!-- Instagram -->
                        <div class="group-form group-form--label-250">
                          <div class="group-form__label">
                            <span class="group-form__label__text" [translate]="'contactInfo.labels.instagram'"></span>
                          </div>
                          <div class="group-form__data group-form__data--column relative">
                            <input id="instagram" class="input width-100-percent padding-right-40" [(ngModel)]="companyForm.instagram"
                              (ngModelChange)="onChangeUrl(['instagram'])" maxlength="256" placeholder="{{'contactInfo.placeHolders.instagram' | translate}}" autocomplete="off"
                              [ngClass]="{'input--error': !isValidURLInstagram && !!companyForm.instagram}" />

                              <div class="input-icon-right" kendoTooltip filter="span.icon-external-link">
                                <button id="test-url-button" class="button button--only-icon button--link"
                                  [disabled]="!companyForm.instagram || !isValidURLInstagram" (click)="testUrlLink(companyForm.instagram)">
                                  <span class="button__icon button__icon--lg icon icon-external-link" [title]="'message.urlTest' | translate"></span>
                                </button>
                              </div>
                              @if (!isValidURLInstagram && !!companyForm.instagram) {
                                <div class="margin-top-10">
                                  <label class="danger" [translate]="'global.form.urlWrong'"></label>
                                </div>
                              }
                            </div>
                          </div>
                          <!-- Form action buttons -->
                          <div class="group-form group-form--label-250 margin-top-40 margin-bottom-60">
                            <div class="group-form__label"></div>
                            <div class="group-form__data group-form__data--inline">
                              <feedback-action-button [buttonId]="'save-button'" [classes]="'button--secondary'" [text]="'global.form.save'"
                                [tooltipText]="'formValidation.invalidSingleLanguage'" (action)="companyFormSave()"
                              [isValid]="isFormDataChanged && isFormDataValid"></feedback-action-button>
                              <loading-button [text]="'global.form.cancel'"
                                class="margin-left-20"
                                [classes]="'button'"
                                (action)="goBack()" [isValid]="true">
                              </loading-button>
                            </div>
                          </div>
                        </div>
                      </div>