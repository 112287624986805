import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TimeoffService {

    public apiUrl: string;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
    }

    getCalendarHolidays(employeeId): Observable<any> {
        return this.httpClient.get<any[]>(`${this.apiUrl}/timeoff/config/employees/${employeeId}`);
    }

}
