import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Constants } from "../../constants";

@Injectable({ providedIn: "root" })
export class TimeTrackingService {

    public apiUrl: string;
    public schedulesApiUrl: string;
    public globalConfigApiUrl: string;
    public notificationsApiUrl: string;
    public timeRecordsApiUrl: string;
    public timeTrackingGridLastState: any;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
        this.schedulesApiUrl = "/time-tracking-schedule";
        this.timeRecordsApiUrl = "/time-tracking-record";
        this.globalConfigApiUrl = "/time-tracking-configuration";
        this.notificationsApiUrl = "/time-tracking-notification-configuration";
    }

    // SCHEDULES METHODS CRUD

    findAllSchedules(pagerOptions: any, filterOptions: any) {  
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10,
                active: filterOptions.active
            },
            reportProgress: true
        };
        if (pagerOptions?.sort) { httpOptions.params['sort'] = pagerOptions.sort; }
        if (filterOptions?.name) { httpOptions.params['name'] = filterOptions.name; }
        
        return this.httpClient.get<any>(`${this.apiUrl}${this.schedulesApiUrl}`, httpOptions);
    }

    findSchedule(id: number) {
        return this.httpClient.get<any>(`${this.apiUrl}${this.schedulesApiUrl}/${id}`);
    }

    create(data: any) {
        return this.httpClient.post(`${this.apiUrl}${this.schedulesApiUrl}`, data);
    }

    update(id: number, scheduleData: any) {
        return this.httpClient.put<any>(`${this.apiUrl}${this.schedulesApiUrl}/${id}`, scheduleData);
    }

    updateScheduleName(id: number, newName: any) {
        return this.httpClient.put<any>(`${this.apiUrl}${this.schedulesApiUrl}/${id}/name`, {name: newName});
    }

    archive(id: number) {
        return this.httpClient.put(`${this.apiUrl}${this.schedulesApiUrl}/${id}/archive`, null);
    }

    activate(id: number) {
        return this.httpClient.put(`${this.apiUrl}${this.schedulesApiUrl}/${id}/activate`, null);
    }

    delete(id: number) {
        return this.httpClient.delete<any>(`${this.apiUrl}${this.schedulesApiUrl}/${id}`);
    }

    // SCHEDULES EMPLOYEES METHODS

    findAllEmployees(id: number, pagerOptions: any, filterOptions: any) {
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10
            },
            reportProgress: true
        };
        if (pagerOptions?.sort) { httpOptions.params['sort'] = pagerOptions.sort; }
        if (filterOptions?.name) { httpOptions.params['name'] = filterOptions.name; }
        
        return this.httpClient.get<any>(`${this.apiUrl}${this.schedulesApiUrl}/${id}/employees`, httpOptions);
    }

    updateEmployees(id: number, employees: Array<any>) {
        let employeesIds: Array<any> = [... employees];
        employeesIds = employeesIds.length > 0 ? employeesIds.map((employee: any) => {
            return employee.hasOwnProperty('id') ? employee['id'] : employee['user'];
        }) : [];
        return this.httpClient.put<any>(`${this.apiUrl}${this.schedulesApiUrl}/${id}/employees`, employeesIds);
    }

    deleteEmployee(scheduleId: number, employeeId: number) {
        return this.httpClient.delete<any>(`${this.apiUrl}${this.schedulesApiUrl}/${scheduleId}/employees/${employeeId}`);
    }

    // SCHEDULE SETTINGS METHODS

    getTimeTrackingScheduleSettings(scheduleId: number) {
        return this.httpClient.get<any>(`${this.apiUrl}${this.schedulesApiUrl}/${scheduleId}/settings`);
    }

    getAvailableTimeTrackingKinds() {
        return this.httpClient.get<any>(`${this.apiUrl}/time-tracking-kind`);
    }

    setDayRecordMode(scheduleId: number, dataForm: any) {
        const httpOptions = {
            params: {
                mode: dataForm.timeTrackingMode,
                hours: dataForm.hoursToEnd
            }
        };
        return this.httpClient.put<any>(`${this.apiUrl}${this.schedulesApiUrl}/${scheduleId}/time-tracking-mode`, null, httpOptions);
    }

    setScheduleTimeTrackingKinds(scheduleId: number, data: any) {
        return this.httpClient.put<any>(`${this.apiUrl}${this.schedulesApiUrl}/${scheduleId}/time-tracking-kinds`, data);
    }

    setStartShiftLimitation(scheduleId: number, data: any) {
        return this.httpClient.put<any>(`${this.apiUrl}${this.schedulesApiUrl}/${scheduleId}/start-shift-limitation`, data);
    }

    // USER TIME TRACKING RECORDS METHODS

    findUsersTimeTracking(pagerOptions: any, filterOptions: any) {
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10
            },
            reportProgress: true
        };
        if (pagerOptions?.sort) { httpOptions.params['sort'] = pagerOptions.sort; }

        const dataFilter: any = {
            department: filterOptions.department?.id || null,
            headquarter: filterOptions.headquarter?.id || null,
            subsidiaryCompany: filterOptions.subsidiaryCompany?.id || null,
            team: filterOptions.team?.id || null,
            startDate: filterOptions.startDate,
            endDate: filterOptions.endDate,
            name: filterOptions.name,
        }
        
        return this.httpClient.post<any>(`${this.apiUrl}${this.timeRecordsApiUrl}/period`, dataFilter, httpOptions);
    }

    findUserTimeRecordsByPeriod(userId: number, startDate: string, endDate: string) {
        const httpOptions = {
            params: {
                startDate: startDate,
                endDate: endDate
            }
        }
        return this.httpClient.get<any>(`${this.apiUrl}${this.timeRecordsApiUrl}/${userId}/period`, httpOptions)
    }

    findUserTimeRecordsDetailByDay(userId: number, date: string) {
        const httpOptions = {
            params: {
                date: date
            }
        }
        return this.httpClient.get<any>(`${this.apiUrl}${this.timeRecordsApiUrl}/${userId}/day`, httpOptions)
    }
    
    createUserDayRecords(userId: number, data: any) {
        return this.httpClient.post(`${this.apiUrl}${this.timeRecordsApiUrl}/${userId}`, data);
    }

    updateUserDayRecord(userId: number, data: any) {
        const recordId = data.records[0].id;
        const recordData = data.records[0];
        return this.httpClient.put<any>(`${this.apiUrl}${this.timeRecordsApiUrl}/${recordId}`, recordData);
    }

    deleteUserDayRecord(recordId: number) {
        return this.httpClient.delete<any>(`${this.apiUrl}${this.timeRecordsApiUrl}/${recordId}`);
    }

    downloadTimeTrackingReport(options: any, detail: boolean) {
        const dataFilter: any = {
            department: options.department?.id || null,
            headquarter: options.headquarter?.id || null,
            subsidiaryCompany: options.subsidiaryCompany?.id || null,
            team: options.team?.id || null,
            startDate: options.startDate,
            endDate: options.endDate,
            name: options.name,
        }
        const reportUrl: string = detail ? '/records' : '/period';
        
        return this.httpClient.post(`${this.apiUrl}${this.timeRecordsApiUrl}${reportUrl}/export-csv`, dataFilter, { observe: 'response', responseType: 'blob'});
    }

    // SCHEDULES GLOBAL CONFIGURATION METHODS

    getGlobalConfig() {
        return this.httpClient.get<any>(`${this.apiUrl}${this.globalConfigApiUrl}`);
    }

    setShiftEditionPeriod(shiftEditionPeriod: number, daysMarginToEdit: number) {
        return this.httpClient.put<any>(`${this.apiUrl}${this.globalConfigApiUrl}/shift-edition-period`, {
            shiftEditionPeriod: shiftEditionPeriod,
            daysMarginToEdit: daysMarginToEdit
        });
    }

    // SCHEDULES NOTIFICATIONS SETTINGS METHODS

    getNotificationsConfig() {
        return this.httpClient.get<any>(`${this.apiUrl}${this.notificationsApiUrl}`);
    }

    togglePendingStartShiftNotification(enable: boolean) {
        const action = enable ? 'enable' : 'disable';
        return this.httpClient.patch<any>(`${this.apiUrl}${this.notificationsApiUrl}/${action}-pending-start-shift-notification`, null);
    }

    togglePendingEndShiftNotification(enable: boolean) {
        const action = enable ? 'enable' : 'disable';
        return this.httpClient.patch<any>(`${this.apiUrl}${this.notificationsApiUrl}/${action}-pending-end-shift-notification`, null);
    }

    togglePendingRecordNotification(enable: boolean) {
        const action = enable ? 'enable' : 'disable';
        return this.httpClient.patch<any>(`${this.apiUrl}${this.notificationsApiUrl}/${action}-pending-record-notification`, null);
    }

    togglePeriodClosingNotification(enable: boolean) {
        const action = enable ? 'enable' : 'disable';
        return this.httpClient.patch<any>(`${this.apiUrl}${this.notificationsApiUrl}/${action}-period-closing-notification`, null);
    }

    // MISCELANEOUS METHODS

    getCompaniesData() {
        const httpOptions = {
            params: {
                page: 0,
                size: 20000,
                active: true,
                sort: 'name,asc'
            },
            reportProgress: true
        };
        return this.httpClient.get<any>(`${this.apiUrl}/subsidiary-companies`, httpOptions);
    }

    getHeadquartersData() {
        const httpOptions = {
            params: {
                page: 0,
                size: 20000,
                active: true,
                sort: 'name,asc'
            },
            reportProgress: true
        };
        return this.httpClient.get<any>(`${this.apiUrl}/headquarters`, httpOptions);
    }

    getDepartmentsData() {
        const httpOptions = {
            params: {
                page: 0,
                size: 20000,
                active: true,
                sort: 'name,asc'
            },
            reportProgress: true
        };
        return this.httpClient.get<any>(`${this.apiUrl}/departments`, httpOptions);
    }

    getTeamsData() {
        const httpOptions = {
            params: {
                page: 0,
                size: 20000,
                active: true,
                sort: 'name,asc'
            },
            reportProgress: true
        };
        return this.httpClient.get<any>(`${this.apiUrl}/timeoff/teams`, httpOptions);
    }
}
