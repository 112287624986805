import { Component, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";
import { SortDescriptor} from "@progress/kendo-data-query";
import { ChatroomsService } from '../chatrooms/chatrooms.service';
import { Constants } from '../../constants';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';

@Component({
    selector: 'chatroom-users',
    templateUrl: './chatroom-users.component.html',
    styleUrls: ['./_chatroom-users.scss']
})
export class ChatroomUsersComponent implements OnChanges {
    @Input() chatId: number;
    @Input() chatroomForm: any;
    @Input() participants: Array<any>;
    @Input() segments: Array<any>;

    public CHATROOM_USERS_SYNCHRO_KIND: any;

    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [5,10,20,100],
    };
    public isSyncing: boolean = false;
    public chatUsersDatagrid: GridDataResult = {
        data: [],
        total: 0
    };
    
    public gridPagingOps: any = {
        page: 0,
        size: 10,
        skip: 0,
        sort: ''
    };
    public gridFilteringOps: any = {
        name: ''
    };
    public gridSort: SortDescriptor[];
    public totalPages: number = 0;

    public dataSkeleton = [{},{},{}];
    public selectedRows: number = 0;
    public toggleAllItemsSelect: boolean = false;

    @ViewChild('chatUsersGrid') chatUsersGrid: GridComponent;

    constructor(
        private chatroomsService: ChatroomsService, private formUtilsService: FormUtilsService,
        private notificationService: DuiNotificationsService, private translate: TranslateService,
        public dialogService: DialogService ) 
    { 
        this.CHATROOM_USERS_SYNCHRO_KIND = Constants.CHATROOM_USERS_SYNCHRO_KIND;
    }

    ngOnInit() {
        this.loadChatUsersGridData(this.gridPagingOps, this.gridFilteringOps);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ( changes.participants && !changes.participants.firstChange && changes.participants.previousValue !== undefined ) {
            this.updateParticipants(changes.participants.currentValue);
        }

        if ( changes.segments && !changes.segments.firstChange && changes.segments.previousValue !== undefined) {
            this.updateSegments();
        }
    }

    loadChatUsersGridData(pagingOptions: any, filterOptions: any) :void {
        this.isSyncing = true;

        this.chatroomsService.findAllParticipants(this.chatId, pagingOptions, filterOptions).subscribe(
            (response) => {
                this.chatUsersDatagrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.totalPages = response.totalPages;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    updateParticipants(users: Array<any>): void {
        this.isSyncing = true;

        this.chatroomsService.updateChatParticipants(this.chatId, users).subscribe(
            () => {
                this.loadChatUsersGridData(this.gridPagingOps, this.gridFilteringOps);
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        )
    }

    updateSegments(): void {
        this.isSyncing = true;

        this.chatroomsService.updateChatroom(this.chatId, this.chatroomForm, this.chatroomForm.image).subscribe(
            () => {
                this.loadChatUsersGridData(this.gridPagingOps, this.gridFilteringOps);
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        )
    }
    
    deleteMultipleUsers(): void {
        let deleteUsersIds: Array<number> = [];
        deleteUsersIds = this.chatUsersGrid.data['data']
            .filter(item => { return item.selectedItem })
            .map(item => { return item.id });

        const dialogValues: DialogValueOptions = {
            title: 'chatrooms.participants.modal.delete.title',
            message: 'chatrooms.participants.modal.delete.message',
            messageParam: {totalUsers: deleteUsersIds.length}
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe(result => {
            if(result?.accept) {
                this.deleteParticipantsById(deleteUsersIds);
                this.deselectAllRows();
                this.toggleAllItemsSelect = false;
            }
            this.formUtilsService.finishSubmitAction();
        })
    }

    deleteSingleUser(data: any): void {
        let deleteUsersIds: Array<number> = [];
        deleteUsersIds.push(data.id);

        const dialogValues: DialogValueOptions = {
            title: 'chatrooms.participants.modal.delete.title',
            message: 'chatrooms.participants.modal.delete.messageExtended',
            messageParam: {name: data.name, surname: data.surname}
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe(result => {
            if(result?.accept) {
                this.deleteParticipantsById(deleteUsersIds);
            }
        })
    }

    deleteParticipantsById(users: Array<number>): void {
        this.isSyncing = true;
        this.chatroomsService.deleteChatParticipants(this.chatId, users).subscribe(
            (response) => {
                this.loadChatUsersGridData(this.gridPagingOps, this.gridFilteringOps);
                const notificationOptions: NotificationOptions = {
                    kind: 'success',
                    message: this.translate.instant('chatrooms.participants.notifications.usersDeleted', {totalDeleted: users.length})
                }
                this.notificationService.showNotification(notificationOptions);
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    listItemsSearch() {
        if (this.gridFilteringOps.name.length >= 3 || this.gridFilteringOps.name.length === 0) {
            this.loadChatUsersGridData(this.gridPagingOps, this.gridFilteringOps);
        }
    }

    clearSearchInput() {
        this.gridFilteringOps.name = '';
        this.loadChatUsersGridData(this.gridPagingOps, this.gridFilteringOps);
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2 
        }
    }
    
    public onCellClick(e: any): void {
        if (this.chatroomForm.synchronizationKind === this.CHATROOM_USERS_SYNCHRO_KIND.SEGMENTS) { return };
        if (e.originalEvent.target.classList.value !== 'icon icon-delete') {
            this.selectRow(e.dataItem);
        }
    }

    public onSortChange(sort: SortDescriptor[]): void {
        this.gridSort = sort;        
        this.gridPagingOps.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : '';
        this.loadChatUsersGridData(this.gridPagingOps, this.gridFilteringOps);
    }

    public onPageChange(event: PageChangeEvent): void {
        this.gridPagingOps.page = (event.skip / this.gridPagingOps.size);
        this.gridPagingOps.skip = event.skip;
        this.gridPagingOps.size = event.take;

        this.loadChatUsersGridData(this.gridPagingOps, this.gridFilteringOps);
        this.toggleAllItemsSelect = false;
        this.selectedRows = 0;
    }
    
    deselectAllRows(): void {
        for (const item of this.chatUsersGrid.data['data']) {
            item.selectedItem = false;
        }
        this.updateRowsSelected()
    }
    
    selectRow(item: any): void {
        if (!item.admin) {
            item.selectedItem = !item.selectedItem;
            this.updateRowsSelected()   
        }
    }

    updateRowsSelected(): void {
        let counterRows = [];
        counterRows = this.chatUsersGrid.data['data'].filter(item => {return item.selectedItem });
        this.selectedRows = counterRows.length;
    }

    public allRowsToggleSelectedProperty(): void {
        for (const item of this.chatUsersGrid.data['data']) {
            if (!item.admin) {
                item.selectedItem = !this.toggleAllItemsSelect;
            }
        }
        this.updateRowsSelected()
    }
}
