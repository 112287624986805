import { Component, ViewChild, OnInit } from '@angular/core';
import { GridComponent, GridDataResult, RowClassArgs } from "@progress/kendo-angular-grid";
import { UtilsService } from '../../shared/services/utils.service';
import { Constants } from '../../constants';
import { DocumentSettingsService } from '../document-settings/document-settings.service';

@Component({
    selector: 'document-settings-conventions',
    templateUrl: './document-settings-conventions.component.html',
    styleUrls: [ './_document-settings-conventions.scss' ]
})
export class DocumentSettingsConventionsComponent implements OnInit {

    public isSyncing: boolean = false;
    public dataSkeleton = [{},{},{}];
    private FILE_KINDS = Constants.FILE_KINDS;

    public conventionsDatagrid: GridDataResult = {
        data: [],
        total: 0
    };

    @ViewChild('conventionsGrid') conventionsGrid: GridComponent;

    constructor( private documentSettingsService: DocumentSettingsService, private utilsService: UtilsService ) { 

    }

    ngOnInit() {
        this.getDocumentConventionsGridData();
    }

    getDocumentConventionsGridData(): void {
        this.isSyncing = true;

        this.documentSettingsService.getDocumentConventions().subscribe(
            (response) => {
                this.conventionsDatagrid = {
                    data: response,
                    total: response.length
                };
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    getDocumentTagHexColor(color: any): string {
        return this.utilsService.decimalToHex(color);
    }

    getDocumentIcon(kind: number): string {
        let icon = 'icon-';
        switch (kind) {
            case this.FILE_KINDS.PAYROLL:
            case this.FILE_KINDS.PAYROLL_ADVANCE:
                icon += 'doc-payroll';
                break;
            case this.FILE_KINDS.EXTRA:
                icon += 'doc-extra';
                break;
            case this.FILE_KINDS.CERTIFICATE:
            case this.FILE_KINDS.AUTHORIZATION:
                icon += 'doc-certificate';
                break;
            case this.FILE_KINDS.TIME_REGISTRATION:
                icon += 'doc-time-resume';
                break;
            case this.FILE_KINDS.CONTRACT:
                icon += 'doc-signed';
                break;
            case this.FILE_KINDS.MANUALS:
                icon += 'doc-undefined';
                break;
            default:
                icon = 'doc-undefined';
                break;
        } 
        return icon;
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2 
        }
    }

    checkElementOverflow(element: any): boolean {
        return element.offsetHeight < element.scrollHeight ||
               element.offsetWidth < element.scrollWidth;
    }
}