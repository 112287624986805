import { Injectable } from '@angular/core';
import { IconSettingsService } from '@progress/kendo-angular-icons';

@Injectable()
export class IconsService extends IconSettingsService {
    private iconDictionary: { [key: string]: string } = {
        'caret-alt-down': 'icon icon-Arrow-down',
        'caret-alt-right': 'k-icon k-i-arrow-60-right',
        'caret-alt-to-right': 'k-icon k-i-arrow-end-right',
        'caret-alt-left': 'k-icon k-i-arrow-60-left',
        'caret-alt-to-left': 'k-icon k-i-arrow-end-left',
        'plus': 'icon icon-Arrow-down',
        'minus': 'icon icon-Arrow-up',
        'sort-asc-small': 'k-icon k-i-sort-asc-sm',
        'sort-desc-small': 'k-icon k-i-sort-desc-sm',
    };

    public getCustomFontIconClass(iconName: string): string {
        return this.iconDictionary[iconName] || super.getCustomFontIconClass(iconName);
    }
}