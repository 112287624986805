<ul class="category-breadcrumb margin-top-20">
  <li class="category-breadcrumb__item" (click)="removeCategoryFilter()">
    <a class="category-breadcrumb__link" [translate]="'knowledgecenter.categories.all'"></a>
  </li>
  @for (category of categoriesPath | limitItemsDisplayed:3; track category; let last = $last) {
    <li class="category-breadcrumb__item" (click)="filterByCategory(category.id)">
      <span class="icon icon-arrow-right category-breadcrumb__icon"></span>
      <a class="category-breadcrumb__link" [textContent]="category.name" [ngClass]="{'category-breadcrumb__link--disabled': !category.id || last}"></a>
    </li>
  }
</ul>