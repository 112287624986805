<div class="boxes-groups-container margin-top-10">
  <div class="group">
    @if (!availableUsers || availableUsers.length > 0) {
      <ul id="available-users-container" class="box-groups provider relative-to-view">
        @for (user of availableUsers; track user) {
          <li class="user-item available-user non-selectable text-left" attr.data-name="{{user.name}}" [ngClass]="{'added disabled': user.added, 'selected': user.selected }"
            (click)="selectUserFromAvailables(user, $event)" (dblclick)="doubleClickedUser(user)">
            <user-avatar class="margin-right-10" [user]="user" [showUsername]="true"
              [thumbnails]="{thumbnail_M: user.avatarThumbnailMUrl || user.avatarUrl, thumbnail_S: user.avatarThumbnailSUrl || user.avatarThumbnailMUrl}">
            </user-avatar>
          </li>
        }
      </ul>
    }
    @if (availableUsers?.length > 0) {
      <div class="flex justify-space-between direction-reverse align-items-center group-footer">
        @if (availableUsers?.length > 0) {
          <a [routerLink]="" queryParamsHandling="preserve" [translate]="'segments.addAll'" (click)="addAllFilteredAvailableUsers()">{{'segments.addAll' | translate}} ({{availableUsers.length}})</a>
        }
        @if (selectedUsers?.length > 0) {
          <div class="flex align-items-center">
            <span class="number margin-left-0" [textContent]="selectedUsers.length"></span>
            <span class="margin-left-10" [translate]="'segments.selectedUsers'" [translateParams]="{'count': selectedUsers.length}"></span>
          </div>
        }
      </div>
    }
    @if (availableUsers?.length == 0) {
      <div class="box-groups flex justify-center align-items-center relative-to-view">
        @if (!filterAvailable && isSyncing === false) {
          <no-results [imageTitle]="'model.segment.noResultsTitle'"></no-results>
        }
        @if (filterAvailable && isSyncing === false) {
          <no-search-results></no-search-results>
        }
      </div>
    }
  </div>

  <div id="add-selected-items-button" class="icon icon-arrow-fill" [ngClass]="{'active': selectedUsers.length > 0 }" (click)="addSelectedUsers()"></div>

  <div class="group">
    @if (filteredAddedUsers?.length > 0) {
      <ul id="added-users-container" class="box-groups relative-to-view">
        @for (addedUser of filteredAddedUsers; track trackByFn($index, addedUser)) {
          <li id="{{'selected-user-' + addedUser.id}}" class="user-item text-left" [ngClass]="{'user-item--warning' : (!!remoteSignatureErrors && (!addedUser.nationalIdNumber || !addedUser.phoneNumber)) }"
            >
            <user-avatar class="margin-right-10" [user]="addedUser" [showUsername]="true"
              [thumbnails]="{thumbnail_M: addedUser.avatarThumbnailMUrl || addedUser.avatarUrl, thumbnail_S: addedUser.avatarThumbnailSUrl || addedUser.avatarThumbnailMUrl}">
            </user-avatar>
            <div class="icon icon-remove" (click)="removeAddedItemFromList(addedUser)"></div>
          </li>
        }
      </ul>
    }
    @if (addedUsers?.length > 0) {
      <div class="flex justify-space-between direction-reverse align-items-center group-footer">
        <a [routerLink]="" queryParamsHandling="preserve" [translate]="'segments.removeAll'" (click)="removeAllFilteredAddedUsers()"></a>
        <div class="flex align-items-center">
          <span class="number margin-left-0" [textContent]="addedUsers.length"></span>
          <span class="margin-left-10" [translate]="'segments.addedUsers'" [translateParams]="{'count': addedUsers.length}"></span>
        </div>
      </div>
    }
    @if (filteredAddedUsers?.length == 0) {
      <div class="box-groups flex justify-center align-items-center relative-to-view">
        @if (!filterAdded && isSyncing === false) {
          <no-results [imageTitle]="'global.form.noDataTitleMessage'" [description]="'global.form.noDataTextMessage'"></no-results>
        }
        @if (filterAdded && isSyncing === false) {
          <no-search-results></no-search-results>
        }
      </div>
    }
  </div>
</div>