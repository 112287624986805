import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable()
export class AttachedContentUtilsService {

  constructor() { }

	selectImage(image: any): Observable<any> {
		let reader = new FileReader();
		reader.readAsDataURL(image);

    	return new Observable((observer: Subscriber<any>): void => {
      		reader.onload = (event: any) => {
        		let imageData: any = event.target.result;

				let image = new Image();
				image.src = imageData;

				image.onload = (event: any) => {
					observer.next({
						image: imageData,
						size: { width: event.target.width, height: event.target.height},
						mimeType: imageData.split(',')[0].split(':')[1].split(';')[0]
					});
					observer.complete();
				};
				image.onerror = () => {
					observer.error();
				};
      		};
			reader.onerror = () => {
				observer.error();
			}
    	})
  	}

	dataURItoBlob (dataURI: any) {
	// convert base64/URLEncoded data component to raw binary data held in a string
	let byteString: any;
	if (dataURI.split(',')[0].indexOf('base64') >= 0) {
		byteString = atob(dataURI.split(',')[1]);
	} else {
		byteString = decodeURI(dataURI.split(',')[1]);
	}

	// separate out the mime component
	let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to a typed array
	let ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: mimeString });
};
}