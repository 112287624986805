import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { TimeTrackingService } from '../time-tracking/time-tracking.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './schedule-create-modal.component.html'
  })
  export class ScheduleCreateModalComponent implements AfterViewInit {

    public isFormValid: boolean = false;
    public scheduleForm: any = {
        name: ''
    };

    @ViewChild('scheduleName') scheduleNameRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<ScheduleCreateModalComponent>, private formUtilsService: FormUtilsService, private timeTrackingService: TimeTrackingService,
        private duiNotificationService: DuiNotificationsService, private _elementRef: ElementRef, private translate: TranslateService
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => this.scheduleNameRef.nativeElement.focus(), 200);
    }

    onChange(): void {
        this.isFormValid = this.scheduleForm.name.length > 0;
    }

    onEnterPressed(): void {
        if(this.isFormValid) {
            const createScheduleBtn: HTMLElement = (this._elementRef.nativeElement).querySelector('.button--secondary');
            createScheduleBtn.click();
        }
    }

    onAccept(): void {
        this.timeTrackingService.create(this.scheduleForm).subscribe(
            (onSuccess: any) => {
                // Get the new schedule id, return it and close modal
                this.dialogRef.close({success: onSuccess});
            },
            (onError: any) => {
                const notificationOptions: NotificationOptions = {
                    kind: 'error',
                    message: onError.status === 422 ? onError.error.localizedError : this.translate.instant('500.content.title')
                }
                this.duiNotificationService.showNotification(notificationOptions);

                this.scheduleNameRef.nativeElement.focus();
                this.formUtilsService.finishSubmitAction();
            }
        );
    }

    onCancel(): void {
        this.dialogRef.close({closed: true});
    }

}