import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';

import { Constants } from '../../constants';
import { DocumentsHistoryService } from './documents-history.service';

@Component({
    selector: 'documents-history',
    templateUrl: './documents-history.component.html',
    styleUrls: ['_documents-history.component.scss']
})

export class DocumentsHistoryComponent implements OnInit {

    public isSyncing: boolean = false;

    public documentsHistoryData: GridDataResult = {
        data: [],
        total: 0
    }

    public gridPagerSettings: GridPagerSettings = {
        buttonCount: 5,
        pageSizes: [10, 20, 100]
    }
    
    public gridPagingOptions: GridPagingOptions = {
        page: 0,
        size: Constants.PAGE_SIZE,
        skip: 0,
        sort: 'transactionDate,desc'
    }

    public gridSort: SortDescriptor[];

    public totalPages: number = 0;

    private readonly DOCUMENT_SUBMISSION_KIND: { [key: string]: number } = { 
        PAYROLL: 1,
        EXTRA: 2,
        CERT: 3,
        TIME_REGISTRATION: 4,
        OTHER: 5,
        MIXED: 100
    }

    private clickedRowItem: any = '';

    constructor(
        private location: Location,
        private documentsHistoryService: DocumentsHistoryService
    ) { }

    getSubmissionDocumentsClass(state: number): string {
        const stateClassMap = {
          [Constants.DOCUMENT_TRANSACTION_STATE.SOME_ERRORS]: 'bg-warning-color',
          [Constants.DOCUMENT_TRANSACTION_STATE.FAILURE]: 'bg-error-color'
        };
        return stateClassMap[state] || '';
    }

    getDocumentKindClass(kind: number): string {
        const kindClassMap = {
          [this.DOCUMENT_SUBMISSION_KIND.PAYROLL]: 'icon-menu-payroll',
          [this.DOCUMENT_SUBMISSION_KIND.EXTRA]: 'icon-extra-type',
          [this.DOCUMENT_SUBMISSION_KIND.CERT]: 'icon-certificate',
          [this.DOCUMENT_SUBMISSION_KIND.TIME_REGISTRATION]: 'icon-time-resume',
          [this.DOCUMENT_SUBMISSION_KIND.MIXED]: 'icon-mixed-files',
        };
        return kindClassMap[kind] || 'icon-document';
    }

    getErrorEntriesClass(state: number): string {
        const errorEntriesClassMap = {
            [Constants.DOCUMENT_TRANSACTION_STATE.SOME_ERRORS]: 'bg-warning-light-color',
            [Constants.DOCUMENT_TRANSACTION_STATE.FAILURE]: 'bg-error-light-color'
        };
        return errorEntriesClassMap[state] || '';
    }

    redirectToDetailView(id: string):void {
        const path = `/import-documents/history/${id}`;
        this.location.go(path);
    }

    onSortChange(sort: SortDescriptor[]): void {
        if (sort.length > 0 && sort[0].dir) {
            this.gridPagingOptions.sort = `${sort[0].field},${sort[0].dir}`;
        } else {
            this.gridPagingOptions.sort = '';
        }
    
        this.gridSort = sort;
        this.getAllTransactions(this.gridPagingOptions);
    }

    onPageChange(event: PageChangeEvent): void {
        this.gridPagingOptions.page = (event.skip / this.gridPagingOptions.size);
        this.gridPagingOptions.skip = event.skip;
        this.gridPagingOptions.size = event.take;
        this.getAllTransactions(this.gridPagingOptions);
    }

    onCellClick(event: any) {
        this.clickedRowItem = event.dataItem;
    }

    onDoubleClick(): void {
        if (this.clickedRowItem && this.clickedRowItem.id) {
            this.redirectToDetailView(this.clickedRowItem.id);
        }
    }

    getAllTransactions(pagingOptions: any) {
        if (!pagingOptions) { return; }

        this.isSyncing = true;
    
        this.documentsHistoryService.findAll(pagingOptions).subscribe({
            next: (response) => {
                this.documentsHistoryData = {
                    data: response.content,
                    total: response.totalElements
                };
                this.totalPages = response.totalPages;
            },
            error: (error: any) => {
                console.error(error);
            },
            complete: () => {
                this.isSyncing = false;
            }
        });
    }

    ngOnInit() { 
        this.getAllTransactions(this.gridPagingOptions);
    }
}

interface GridPagingOptions {
    page: number;
    size: number;
    skip: number;
    sort: string;
}

interface GridPagerSettings {
    buttonCount: number;
    pageSizes: number[];
}