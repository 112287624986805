import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { FormsModule } from '@angular/forms';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { AppComponent } from './app.component';
import { FormUtilsService } from './shared/services/form-utils.service';
import { SharedDataService } from './shared/services/shared-data.service';
import { ImageBankService } from './components/image-bank/image-bank.service';
import { UtilsService } from './shared/services/utils.service';
import { DialogService } from './shared/services/dialog.service';
import { AttachedContentUtilsService } from './shared/services/attached-content-utils.service';
import { DuiNotificationsService } from './shared/services/dui-notifications.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { TranslateICUParser } from 'ngx-translate-parser-plural-select';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';

import { IconSettingsService, IconsModule, ICON_SETTINGS } from "@progress/kendo-angular-icons";
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { SliderModule } from '@progress/kendo-angular-inputs';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { NotificationModule } from "@progress/kendo-angular-notification";
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { FileSaverModule } from 'ngx-filesaver';
import { ComboBoxModule, DropDownListModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from "@progress/kendo-angular-editor";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { ProgressBarModule } from "@progress/kendo-angular-progressbar";
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import * as $ from 'jquery';
import * as moment from '../../ng1/libs/momentjs/moment-with-locales.min.js';

import { globalAngularJSServiceProvider } from './global-angularjs.service';
import { FeedbackActionButtonComponent } from './shared/components/feedback-action-button/feedback-action-button.component';
import { SpinnerDialengaComponent } from './shared/components/spinner-dialenga/spinner-dialenga.component';
import { LoadingButtonComponent } from './shared/components/loading-button/loading-button.component';
import { DisabledButtonDirective } from 'src/ng2/app/shared/components/disabled-button.component';
import { DialengaSelectDirective } from 'src/ng2/app/shared/components/dialenga-select/dialenga-select.component';
import { QuestionListDirective } from 'src/ng2/app/shared/components/question-list.component';
import { SlimButtonComponent } from 'src/ng2/app/shared/components/slim-button/slim-button.component';
import { SortableQuestionListComponent } from './shared/components/sortable-question-list/sortable-question-list.component';
import { ArraySortPipe } from './shared/pipes/array-sort.pipe';
import { DragSelectorComponent } from './shared/components/drag-selector.component';
import { RelatedLinkComponent } from './components/publication/related-link.component';
import { ToggleInputComponent } from './shared/components/toggle-input/toggle-input.component';
import { CategoryPreviewDeviceComponent } from './components/category-preview-device/category-preview-device.component';
import { CategoriesTreeComponent } from './components/categories-tree/categories-tree.component';
import { CategoriesTreeItemComponent } from './components/categories-tree/categories-tree-item.component';
import { CategoriesTreeService } from './components/categories-tree/categories-tree.service';
import { CategoriesFilterComponent } from './components/categories-filter/categories-filter.component';
import { LimitItemsDisplayedPipe } from './shared/pipes/limit-items-displayed.pipe';
import { CustomInterceptor } from './http-interceptor';
import { CategoriesService } from './shared/services/categories.service';
import { UsersService } from './shared/services/users.service';
import { UsersImportService } from './shared/services/users-import.service';
import { TimeoffService } from './shared/services/timeoff.service';
import { UsersSegmentsService } from './shared/services/users-segments.service';
import { PreviewButtonComponent } from './shared/components/preview-button/preview-button.component';
import { InfoTooltipDirective } from './shared/components/info-tooltip.component';
import { UserAvatarComponent } from './shared/components/user-avatar/user-avatar.component';
import { AvatarComponent } from './shared/components/avatar/avatar.component';
import { ItemsSelectionBoxesComponent } from './shared/components/items-selection-boxes/items-selection-boxes.component';
import { SegmentsSelectionBoxesComponent } from './shared/components/segments-selection-boxes/segments-selection-boxes.component';
import { CategoriesSelectionBoxesComponent } from './shared/components/categories-selection-boxes/categories-selection-boxes.component';
import { FilekindsSelectionBoxesComponent } from './shared/components/filekinds-selection-boxes/filekinds-selection-boxes.component';
import { NormalizeUnicodePipe } from './shared/pipes/normalize-unicode.pipe';
import { NumberColorPipe } from './shared/pipes/number-color.pipe';
import { UserOriginIconPipe } from './shared/pipes/user-origin-icon.pipe';
import { UserOriginTextPipe } from './shared/pipes/user-origin-text.pipe';
import { UserStateIconPipe } from './shared/pipes/user-state-icon.pipe';
import { UserStateTextPipe } from './shared/pipes/user-state-text.pipe';
import { HoursMinutesTimePipe } from './shared/pipes/hours-minutes-time.pipe';
import { NumberToDecimal } from './shared/pipes/number-to-decimal.pipe';
import { NoResultsComponent } from './shared/components/no-results/no-results.component';
import { NoSearchResultsComponent } from './shared/components/no-search-results/no-search-results.component';
import { DateRangeSelectorComponent } from './shared/components/date-range-selector/date-range-selector.component';
import { ShowRatingIconComponent } from './shared/components/show-rating-icon/show-rating-icon.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { DialengaInputTextComponent } from './shared/components/dialenga-input-text/dialenga-input-text.component';
import { DialengaDatePickerComponent } from './shared/components/dialenga-date-picker/dialenga-date-picker.component';
import { DateSelectorComponent } from './shared/components/date-selector/date-selector.component';
import { DuiSelectMultipleSoftComponent } from './shared/components/dui-select-multiple-soft/dui-select-multiple-soft.component';
import { DialengaTooltipDirective } from './shared/components/dialenga-tooltip.component';
import { DialengaImageCropperComponent } from './shared/components/dialenga-image-cropper/dialenga-image-cropper.component';
import { ConversationNoteStatusTagComponent } from './components/conversation/conversation-note-status-tag.component';
import { ImportUsersComponent } from './components/import-users/import-users.component';
import { RemoteSignatureComponent } from './components/remote-signature/remote-signature.component';
import { RemoteSignatureDetailComponent } from './components/remote-signature-detail/remote-signature-detail.component';
import { RemoteSignatureService } from './components/remote-signature/remote-signature.service';
import { DuiPopoverComponent } from './shared/components/dui-popover/dui-popover.component';
import { AvatarPopoverComponent } from './components/avatar-popover/avatar-popover.component';
import { RemoteSignatureCreationComponent } from './components/remote-signature-creation/remote-signature-creation.component';
import { DocumentsUploadComponent } from './components/documents-upload/documents-upload.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { DocumentsService } from './components/documents/documents.service';
import { DocumentsListComponent} from './components/documents-list/documents-list.component'
import { DocumentPreviewModalComponent} from './components/documents-list/document-preview-modal.component'
import { ImageBankComponent} from './components/image-bank/image-bank.component'
import { ConfirmationDialogComponent} from '././shared/components/confirmation-dialog/confirmation-dialog.component'
import { DuiNotificationsComponent} from '././shared/components/dui-notifications/dui-notifications.component'
import { UserDropdownComponent } from './shared/components/user-dropdown/user-dropdown.component';
import { DialengaTextareaComponent } from './shared/components/dialenga-textarea/dialenga-textarea.component';
import { SignatureAreaPopoverComponent } from './components/signature-area-popover/signature-area-popover.component';
import { NewFeaturesComponent} from './components/new-features/new-features.component';
import { AvatarHexagonComponent } from './shared/components/avatar-hexagon/avatar-hexagon.component';
import { MentionComponent } from './shared/components/mention/mention.component';
import { GlobalSettingsComponent } from './components/global-settings/global-settings.component';
import { ChatroomsComponent } from './components/chatrooms/chatrooms.component';
import { ChatroomsService } from './components/chatrooms/chatrooms.service';
import { ChatroomCreateModalComponent } from './components/chatroom-create/chatroom-create-modal.component';
import { ChatroomComponent } from './components/chatroom/chatroom.component';
import { ChatroomGeneralComponent } from './components/chatroom-general/chatroom-general.component';
import { DateRangeFilterComponent } from './shared/components/date-range-filter/date-range-filter.component';
import { ChatroomAdminsModalComponent } from './components/chatroom/chatroom-admins-modal.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ChatroomUsersComponent } from './components/chatroom-users/chatroom-users.component'
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component'
import { ImageCropperModalComponent } from './components/image-cropper-modal/image-cropper-modal.component';
import { RemoteSignatureRecipientsModalComponent } from './components/remote-signature-creation/remote-signature-recipients-modal.component';
import { MyCompanyComponent } from './components/my-company/my-company.component';
import { MyCompanyGlobalComponent } from './components/my-company-global/my-company-global.component';
import { WorkplacesComponent } from './components/workplaces/workplaces.component';
import { WorkplacesService } from './components/workplaces/workplaces.service';
import { WorkplaceCreateModalComponent } from './components/workplace-create/workplace-create-modal.component';
import { DepartmentsComponent } from './components/departments/departments.component';
import { DepartmentsService } from './components/departments/departments.service';
import { DepartmentCreateModalComponent } from './components/department-create/department-create-modal.component';
import { TeamsComponent } from './components/teams/teams.component';
import { TeamsService } from './components/teams/teams.service';
import { TeamCreateModalComponent } from './components/team-create/team-create-modal.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { CompaniesService } from './components/companies/companies.service';
import { CompanyCreateModalComponent } from './components/company-create/company-create-modal.component';
import { CompanyComponent } from './components/company/company.component';
import { CompanyGeneralComponent } from './components/company-general/company-general.component';
import { CompanyEmployeesComponent } from './components/company-employees/company-employees.component';
import { DepartmentComponent } from './components/department/department.component';
import { SegmentsManagementModalComponent } from './shared/components/segments-management-modal/segments-management-modal.component';
import { CreationLinkModalComponent } from './shared/components/creation-link-modal/creation-link-modal.component';
import { DocumentSettingsComponent } from './components/document-settings/document-settings.component';
import { DocumentSettingsGlobalComponent } from './components/document-settings-global/document-settings-global.component';
import { DocumentSettingsConventionsComponent } from './components/document-settings-conventions/document-settings-conventions.component';
import { TimeTrackingComponent } from './components/time-tracking/time-tracking.component';
import { TimeTrackingSchedulesComponent } from './components/time-tracking-schedules/time-tracking-schedules.component';
import { TimeTrackingGlobalComponent } from './components/time-tracking-global/time-tracking-global.component';
import { TimeTrackingNotificationsComponent } from './components/time-tracking-notifications/time-tracking-notifications.component';
import { ScheduleCreateModalComponent } from './components/schedule-create/schedule-create-modal.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { ScheduleGeneralComponent } from './components/schedule-general/schedule-general.component';
import { ScheduleSettingsComponent } from './components/schedule-settings/schedule-settings.component';
import { ScheduleEmployeesComponent } from './components/schedule-employees/schedule-employees.component';
import { ExternalLinksComponent } from './components/external-links/external-links.component';
import { ExternalLinkComponent } from './components/external-link/external-link.component';
import { ParamsModalComponent } from './components/external-link/params-modal/params-modal.component';
import { LanguageSelectorComponent } from './shared/components/language-selector/language-selector.component';
import { SegmentsComponent } from './components/segments/segments.component';
import { SegmentsService } from './components/segments/segments.service';
import { SegmentCreateModalComponent } from './components/segment-create/segment-create-modal.component';
import { SegmentComponent } from './components/segment/segment.component';
import { TimeTrackingListComponent } from './components/time-tracking-list/time-tracking-list.component';
import { TimeTrackingDownloadModalComponent } from './components/time-tracking-list/time-tracking-download-modal.component';
import { TimeTrackingListDetailComponent } from './components/time-tracking-list-detail/time-tracking-list-detail.component';
import { TimeRecordEditComponent } from './shared/components/time-record-edit/time-record-edit.component';
import { TimeRecordEditModalComponent } from './shared/components/time-record-edit/time-record-edit-modal.component';
import { RequestsComponent } from './components/requests/requests.component';
import { RequestsService } from './components/requests/requests.service';
import { RequestDetailComponent } from './components/request-detail/request-detail.component';
import { RequestCreateModalComponent } from './components/request-create/request-create-modal.component';
import { RequestValidationModalComponent } from './components/request-detail/request-validation-modal.component';
import { ImportDocumentsComponent } from './components/import-documents/import-documents.component';
import { NamingConventionModalComponent } from './components/import-documents/naming-convention-modal.component';
import { DocumentTransactionDetailComponent } from './components/document-transaction-detail/document-transaction-detail.component';
import { DocumentsHistoryComponent } from './components/documents-history/documents-history.component';
import { InternalLinkModalComponent } from './shared/components/internal-link/internal-link-modal.component';
import { InternalLinkService } from './shared/components/internal-link/internal-link.service';
import { IconsService } from "./shared/services/kendo-icons.service";

@NgModule({
    declarations: [
        AppComponent,
        ArraySortPipe,
        AvatarPopoverComponent,
        DragSelectorComponent,
        SpinnerDialengaComponent,
        LoadingButtonComponent,
        FeedbackActionButtonComponent,
        SortableQuestionListComponent,
        DisabledButtonDirective,
        DialengaTooltipDirective,
        DialengaSelectDirective,
        QuestionListDirective,
        SlimButtonComponent,
        RelatedLinkComponent,
        ToggleInputComponent,
        CategoryPreviewDeviceComponent,
        CategoriesTreeComponent,
        CategoriesTreeItemComponent,
        CategoriesFilterComponent,
        LimitItemsDisplayedPipe,
        PreviewButtonComponent,
        InfoTooltipDirective,
        AvatarComponent,
        UserAvatarComponent,
        ItemsSelectionBoxesComponent,
        SegmentsSelectionBoxesComponent,
        CategoriesSelectionBoxesComponent,
        FilekindsSelectionBoxesComponent,
        NormalizeUnicodePipe,
        NumberColorPipe,
        UserOriginIconPipe,
        UserOriginTextPipe,
        UserStateIconPipe,
        UserStateTextPipe,
        HoursMinutesTimePipe,
        NumberToDecimal,
        NoResultsComponent,
        NoSearchResultsComponent,
        DateRangeSelectorComponent,
        DateRangeFilterComponent,
        DateSelectorComponent,
        SidemenuComponent,
        DialengaInputTextComponent,
        DialengaDatePickerComponent,
        DialengaTextareaComponent,
        DuiPopoverComponent,
        DialengaImageCropperComponent,
        DuiSelectMultipleSoftComponent,
        ConversationNoteStatusTagComponent,
        ImportUsersComponent,
        RemoteSignatureComponent,
        RemoteSignatureDetailComponent,
        RemoteSignatureCreationComponent,
        DocumentsUploadComponent,
        DocumentsComponent,
        DocumentsListComponent,
        DocumentPreviewModalComponent,
        ImageBankComponent,
        NewFeaturesComponent,
        ConfirmationDialogComponent,
        DuiNotificationsComponent,
        UserDropdownComponent,
        SignatureAreaPopoverComponent,
        AvatarHexagonComponent,
        MentionComponent,
        GlobalSettingsComponent,
        ChatroomsComponent,
        ChatroomCreateModalComponent,
        ChatroomComponent,
        ChatroomGeneralComponent,
        ChatroomAdminsModalComponent,
        ChatroomUsersComponent,
        ImageUploaderComponent,
        ImageCropperModalComponent,
        RemoteSignatureRecipientsModalComponent,
        MyCompanyComponent,
        MyCompanyGlobalComponent,
        WorkplacesComponent,
        WorkplaceCreateModalComponent,
        DepartmentCreateModalComponent,
        DepartmentsComponent,
        TeamsComponent,
        TeamCreateModalComponent,
        CompaniesComponent,
        ExternalLinksComponent,
        ExternalLinkComponent,
        LanguageSelectorComponent,
        ParamsModalComponent,
        CompanyCreateModalComponent,
        CompanyComponent,
        CompanyGeneralComponent,
        CompanyEmployeesComponent,
        DepartmentComponent,
        SegmentsManagementModalComponent,
        CreationLinkModalComponent,
        DocumentSettingsComponent,
        DocumentSettingsGlobalComponent,
        DocumentSettingsConventionsComponent,
        TimeTrackingComponent,
        TimeTrackingSchedulesComponent,
        TimeTrackingGlobalComponent,
        TimeTrackingNotificationsComponent,
        ScheduleCreateModalComponent,
        ScheduleComponent,
        ScheduleGeneralComponent,
        ScheduleSettingsComponent,
        ScheduleEmployeesComponent,
        SegmentsComponent,
        SegmentCreateModalComponent,
        SegmentComponent,
        TimeTrackingListComponent,
        TimeTrackingDownloadModalComponent,
        TimeTrackingListDetailComponent,
        TimeRecordEditComponent,
        TimeRecordEditModalComponent,
        RequestsComponent,
        ShowRatingIconComponent,
        RequestDetailComponent,
        RequestCreateModalComponent,
        RequestValidationModalComponent,
        ImportDocumentsComponent,
        NamingConventionModalComponent,
        DocumentTransactionDetailComponent,
        DocumentsHistoryComponent,
        InternalLinkModalComponent
    ],
    imports: [
        BrowserModule,
        UpgradeModule,
        MatDialogModule,
        BrowserAnimationsModule,
        HttpClientModule,
        DragDropModule,
        TextFieldModule,
        TranslateModule.forRoot({
            defaultLanguage: 'es',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            parser: {
                provide: TranslateParser,
                useClass: TranslateICUParser
            }
        }),
        FormsModule,
        NgxExtendedPdfViewerModule,
        ToastrModule.forRoot({
            toastComponent: DuiNotificationsComponent,
            maxOpened: 3,
            autoDismiss: true
        }),
        ComboBoxModule,
        DropDownListModule,
        EditorModule,
        GridModule,
        ExcelModule,
        SliderModule,
        TooltipsModule,
        ContextMenuModule,
        FileSaverModule,
        NotificationModule,
        IndicatorsModule,
        MultiSelectModule,
        LayoutModule,
        InfiniteScrollModule,
        ImageCropperModule,
        ScrollViewModule,
        ProgressBarModule,
        LeafletModule,
        IconsModule
    ],
    providers: [
        globalAngularJSServiceProvider,
        CategoriesTreeService,
        CategoriesService,
        UsersService,
        UsersImportService,
        RemoteSignatureService,
        DocumentsService,
        FormUtilsService,
        NormalizeUnicodePipe,
        NumberColorPipe,
        UserOriginIconPipe,
        UserOriginTextPipe,
        UserStateIconPipe,
        UserStateTextPipe,
        SharedDataService,
        ImageBankService,
        TimeoffService,
        UsersSegmentsService,
        UtilsService,
        DialogService,
        DuiNotificationsService,
        ChatroomsService,
        AttachedContentUtilsService,
        WorkplacesService,
        DepartmentsService,
        TeamsService,
        CompaniesService,
        SegmentsService,
        RequestsService,
        InternalLinkService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomInterceptor,
            multi: true
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { scrollStrategy: new NoopScrollStrategy() }
        },
        { 
            provide: ICON_SETTINGS, 
            useValue: { type: "font" }
        },
        { 
            provide: IconSettingsService, 
            useClass: IconsService 
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './ng1/i18n/', '.json');
}

export function TRANSLATE(str: string) {
  return str;
}
