import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

declare var moment: any;

@Component({
    templateUrl: './time-tracking-download-modal.component.html'
  })

  export class TimeTrackingDownloadModalComponent {

    public startDate: string = '';
    public endDate: string = '';
    public includeDetail: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<TimeTrackingDownloadModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) 
    {
        this.startDate = moment(new Date(this.data.filter.startDate)).format('DD-MM-YYYY');
        this.endDate = moment(new Date(this.data.filter.endDate)).format('DD-MM-YYYY');
    }

    onAccept(): void {
        this.dialogRef.close({accept: true, includeDetail: this.includeDetail});
    }

    onCancel(): void {
        this.dialogRef.close({cancel: true});
    }

}