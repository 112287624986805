import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { WorkplacesService } from '../workplaces/workplaces.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './workplace-create-modal.component.html'
  })
  export class WorkplaceCreateModalComponent implements AfterViewInit {

    public isFormValid: boolean = false;
    public workplaceForm: any = {
        name: '',
        director: {}
    };
    @ViewChild('workplaceName') workplaceNameRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<WorkplaceCreateModalComponent>, private formUtilsService: FormUtilsService, private workplacesService: WorkplacesService,
        private duiNotificationService: DuiNotificationsService, private _elementRef: ElementRef, private translate: TranslateService
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => this.workplaceNameRef.nativeElement.focus(), 200);
    }

    onChange(): void {
        // this.workplaceNameRef.nativeElement.classList.remove('input--error');
        this.isFormValid = this.workplaceForm.name.length > 0;
    }

    onEnterPressed(): void {
        if(this.isFormValid) {
            const createChatBtn: HTMLElement = (this._elementRef.nativeElement).querySelector('.button--secondary');
            createChatBtn.click();
        }
    }

    onAccept(): void {
        if (!this.workplaceForm.director?.id) {
            this.workplaceForm.director = null;
        }
        this.workplacesService.create(this.workplaceForm).subscribe(
            (onSuccess: any) => {
                // Get the new workplace id, return it and close modal
                this.dialogRef.close({success: onSuccess});
            },
            (onError: any) => {
                const notificationOptions: NotificationOptions = {
                    kind: 'error',
                    message: onError.status === 422 ? onError.error.localizedError : this.translate.instant('500.content.title')
                }
                this.duiNotificationService.showNotification(notificationOptions);

                // this.workplaceNameRef.nativeElement.classList.add('input--error');
                this.workplaceNameRef.nativeElement.focus();
                this.formUtilsService.finishSubmitAction();
            }
        );

    }

    onCancel(): void {
        this.dialogRef.close({closed: true});
    }

}