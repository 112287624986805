import { Component, OnInit } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ScheduleCreateModalComponent } from '../schedule-create/schedule-create-modal.component';
import { Constants } from "../../constants";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "time-tracking",
    templateUrl: "time-tracking.component.html",
})
export class TimeTrackingComponent implements OnInit {
    public TIME_TRACKING_TABS: any;
    public selectedTab: number;
    private _route: any;
    public userSession: any;
    public userLoggedHasAdminRole: boolean;

    constructor(
        private upgrade: UpgradeModule, private titleService: Title,
        private translate: TranslateService, private location: Location, private dialog: MatDialog
    ) {
        this.TIME_TRACKING_TABS = Constants.TIME_TRACKING_TABS;
        this._route = this.upgrade.$injector.get("$route");
        this.selectedTab = this._route.current.locals.selectedTab;

        this.userSession = window["getUserSession"]();
        this.userLoggedHasAdminRole = this.userSession.roles.indexOf(Constants.USER_ROLES.admin) !== -1;
    }

    ngOnInit() { }

    loadTabContent(tab: number) {
        if (this.selectedTab !== tab) {
            this.selectedTab = tab;
            switch (tab) {
                case this.TIME_TRACKING_TABS.SCHEDULES:
                    this.titleService.setTitle(this.translate.instant("timeTracking.tabs.schedules"));
                    this._route.updateParams({ tabName: "schedules" });
                    break;
                case this.TIME_TRACKING_TABS.GLOBAL:
                    this.titleService.setTitle(this.translate.instant("timeTracking.tabs.global"));
                    this._route.updateParams({ tabName: "global" });
                    break;
                case this.TIME_TRACKING_TABS.NOTIFICATIONS:
                    this.titleService.setTitle(this.translate.instant("timeTracking.tabs.notifications"));
                    this._route.updateParams({ tabName: "notifications" });
                    break;
                default:
                    break;
            }
        }
    }

    createSchedule(): void {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '8vh'};
        dialogSettings.panelClass = ['animated','slideInDown'];

        this.dialog.open(ScheduleCreateModalComponent, dialogSettings).afterClosed().subscribe(result => {
            if (result?.success) {
                // Get the new schedule id from success and navigate to update view
                this.location.go('/settings/time-tracking/schedule/general/update/' + result.success.id);
            }
        });
    }
}
