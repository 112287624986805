<div class="main-container__content margin-top-40">
  <div class="main-container--medium-width">
    <h3 class="bold margin-bottom-30" [translate]="'documents.settings.documentsDisplayTitle'"></h3>
    <!-- Default opening documents -->
    <div class="group-form group-form--label-300">
      <div class="group-form__label">
        <span class="group-form__label__text" [translate]="'documents.settings.documentOpeningKind'"></span>
      </div>
      <div class="group-form__data padding-top-5 align-items-start max-width-55-percent">
        <div class="radio-button-container radio-button-container--column margin-left-20 padding-top-5">
          <div class="radio-button__item margin-bottom-16" style="align-items: flex-start;" (click)="changeDocumentOpener(DOCUMENT_OPENER.INTERNAL_VIEWER)"
            [ngClass]="{'radio-button__item--selected' : docSettingsGlobalForm.documentOpener == DOCUMENT_OPENER.INTERNAL_VIEWER}">
            <span class="radio-button__item__icon icon "
            [ngClass]="docSettingsGlobalForm.documentOpener == DOCUMENT_OPENER.INTERNAL_VIEWER ? 'icon-circle-selected' : 'icon-circle'"></span>
            <div class="flex direction-column padding-left-3">
              <span class="radio-button__item__label" [translate]="'documents.settings.integratedViewer'"></span>
              <span class="radio-button__item__label-comment" [translate]="'documents.settings.integratedViewerDescription'"></span>
            </div>
          </div>
          @if (docSettingsGlobalForm.documentOpener == DOCUMENT_OPENER.INTERNAL_VIEWER) {
            <div class="flex margin-left-30 margin-bottom-16">
              <toggle-input id="rating-allowed" [inputId]="'rating-allowed'" class="padding-top-5" (change)="setDocumentSettings()" [isSmall]="true"
                [(inputModel)]="docSettingsGlobalForm.allowDownloading">
              </toggle-input>
              <div class="flex flex-wrap">
                <span class="margin-left-5 min-width-fit-content black-45" [translate]="'documents.settings.allowDownloadingTextOne'"></span>
                <span class="margin-left-5 min-width-fit-content black-85" [translate]="'documents.settings.allowDownloadingTextTwo'"></span>
                <span class="margin-left-5 min-width-fit-content black-45" [translate]="'documents.settings.allowDownloadingTextThree'"></span>
                <span class="margin-left-5 min-width-fit-content black-45 font-size-13" [translate]="'documents.settings.allowDownloadingTextFour'"></span>
              </div>
            </div>
            @if (!docSettingsGlobalForm.allowDownloading) {
              <div class="info-advice margin-left-30 margin-top-0">
                <p [translate]="'documents.settings.allowDownloadingAdvice'"></p>
              </div>
            }
          }
          <div class="radio-button__item margin-bottom-16" style="align-items: flex-start;" (click)="changeDocumentOpener(DOCUMENT_OPENER.USER_DESIRED_APP)"
            [ngClass]="{'radio-button__item--selected' : docSettingsGlobalForm.documentOpener == DOCUMENT_OPENER.USER_DESIRED_APP}">
            <span class="radio-button__item__icon icon "
            [ngClass]="docSettingsGlobalForm.documentOpener == DOCUMENT_OPENER.USER_DESIRED_APP ? 'icon-circle-selected' : 'icon-circle'"></span>
            <div class="flex direction-column padding-left-3">
              <span class="radio-button__item__label" [translate]="'documents.settings.defaultUserApp'"></span>
              <span class="radio-button__item__label-comment" [translate]="'documents.settings.defaultUserAppDescription'"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>