import { Component, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormUtilsService } from '../../services/form-utils.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'loading-button',
  templateUrl: './loading-button.component.html'
})
export class LoadingButtonComponent implements OnDestroy {

  @Input() buttonId?: string;
  @Input() text: string;
  @Input() classes: string;
  @Input() iconClass: string;
  @Input() isValid = false;
  @Input() avoidTextTranslation? = false;
  @Input() title?: string;
  @Output() action: EventEmitter<any> = new EventEmitter();

  public isLoading = false;

  private submitActionFinishedSubscription: Subscription;

  constructor(private formUtilsService: FormUtilsService) { }

  ngOnDestroy() {
    if (this.submitActionFinishedSubscription) {
      this.submitActionFinishedSubscription.unsubscribe();
    }
  }

  submitForm(): void {
    this.isLoading = true;
    this.submitActionFinishedSubscription = this.formUtilsService.submitActionFinishedEmitter$
      .subscribe(() => {
        this.isLoading = false;
      });

    this.action.emit(null);
  }

}
