import { Component, OnInit } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";
import { TimeTrackingService } from '../time-tracking/time-tracking.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { SortDescriptor} from "@progress/kendo-data-query";
import { UtilsService } from '../../shared/services/utils.service';

declare var moment: any;

@Component({
    selector: 'time-tracking-list-detail',
    templateUrl: './time-tracking-list-detail.component.html',
    styleUrls: ['./_time-tracking-list-detail.scss']
})
export class TimeTrackingListDetailComponent implements OnInit {

    public isSyncing: boolean = false;
    public today: any = moment().hour('01').minute('00').second('00').millisecond('000');
    public months: Array<any> = [];

    public userRecordsForm: any = {};
    public dayRecordsDatagrid: GridDataResult = {
        data: [],
        total: 0
    };
    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [50,100],
    };
    public dataPagingOpts: any = {
        page: 0,
        size: 50,
        skip: 0,
        sort: ['lastModifiedDate,desc']
    };
    public dataFilterOpts: any = {
        date: this.today,
        name: '',
        dateRange: {
            startDate: null,
            endDate: null
        }
    };
    public totalPages: number = 0;
    public dataSkeleton = [{},{},{}];
    public gridSort: SortDescriptor[];
    public selectedRow = [];
    public selectedDay: any = {};
    public startDate: any;
    public endDate: any;

    public specificLabelKinds: any = {
        notWorkingDay: 9211020,
        publicHoliday: 10660061,
        holiday: 13158555,
        absence: 13010605
    }

    public selectRowEventFired = false; 
    private timeTrackingUrl: string = '/time-tracking';
    private _route: any;
   
    private userId: number;

    constructor( private location: Location, private upgrade: UpgradeModule, private notificationService: DuiNotificationsService, public utils: UtilsService,
                 private timeTrackingService: TimeTrackingService, private translate: TranslateService ) {

        this._route = this.upgrade.$injector.get('$route');
        this.userId = this._route.current.params.id;

        if (window['getLocalizedMonthsNames']) { this.months = window['getLocalizedMonthsNames'](); }

        const paramStart: Array<any> = this._route.current.params.start.split('-');
        const paramEnd: Array<any> = this._route.current.params.end.split('-');
        // Timeout because when refreshing page, translate is not working properly, translation is maked using default language 'es'
        setTimeout(() => {
            this.startDate = this.translate.instant('global.form.formattedDateLong', 
                {day: paramStart[2], month: this.months.find((month: any) => { return month.value == parseInt(paramStart[1]) }).name, year: paramStart[0]});
            this.endDate = this.translate.instant('global.form.formattedDateLong', 
                {day: paramEnd[2], month: this.months.find((month: any) => { return month.value == parseInt(paramEnd[1]) }).name, year: paramEnd[0]});
        }, 100);

    }

    ngOnInit() {
        this.getTimeTrackingGridData();
    }

    getTimeTrackingGridData() {
        this.isSyncing = true;

        this.timeTrackingService.findUserTimeRecordsByPeriod(this.userId, this._route.current.params.start, this._route.current.params.end).subscribe(
            (response) => {
                this.userRecordsForm = response;
                this.dayRecordsDatagrid.data = response.workdayRecords.reverse();
                this.dayRecordsDatagrid.total = response.workdayRecords.length;
                this.totalPages = 1;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    getFormattedDate(date: any): string {
        date = new Date(date);
        let formattedDate: string = '';
        // Return date with year if date is not current year
        if (!date || date.getFullYear() < this.today.year()) {
            formattedDate = this.translate.instant('global.form.formattedDateLong', 
                {day: date.getDate(), month: this.months.find((month: any) => { return month.value == date.getMonth() + 1 })?.name, year: date.getFullYear()});
        } else {
            formattedDate = this.translate.instant('global.form.dateLongNoYear', 
                {day: date.getDate(), month: this.months.find((month: any) => { return month.value == date.getMonth() + 1 })?.name});
        }
        return formattedDate;
    }

    goBack(): void {
        this.location.go(this.timeTrackingUrl);
    }

    onSortChange(sort: SortDescriptor[]): void {      
        this.gridSort = sort;
        if( !!sort[0].dir) {
            this.dayRecordsDatagrid.data.reverse();
        }
    }

    onPageChange(event: PageChangeEvent): void {
        this.dataPagingOpts.page = (event.skip / this.dataPagingOpts.size);
        this.dataPagingOpts.skip = event.skip;
        this.dataPagingOpts.size = event.take;
        // this.getTimeTrackingGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    getItemDate(itemDate: string): any {
        return moment(itemDate).hour('01').minute('00').second('00').millisecond('000');
    }

    onSelectRecord(event: any): void {
        let selectedRecordDate = moment(event.selectedRows[0].dataItem.date).hour('01').minute('00').second('00').millisecond('000');
        if (selectedRecordDate > this.today) { return }

        this.selectRowEventFired = true;
        this.selectedDay = event.selectedRows[0].dataItem;
    }

    onDayRecordsChanges(): void {
        this.isSyncing = true;
        // Call endpoint for retrieve updated day and update selectedDay for refresh side panel
        this.timeTrackingService.findUserTimeRecordsByPeriod(this.userId, this.selectedDay.date, this.selectedDay.date).subscribe(
            (response) => {
                this.selectedDay = response.workdayRecords[0];
                // Update grid data, only selected row
                this.dayRecordsDatagrid.data[this.selectedRow[0]] = this.selectedDay;
                this.isSyncing= false;
                this.notificationService.showSuccessNotification();
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
                this.notificationService.showErrorNotification();
            }
        );
    }

    onCellClick(): void {
        // if click in selected row, the kendo selectionChange event is not triggered. In this case, deselect the row manually when click
        if (!this.selectRowEventFired) {
            this.selectedRow = [];
            this.selectedDay = {};
        }
        this.selectRowEventFired = false;
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2 
        }
    }
}