<div class="image-wrapper width-100-percent">

  <div id="image-drag-drop" class="drag-drop fileUpload relative"
    [ngClass]="{'image-container': itemHasImage(), 'dragging': isDropActive, 'image-container--rounded': itemHasImage()}"
    [ngStyle]="itemHasImage() && roundedCanvas ? {'height': cropOptions.MINIMUM_HEIGHT + 'px', 'width': cropOptions.MINIMUM_WIDTH + 'px'} : {'min-height': canvasHeight || cropOptions.MINIMUM_HEIGHT + 'px'}">

    @if (itemHasImage()) {
      <span class="delete-button icon icon-24 icon-delete delete-button--rounded-canvas" (click)="deleteImage()"></span>
    }

    <div class="selection-items">
      <img class="normal-state-img" [src]="'/ng1/assets/img/upload-picture.svg'" alt="">
      <img class="hover-state-img" [src]="'/ng1/assets/img/upload-picture-blue.svg'" alt="">
      <span [translate]="textLabel || 'message.dragimage'"></span>
    </div>
    <div class="selection-items">
      <span [textContent]="'message.dragimageor' | translate"></span>
    </div>
    <div class="flex selection-items relative">
      <button class="button button--small" style="z-index: 0;">
        <span class="button__label" [textContent]="'message.attachimage' | translate"></span>
      </button>
      @if (!hideSearchBtn) {
        <button class="button button--small" style="z-index: 10;" (click)="onImageSearch()">
          <span class="button__label" [textContent]="'imageBank.searchImage' | translate"></span>
        </button>
      }
    </div>

    <input #imageFileUpload id="image-file-upload" type="file" (change)="onSelectImage($event.target.files)" class="upload" title="" [disabled]="itemHasImage()"
      (onClick)="'this.value = null'" accept="{{acceptedFormats || 'image/bmp, image/gif, image/jpeg, image/jpg, image/png'}}" />

      @if (itemHasImage()) {
        <img #imagePreview id="imagePreview" [src]="croppedImageSrc" class="image-thumbnail" alt="" style="object-fit: contain; background-color: white;"
          [ngStyle]="{'height': canvasHeight || '283px'}"/>
      }

      @if (roundedCanvas && itemHasImage()) {
        <div id="overlay" class="rounded-overlay"></div>
      }
    </div>

  </div>