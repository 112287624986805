import { Component, Input, EventEmitter, Output, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { Constants } from '../../../constants';
import { TimeoffService } from '../../services/timeoff.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../services/utils.service';

declare var mobiscroll: any;

@Component({
    selector: 'date-range-selector',
    template: `<div style="display: none"></div>`
})
export class DateRangeSelectorComponent implements OnChanges {

    @Input() startInput: string;
    @Input() endInput: string;
    @Input() showEndDate = false;
    @Input() employeeId: string;
    @Input() instance: any;
    @Output() instanceChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() errorFunction?: EventEmitter<any> = new EventEmitter<any>();

    private componentId: string;

    constructor(private hostElement: ElementRef, private timeoffService: TimeoffService, private translate: TranslateService,
        private utilsService: UtilsService) {
        this.componentId = this.hostElement.nativeElement.id;
        this.translate = translate;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.employeeId && changes.employeeId.currentValue) {
            this.getEmployeeCalendar(changes.employeeId.currentValue);
        }
    }

    getEmployeeCalendar(employeeId) {
        if (this.componentId) {
            this.timeoffService.getCalendarHolidays(employeeId).subscribe(calendar => {
                let maxDate;
                if (!!calendar.lastSelectableDay) {
                    maxDate = new Date(calendar.lastSelectableDay);
                }
                let invalidWeekDays = [];
                if (!!calendar.workDays) {
                    invalidWeekDays = this.getInvalidWeekDays(calendar.workDays);
                }
                let invalidHolidays = [];
                if (!!calendar.publicHolidays) {
                    invalidHolidays = this.getInvalidHolidays(calendar.publicHolidays);
                }
                const invalidDays = invalidWeekDays.concat(invalidHolidays);
                this.initializeRangeComponent(this.componentId, {
                    max: maxDate,
                    invalid: invalidDays,
                    fromText: this.translate.instant('timeoffRequests.rangeStartText')
                });
            },
            error => {
                this.instanceChange.emit(null);
                if (!!this.errorFunction && this.errorFunction.observers.length > 0) {
                    const message = !!error.error && !!error.error.localizedError ? error.error.localizedError : '';
                    this.errorFunction.emit({message: message});
                }
            });
        }
    }

    initializeRangeComponent(id: string, options: any) {
        const userSession = window['getUserSession']();
        let language = userSession && userSession.language ? userSession.language : Constants.defaultLanguage;
        language = this.utilsService.getShortLanguageKey(language);
        const self = this;
        this.instance = mobiscroll.range('#' + id, {
            startInput: '#' + this.startInput,
            endInput: '#' + this.endInput,
            defaultValue: [],
            invalid: options.invalid,
            max: options.max,
            yearChange: false,
            display: 'center',
            months: 2,
            lang: language,
            fromText: options.fromText,
            onSetDate: function (event, inst) {
                if (self.showEndDate === false && event.control === 'calendar' && event.active === 'start') {
                    inst._isVisible = false;
                    inst.setActiveDate('start');
                    inst._isVisible = true;
                }
                if (inst._markup && self.showEndDate === false) {
                    inst._isValid = true;
                    // Enable 'Set' button after selecting start date if no end date is required
                    inst._markup.find('.mbsc-fr-btn-s .mbsc-fr-btn').removeClass('mbsc-fr-btn-d mbsc-disabled');
                }
            },
            onBeforeClose: function (event, inst) {
                if (event.button === 'set' && self.showEndDate === false) {
                    inst.setVal([inst.startVal, inst.startVal]);
                    return true;
                }
            }
        });
        this.instanceChange.emit(this.instance);
        if (!!this.errorFunction && this.errorFunction.observers.length > 0) {
            // Clear error message if exists.
            this.errorFunction.emit({message: null});
        }
    }

    getInvalidWeekDays(weekDays: any): Array<string> {
        const invalidWeekDays = [];
        if (weekDays.mondayEnabled === false) {
            invalidWeekDays.push('w1');
        }
        if (weekDays.tuesdayEnabled === false) {
            invalidWeekDays.push('w2');
        }
        if (weekDays.wednesdayEnabled === false) {
            invalidWeekDays.push('w3');
        }
        if (weekDays.thursdayEnabled === false) {
            invalidWeekDays.push('w4');
        }
        if (weekDays.fridayEnabled === false) {
            invalidWeekDays.push('w5');
        }
        if (weekDays.saturdayEnabled === false) {
            invalidWeekDays.push('w6');
        }
        if (weekDays.sundayEnabled === false) {
            invalidWeekDays.push('w0');
        }
        return invalidWeekDays;
    }

    getInvalidHolidays(holidays: Array<any>): Array<any> {
        const invalidHolidays = [];
        for (let index = 0; index < holidays.length; index++) {
            invalidHolidays.push(new Date(holidays[index]));
        }
        return invalidHolidays;
    }

}
