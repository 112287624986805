<div class="main-container">
  <div class="main-container__header margin-bottom-8" style="min-width: 891px;">
    <div class="flex align-items-start justify-space-between width-100-percent">
      <div class="flex direction-column width-100-percent" spellcheck="false">
        <ul class="breadcrumb margin-bottom-0">
          <li>
            <a href="/global-settings" [translate]="'global.navbar.globalSettings'"></a>
          </li>
          <li>
            <a id="go-segments" href="/settings/users-groups" [translate]="'segments.title'"></a>
          </li>
        </ul>
        <div class="editable-wrapper">
          <h2 #segmentName data-cy="segment-name" class="icon icon-edit contenteditable max-width-80-percent"
            [textContent]="segmentForm.name" ([ngModel])="segmentForm.name" [contentEditable]="true" prevent-event="dragover drop"
            (blur)="onSegmentNameInputEvent($event)" (keydown.enter)="onSegmentNameInputEvent($event)" (keydown.esc)="onSegmentNameInputEvent($event)"
          maxlength="64" (paste)="onSegmentNameInputPaste($event)"></h2>
          <div class="bottom-line"></div>
        </div>
        <div class="editable-wrapper">
          <p #segmentDescription id="segment-description" class="icon icon-edit contenteditable title" [title]="'segments.placeholderDescription' | translate"
            [textContent]="segmentForm.description" ([ngModel])="segmentForm.description" [contentEditable]="true" prevent-event="dragover drop"
            (blur)="onSegmentDescriptionInputEvent($event)" (keydown.enter)="onSegmentDescriptionInputEvent($event)" (keydown.esc)="onSegmentDescriptionInputEvent($event)"
          maxlength="64" (paste)="onSegmentDescriptionInputPaste($event)"></p>
        </div>
      </div>
      <div class="flex align-items-end">
        <loading-button [buttonId]="'add-users-link'" [classes]="'button button--small margin-top-24'"
          (action)="manageSegmentUsers()" [isValid]="segmentForm.id" [iconClass]="'icon-add'"
        [text]="'segments.addUsers'"></loading-button>

        @if (segmentForm.active) {
          @if (segmentForm.deletable) {
            <loading-button [buttonId]="'delete-users-group'" kendoTooltip filter="button" position="top" offset="8"
              [classes]="'button button--small button--only-icon margin-left-10'" (action)="deleteSegment()"
              [title]="'global.form.delete' | translate" [iconClass]="'button__icon icon icon-delete'" [isValid]="true">
            </loading-button>
          }
          @if (!segmentForm.deletable) {
            <loading-button [buttonId]="'archive-users-group'" kendoTooltip filter="button" position="top" offset="8"
              [classes]="'button button--small button--only-icon margin-left-10'" (action)="archiveOrActivateSegment()"
              [title]="'global.form.archive' | translate" [iconClass]="'button__icon icon icon-archive'" [isValid]="true">
            </loading-button>
          }
        } @else {
          <loading-button [buttonId]="'activate-users-group'" kendoTooltip filter="button" position="top" offset="8"
            [classes]="'button button--small button--only-icon margin-left-10'" (action)="archiveOrActivateSegment()"
            [title]="'global.form.activate' | translate" [iconClass]="'button__icon icon icon-unarchive'" [isValid]="true">
          </loading-button>
        }

      </div>
    </div>
  </div>
  <!-- Segment users list -->
  <div class="main-container__content margin-bottom-40">
    <div class="main-container--full-width">

      <div class="flex align-items-start justify-space-between">
        <div class="group-form group-form--label-100 margin-bottom-8">
          <div class="group-form__label">
            <span class="group-form__label__text font-size-16" [translate]="'model.schemes.employees'"></span>
          </div>
          <div class="group-form__data ">
            <div class="blue-counter">
              <span class="blue-counter__label" [textContent]="segmentUsersDatagrid?.total"></span>
            </div>
          </div>
        </div>
        <!-- filter by username, internal id, email and document name -->
        <div class="search-filter-container align-items-center margin-bottom-5">
          <input type="text" class="input input--search" maxlength="1024" [placeholder]="'users.search' | translate" [(ngModel)]="gridFilteringOps.name"
            (input)="listItemsSearch()" ngModelOptions="{ debounce: 200 }" [ngClass]="gridFilteringOps.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'" [disabled]="segmentUsersDatagrid?.total < 1">
            <div class="icon icon-remove" (click)="clearSearchInput()"></div>
          </div>
        </div>
        <!-- Segment users grid -->
        @if (segmentUsersDatagrid?.total > 0) {
          <kendo-grid #segmentUsersGrid [data]="segmentUsersDatagrid" class="master-grid"
            [sortable]="true" [sort]="gridSort" (sortChange)="onSortChange($event)" [resizable]="false" [selectable]="false" [navigable]="true" [loading]="isSyncing"
            [pageable]="true" (pageChange)="onPageChange($event)" [skip]="gridPagingOps.skip" [pageSize]="gridPagingOps.size">
            <kendo-grid-messages
              [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate" [pagerOf]="'global.lists.pagerOf' | translate"
              [pagerItems]="'global.lists.pagerItems' | translate">
            </kendo-grid-messages>
            <!-- User state -->
            <kendo-grid-column width="76" class="cursor-pointer" [headerClass]="'padding-0'" [sortable]="false">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center" kendoTooltip filter="span" position="top" offset="8" >
                  <span [ngClass]="dataItem | userStateIcon" [title]="dataItem | userStateText"></span>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- User avatar -->
            <kendo-grid-column width="76" class="cursor-pointer" [headerClass]="'padding-0'" [sortable]="false">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <avatar [name]="dataItem.name" [surname]="dataItem.surname" [small]="false"
                    [thumbnails]="{thumbnail_M: dataItem.avatarThumbnailMUrl || dataItem.avatarUrl, thumbnail_S: dataItem.avatarThumbnailSUrl || dataItem.avatarThumbnailMUrl}">
                  </avatar>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- User name -->
            <kendo-grid-column field="user.name" [sortable]="true" class="cursor-pointer">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center cursor-pointer user-select-none">
                  <span [textContent]="'users.name' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <span class="truncate" [textContent]="dataItem.name"></span>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- User surname -->
            <kendo-grid-column field="user.surname" [sortable]="true" class="cursor-pointer">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center cursor-pointer user-select-none padding-left-5">
                  <span [textContent]="'users.surname' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <span class="truncate" [textContent]="dataItem.surname"></span>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- Employee username -->
            <kendo-grid-column field="user.username" [sortable]="true" class="cursor-pointer">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center padding-left-8 cursor-pointer user-select-none">
                  <span [textContent]="'users.username' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <span class="truncate" [textContent]="dataItem.username"></span>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- User origin -->
            <kendo-grid-column width="90" class="cursor-pointer" [sortable]="false">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center justify-content-end padding-left-10">
                  <span [textContent]="'segments.segmentedUsers.origin.header' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="flex align-items-center k-button no-hover" kendoTooltip filter="span" position="top" offset="8" >
                  <span [ngClass]="dataItem.origin | userOriginIcon" [title]="dataItem.origin | userOriginText"></span>
                </span>
              </ng-template>
            </kendo-grid-column>
            <!-- CTA buttons column -->
            <kendo-grid-command-column width="90" [style]="{ 'text-align': 'end' }" class="cursor-pointer">
              <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <div class="td-on-hover">
                  <a id="delete-user-btn" class="k-button k-master-button" (click)="deleteUserFromSegment(dataItem)">
                    <span class="icon icon-delete"></span>
                  </a>
                </div>
              </ng-template>
            </kendo-grid-command-column>

            <!-- Custom Pager -->
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
              <article class="dui-grid-pager">
                <kendo-pager-info></kendo-pager-info>
                @if (segmentUsersDatagrid.total > 10) {
                  <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                    <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                    <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                    <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                  </article>
                  <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                }
              </article>
            </ng-template>
          </kendo-grid>
        }

        <!-- Skeleton company employees grid -->
        @if (segmentUsersDatagrid?.total == 0 && isSyncing) {
          <div>
            <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid" [rowClass]="skeletonRowCallback">
              <kendo-grid-column width="76"></kendo-grid-column>
              <kendo-grid-column width="76">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="flex align-items-center">
                    <kendo-skeleton shape="circle" animation="pulse" [width]="40" [height]="40"></kendo-skeleton>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column [headerStyle]="{'padding': '0.5em 0.4em'}">
                <ng-template kendoGridHeaderTemplate>
                  <div class="flex align-items-center">
                    <span class="padding-left-10" [translate]="'users.name'"></span>
                  </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="flex align-items-center">
                    <kendo-skeleton shape="text" animation="pulse" [width]="200" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column [headerStyle]="{'padding': '0.5em 0.6em'}">
                <ng-template kendoGridHeaderTemplate>
                  <div class="flex align-items-center padding-left-5">
                    <span class="padding-left-10" [translate]="'users.surname'"></span>
                  </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="flex align-items-center">
                    <kendo-skeleton shape="text" animation="pulse" [width]="200" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column>
                <ng-template kendoGridHeaderTemplate>
                  <div class="flex align-items-center">
                    <span class="padding-left-10" [translate]="'users.username'"></span>
                  </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-skeleton shape="text" animation="pulse" [width]="250" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column width="90">
                <ng-template kendoGridHeaderTemplate>
                  <div class="flex align-items-center justify-content-end padding-left-10">
                    <span [translate]="'segments.segmentedUsers.origin.header'"></span>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-command-column title="" width="90"></kendo-grid-command-column>
            </kendo-grid>
          </div>
        }
        @if (segmentUsersDatagrid?.total == 0 && !gridFilteringOps.name && !isSyncing) {
          <no-results [imageTitle]="'model.segment.noResultsTitle'"
            [description]="'model.segment.noResultsText'">
          </no-results>
        }
        @if (segmentUsersDatagrid?.total == 0 && gridFilteringOps.name && !isSyncing) {
          <no-search-results></no-search-results>
        }
        <div class="row disable-animation margin-top-20 margin-bottom-20 left">
          <div class="col-xs-12">
            <loading-button [buttonId]="'go-back'" [classes]="'button margin-top-20'" (action)="navigateTo('back')"
              [isValid]="true" [text]="'global.form.back'" [iconClass]="'icon-back'">
            </loading-button>
          </div>
        </div>
      </div>
    </div>
  </div>