import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormUtilsService } from '../../services/form-utils.service';
import { CreationLinkModalComponent } from '../creation-link-modal/creation-link-modal.component';

@Component({
    selector: 'dialenga-textarea',
    templateUrl: './dialenga-textarea.component.html'
})
export class DialengaTextareaComponent implements OnChanges, OnInit {

    @Input() isDisabled?: false;
    @Input() isReadOnly?: false;
    @Input() isRichFormat?: false;
    @Input() showLinkBtns?: false;
    @Input() showListsBtns?: false;
    @Input() hideActionsBtns?: false;
    @Input() textareaId: string;
    @Input() required?: { state: boolean, errorMessage: string };
    @Input() minLength?: { amount: number, errorMessage: string };
    @Input() maxLength?: { amount: number, warningAmount?: number };
    @Input() inputModel: string;
    @Input() placeholder?: string;
    @Output() inputChanged?: EventEmitter<any> = new EventEmitter();
    @Output() inputModelChange: EventEmitter<string> = new EventEmitter<string>();

    public isValid = true;
    public errorMessage: string;
    public inputOnFocus = false;
    public warningMessage: string;
    public colorWarningMessage: number;
    public isWarningMessageActive = false;
    public MESSAGE_COLOR = {
        WARNING: 1,
        DANGER: 2
    };
    public pasteCleanupSettings = {
        removeAttributes: 'all',
        removeHtmlComments: true,
        removeInvalidHTML: true
    };
    private isInputModelAlreadyEdited = false;
    private validRequired = true;
    private validMinLength = true;
    private validMaxLength = true;
    private lastInputModel: string;
    private cleanHtmlRegExp = /<[^>]*>/g;

    constructor(private translate: TranslateService, private Dialog: MatDialog, public formUtilsService: FormUtilsService) {
        this.translate = translate;
    }

    ngOnInit(): void {
        this.lastInputModel = this.inputModel;
    }

    ngOnChanges(changes: SimpleChanges) {
        if( changes.inputModel && typeof changes.inputModel.previousValue === 'undefined') {
            this.lastInputModel = this.inputModel;
            this.placeholder = this.placeholder || 'global.form.notSet';
        }
    }

    modelValueChanged (): void {
        if (this.isValid && this.inputModel !== this.lastInputModel) {
            this.inputModelChange.emit(this.inputModel);
            if (this.inputChanged) {
                this.inputChanged.emit(null);
            }
        } else {
            this.inputModel = this.lastInputModel;
            this.inputModelChange.emit(this.inputModel);
            this.checkValidations();
        }
        this.lastInputModel = this.inputModel;
    }

    checkValidations (): void {
        let errorMessageKey: string;
        let plainText = !!this.inputModel ? this.inputModel.replace(this.cleanHtmlRegExp, '') : '';

        if (this.required?.state) {
            this.validRequired = this.inputModel !== '';
            errorMessageKey = !this.validRequired ? this.required.errorMessage : '';
        }

        if (this.minLength?.amount && this.validRequired) {
            if (this.inputModel?.length > 0) {
                this.validMinLength = this.inputModel.length >= this.minLength.amount;
                errorMessageKey = !this.validMinLength ? this.minLength.errorMessage : '';
            } else {
                this.validMinLength = true;
            }
        }
        if (this.maxLength?.amount && this.maxLength?.warningAmount && this.validMinLength && this.validRequired) {
            if (this.inputModel) {
                this.isWarningMessageActive = plainText.length >= this.maxLength.warningAmount;
                if (plainText.length >= this.maxLength.warningAmount && plainText.length < this.maxLength.amount ) {
                    this.isWarningMessageActive = true;
                    this.colorWarningMessage = this.MESSAGE_COLOR.WARNING;
                } else if (plainText.length === this.maxLength.amount ) {
                    this.isWarningMessageActive = true;
                    this.colorWarningMessage = this.MESSAGE_COLOR.DANGER;
                }
            }
        }

        this.isValid = this.validRequired && this.validMinLength && this.validMaxLength;
        if (errorMessageKey) {
            this.errorMessage = this.translate.instant(errorMessageKey, {amount: this.minLength?.amount});
        }
        if (this.isWarningMessageActive) {
            this.warningMessage = this.translate.instant('global.validation.warningAmount', {inputLength: plainText.length, totalLength: this.maxLength.amount});
        }
    }

    modelValueIsChanging (): void {
        this.inputModel = this.inputModel.trim();
        this.checkValidations();
    }

    editorValueChange(value: any, editor: any): void {
        // Clean html tags value and check if maxlength has been reached (if afirmative, restore previous value and cancel)
        if (!!this.maxLength && this.maxLength?.amount) {
            let plainText = value.replace(this.cleanHtmlRegExp, '');
            if (plainText.length > this.maxLength.amount) {
                editor.changeValue(this.inputModel)
                return
            }
        }
        this.inputModel = value;
        this.checkValidations();
        this.inputModelChange.emit(this.inputModel);
    }

    modelValueOnFocus (): void {
        this.inputOnFocus = true;
        this.checkValidations();
    }

    modelValueOnFocusOut (): void {
        this.inputOnFocus = false;
        this.isWarningMessageActive = false;
        if (!this.isInputModelAlreadyEdited) {
            this.modelValueChanged();
        }
        this.isInputModelAlreadyEdited = false;
    }

    modelValueCancel (): void {
        this.isInputModelAlreadyEdited = true;
        this.inputModel = this.lastInputModel;
        this.modelValueChanged();
    }

    modelValueAccept (): void {
        this.isInputModelAlreadyEdited = true;
        this.modelValueChanged();
    }

    onKeyPressed (event: any): void {
        if (event.keyCode === 27) {
            this.modelValueCancel();
        }
        event.srcElement.blur();
    }

    createLink(editor: any) {
        const isTextSelected = !!editor.selectionText;
        let domNode: any;
        let linkUrl: string = '';
        let linkText: string = '';
        let nodeToReplace: string = '';
        let editorOriginalValue = editor.value;

        // Select the domNode depending there are text selected or cursor position
        if (isTextSelected) {
            domNode = editor.view.domAtPos(editor.view.state.selection.$head.parentOffset);
        } else {
            domNode = editor.view.domAtPos(editor.view.state.selection.$anchor?.pos);
        }
        // Check if domNode is already a link or not
        if (domNode.node.parentNode.tagName === 'A' && !!domNode.node.parentNode.href) {
            // domNode contents a link: take url and text for send to modal, and domNode in text format for replace it later
            linkUrl = domNode.node.parentNode.href;
            linkText = domNode.node.textContent || domNode.node.wholeText;
            nodeToReplace = domNode.node.parentNode.outerHTML;
        } else {
            // domNode is not a link: url empty and text is selectedText or empty. Insert dummy text for replace it later
            linkText = editor.selectionText || '';
            nodeToReplace = '#a';
            editor.exec('insertText', { text: nodeToReplace});
        }
        // Open modal to get link information
        const dialogSettings = new MatDialogConfig();
        dialogSettings.data = {
          url: linkUrl,
          text: linkText
        };
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '8vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.Dialog.open(CreationLinkModalComponent, dialogSettings).afterClosed().subscribe(result => {
            this.formUtilsService.finishSubmitAction();
  
            if (result?.accept) {
                let editorText = editor.value;
                editorText = editorText.replace(nodeToReplace, result.generatedLink);
                editor.exec("setHTML", editorText);
        
                if (!isTextSelected || (isTextSelected && domNode.node.parentNode.tagName !== 'A')) {
                    this.modelValueOnFocusOut()
                }
            }

            if (!result || result?.cancel) {
                editor.exec("setHTML", editorOriginalValue);
            }
        });
    }
}
