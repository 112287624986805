import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InternalLinkService } from './internal-link.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Constants } from '../../../constants';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../../shared/services/utils.service';
import { DuiNotificationsService } from '../../../shared/services/dui-notifications.service';

@Component({
    templateUrl: './internal-link-modal.component.html',
    styleUrls: ['./_internal-link.scss']
  })
  export class InternalLinkModalComponent implements OnInit, AfterViewInit, OnDestroy {

    public isFormValid: boolean = false;
    public isSyncing: boolean = false;

    public contentSearchText: string = '';
    public internalLinks: any = {
        content: [],
        totalElements: null
    };
    
    public SECTION_FILTER = {
        ALL: 0,
        TIMELINE: 1,
        QUESTIONNAIRES: 2,
        KNOWLEDGE_CENTER: 3
    };
    public CONTENT_KIND: any = Constants.CONTENT_KIND;
    public filterButtons: Array<any>;
    public selectedFilter: any = {};

    public showFilters: boolean = false;

    private searchSubject = new Subject<string>();
    private readonly debounceTimeMs = 500;
    private contentSelectedIndex: number = null; 
    public getTimeElapsedFrom: (startDate: Date) => string;

    @ViewChild('contentSearch') contentSearchRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<InternalLinkModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private internalLinkService: InternalLinkService,
        private translate: TranslateService, private utilsService: UtilsService, private notificationService: DuiNotificationsService
    ) {
        this.getTimeElapsedFrom = this.utilsService.getTimeElapsedFrom;
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.contentSearchRef.nativeElement.focus(), 200);
    }

    ngOnInit(): void {
        this.searchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe((searchValue) => {
            this.onChangeSearchInput(searchValue);
        });
        this.filterButtons = [
            {id: 'btn-all', text: this.translate.instant('knowledgecenter.categories.all'), value: this.SECTION_FILTER.ALL, kind: ''},
            {id: 'btn-timeline', text: this.translate.instant('messages.appTitle'), value: this.SECTION_FILTER.TIMELINE, kind: 'MESSAGE'},
            {id: 'btn-questionnaires', text: this.translate.instant('global.navbar.questionnaires'), value: this.SECTION_FILTER.QUESTIONNAIRES, kind: 'SURVEY'},
            {id: 'btn-knowledgecenter', text: this.translate.instant('global.navbar.knowledgecenter'), value: this.SECTION_FILTER.KNOWLEDGE_CENTER, kind: 'KNOWLEDGE_PILL'},
        ];
        this.selectedFilter = this.filterButtons[0];
    }

    ngOnDestroy() {
        this.searchSubject.complete();
      }

    private validateModel(): void {
        this.contentSelectedIndex = this.internalLinks.content.findIndex((item: any) => { return item.selected; });
        this.isFormValid = this.contentSelectedIndex !== -1;
    }

    private onChangeSearchInput(searchValue: string): void {
        
        if (searchValue.length < 3) {
            this.internalLinks.content = [];
            this.internalLinks.totalElements = null;
            return;
        }

        this.isSyncing = true;

        this.internalLinkService.findContents(searchValue, this.selectedFilter.kind).subscribe(
            (response: any) => {
                this.internalLinks.content = response;
                this.internalLinks.totalElements = response.length;
                this.isSyncing = false;
                this.showFilters = true;
            },
            (error: any) => {
                this.isSyncing = false;
                this.notificationService.showErrorNotification();
            }
        )
    }

    private getVideoId(url: string): string {
        let videoId = url.split('?v=')[1];
        if (videoId && videoId.length > 1) {
            // Regular links
            let ampersandPosition = videoId.indexOf('&');
            if (ampersandPosition !== -1) {
                videoId = videoId.substring(0, ampersandPosition);
            }
        } else {
            // Shortened links
            videoId = url.split('youtu.be/')[1];
            if (videoId && videoId.length > 1) {
                let paramsPosition = videoId.indexOf('?');
                if (paramsPosition !== -1) {
                    videoId = videoId.substring(0, paramsPosition);
                }
            } else {
                // Shorts links
                videoId = url.split('/shorts/')[1];
                if (!videoId || videoId.length === 0) {
                    // Embeded links
                    videoId = url.split('/embed/')[1];
                    if (!videoId || videoId.length === 0) {
                        // Invalid links
                        videoId = '';
                    }
                }
            }
        }
        return videoId;
    }

    onClearSearchInput(): void {
        this.internalLinks.content = [];
        this.internalLinks.totalElements = null;
        this.contentSearchText = '';
        this.showFilters = false;
    }

    onSearch(): void {
        if (this.contentSearchText.length < 3) {
            this.showFilters = false;
        }
        this.searchSubject.next(this.contentSearchText);
    }

    onSelectFilter(currentFilter: any): void {
        this.selectedFilter = currentFilter;
        this.onChangeSearchInput(this.contentSearchText);
    }

    onSelectContent(newContent: any): void {
        let contentAlreadySelectedIdx = this.internalLinks.content.findIndex((item: any) => {
            return item.selected;
        });

        if (contentAlreadySelectedIdx === -1) {
            // They are no content already selected, select current this
            newContent.selected = true;
        } else {
            this.internalLinks.content[contentAlreadySelectedIdx].selected = false;
            if (this.internalLinks.content[contentAlreadySelectedIdx].id !== newContent.id) {
                newContent.selected = true;
            }
        }
        this.validateModel();
    }

    getContentIconClass(kind: number, isGallery?: boolean): string {
        let iconClass: string = '';

        switch (kind) {
            case this.CONTENT_KIND.MESSAGE:
                iconClass = isGallery ? 'icon-related-gallery' : 'icon-related-default';
                break;
            case this.CONTENT_KIND.KNOWLEDGE_PILL:
                iconClass = 'icon-related-knowledge';
                break;
            case this.CONTENT_KIND.SURVEY:
                iconClass = 'icon-related-survey';
                break;
            default:
                iconClass = 'icon-related-default';
                break;
        }
        return iconClass;
    }

    getVideoImgPreview(videoUrl: string): string {
        let imgPreviewUrl: string = '';
        const videoId = this.getVideoId(videoUrl);
        if (videoId) {
            imgPreviewUrl = 'https://img.youtube.com/vi/' + videoId + '/hqdefault.jpg';
        } 
        return imgPreviewUrl;
    }

    onAccept(): void {
        this.dialogRef.close({success: true, content: this.internalLinks.content[this.contentSelectedIndex]});
    }

    onCancel(): void {
        this.dialogRef.close({closed: true});
    }

}