import { Component, ElementRef, HostListener, Input, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { UsersService } from "../../services/users.service";

@Component({
    selector: 'mention',
    templateUrl: './mention.component.html',
    styleUrls: ['./_mention.component.scss']
})
export class MentionComponent implements OnInit {

    @Input() id: number;

    public isActive: boolean = false;
    public userData: any = {};
    public popoverTop: string;
    public popoverLeft: string;

    @HostListener('document:click', ['$event'])
    clickout (event: MouseEvent) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.isActive = false;
        }
    }
    @HostListener('document:keydown.escape', ['$event']) 
    onKeydownHandler (event: KeyboardEvent) {
        this.isActive = false;
    }

    constructor(private eRef: ElementRef, private usersService: UsersService, private location: Location) { }

    ngOnInit() {}

    showMentionedInfo(item: any) {
        
        this.popoverTop = item.eRef.nativeElement.offsetTop >= 160 ? '-130px' : '25px';
        this.popoverLeft = (-item.eRef.nativeElement.offsetLeft / 2) + 'px';

        if(!this.isActive) {
            this.isActive = true;

            this.usersService.findOne(item.id).subscribe(
                (response: any) => {
                    this.userData = response;
                },
                (error: any) => {
                    console.error(error);
                }
            );
        }
    }

    goMentionedProfile() {
        this.location.go('/user/companydata/update/' + this.userData.id);
    }
}