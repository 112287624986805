import { Component, HostListener, OnInit } from '@angular/core';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { UsersImportService } from '../../shared/services/users-import.service';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../constants';
import * as XLSX from 'xlsx';

@Component({
    selector: 'import-users',
    templateUrl: './import-users.component.html'
})
export class ImportUsersComponent implements OnInit{
    
    public loadCsvStepOneEnabled: boolean = true;
    public loadCsvStepTwoEnabled: boolean = false;
    public isValidatingCsvFile: boolean = false;
    public csvToUpload: any = '';
    public nextButtonOptions = {
        text: '',
        icon: ''
    };
    public resumeActions: {
        newEmployees: 0,
        issues: [],
        warningsCount: 0,
        errorsCount: 0
    };
    public userImportForm = {
        allowUsersArchiving: false,
        sendActivateEmails: true
    }
    public downloadTemplateUrl = Constants.apiUrl + '/users/load-users-template';
    public helpBoxImprovedUsersImportAdvice: boolean;

    @HostListener('document:click', ['$event']) clickout(event: any) : void {};

    constructor(private formUtilsService: FormUtilsService, private usersImportService: UsersImportService, private translate: TranslateService) {}

    ngOnInit() {

        var helpBoxVisible = localStorage.getItem('helpBoxImprovedUsersImportAdvice');
        this.helpBoxImprovedUsersImportAdvice = JSON.parse(helpBoxVisible) === true || JSON.parse(helpBoxVisible) === null;
        if(this.helpBoxImprovedUsersImportAdvice) {
            this.clickout = function(event: any) : void {
                this.hideHelpBoxImprovedUsersImportAdvice();
            }
        }

        this.setNextButtonOptions({text: 'global.form.next', icon: 'icon-next'});
        this.userImportForm.sendActivateEmails = true;
    }

    handleFileInput(files: FileList) {
        this.csvToUpload = files.item(0);
    }

    removeCsvFileSelected() {
        this.csvToUpload = '';
    }

    setNextButtonOptions(options: any) {
        this.nextButtonOptions.text = options.text;
        this.nextButtonOptions.icon = options.icon;
    }

    setStepTwoConditions() {
        this.loadCsvStepOneEnabled = false;
        this.loadCsvStepTwoEnabled = true;
        this.setNextButtonOptions({text: 'global.form.next', icon: 'icon-next'});
        this.formUtilsService.finishSubmitAction();
    }

    getImportResume() {
        this.isValidatingCsvFile = true;
        this.setNextButtonOptions({text: 'global.form.processing', icon: ''})
        
        this.usersImportService.getImportUsersResume(this.userImportForm, this.csvToUpload).subscribe(
            (response) => { 
                this.setStepTwoConditions();
                this.resumeActions = response;
                this.userImportForm.sendActivateEmails = this.resumeActions.newEmployees > 0;
            },
            (error: any) => {
                let message = error.error.localizedError;
                this.discardImport();
                this.setNextButtonOptions({text: 'global.form.next', icon: 'icon-next'});
                this.formUtilsService.finishSubmitAction();
                if (window['notificationImportUsersError']) {
                    window['notificationImportUsersError'](message);
                }
            }
        );
    }
    
    discardImport() {
        this.loadCsvStepOneEnabled = true;
        this.loadCsvStepTwoEnabled = false;
        this.isValidatingCsvFile = false;
        this.csvToUpload = '';
        this.userImportForm.allowUsersArchiving = false;
        this.userImportForm.sendActivateEmails = true;
    }

    processUsersImport() {
        this.usersImportService.uploadImportUsersCsvFile(this.userImportForm, this.csvToUpload).subscribe(
            () => { 
                if(window['notifyImportUsersSuccessAndRedirect']) {
                    window['notifyImportUsersSuccessAndRedirect']();
                }
            },
            (error: any) => {
                let message = error.error.localizedError;
                if (window['notificationImportUsersError']) {
                    window['notificationImportUsersError'](message);
                }
            }
        );
    }

    downloadErrorsLog() {
        // Generate worksheet
		const wsheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.resumeActions.issues);
        wsheet['!autofilter'] = { ref: "A1:B1" };
        wsheet['!cols'] = [{ wch: 10 }, { wch: 76 }];
        wsheet['A1'].v = this.translate.instant('usersImport.errors.line');
        wsheet['B1'].v = this.translate.instant('usersImport.errors.error');
		// Generate workbook and add the worksheet 
		const wbook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wbook, wsheet, 'Sheet1');
		// Save to file
		XLSX.writeFile(wbook, 'load-users-errors.xlsx', {cellStyles: true});
    }

    showErrorsLog() {
        if(window['showImportUsersErrorLog']) {
            window['showImportUsersErrorLog'](this.resumeActions.issues, this.resumeActions.warningsCount, this.resumeActions.errorsCount);
        }
    }

    hideHelpBoxImprovedUsersImportAdvice() {
        localStorage.setItem('helpBoxImprovedUsersImportAdvice', JSON.stringify(false));
        this.helpBoxImprovedUsersImportAdvice = false;
        this.clickout = function() : void {};
    }
}
