import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'feedback-action-button',
  templateUrl: './feedback-action-button.component.html'
})
export class FeedbackActionButtonComponent {

  @Input() buttonId?: string;
  @Input() text: string;
  @Input() classes: string;
  @Input() iconClass: string;
  @Input() tooltipText: string;
  @Input() isValid = false;
  @Output() action: EventEmitter<any> = new EventEmitter();

  constructor() { }

  submitForm(): void {
    this.action.emit(null);
  }
}
