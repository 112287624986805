import { Component, OnInit } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { TranslateService } from '@ngx-translate/core';
import { dialengaExeviBackoffice } from './ng1.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private upgrade: UpgradeModule, private translate: TranslateService) {
    translate.setDefaultLang('es');
  }

  ngOnInit() {
    this.upgrade.bootstrap(document.documentElement, [dialengaExeviBackoffice.name]);
  }
}
