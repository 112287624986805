import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../constants';

@Injectable({providedIn: 'root'})
export class ExternalLinksService {

    private apiUrl: string;
    
    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
    }
    
    findAll(): any {
        return this.httpClient.get<any>(`${this.apiUrl}/external-links`);
    }

    findOne(id: number) {
        return this.httpClient.get<any>(`${this.apiUrl}/external-links/${id}`);
    }

    create(data: any) {
        return this.httpClient.post(`${this.apiUrl}/external-links`, data);
    }

    update(data: any) {
        return this.httpClient.put<any>(`${this.apiUrl}/external-links/${data.id}`, data);
    }

    delete(id: number) {
        return this.httpClient.delete<any>(`${this.apiUrl}/external-links/${id}`);
    }

    reorderLink(linkId: number, newOrder: number) {
        const httpOptions = {
            params: {
                newOrder: newOrder
            }
        };
        return this.httpClient.put<any>(`${this.apiUrl}/external-links/${linkId}/reorder`, null, httpOptions);
    }
}

export interface externalLink {
    availabilityKind: number,
    contents: any,
    externalApp: boolean,
    icon: string,
    id?: number,
    order?: number,
    populationKind: number,
    segmentationKind: number,
    segments: Array<any>
    url: string,
}