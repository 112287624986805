<div class="main-container__content">
  <div class="main-container--small-width margin-top-20">

    <section>
      <h3 class="bold margin-bottom-24" [translate]="'timeTracking.global.appConfigurationTitle'"></h3>
      <!-- Set time tracking mode -->
      <div class="group-form group-form--label-300">
        <div class="group-form__label">
          <span class="group-form__label__text" [translate]="'timeTracking.global.trackingModeTitle'"></span>
        </div>
        <div class="group-form__data padding-top-5 align-items-start">
          <fieldset class="radio-button-container padding-top-5">
            <input type="radio" name="clockOrManual" id="clockOrManual" class="sr-only"
              [value]="TIME_TRACKING_MODE.CLOCK_OR_MANUAL" [(ngModel)]="scheduleSettingsForm.timeTrackingMode"
              (ngModelChange)="onChangeTrackingMode()">
              <label for="clockOrManual" class="radio-button__item"
                [ngClass]="{'radio-button__item--selected': scheduleSettingsForm.timeTrackingMode === TIME_TRACKING_MODE.CLOCK_OR_MANUAL}">
                <span class="radio-button__item__icon icon"
                [ngClass]="scheduleSettingsForm.timeTrackingMode === TIME_TRACKING_MODE.CLOCK_OR_MANUAL ? 'icon-circle-selected' : 'icon-circle'"></span>
                <div class="flex direction-column">
                  <span class="radio-button__item__label"
                  [translate]="'timeTracking.global.clockOrManualModeTitle'"></span>
                  <span class="radio-button__item__label-comment"
                  [innerHTML]="'timeTracking.global.clockOrManualModeDescription' | translate"></span>
                </div>
              </label>

              <input type="radio" name="manualMode" id="manualMode" class="sr-only"
                [value]="TIME_TRACKING_MODE.MANUAL" [(ngModel)]="scheduleSettingsForm.timeTrackingMode"
                (ngModelChange)="onChangeTrackingMode()">
                <label for="manualMode" class="radio-button__item"
                  [ngClass]="{'radio-button__item--selected': scheduleSettingsForm.timeTrackingMode === TIME_TRACKING_MODE.MANUAL}">
                  <span class="radio-button__item__icon icon"
                  [ngClass]="scheduleSettingsForm.timeTrackingMode === TIME_TRACKING_MODE.MANUAL ? 'icon-circle-selected' : 'icon-circle'"></span>
                  <div class="flex direction-column">
                    <span class="radio-button__item__label"
                    [translate]="'timeTracking.global.manualModeTitle'"></span>
                    <span class="radio-button__item__label-comment"
                    [translate]="'timeTracking.global.manualModeDescription'"></span>
                  </div>
                </label>

                <input type="radio" name="clockMode" id="clockMode" class="sr-only"
                  [value]="TIME_TRACKING_MODE.CLOCK" [(ngModel)]="scheduleSettingsForm.timeTrackingMode"
                  (ngModelChange)="onChangeTrackingMode()">
                  <label for="clockMode" class="radio-button__item"
                    [ngClass]="{'radio-button__item--selected': scheduleSettingsForm.timeTrackingMode === TIME_TRACKING_MODE.CLOCK}">
                    <span class="radio-button__item__icon icon"
                    [ngClass]="scheduleSettingsForm.timeTrackingMode === TIME_TRACKING_MODE.CLOCK ? 'icon-circle-selected' : 'icon-circle'"></span>
                    <div class="flex direction-column">
                      <span class="radio-button__item__label"
                      [translate]="'timeTracking.global.clockModeTitle'"></span>
                      <span class="radio-button__item__label-comment"
                      [translate]="'timeTracking.global.clockModeDescription'"></span>
                    </div>
                  </label>

                </fieldset>
              </div>
            </div>

            <!-- Stop timer after n hours  -->
            @if (scheduleSettingsForm.timeTrackingMode === TIME_TRACKING_MODE.CLOCK_OR_MANUAL ||
              scheduleSettingsForm.timeTrackingMode === TIME_TRACKING_MODE.CLOCK) {
              <div class="group-form group-form--label-300">
                <div class="group-form__label">
                  <span class="group-form__label__text" [translate]="'timeTracking.global.endShiftAfterHoursLabel'"></span>
                </div>
                <div class="group-form__data group-form__data--column padding-top-8">
                  <div class="info-advice margin-top-8">
                    <p [innerHTML]="'timeTracking.global.endShiftAfterHoursAdvice' | translate"></p>
                  </div>
                  <div class="flex align-items-center">
                    <span class="black-45" [translate]="'timeTracking.global.endShiftAfterHours'"></span>
                    <kendo-slider #hoursToEnd class="margin-left-16 margin-right-16"
                      [tickPlacement]="'after'" [showButtons]="false" [min]="1" [max]="5" [smallStep]="1" [fixedTickWidth]="30"
                      (mouseup)="onChangeHoursToEnd(hoursToEnd)" (mouseleave)="onChangeHoursToEnd(hoursToEnd)"
                      [(ngModel)]="hoursToEndSlider">
                    </kendo-slider>
                    <span [translate]="'global.form.nRegisteredHours'" [translateParams]="{hours: hoursToEndSlider}"></span>
                  </div>
                </div>
              </div>
            }
          </section>

          <h3 class="bold margin-bottom-24" [translate]="'timeTracking.global.startShiftLimitationTitle'"></h3>

          <!-- Limit start to clock in -->
          <div class="group-form group-form--label-300 margin-bottom-32">
            <div class="group-form__label">
              <span class="group-form__label__text" [translate]="'timeTracking.global.startShiftLimitationLabel'"></span>
            </div>

            <div class="group-form__data direction-column align-items-start dui-select">
              <kendo-dropdownlist class="dui-dropdown max-width-200" [data]="startShiftLimitations"
                [(ngModel)]="scheduleSettingsForm.startShiftLimitation" (valueChange)="onChangeStartShiftLimitation()"
                [textField]="'name'" [valueField]="'value'" [valuePrimitive]="true"
                [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
              </kendo-dropdownlist>

              @if ((!!scheduleSettingsForm.startShiftLimitation && scheduleSettingsForm.startShiftLimitation !== START_SHIFT_LIMITATION.NO_LIMIT)) {
                <section class="padding-top-16 flex align-items-center"
                  >
                  <span class="flex-no-shrink" [translate]="'timeTracking.global.marginOf'"></span>
                  <kendo-slider #minutesBefore class="margin-left-16 margin-right-16"
                    [tickPlacement]="'after'" [showButtons]="false" [min]="10" [max]="30" [smallStep]="5" [fixedTickWidth]="30"
                    (mouseup)="onChangeMarginToStart(minutesBefore)" (mouseleave)="onChangeMarginToStart(minutesBefore)"
                    [(ngModel)]="minutesMarginSlider">
                  </kendo-slider>
                  <span class="flex-no-shrink" [translate]="'timeTracking.global.minutesInAdvance'" [translateParams]="{minutes: minutesMarginSlider}"></span>
                </section>
              }
            </div>
          </div>
          <h3 class="bold margin-bottom-24" [translate]="'timeTracking.global.timeTrackingKinds'"></h3>

          <!-- Required record kind property toggle -->
          <div class="group-form group-form--label-300">
            <div class="group-form__label">
              <span class="group-form__label__text" [translate]="'timeTracking.global.kindRequired'"></span>
            </div>

            <div class="group-form__data padding-top-5 align-items-start">
              <toggle-input id="kind-required" [inputId]="'kind-required'" (change)="toggleKindRequired()"
                class="padding-top-5" [(inputModel)]="scheduleSettingsForm.timeTrackingKindRequired">
              </toggle-input>
            </div>
          </div>

          <!-- Record kinds enabled / record kinds availables -->
          @if (!!scheduleSettingsForm.timeTrackingKindRequired) {
            <div class="group-form group-form--label-300">
              <div class="group-form__label">
                <span class="group-form__label__text"
                [translate]="'users.documentsManager.fileKinds.modal.availableFilekinds'"></span>
              </div>
              <div class="group-form__data padding-top-5 direction-column align-items-start">
                @for (kind of timeTrackingKinds; track kind) {
                  <section
                    class="flex align-items-center margin-bottom-16">
                    <toggle-input id="tracking-kind-{{kind.id}}" [isDisabled]="ENABLED_KINDS_DEFAULT.indexOf(kind.id) !== -1"
                      [inputId]="'tracking-kind-{{kind.id}}'" [(inputModel)]="kind.active"
                      [isSmall]="true" (change)="onChangeRecordKindStatus()">
                    </toggle-input>
                    <article class="flex align-items-center tracking-kind">
                      <span class="tracking-kind__color"
                      style="--tracking-kind-color: {{decimalToHex(kind.color)}}"></span>
                      <span class="tracking-kind__label" [textContent]="kind.name"></span>
                    </article>
                  </section>
                }
              </div>
            </div>
          }
        </div>
      </div>