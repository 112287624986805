import { Injectable } from '@angular/core';
import { Constants } from '../../constants';
import { TranslateService } from '@ngx-translate/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { FileSaverService } from 'ngx-filesaver';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewFeaturesComponent } from '../../components/new-features/new-features.component';

@Injectable()
export class SharedDataService {

  public apiUrl;
  public backofficeUrl;
  public webappUrl;
  public environmentName;
  public translate;
  public AVAILABLE_FEEDS;
  public USER_ROLES;

  constructor(private _translate: TranslateService, private upgrade: UpgradeModule, private _httpClient: HttpClient, public _dialog: MatDialog, private fileSaverService: FileSaverService) {
    this.translate = _translate;
    this.apiUrl = Constants.apiUrl;
    this.backofficeUrl = Constants.backofficeUrl;
    this.webappUrl = Constants.webappUrl;
    this.environmentName = Constants.environmentName;
    this.AVAILABLE_FEEDS = Constants.AVAILABLE_FEEDS;
    this.USER_ROLES = Constants.USER_ROLES;
  }

  changeTranslationsLanguage(language: string): void {
    this.translate.use(language);
  }

  getMainContentLanguage(contents: any) {
    const userSession = window['getUserSession']();
    const companyDefaultLanguage = userSession.companyDefaultLanguage;
    const contentLanguages = Object.keys(contents);
    if (!contentLanguages || contentLanguages.length === 0) {
      return companyDefaultLanguage;
    } else {
      return (contentLanguages.indexOf(companyDefaultLanguage) > -1) ? companyDefaultLanguage : contentLanguages[0];
    }
  }

  checkIfItemIsAvailableForLoggedUser(item: any): boolean {
    const _rootScope = this.upgrade.$injector.get('$rootScope');
    // Check if access is restricted to specific roles
    if (item && item.access && item.access.length && _rootScope['account'] && _rootScope['account'].roles) {
        for (let index in item.access) {
          if (_rootScope['account'].roles.indexOf(item.access[index]) !== -1) {
            if (item.access[index] == this.USER_ROLES.manager && _rootScope['account'].roles.indexOf(this.USER_ROLES.admin) == -1 && _rootScope['account'].roles.indexOf(this.USER_ROLES.publisher) == -1) {
              switch (_rootScope['account'].editorPermissions.availableFeeds) {
                case this.AVAILABLE_FEEDS.ALL:
                  return true;
                case this.AVAILABLE_FEEDS.TIMELINE:
                  return item.url === '/' || item.url === '/messages' || item.url === '/questionnaires';
                case this.AVAILABLE_FEEDS.KNOWLEDGE_CENTER:
                  return item.url === '/' || item.url === '/knowledge-center';
                default:
                  return false;
              }
            } else {
              return true;
            }
          }
        }
        return false;
    } else {
        return true;
    }
  }

  showNewFeaturesModalAdvice(userLanguage: string, version: string, features: Array<[]>, modalTitle: any ) {
    const dialogSettings = new MatDialogConfig();
      dialogSettings.height = '458px';
      dialogSettings.width = '850px';
      dialogSettings.position = { top: '20vh'};
      dialogSettings.panelClass = ['animated','slideInDown'];
      dialogSettings.data = {
        language: userLanguage,
        version: version,
        features: features,
        modalTitle: modalTitle
      };
      this._dialog.open(NewFeaturesComponent, dialogSettings).afterClosed().subscribe(result => {
        if (result && result.closed) {
          let whatsNew = JSON.parse(localStorage.getItem('whatsNew'));
          whatsNew[0] = version;
          localStorage.setItem('whatsNew', JSON.stringify(whatsNew));
        }
      });
  }

  checkForNewFeaturesAdvice(userLanguage: string, eventSource?: string) {
    // Get the json file with new features for the current version
    this._httpClient.get("newFeatures.json").subscribe(
      (response: any) => {
        let currentVersion = response.version;
        // Get 'whatsnew' item in localStorage, with the versions shown
        setTimeout(() => {
          var whatsNew = JSON.parse(localStorage.getItem('whatsNew'));
          if (!whatsNew) {
            whatsNew = [];
            // If not exit 'whatsnew' item, create it empty
            localStorage.setItem('whatsNew', JSON.stringify(whatsNew));
          } 
          // Check if whatsnew item contain the current version. If negative, show the features
          if (!whatsNew.includes(currentVersion) || eventSource === 'popover') {
            this.showNewFeaturesModalAdvice(userLanguage, currentVersion, response.features, response.modalTitle);
          }
        }, 500);
       
      },
      (error) => {
        // The file with the features for the current version not exist, no features to show in current version
      }
    );
  }

  saveDownloadedFile(data: any, fileName: string) {
    this.fileSaverService.save(data, fileName);
  }
}
