<div class="main-container">
  <div class="main-container__header">
    <div class="flex align-items-start justify-space-between width-100-percent">
      <div class="flex direction-column width-100-percent" spellcheck="false">
        <ul class="breadcrumb">
          <li>
            <a href="/global-settings" [textContent]="'global.navbar.globalSettings' | translate"></a>
          </li>
        </ul>
        <h2 [textContent]="'segments.title' | translate"></h2>
      </div>
      <div class="flex align-items-end">
        <button id="create-segment-button" class="button button--secondary button--small" (click)="createSegment()">
          <span class="button__icon icon icon-add"></span>
          <span class="button__label" [textContent]="'segments.newsegment' | translate"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="main-container__content">
    <div class="main-container--full-width">
      <div class="filter-container margin-top-0">
        <div class="filters-buttons-bar-container flex vertical-center">
          <!-- Segments Actives / Archived filter bar -->
          <div class="label-filters">
            <a [ngClass]="{'selected': dataFilterOpts.active}" (click)="getSegmentsByIsActive(true)" [translate]="'global.listFilters.active'"></a>
            <a [ngClass]="{'selected': !dataFilterOpts.active}" (click)="getSegmentsByIsActive(false)" [translate]="'global.listFilters.archived'"></a>
          </div>
        </div>
        <div class="search-filter-container align-items-center">
          <input type="text" class="input input--search" maxlength="1024" [placeholder]="'global.search.placeholder' | translate" [(ngModel)]="dataFilterOpts.name"
            (input)="listItemsSearch()" ngModelOptions="{ debounce: 200 }" [ngClass]="dataFilterOpts.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'" [disabled]="segmentsDatagrid?.total < 1">
            <div class="icon icon-remove" (click)="clearSearchInput()"></div>
          </div>
        </div>

        @if (segmentsDatagrid?.data.length > 0) {
          <kendo-grid #segmentsGrid [data]="segmentsDatagrid" class="master-grid user-select-none margin-bottom-40"
            [sortable]="true" [sort]="gridSort" (sortChange)="onSortChange($event)" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()"
            [pageable]="gridPagerSettings" (pageChange)="onPageChange($event)" [skip]="dataPagingOpts.skip" [pageSize]="dataPagingOpts.size"
            [navigable]="true" [resizable]="false" [selectable]="false" [loading]="isSyncing">
            <kendo-grid-messages
              [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate" [pagerOf]="'global.lists.pagerOf' | translate"
              [pagerItems]="'global.lists.pagerItems' | translate">
            </kendo-grid-messages>
            <!-- Segment name -->
            <kendo-grid-column field="name" width="300">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center cursor-pointer">
                  <span class="truncate" [textContent]="'model.segment.name' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <span class="truncate cursor-default" [textContent]="dataItem.name"></span>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- Segment description -->
            <kendo-grid-column field="description">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center cursor-pointer">
                  <span class="truncate" [textContent]="'model.segment.description' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center">
                  <span class="truncate cursor-default" [textContent]="dataItem.description"></span>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- Segment employees count -->
            <kendo-grid-column field="employeeCount" width="150" [sortable]="false">
              <ng-template kendoGridHeaderTemplate>
                <div class="flex align-items-center justify-content-center cursor-default" kendoTooltip filter="span.icon-refresh" offset="5">
                  <span [translate]="'model.segment.totalusers'"></span>
                  <span class="icon icon-refresh refresh-user-groups cursor-pointer" (click)="refreshSegmentsDataGrid()"
                  [title]="'segments.updatedata' | translate"></span>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex align-items-center justify-content-center">
                  <span class="document-label__text cursor-default" [textContent]="dataItem.employeeCount"></span>
                </div>
              </ng-template>
            </kendo-grid-column>
            <!-- CTA buttons column -->
            <kendo-grid-command-column title="" width="136" [style]="{ 'text-align': 'end' }">
              <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <div class="flex justify-end td-on-hover" kendoTooltip filter="a.k-master-button" offset="0">
                  <a class="k-button k-master-button" (click)="updateSegment(dataItem)" [title]="'global.form.edit' | translate">
                    <span class="icon icon-edit"></span>
                  </a>
                  @if (!dataItem.deletable || !dataItem.active) {
                    <a class="k-button k-master-button" (click)="archiveOrActivateSegment(dataItem)"
                      [title]="(dataItem.active ? 'global.form.archive' : 'global.form.activate') | translate">
                      <span class="icon" [ngClass]="dataItem.active ? 'icon-archive' : 'icon-unarchive'"></span>
                    </a>
                  }
                  @if (dataItem.deletable && dataItem.active) {
                    <a class="k-button k-master-button"
                      (click)="deleteSegment(dataItem)" [title]="'global.form.delete' | translate">
                      <span class="icon icon-delete"></span>
                    </a>
                  }
                </div>
              </ng-template>
            </kendo-grid-command-column>
            <ng-template kendoGridNoRecordsTemplate></ng-template>
            <!-- Custom Pager -->
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
              <article class="dui-grid-pager">
                <kendo-pager-info></kendo-pager-info>
                @if (segmentsDatagrid.total > 10) {
                  <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                    <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                    <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                    <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                  </article>
                  <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                }
              </article>
            </ng-template>
          </kendo-grid>
        }

        <!-- Skeleton segments grid -->
        @if (segmentsDatagrid?.total == 0 && isSyncing) {
          <div>
            <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid" [rowClass]="skeletonRowCallback">
              <!-- Segment name -->
              <kendo-grid-column width="300">
                <ng-template kendoGridHeaderTemplate>
                  <div class="flex align-items-center">
                    <span [textContent]="'model.segment.name' | translate"></span>
                  </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="flex align-items-center">
                    <kendo-skeleton shape="text" animation="pulse" [width]="200" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <!-- Segment description -->
              <kendo-grid-column>
                <ng-template kendoGridHeaderTemplate>
                  <div class="flex align-items-center">
                    <span [textContent]="'model.segment.description' | translate"></span>
                  </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="flex align-items-center">
                    <kendo-skeleton shape="text" animation="pulse" [width]="350" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <!-- Segment employees count -->
              <kendo-grid-column width="150" media="(min-width: 1240px)">
                <ng-template kendoGridHeaderTemplate>
                  <div class="flex align-items-center justify-content-center">
                    <span [translate]="'model.segment.totalusers'"></span>
                    <span class="icon icon-refresh refresh-user-groups"></span>
                  </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="flex align-items-center justify-content-center">
                    <kendo-skeleton shape="text" animation="pulse" [width]="20" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <!-- CTA column -->
              <kendo-grid-command-column width="136"></kendo-grid-command-column>
            </kendo-grid>
          </div>
        }
        <!-- No data information -->
        @if (segmentsDatagrid.total == 0 && !dataFilterOpts.name && !isSyncing) {
          <no-results
            [imageTitle]="dataFilterOpts.active ? 'segments.noResultsTitle' : 'segments.noResultsArchiveTitle'"
            [description]="dataFilterOpts.active ? 'segments.noResultsText' : 'segments.noResultsArchiveText'">
          </no-results>
        }
        @if (segmentsDatagrid.total == 0 && dataFilterOpts.name && !isSyncing) {
          <no-search-results></no-search-results>
        }
      </div>
    </div>
  </div>