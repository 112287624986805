@if (_rootScope.authenticated) {
  <div id="sidemenu" class="the-menu scroll scroll2"
    [ngClass]="{'the-menu--small' : !_rootScope.isSidemenuVisible || categoryContainerState == CATEGORY_CONTAINER_STATE.OPENED,
'the-menu--inmobile' : _rootScope.inmobile, 'the-menu--is-mobile-size' : isMobileSize, 'the-menu--open-mobile-size' : _rootScope.openedMenuMobileSize }">
    @for (link of menuLinks; track link) {
      <div class="the-menu__item {{link.class}}"
        [attr.id]="link.id" [ngClass]="{'the-menu__item--selected': checkActive(link),
  'the-menu__item--disabled': !link.isAccessible}" (click)="redirectTo(link.url)">
        <span class="the-menu__icon {{link.icon}}"></span>
        <!-- <span class="the-menu__label" [translate]="link.title"></span> -->
        <span class="the-menu__label" [innerHTML]="link.title | translate"></span>
        @if (!link.isAccessible) {
          <span class="the-menu__locked icon icon-state-locked justify-self-end"></span>
        }
      </div>
    }
    @if (isSideMenuMoreItemVisible) {
      <div class="the-menu__item the-menu__item--bottom cursor-pointer" id="menu-more" (click)="toggleSubmenuVisibility()"
        [ngClass]="{'the-menu__item--selected': checkActiveMenuMore()}">
        <span class="the-menu__icon icon the-menu__icon icon icon-menu-more"></span>
        <span class="the-menu__label" [translate]="'global.navbar.more'"></span>
        <!-- <span class="the-menu__locked icon icon-state-locked justify-self-end"></span> -->
      </div>
    }
    @if (categoryContainerState != CATEGORY_CONTAINER_STATE.OPENED  || _rootScope.currentRoute?.originalPath != '/knowledge-center') {
      <div class="the-menu__arrows-container"
        >
        @if (!_rootScope.inmobile && _rootScope.isSidemenuVisible) {
          <span class="the-menu__arrow icon icon-arrow-left" (click)="closeSidemenu()"></span>
        }
        @if (!_rootScope.isSidemenuVisible && !_rootScope.openedMenuMobileSize) {
          <span class="the-menu__arrow icon icon-arrow-right" (click)="openSidemenu()"
          ></span>
        }
      </div>
    }
    @if (isSubmenuVisible && submenuLinks.length > 0) {
      <div class="the-menu the-menu__submenu"
        [ngClass]="{'the-menu__submenu--from-small' : !_rootScope.isSidemenuVisible || categoryContainerState == CATEGORY_CONTAINER_STATE.OPENED || (_rootScope.isMobileSize && ! _rootScope.openedMenuMobileSize)}">
        @for (link of submenuLinks; track link) {
          <div class="the-menu__item {{link.class}}"
            [attr.id]="link.id" [ngClass]="{'the-menu__item--selected': checkActive(link),
  'the-menu__item--disabled': !link.isAccessible}" (click)="redirectTo(link.url)">
            <span class="the-menu__icon {{link.icon}}"></span>
            <span class="the-menu__label" [translate]="link.title"></span>
            @if (!link.isAccessible) {
              <span class="the-menu__locked icon icon-state-locked justify-self-end"
              ></span>
            }
          </div>
        }
      </div>
    }
  </div>
}