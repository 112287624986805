import { Component } from '@angular/core';

@Component({
  selector: 'no-search-results',
  templateUrl: './no-search-results.component.html'
})
export class NoSearchResultsComponent {

  constructor() { }

}
