import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { NormalizeUnicodePipe } from '../../pipes/normalize-unicode.pipe';

@Component({
    selector: 'segments-selection-boxes',
    templateUrl: './segments-selection-boxes.html'
})
export class SegmentsSelectionBoxesComponent implements OnChanges {

    @Input() availableSegments: Array<any> = [];
    @Input() addedSegments: Array<any> = [];
    @Input() filterAvailable = '';
    @Input() filterAdded = '';
    @Input() isSyncing: boolean;
    @Input() addedSegmentsIds: Array<number>;
    @Output() addedSegmentsIdsChange: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

    private singleClickTimer: any;
    private preventSingleClick = false;
    public selectedSegments = [];
    public filteredAddedSegments = [];

    constructor(private normalizeUnicodePipe: NormalizeUnicodePipe) { }

    ngOnChanges(changes: any) {
        if (changes.availableSegments && changes.availableSegments.currentValue &&
            changes.availableSegments.currentValue !== changes.availableSegments.previousValue) {
            this.unselectAvailableSegments();
            this.toggleAddedPropertyInAvailableSegments();
        }
        if (changes.addedSegments && changes.addedSegments.currentValue &&
            (changes.addedSegments.currentValue !== changes.addedSegments.previousValue || changes.addedSegments.currentValue.length > 0)) {
            this.searchAddedSegments();
            this.addedSegmentsIds = this.getAddedSegmentsIds();
            this.addedSegmentsIdsChange.emit(this.addedSegmentsIds);
        }
        if (changes.filterAdded && changes.filterAdded.currentValue !== changes.filterAdded.previousValue) {
            this.searchAddedSegments();
        }
    }

    trackByFn(index: number, item: any): number {
        return index;
    }

    private getIndexOfItemInSegments(segments: Array<any>, segmentId: number): number {
        const segmentsLength = segments.length;
        for (let index = 0; index < segmentsLength; index++) {
            if (segments[index]['id'] === segmentId) {
                return index;
            }
        }
        return -1;
    }

    private addAvailableSegment(segment: any): void {
        segment['selected'] = false;

        if (this.addedSegmentsIds.indexOf(segment['id']) === -1) {
            // If segment is not already added, add it
            this.addedSegments.push(segment);
        }

        const selectedSegmentIndex = this.getIndexOfItemInSegments(this.selectedSegments, segment['id']);
        if (selectedSegmentIndex !== -1) {
            // If segment was selected, remove from selectedSegments array
            this.selectedSegments.splice(selectedSegmentIndex, 1);
        }
    }

    private getAddedSegmentsIds(): Array<number> {
        const segmentIds = this.addedSegments.length > 0 ? this.addedSegments.map(function (segment) {
            return segment['id'];
        }) : [];
        return segmentIds;
    }

    private toggleAddedPropertyInAvailableSegments(): void {
        const numAvailableSegments = this.availableSegments.length;
        for (let index = 0; index < numAvailableSegments; index++) {
            this.availableSegments[index]['added'] = this.addedSegmentsIds.indexOf(this.availableSegments[index]['id']) !== -1;
        }
    }

    private searchAddedSegments(): void {
        const self = this;
        this.filteredAddedSegments = this.addedSegments.filter(function (segment) {
            const searchString = self.normalizeUnicodePipe.transform(segment['name'] + ' ' + segment['description']);
            const inputText = self.normalizeUnicodePipe.transform(self.filterAdded);
            return inputText.length > 2 ? searchString.toLowerCase().indexOf(inputText.toLowerCase()) > -1 : true;
        });
    }

    private syncAddedSegments(): void {
        this.searchAddedSegments();
        this.addedSegmentsIds = this.getAddedSegmentsIds();
        this.addedSegmentsIdsChange.emit(this.addedSegmentsIds);
        this.toggleAddedPropertyInAvailableSegments();
    }

    doubleClickedSegment(segment: any): void {
        clearTimeout(this.singleClickTimer);
        this.preventSingleClick = true;
        if (!segment.added) {
            this.addAvailableSegment(segment);
            this.syncAddedSegments();
        }
    }

    addAllFilteredAvailableSegments(): void {
        const numFilteredAvailableSegments = this.availableSegments.length;
        for (let index = 0; index < numFilteredAvailableSegments; index++) {
            this.addAvailableSegment(this.availableSegments[index]);
        }
        this.syncAddedSegments();
    }

    private unselectAvailableSegments(): void {
        const numAvailableSegments = this.availableSegments.length;
        for (let index = 0; index < numAvailableSegments; index++) {
            this.availableSegments[index]['selected'] = false;
        }
    }

    addSelectedSegments(): void {
        if (document.querySelectorAll('.selected').length > 0) {
            Array.prototype.push.apply(this.addedSegments, this.selectedSegments);
            this.selectedSegments = [];
            this.syncAddedSegments();

            this.unselectAvailableSegments();
        }
    }

    private toggleSegmentInSelectedSegments(segment: any, index: number): void {
        if (index === -1) {
            this.selectedSegments.push(segment);
        } else {
            this.selectedSegments.splice(index, 1);
        }
    }

    selectSegmentFromAvailables(segment: any, $event: any): void {
        const self = this;
        this.singleClickTimer = setTimeout(function () {
            if (!self.preventSingleClick) {
                if (!segment.added) {
                    segment.selected = !segment.selected;
                    const segmentIndex = self.getIndexOfItemInSegments(self.selectedSegments, segment['id']);
                    self.toggleSegmentInSelectedSegments(segment, segmentIndex);
                }
            }
            self.preventSingleClick = false;
        }, 200);
    }

    private removeAddedSegment(segment: any): void {
        segment.added = false;

        const segmentIndex = this.getIndexOfItemInSegments(this.addedSegments, segment['id']);
        if (segmentIndex !== -1) {
            this.addedSegments.splice(segmentIndex, 1);
        }
    }

    removeAddedItemFromList(segment: any): void {
        this.removeAddedSegment(segment);
        this.syncAddedSegments();
    }

    removeAllFilteredAddedSegments(): void {
        if (this.filteredAddedSegments.length > 0) {
            const numFilteredAddedSegments = this.filteredAddedSegments.length;
            for (let index = 0; index < numFilteredAddedSegments; index++) {
                this.removeAddedSegment(this.filteredAddedSegments[index]);
            }
        }
        this.syncAddedSegments();
    }
}
