import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UtilsService } from '../../shared/services/utils.service';

@Component({
    selector: 'category-preview-device',
    templateUrl: './category-preview-device.component.html'
})
export class CategoryPreviewDeviceComponent implements OnChanges {

    @Input() category: any;
    @Input() language: string;
    @Input() color: number;
    @Input() image: string;
    @Input() isOpened: boolean;
    @Input() availableIn: number;
    @Output() isOpenedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public darkenColor: string;
    public previewVisible = 1;

    public PREVIEW = {
        INBOX: 1,
        KNOWLEDGE_CENTER: 2
    };

    public AVAILABLE_IN = {
        ALL: 1,
        INBOX: 2,
        KNOWLEDGE_CENTER: 3
    };
    constructor(private utilsService: UtilsService) {}

    onClick() {
        this.isOpenedChange.emit(false);
    }

    setPreviewVisible (number) {
        this.previewVisible = number;
    }

    getImage () {
        const categoryImage = this.image || this.category.imageURL;
        return categoryImage || '/ng1/assets/img/background-test.png';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.color && changes.color.currentValue) {
            this.darkenColor = this.utilsService.decreaseBrightness(this.color, 40);
        }
    }

}
