import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'document-preview-modal',
    templateUrl: 'document-preview-modal.html',
  })
  export class DocumentPreviewModalComponent {
    constructor(
        public dialogRef: MatDialogRef<DocumentPreviewModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}
  
    onClose(): void {
        this.dialogRef.close();
    }
}