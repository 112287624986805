<div class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [translate]="'global.editor.addLink'"></h3>
</div>
<div class="modal-body">
    <div class="group-form group-form--label-150">
        <div class="group-form__label">
            <span class="group-form__label__text margin-right-5" [translate]="'model.message.relatedLinkURL'"></span>
        </div>
        <div class="group-form__data">
            <input id="linkUrl" data-cy="link-url" class="input" [(ngModel)]="createLinkForm.url" maxlength="256" placeholder="URL"
                (keyup)="onChange()" />
        </div>
    </div>

    <div class="group-form group-form--label-150">
        <div class="group-form__label ">
            <span class="group-form__label__text margin-right-5" [translate]="'model.message.relatedlinkTitle'"></span>
        </div>
        <div class="group-form__data">
            <input id="linkText" data-cy="link-text" class="input" [(ngModel)]="createLinkForm.text" maxlength="256"
                [placeholder]="getPlaceholder()" (change)="onChange()" />
        </div>
    </div>
</div>
<div class="modal-footer flex justify-end">
    <loading-button
        [buttonId]="'link-create'"
        [classes]="'button--secondary margin-right-10'" [text]="'global.form.add'" (action)="onAccept()" [isValid]="isFormValid">
    </loading-button>
    <button class="button margin-left-10 margin-right-10" (click)="onCancel()">
        <span class="button__label" [translate]="'global.modal.cancel'"></span>
    </button>
</div>