import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class UsersImportService {

    public apiUrl: string;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
    }

    getImportUsersResume(userImportForm, csvToUpload) {
        var fd = new FormData();
        fd.append('usersMultipart', csvToUpload);

        const httpOptions = {
            params: {
                deactivate: userImportForm.allowUsersArchiving,
            },
            reportProgress: true
        }
        return this.httpClient.post<any>(`${this.apiUrl}/users/load-csv-summary`, fd, httpOptions);
    }

    uploadImportUsersCsvFile(userImportForm, csvToUpload) {
        var fd = new FormData();
        fd.append('usersMultipart', csvToUpload);

        const httpOptions = {
            params: {
                deactivate: userImportForm.allowUsersArchiving,
                sendNotification: userImportForm.sendActivateEmails
            },
            reportProgress: true
        }
        return this.httpClient.post<any>(`${this.apiUrl}/users/load-csv`, fd, httpOptions);
    }
}
