<kendo-multiselect [data]="availableOptions" [(ngModel)]="inputModel" [textField]="textFieldName" [valueField]="valueFieldName" (valueChange)="onChangeSelectedValue()" 
    [clearButton]="showClearBtn" [popupSettings]="{ width: 'auto', popupClass: 'dui-select-multiple ' + popupClass }" [autoClose]="false" [valuePrimitive]="true"
    class="dui-select-multiple" [placeholder]="placeholder" [kendoMultiSelectSummaryTag]="summaryTagFrom || availableOptions.length" [loading]="!availableOptions">
    <ng-template kendoMultiSelectTagTemplate let-dataItem>
        <span [textContent]="dataItem[textFieldName]" [ngClass]="{'no-chip': !dataItem[valueFieldName]}"></span>
    </ng-template>
    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
        <span class="no-chip" [translate]="'global.form.nMore'" [translateParams]="{number: dataItems.length}"></span>
    </ng-template>
    <ng-template kendoMultiSelectItemTemplate let-dataItem>
        <div class="popup-multiselect-options min-height-28">
            <span class="popup-multiselect-options__label truncate" [textContent]="dataItem[textFieldName]"></span>
            <label class="toggle__container popup-multiselect-options__switch margin-left-5 align-items-right">
                <div class="toggle toggle--small" style="width: 12px !important;">
                    <span class="toggle__indicator"></span>
                </div>
            </label>
        </div>
    </ng-template>
</kendo-multiselect>