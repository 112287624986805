<div cdkDropList class="questions-drop-area" (cdkDropListDropped)="drop($event)">
  @for (question of questions | sort:'order'; track trackByFn(index, question); let first = $first; let last = $last; let index = $index) {
    <div
      class="hidden-new-question-container"
      cdkDragBoundary=".questions-drop-area" cdkDrag [cdkDragData]="question"
      (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)">
      <question-list [question]="question" [linkedItems]="linkedItems" [showEndingElement]="showEndingElement"
      [isFirst]="first" [isLast]="last" [index]="index" [isTray]="isTray" [hasCover]="hasCover" [questionnaireKind]="questionnaireKind"></question-list>
    </div>
  }
</div>
