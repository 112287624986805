import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UsersSegmentsService } from '../../services/users-segments.service'
import { isEqual } from 'lodash';
import { Constants } from '../../../constants';

@Component({
    selector: 'segments-management-modal',
    templateUrl: './segments-management-modal.component.html',
    styleUrls: ['_segments-management-modal.component.scss']
})
export class SegmentsManagementModalComponent implements OnInit {

    public isSyncing: boolean = false;
    public filter: any = {
        availableSegments: '',
        addedSegments: ''
    };
    public availableSegments: Array<any> = [];
    public addedSegmentsIds: Array<any> = [];
    public arePendingChanges: boolean = false;
    private initialAddedSegmentsIds: Array<any> = [];
    private initialSegmentationKind: number;
    public addedSegments: Array<any> = [...this.modalParams.segments];
    public globalSearchOpened: boolean = false;
    public groupSearchOpened: boolean = false;
    public SEGMENTATION_KIND: any;
    public unionCount: number = null;
    public intersectionCount: number = null;
        
    @ViewChild('globalSearch') globalSearchRef: ElementRef;
    @ViewChild('groupSearch') groupSearchRef: ElementRef;

    constructor( 
        public dialogRef: MatDialogRef<SegmentsManagementModalComponent>, private SegmentsService: UsersSegmentsService, 
        @Inject(MAT_DIALOG_DATA) public modalParams: any)  
    { 
        this.SEGMENTATION_KIND = Constants.SEGMENTATION_KIND;
    }

    ngOnInit() {
        this.loadAvailableSegments();
        this.initialAddedSegmentsIds = this.getAddedSegmentsIds();
        this.addedSegmentsIds = this.getAddedSegmentsIds();

        if (this.modalParams.showSegmentationSelection) {
            this.initialSegmentationKind = this.modalParams.segmentationKind;

            if (this.modalParams.segmentationKind === null) {
                this.modalParams.segmentationKind = this.SEGMENTATION_KIND.UNION;
            } 

        }
    }

    onAddedSegmentsIdsChange(): void {
        if (this.modalParams.showSegmentationSelection) {
            this.SegmentsService.getUserCountBySegmentationKind(this.modalParams.population, this.addedSegmentsIds).subscribe(
                (response: any) => {
                    this.unionCount = response.unionCount;
                    this.intersectionCount = response.intersectionCount;
                },
                (error: any) => {

                }
            )
        } 
    }

    loadAvailableSegments() {
        let options = {
            active: true,
            page: 0,
            size: 20000,
            sort: 'name'
        };
        if (!!this.filter.availableSegments) { options['name'] = this.filter.availableSegments };
        this.isSyncing = true;
        this.SegmentsService.findAll(options).subscribe(
            (response) => {
                this.isSyncing = false;
                this.availableSegments = response.content;
            },
            (error) => {
                this.isSyncing = false;
            }
        )
    }

    searchAvailableSegments(clearInput: boolean) {
        if(clearInput) { this.filter.availableSegments = ''; this.globalSearchRef.nativeElement.focus();}
       
        if (this.filter.availableSegments.length >= 3 || this.filter.availableSegments.length === 0) {
            this.loadAvailableSegments();
        }
    }

    getAddedSegmentsIds(): Array<any> {
        return this.addedSegments.map(function (segment) {
            return segment.id;
        });
    }

    globalSearchOpen(): void {
        this.globalSearchOpened = !this.globalSearchOpened;
        setTimeout(() => {
            this.globalSearchRef.nativeElement.focus();
        }, 500);
    }

    groupSearchOpen(): void {
        this.groupSearchOpened = !this.groupSearchOpened;
        setTimeout(() => {
            this.groupSearchRef.nativeElement.focus();
        }, 100);
    }

    clearGroupSearch(): void {
        this.filter.addedSegments = '';
        this.groupSearchRef.nativeElement.focus();
    }

    onAccept() {
        this.arePendingChanges = !isEqual(this.initialAddedSegmentsIds, this.addedSegmentsIds);
        let dataReturnedOnAccept: any = {
            arePendingChanges: this.arePendingChanges,
            addedSegments: this.arePendingChanges ? this.addedSegments :  null,
            addedSegmentsIds: this.arePendingChanges ? this.addedSegmentsIds : null
        };
        if (this.modalParams.showSegmentationSelection) {
            dataReturnedOnAccept.segmentationKind = this.modalParams.segmentationKind;
            dataReturnedOnAccept.segmentationKindChanged = (this.initialSegmentationKind !== this.modalParams.segmentationKind);
        } else {
            dataReturnedOnAccept.segmentationKindChanged = false;
        }
        this.dialogRef.close(dataReturnedOnAccept);
    }
   
    onCancel() {
        this.dialogRef.close({ cancelled: true });
    }
    
}