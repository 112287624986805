import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { createClient } from 'pexels';
import { from, Observable, Subscriber } from 'rxjs';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class ImageBankService {

    public apiUrl: string;
    private gifApiUrl: string = 'https://api.giphy.com/v1/gifs/search?';
    private gifApiKey: string = 'mKCBCe9M9TONAqw2hIhtNhK7IxO1RK9W';
    public pexelsApiKey: string = '563492ad6f91700001000001cf2ef3cd77584458becae9310cfad4a1';
    public PAGE_SIZE= 20;
    private OFFSET = 20;

    constructor( private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
    }

    getImagesFromQuery(text: string, pageStart: number) {

        const client = createClient(this.pexelsApiKey);

        const promise = client.photos.search({ query: text, page: pageStart, per_page: this.PAGE_SIZE });
        return from(promise);

    }

    getGifsFromQuery(text: string, pageStart: number) {
        // const httpOptions = {
        //     withCredentials: true,
        //     params: {
        //         api_key: this.gifApiKey,
        //         q: text,
        //         limit: this.PAGE_SIZE,
        //         offset: pageStart === 1 ? 0 : pageStart * this.OFFSET,
        //         rating: 'g'
        //     }
        // }
        // return this.httpClient.get<any>(`${this.gifApiUrl}`, httpOptions);

        return new Observable((observer: Subscriber<any>): void => {
            const params = {
                api_key: this.gifApiKey,
                q: text,
                limit: this.PAGE_SIZE.toString(),
                offset: pageStart === 1 ? '0' : (pageStart * this.OFFSET).toString(),
                rating: 'g'
            }
            fetch(this.gifApiUrl + new URLSearchParams(params)) 
                .then(
                    (response: any) => { return response.json(); }
                )
                .then(
                    (response: any) => {
                        observer.next({
                            data: response.data,
                            pagination: response.pagination
                        });
                        observer.complete();
                    }
                )
        })
    }

    uploadImageFile(file: any, name: string) {
        const formData = new FormData();
        formData.append('files', file, name);
        
        return this.httpClient.post<any>(`${this.apiUrl}/resources`, formData);
    }
}