import { Component, OnInit } from '@angular/core';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../constants';
import { TimeTrackingService } from '../time-tracking/time-tracking.service';

@Component({
    selector: "time-tracking-global",
    templateUrl: "time-tracking-global.component.html",
    styleUrls: ["./_time-tracking-global.component.scss"],
})
export class TimeTrackingGlobalComponent implements OnInit {

    public editablePeriodOptions: { value: number; name: string }[];
    public timeTrackingGlobalForm: timeTrackingGlobalForm;

    public hasExtraMargin: boolean;
    public daysMarginToEditSlider: number;
    public daysMarginToEditInput: number;

    public SHIFT_EDITION_PERIOD: any;

    private EDIT_MARGIN_DAYS_DEFAULT: number = 2;
    private EDIT_MARGIN_DAYS_CUSTOM_DEFAULT: number = 60;

    constructor(private timeTrackingService: TimeTrackingService, private notificationService: DuiNotificationsService, private translate: TranslateService) {
        this.SHIFT_EDITION_PERIOD = Constants.SHIFT_EDITION_PERIOD;

        this.timeTrackingGlobalForm = {
            shiftEditionPeriod: null,
            daysMarginToEdit: null
        };

        this.hasExtraMargin = false;

        this.editablePeriodOptions = [
            {
                value: this.SHIFT_EDITION_PERIOD.DISABLED,
                name: this.translate.instant("global.form.notAllow"),
            },
            {
                value: this.SHIFT_EDITION_PERIOD.ONGOING_WORKDAY,
                name: this.translate.instant("timeTracking.global.editablePeriods.ongoingWorkday"),
            },
            {
                value: this.SHIFT_EDITION_PERIOD.LAST_7_DAYS,
                name: this.translate.instant("timeTracking.global.editablePeriods.lastSevenDays"),
            },
            {
                value: this.SHIFT_EDITION_PERIOD.ONGOING_MONTH,
                name: this.translate.instant("timeTracking.global.editablePeriods.ongoingMonth"),
            },
            {
                value: this.SHIFT_EDITION_PERIOD.CUSTOMIZED,
                name: this.translate.instant("global.form.custom")
            },
        ];

    }

    ngOnInit(): void {
        this.getDayRecordGlobalConfig();
    }
    
    /** GET */

    private getDayRecordGlobalConfig(): void {
        this.timeTrackingService.getGlobalConfig().subscribe(
            (response): void => {
                this.timeTrackingGlobalForm = response;
                this.hasExtraMargin = this.timeTrackingGlobalForm.shiftEditionPeriod !== this.SHIFT_EDITION_PERIOD.ONGOING_WORKDAY && 
                                      this.timeTrackingGlobalForm.shiftEditionPeriod !== this.SHIFT_EDITION_PERIOD.DISABLED;
                this.daysMarginToEditSlider = this.timeTrackingGlobalForm.daysMarginToEdit;
                this.daysMarginToEditInput = this.timeTrackingGlobalForm.daysMarginToEdit;
            },
            (error: any): void => {
                console.error(error);
            }
        );
    }
    
    /** PUT */

    private setShiftEditionPeriod(): void {
        this.timeTrackingService.setShiftEditionPeriod(this.timeTrackingGlobalForm.shiftEditionPeriod, this.timeTrackingGlobalForm.daysMarginToEdit).subscribe(
            (response): void => {
                this.notificationService.showSuccessNotification();
            },
            (error): void => {
                this.notificationService.showErrorNotification();
            }
        );
    }

    /** EVENTS */

    onChangeEditablePeriod(): void {
        this.hasExtraMargin = this.timeTrackingGlobalForm.shiftEditionPeriod !== this.SHIFT_EDITION_PERIOD.ONGOING_WORKDAY && 
                              this.timeTrackingGlobalForm.shiftEditionPeriod !== this.SHIFT_EDITION_PERIOD.DISABLED;

        switch (this.timeTrackingGlobalForm.shiftEditionPeriod) {
            case this.SHIFT_EDITION_PERIOD.LAST_7_DAYS:
            case this.SHIFT_EDITION_PERIOD.ONGOING_MONTH:
                this.timeTrackingGlobalForm.daysMarginToEdit = this.EDIT_MARGIN_DAYS_DEFAULT;
                this.daysMarginToEditSlider = this.EDIT_MARGIN_DAYS_DEFAULT;
                break;
            case this.SHIFT_EDITION_PERIOD.CUSTOMIZED:
                this.timeTrackingGlobalForm.daysMarginToEdit = this.EDIT_MARGIN_DAYS_CUSTOM_DEFAULT;
                this.daysMarginToEditInput = this.EDIT_MARGIN_DAYS_CUSTOM_DEFAULT;
                break;
            default:
                this.timeTrackingGlobalForm.daysMarginToEdit = null;
                break;
        }

        this.setShiftEditionPeriod();
    }

    onChangeEditMarginSlider(slider: any): void {
        if (slider.value !== this.timeTrackingGlobalForm.daysMarginToEdit) {
            this.timeTrackingGlobalForm.daysMarginToEdit = slider.value;
            this.setShiftEditionPeriod();
        }
    }

    onChangeEditMarginInput(field: any): void {
        // Parse value of input:  if field value is not between 1 and 90, field value is replaced
        field.value = field.value <= 0 ? 1 : (field.value > 90 ? 90 : field.value);

        if (field.value != this.timeTrackingGlobalForm.daysMarginToEdit) {
            this.timeTrackingGlobalForm.daysMarginToEdit = field.value;
            this.setShiftEditionPeriod();
        }
    }
}

type timeTrackingGlobalForm = {
    shiftEditionPeriod: number,
    daysMarginToEdit: number | null
};
