<div class="request-detail" [ngClass]="{'justify-start': selectedTab === REQUEST_TABS.FILES || !(selectedTab !== REQUEST_TABS.FILES && !request?.closedOn) || (selectedTab === REQUEST_TABS.NOTES && !!request?.closedOn)}">
    <section class="request-detail__header" *ngIf="!!request">
        <div class="first-row">
            <ng-container *ngIf="request?.anonymous; then userAnonymousHeader else userHeader"></ng-container>
            <ng-template #userAnonymousHeader>
                <user-avatar [medium]="true" [user]="{name: 'global.userstates.anonymous' | translate, surname: '' }" [anonymous]="true"></user-avatar>
            </ng-template>
            <ng-template #userHeader>
                <div class="flex align-items-center" kendoTooltip filter="a.request-detail__header__sender-name">
                    <avatar-popover class="margin-right-10 cursor-default" [nationalId]="request?.author?.nationalIdNumber"
                        [username]="request?.author?.username" [internalId]="request?.author?.companyInternalId">
                        <avatar [name]="request?.author?.name" [surname]="request?.author?.surname" [medium]="true"
                            [thumbnails]="{thumbnail_M: request?.author?.avatarThumbnailMUrl || request?.author?.avatarUrl, thumbnail_S: request?.author?.avatarThumbnailSUrl || request?.author?.avatarThumbnailMUrl}">
                        </avatar>
                    </avatar-popover>
                    <a class="request-detail__header__sender-name text-decoration-none cursor-pointer non-selectable" [title]="'users.linkToUser' | translate"
                        [textContent]="request?.author?.fullName" [href]="'/user/personaldata/update/' + request?.author?.user"></a>
                </div>
            </ng-template>
            <!-- Actions -->
            <div class="flex align-items-center" kendoTooltip filter="span">
                <!-- Validate request -->
                <ng-container *ngIf="isAdmin && request && !!request?.validationState && !!request?.closedOn && request?.validationState === REQUEST_VALIDATION_STATE.PENDING">
                    <span id="validate-request" class="icon icon-ok-fill warning-light-intermediate-color icon-28 margin-right-16 cursor-pointer" (click)="validationRequest()"
                    [title]="'conversations.pendingValidation' | translate"></span>
                </ng-container>
                <!-- Discard request validation -->
                <ng-container *ngIf="isAdmin && request && !!request?.validationState && request?.validationState !== REQUEST_VALIDATION_STATE.PENDING">
                    <dui-popover [onlyOnClick]="true" class="popover-discard-validation align-self-center height-28">
                        <ng-container popoverItem>
                            <span id="dismiss-request-validation" class="icon icon-28 margin-right-16 cursor-pointer"
                                [ngClass]="{ 'icon-ok-fill green-icon-color': request?.validationState === REQUEST_VALIDATION_STATE.VALIDATED, 
                                             'icon-remove-fill advice-color': request?.validationState === REQUEST_VALIDATION_STATE.REJECTED }"
                                [title]="(request?.validationState === REQUEST_VALIDATION_STATE.VALIDATED ? 'conversations.validatedConversation' : 'conversations.rejectedConversation') | translate">
                            </span>
                        </ng-container>
                            <div class="popover-discard-validation__container">
                                <button id="discard-validation" class="button button--small" (click)="discardValidation()">
                                    <span class="button__label" [translate]="'conversations.preview.tooltips.discardValidation'"></span>
                                </button>
                            </div>
                    </dui-popover>
                </ng-container>
                <!-- Change Request tray or motive. Request metadata -->
                <dui-popover [onlyOnClick]="true" class="popover-metadata align-self-center">
                    <ng-container popoverItem>
                        <span #requestMetadata id="request-metadata" class="icon icon-info icon-28 secondary-color icon-clickable"
                            [title]="'conversations.preview.tooltips.details' | translate">
                        </span>
                    </ng-container>
                    <div class="popover-metadata__container">
                        <!-- Tray -->
                        <div class="popover-metadata__item">
                            <span class="popover-metadata__label" [translate]="'conversations.tray'"></span>
                            <kendo-dropdownlist #trayRequest [data]="traysItems" [(ngModel)]="requestTray" [disabled]="request?.anonymous"
                                class="kendo-angular-dropdown margin-left-8 width-70-percent" [valuePrimitive]="false" (valueChange)="onTrayChanged()"
                                [textField]="'alias' || 'name'" [valueField]="'id'" [popupSettings]="{ width: 'auto'}">
                                
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                    <span class="truncate" [textContent]="dataItem?.alias || dataItem?.name"></span>
                                </ng-template>
                                <ng-template kendoDropDownListItemTemplate let-dataItem >
                                    <div class="flex align-items-center min-height-28">
                                        <span class="truncate padding-3" [textContent]="dataItem?.alias || dataItem?.name"></span>
                                    </div>
                                </ng-template>
                            </kendo-dropdownlist>
                        </div>
                        <!-- Motive -->
                        <div class="popover-metadata__item">
                            <span class="popover-metadata__label" [translate]="'conversations.motiveLabel'"></span>
                            <kendo-dropdownlist #motiveRequest [data]="motivesItems" [(ngModel)]="requestMotive" [disabled]="request?.anonymous"
                                class="kendo-angular-dropdown margin-left-8 width-70-percent" [valuePrimitive]="false" (valueChange)="onMotiveChanged()"
                                [textField]="'motive'" [valueField]="'id'" [popupSettings]="{ width: 'auto'}">
                                
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                    <span *ngIf="dataItem?.id" class="icon icon-20 icon-tag-fill-s margin-right-10" [ngStyle]="{'color': getMotiveTagHexColor(dataItem.color)}"></span>
                                    <span class="truncate" [textContent]="dataItem?.motive"></span>
                                </ng-template>
                                <ng-template kendoDropDownListItemTemplate let-dataItem >
                                    <div class="flex align-items-center min-height-28 width-300">
                                        <span *ngIf="dataItem?.id" class="icon icon-20 icon-tag-fill-s margin-right-10" [ngStyle]="{'color': getMotiveTagHexColor(dataItem.color)}"></span>
                                        <span class="truncate" [textContent]="dataItem?.motive"></span>
                                    </div>
                                </ng-template>
                            </kendo-dropdownlist>
                        </div>
                        <div class="popover-metadata__data">
                            <p class="black-30 font-size-12 margin-top-15" [translate]="'conversations.openOn_Angular'" 
                                [translateParams]="{date: requestConversation.openOn | date: 'dd/MM/yyyy HH:mm'}"></p>
                            <p class="black-30 font-size-12 truncate" *ngIf="requestConversation.lastResponsibleEditionDate && !requestConversation.closedOn"
                                [translate]="'conversations.modifiedOn_Angular'"
                                [translateParams]="{date: requestConversation.lastResponsibleEditionDate | date: 'dd/MM/yyyy HH:mm', editor: requestConversation.lastResponsibleEditor}">
                            </p>
                            <p class="black-30 font-size-12 truncate" *ngIf="requestConversation.closedOn" [translate]="'conversations.closedOnBy_Angular'"
                                [translateParams]="{date: requestConversation.closedOn | date: 'dd/MM/yyyy HH:mm', editor: requestConversation.lastResponsibleEditor}"></p>
                        </div>
                    </div>
                </dui-popover>
                <!-- Close request -->
                <ng-container *ngIf="request && !request?.closedOn">
                    <button id="close-request" class="button button--small margin-left-10" (click)="request?.id && closeRequest()">
                        <span class="button__icon icon icon-archive"></span>
                        <span class="button__label" [translate]="'global.modal.close'"></span>
                    </button>
                </ng-container>
                <span id="reopen-request" *ngIf="request && request?.closedOn" class="icon icon-unarchive icon-24 secondary-color margin-left-16 icon-clickable"
                    (click)="request?.id && reopenRequest()" [title]="'conversations.modal.reopen.title' | translate"></span>
            </div>
        </div>
        <div class="last-row">
            <div class="tab-nav tab-nav--no-border">
                <span id="detail-tab" class="tab-nav__item font-size-13 black-60" [ngClass]="{'tab-nav__item--selected': selectedTab === REQUEST_TABS.DETAIL}"
                    (click)="loadTabContent(REQUEST_TABS.DETAIL)">
                    <p class="tab-nav__label margin-bottom-0" [translate]="'timeoffRequests.conversation'"></p>
                </span>
                <span id="files-tab" class="tab-nav__item font-size-13 black-60" [ngClass]="{'tab-nav__item--selected': selectedTab === REQUEST_TABS.FILES}"
                    (click)="loadTabContent(REQUEST_TABS.FILES)">
                    <p class="tab-nav__label margin-bottom-0" [translate]="'conversations.files'"></p>
                    <p *ngIf="requestConversation.attachmentsCount" class="tab-nav__dot-number margin-left-8" [textContent]="requestConversation.attachmentsCount"></p>
                </span>
                <span id="notes-tab" class="tab-nav__item font-size-13 black-60" [ngClass]="{'tab-nav__item--selected': selectedTab === REQUEST_TABS.NOTES}"
                    (click)="loadTabContent(REQUEST_TABS.NOTES)">
                    <p class="tab-nav__label margin-bottom-0" [translate]="'conversations.notes'"></p>
                    <p *ngIf="requestConversation.notesCount" class="tab-nav__dot-number margin-left-8" [textContent]="requestConversation.notesCount"></p>
                </span>
            </div>
        </div>
    </section>

    <section class="request-detail__body" *ngIf="!!request">
        <!-- TAB DETAIL: Show request conversation messages-->
        <div #conversationContainer id="conversation-container" *ngIf="selectedTab === REQUEST_TABS.DETAIL" class="request-conversation scroll7">
            <ng-container *ngFor="let message of requestConversation.messages let idx = index; let isLast = last">

                <!-- Show grouped questionnaire -->
                <ng-container *ngIf="message.automaticConversation">
                    <article class="auto-conversation__item">
                        <section class="auto-conversation__sender-info" *ngIf="(idx == 0 || (idx > 0 && !requestConversation.messages[idx - 1].automaticConversation))" kendoTooltip filter="time">
                            <h6 class="auto-conversation__author-name" *ngIf="!!requestConversation.author" [textContent]="requestConversation.author.fullName"></h6>
                            <h6 class="auto-conversation__author-name" *ngIf="!requestConversation.author && requestConversation.anonymous" [translate]="'global.userstates.anonymous'"></h6>
                            <time class="auto-conversation__timestamp" [textContent]="utilsService.getTimeElapsedFrom(message.sentOn)" [title]="message.sentOn | date: 'dd/MM/yyyy HH:mm'"></time>
                        </section>

                        <section class="auto-conversation__avatar margin-top-2" *ngIf="(idx == 0 || (idx > 0 && !requestConversation.messages[idx - 1].automaticConversation))">
                            <avatar [name]="requestConversation.author.name" [surname]="requestConversation.author.surname" [small]="true" [thumbnails]="{thumbnail_M: requestConversation.author.avatarThumbnailMUrl || requestConversation.author.avatarUrl, thumbnail_S: requestConversation.author.avatarThumbnailSUrl || requestConversation.author.avatarThumbnailMUrl}" *ngIf="!!requestConversation.author"></avatar>  
                            <avatar [user]="{name: '', surname: '' }" [small]="true" [anonymous]="true" *ngIf="!requestConversation.author && requestConversation.anonymous"></avatar>
                        </section>

                        <section class="auto-conversation__content" [ngClass]="getDialogQuestionnaireItemPositionClass(idx)">
                            <h3 class="auto-conversation__title margin-bottom-32 margin-top-1" [translate]="'conversations.automaticConversation'" *ngIf="(idx == 0 || (idx > 0 && !requestConversation.messages[idx - 1].automaticConversation))"></h3>

                            <ng-container *ngIf="message.message">
                                <p class="auto-conversation__question margin-top-24" *ngIf="!message.author && !message.anonymousSender" [innerHTML]="message.message"></p>
                                <p class="auto-conversation__answer margin-top-8" *ngIf="!!message.author || message.anonymousSender" [innerHTML]="message.message"></p>
                            </ng-container>

                            <p class="auto-conversation__answer auto-conversation__answer--skip-step margin-top-8" *ngIf="!message.message && !message.resource">
                                <span [translate]="'conversations.senderSkipStep'" [translateParams]="{sender: message.author?.fullName || ''}" *ngIf="!!requestConversation.author && !requestConversation.anonymous"></span>
                                <span *ngIf="!requestConversation.author && requestConversation.anonymous" [translate]="'conversations.anonymousSkipStep'"></span>
                            </p>

                            <div class="cursor-pointer margin-top-8" [ngClass]="{'message-resource__image': isImage(message.resource.mimetype), 'message-resource__file': !isImage(message.resource.mimetype)}" (click)="downloadResource(message.resource.url, message.resource.nameWithExtension)" *ngIf="message.resource">
                                <ng-container *ngIf="isImage(message.resource.mimetype); else resourceFile">
                                    <img [src]="message.resource.url" alt="" onerror="this.src='/ng1/assets/img/mime_image.svg';this.style.border='none'">
                                </ng-container>
                                <ng-template #resourceFile>
                                    <img [src]="utilsService.getIconFromMimeType(message.resource.mimetype)" class="message-resource__file-icon"/>
                                    <div class="message-resource__file-name">
                                        <span [textContent]="message.resource.nameWithExtension"></span>
                                        <span [textContent]="getResourceMetadata(message.resource)"></span>
                                    </div>
                                </ng-template>
                            </div>
                        </section>
                    </article>
                </ng-container>

                <!-- Show messages -->
                <ng-container *ngIf="!message.automaticConversation">
                    <article class="dialog-conversation__item" [ngClass]="{'dialog-conversation__item--mine': !!message.mine}">
                        
                        <section class="dialog-conversation__sender-info" *ngIf="showMessageSentOnInfo(message, idx)" kendoTooltip filter="time">
                            <ng-container *ngIf="!message.mine">
                                <h6 class="dialog-conversation__author-name" *ngIf="!!message.author" [textContent]="message.author.fullName"></h6>
                                <h6 class="dialog-conversation__author-name" *ngIf="!message.author && message.anonymousSender" [translate]="'global.userstates.anonymous'"></h6>
                            </ng-container>                            
                            <time class="dialog-conversation__timestamp" [textContent]="utilsService.getTimeElapsedFrom(message.sentOn)" [title]="message.sentOn | date: 'dd/MM/yyyy HH:mm'"></time>
                        </section>

                        <section class="dialog-conversation__avatar" *ngIf="showMessageSentOnInfo(message, idx) && !message.mine">
                            <avatar [name]="message.author.name" [surname]="message.author.surname" [small]="true" [thumbnails]="{thumbnail_M: message.author.avatarThumbnailMUrl || message.author.avatarUrl, thumbnail_S: message.author.avatarThumbnailSUrl || message.author.avatarThumbnailMUrl}" *ngIf="!!message.author"></avatar>  
                            <avatar [user]="{name: '', surname: '' }" [small]="true" [anonymous]="true" *ngIf="!message.author && message.anonymousSender"></avatar>
                        </section>
                        <section class="dialog-conversation__content" [ngClass]="getDialogItemPositionClass(message, idx)">
                            <p id="message-{{idx}}" class="dialog-conversation__message" *ngIf="!!message.message" [innerHTML]="message.message"></p>
                            <ng-container *ngIf="message.linkedPublications && message.linkedPublications.length > 0">
                                <article class="message-links__container">
                                    <div class="message-links__item" *ngFor="let link of message.linkedPublications" [ngClass]="{ 'expired': link.state === MESSAGE_STATES.EXPIRED }">
                                        <ng-container *ngIf="link.state !== MESSAGE_STATES.EXPIRED; then linkActivePreview else linkExpiredPreview"></ng-container>

                                        <ng-template #linkActivePreview>
                                            <ng-container *ngIf="link.imageURL">
                                                <figure class="message-links__img" [ngClass]="{'message-links__img--gallery': link.gallerySize !== 0, 'message-links__img--survey': link.kind === CONTENT_KIND.SURVEY }" [ngStyle]="{'background-image': 'url(' + link.imageURL + ')'}">
                                                    <span class="icon icon-heisenberg anonymous-badge" *ngIf="link.kind === CONTENT_KIND.SURVEY && link.anonymousSurvey"></span>
                                                    <figcaption class="icon-gallery-number" [textContent]="'+' + (link.gallerySize - 1)" *ngIf="link.gallerySize !== 0"></figcaption>
                                                    <figcaption class="icon-gallery-number icon icon-questionnaire icon-24" *ngIf="link.kind === CONTENT_KIND.SURVEY"></figcaption>
                                                </figure>
                                            </ng-container>
                        
                                            <ng-container *ngIf="!link.imageURL">
                                                <figure class="message-links__img message-links__img--video" [ngStyle]="{'background-image': 'url(' + getVideoImgPreview(link.youtubeURL) + ')'}"  *ngIf="!!link.youtubeURL">
                                                    <img src="/ng1/assets/img/youtube-icon-red.svg" alt="Youtube Icon">
                                                </figure>
                                                <div *ngIf="!link.youtubeURL" class="message-links__img message-links__img--category"
                                                    [ngStyle]="{'background-image': 'url(' + (link.category.imageURL || '/ng1/assets/img/background-test.png') + ')'}">
                                                    <span class="icon icon-heisenberg anonymous-badge" *ngIf="link.kind === CONTENT_KIND.SURVEY && link.anonymousSurvey"></span>
                                                    <span class="overlay-color" [ngStyle]="{'background-color': utilsService.getCategoryColor(link.category)}"></span>
                                                    <figcaption class="icon-gallery-number icon icon-questionnaire icon-24" *ngIf="link.kind === CONTENT_KIND.SURVEY"></figcaption>
                                                </div>
                                            </ng-container>
                                            
                                            <div class="message-links__data">
                                                <span class="message-links__title" [textContent]="link.title"></span>
                                                <span class="message-links__category truncate" [textContent]="link.category.name" 
                                                    [ngStyle]="{'background-color': utilsService.getCategoryColor(link.category)}"></span>
                                            </div>
                                        </ng-template>
                                        <ng-template #linkExpiredPreview>
                                             <div class="message-links__not-available-img">
                                                <img src="/ng1/assets/img/dialenguin-no-matches.svg">
                                                <p [translate]="'publication.notAvailable'"></p>
                                             </div>
                                            <div class="message-links__data">
                                                <span class="message-links__title" [textContent]="link.title"></span>
                                                <span class="message-links__category truncate" [textContent]="link.category.name"></span>
                                            </div>
                                        </ng-template>
                                        
                                    </div>
                                </article>
                            </ng-container>
                            <div class="cursor-pointer" [ngClass]="{'message-resource__image message-resource__image--right': isImage(message.resource.mimetype), 'message-resource__file': !isImage(message.resource.mimetype)}" (click)="downloadResource(message.resource.url, message.resource.nameWithExtension)" *ngIf="message.resource">
                                <ng-container *ngIf="isImage(message.resource.mimetype); else resourceFile">
                                    <img [src]="message.resource.url" alt="" onerror="this.src='/ng1/assets/img/mime_image.svg';this.style.border='none'" (load)="scrollMessagesToBottom()">
                                </ng-container>
                                <ng-template #resourceFile>
                                    <img [src]="utilsService.getIconFromMimeType(message.resource.mimetype)" class="message-resource__file-icon"/>
                                    <div class="message-resource__file-name">
                                        <span [textContent]="message.resource.nameWithExtension"></span>
                                        <span [textContent]="getResourceMetadata(message.resource)"></span>
                                    </div>
                                </ng-template>
                            </div>                            
                        </section>
                    </article>
                </ng-container>

            </ng-container>

            <!-- Request closed info -->
            <section class="dialog-closed margin-block-32" *ngIf="request?.closedOn">
                <h5 class="dialog-closed__metadata">
                    <span [textContent]="requestConversation.resolver?.fullName" *ngIf="requestConversation.resolver"></span>
                    <span [ngClass]="{'margin-left-8': requestConversation.resolver}" [textContent]="utilsService.getTimeElapsedFrom(requestConversation.closedOn)"></span>
                </h5>
                <div class="dialog-closed__wrapper margin-top-6">
                    <article class="dialog-closed__state">
                        <span class="icon-16 icon icon-state-locked"></span>
                        <p class="dialog-closed__label" [translate]="'conversations.closedConversation'"></p>
                    </article>
                </div>
            </section>
            
            <!-- Request rated info -->
            <ng-container *ngIf="request.rating">
                <article class="dialog-conversation__item">
                    <section class="dialog-conversation__sender-info" kendoTooltip filter="time">
                        <h6 class="dialog-conversation__author-name">
                            <span *ngIf="!!request.author" [textContent]="request.author.fullName"></span>
                            <span *ngIf="!request.author && request.anonymous" [translate]="'global.userstates.anonymous'"></span>
                        </h6>
                        <time class="dialog-conversation__timestamp" [textContent]="utilsService.getTimeElapsedFrom(request.ratedOn)" [title]="request.ratedOn | date: 'dd/MM/yyyy HH:mm'"></time>
                    </section>
                    <section class="dialog-conversation__avatar">
                        <avatar [name]="request.author.name" [surname]="request.author.surname" [small]="true" [thumbnails]="{thumbnail_M: request.author.avatarThumbnailMUrl || request.author.avatarUrl, thumbnail_S: request.author.avatarThumbnailSUrl || request.author.avatarThumbnailMUrl}" *ngIf="!!request.author"></avatar>  
                        <avatar [user]="{name: '', surname: '' }" [small]="true" [anonymous]="true" *ngIf="!request.author && request.anonymous"></avatar>
                    </section>
                    <section class="dialog-conversation__content dialog-conversation__content--first">
                        <p class="dialog-conversation__message" [translate]="'conversations.ratedMessage'"></p>
                    </section>                    
                </article>                
                <article class="dialog-conversation__item">
                    <div class="dialog-conversation__content dialog-conversation__content--last" style="width: fit-content;">
                        <show-rating-icon [value]="request.rating" [showTitle]="false"> </show-rating-icon>
                    </div>
                </article>
            </ng-container>
        </div>

        <!-- TAB RESOURCES: Show resources files -->
        <div class="detail-files-list scroll scroll7 margin-0" *ngIf="selectedTab === REQUEST_TABS.FILES">
            <!-- Skeleton -->
            <ng-container *ngIf="isLoadingData">
                <div class="detail-files-list__item" [ngClass]="{'opacity-50': idx == 1, 'opacity-30': idx ===2}"
                    *ngFor="let item of dataSkeleton let idx = index">
                    <div class="detail-files-list__type-container">
                        <kendo-skeleton shape="circle" animation="pulse" [width]="50" [height]="50"></kendo-skeleton>
                    </div>
                    <div class="detail-files-list__texts-container">
                        <kendo-skeleton shape="text" animation="pulse" [width]="200" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                        <kendo-skeleton shape="text" animation="pulse" [width]="100" [height]="15" style="border-radius: 4px"></kendo-skeleton>
                    </div>
                </div>
            </ng-container>
            <!-- Resources -->
            <ng-container *ngIf="!isLoadingData">
                <div class="detail-files-list__item"
                    *ngFor="let resource of requestResources | sort: 'createdDate'; let idx = index">
                    <div class="detail-files-list__type-container">
                        <span *ngIf="!isImage(resource.mimetype)" class="detail-files-list__icon-doc icon icon-doc"></span>
                        <img *ngIf="isImage(resource.mimetype)" [src]="resource.url" class="detail-files-list__thumbnail" alt="" />
                    </div>
                    <div class="detail-files-list__texts-container">
                        <p class="detail-files-list__name" [textContent]="resource.name + resource.extension"></p>
                        <p class="detail-files-list__date" [textContent]="resource.createdDate | date:'dd/MM/yyyy HH:mm'"></p>
                    </div>
                    <span class="detail-files-list__icon-download icon icon-download" (click)="downloadResource(resource.url, resource.nameWithExtension)"></span>
                </div>
                <div *ngIf="requestResources.length == 0 && !isLoadingData">
                    <no-results [imageTitle]="'conversations.noResultsAttachementsTitle'"></no-results>
                </div> 
            </ng-container>
        </div>

        <!-- TAB NOTES: Show request notes -->
        <div #notesContainer id="notes-container" class="request-notes__list scroll scroll7" *ngIf="selectedTab === REQUEST_TABS.NOTES">
            <div *ngIf="(!requestNotes || requestNotes.length == 0) && !isLoadingData">
                <no-results [imageTitle]="'conversations.noResultsNotesTitle'"></no-results>
            </div>
            <div class="request-notes__item" *ngFor="let note of requestNotes"
                [ngClass]="{ 'bg-black-5': note.kind === CONVERSATION_NOTES.CLOSING || note.kind === CONVERSATION_NOTES.REOPENING || note.kind === CONVERSATION_NOTES.DISCARDED_VALIDATION }">
                <div class="request-notes__item__header">
                    <div class="flex" kendoTooltip filter="div.avatar">
                        <avatar [name]="note.sender?.name" [surname]="note.sender?.surname" [small]="true" 
                            [title]="note.sender?.name + ' ' + note.sender?.surname" class="margin-right-10"
                            [thumbnails]="{thumbnail_M: note.sender?.avatarThumbnailMUrl || note.sender?.avatarUrl, thumbnail_S:  note.sender?.avatarThumbnailMUrl || note.sender?.avatarThumbnailSUrl}">
                        </avatar>
                        <span class="request-notes__item__date" [textContent]="note.sentOn | date:'dd/MM/yyyy HH:mm'"></span>
                    </div>
                    <conversation-note-status-tag [status]="note.kind"></conversation-note-status-tag>
                </div>
                <p *ngIf="note.note" class="request-notes__item__text" [innerHTML]="note.note"></p>
                <div *ngIf="note.resources && note.resources.length > 0" class="flex flex-wrap margin-horizontal-16 margin-top-16 margin-left-40">
                    <div class="flex align-items-baseline margin-bottom-10 margin-right-10"
                        *ngFor="let attachment of note.resources">
    
                        <a *ngIf="!isImage(attachment.mimetype)" [href]="attachment.url" class="attachment" target="_blank">
                            <span class="attachment__icon icon icon-attachment"></span>
                            <span class="attachment__filename">{{attachment.name}}{{attachment.extension | lowercase}}</span>
                        </a>
    
                        <a *ngIf="isImage(attachment.mimetype)" [href]="attachment.url" class="request-notes__image-container" target="_blank">
                            <img class="request-notes__image" [src]="attachment.url" alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="request-detail__footer"
        [ngClass]="{'request-detail__footer--text-editor-options-visible' : textEditorOptionsVisible}"
        *ngIf="!isLoadingData && ((selectedTab !== REQUEST_TABS.FILES && !request?.closedOn) || (selectedTab === REQUEST_TABS.NOTES && !!request?.closedOn))">

        <ng-container *ngIf="selectedTab === REQUEST_TABS.DETAIL">
            <dialenga-textarea #requestMsg textareaId="request-message" [(inputModel)]="newMessage.message" placeholder="message.captionPlaceholder" (inputModelChange)="validateModel()"
                [isRichFormat]="true" [showLinkBtns]="true" [showListsBtns]="true" [hideActionsBtns]="true">
            </dialenga-textarea>
        </ng-container>
        <ng-container *ngIf="selectedTab === REQUEST_TABS.NOTES">
            <dialenga-textarea #requestNote textareaId="request-note" [(inputModel)]="newNote" placeholder="message.captionPlaceholder" (inputModelChange)="validateModel()"
                [isRichFormat]="true" [showLinkBtns]="true" [showListsBtns]="true" [hideActionsBtns]="true">
            </dialenga-textarea>
        </ng-container>

        <div class="flex flex-wrap margin-horizontal-16 conversation-detail__footer__attachments-container scroll scroll3">
            <!-- Files / Images / Linked Publications attached in request conversation (detail)-->
            <ng-container *ngIf="selectedTab === REQUEST_TABS.DETAIL">
                <div class="flex align-items-baseline margin-bottom-10 margin-right-10"
                    *ngFor="let attachment of requestDetailAttachments.files let idx = index">
                    <a [href]="attachment.url" class="attachment" target="_blank">
                        <span class="attachment__icon icon icon-attachment"></span>
                        <span class="attachment__filename">{{attachment.name}}{{attachment.extension | lowercase}}</span>
                    </a>
                    <span class="attachment__icon attachment__icon--remove icon nucleo icon-remove-fill margin-left-5" (click)="removeFile(idx)"></span>
                </div>
                <div class="flex flex-wrap" *ngFor="let image of requestDetailAttachments.images let idx = index" >
                    <div class="image-attachment">
                        <img [src]="image.src" class="image-attachment__image" *ngIf="image.src" alt="">
                        <span class="attachment__icon attachment__icon--remove icon nucleo icon-remove-fill margin-left-5" (click)="removeImage(idx)"></span>
                    </div>
                </div> 
                <div class="link-publication" *ngFor="let link of newMessage.linkedPublications let idx = index">
                    <ng-container *ngIf="link.imageURL">
                        <figure class="link-publication__img" [ngClass]="{'link-publication__img--gallery': link.gallerySize !== 0, 'link-publication__img--survey': link.kind === CONTENT_KIND.SURVEY }" [ngStyle]="{'background-image': 'url(' + link.imageURL + ')'}">
                            <figcaption class="icon-gallery-number" [textContent]="'+' + (link.gallerySize - 1)" *ngIf="link.gallerySize !== 0"></figcaption>
                            <figcaption class="icon-gallery-number icon icon-questionnaire icon-16" *ngIf="link.kind === CONTENT_KIND.SURVEY"></figcaption>
                        </figure>
                    </ng-container>

                    <ng-container *ngIf="!link.imageURL">
                        <figure class="link-publication__img link-publication__img--video" [ngStyle]="{'background-image': 'url(' + getVideoImgPreview(link.youtubeURL) + ')'}"  *ngIf="!!link.youtubeURL">
                            <img src="/ng1/assets/img/youtube-icon-red.svg" alt="Youtube Icon">
                        </figure>
                        <div *ngIf="!link.youtubeURL" class="link-publication__img link-publication__img--category"
                            [ngStyle]="{'background-image': 'url(' + (link.category.imageURL || '/ng1/assets/img/background-test.png') + ')'}">
                            <span class="overlay-color" [ngStyle]="{'background-color': utilsService.getCategoryColor(link.category)}"></span>
                            <figcaption class="icon-gallery-number icon icon-questionnaire icon-16" *ngIf="link.kind === CONTENT_KIND.SURVEY"></figcaption>
                        </div>
                    </ng-container>

                    <div class="link-publication__info">
                        <span class="link-publication__title" [textContent]="link.title"></span>
                        <span class="link-publication__category truncate" [textContent]="link.category.name" 
                            [ngStyle]="{'background-color': utilsService.getCategoryColor(link.category)}"></span>
                        <span class="icon icon-heisenberg anonymous-badge anonymous-badge--small-card" *ngIf="link.kind === CONTENT_KIND.SURVEY && link.anonymousSurvey"></span>
                    </div>
                    <span class="link-publication__icon-remove icon nucleo icon-remove-fill margin-left-5" (click)="removeLink(idx)"></span>
                </div>
            </ng-container>
            <!-- Files / Images attached in request Notes -->
            <ng-container *ngIf="selectedTab === REQUEST_TABS.NOTES">
                <div class="flex align-items-baseline margin-bottom-10 margin-right-10"
                    *ngFor="let attachment of requestNotesAttachments.files let idx = index">
                    <a [href]="attachment.url" class="attachment" target="_blank">
                        <span class="attachment__icon icon icon-attachment"></span>
                        <span class="attachment__filename">{{attachment.name}}{{attachment.extension | lowercase}}</span>
                    </a>
                    <span class="attachment__icon attachment__icon--remove icon nucleo icon-remove-fill margin-left-5" (click)="removeFile(idx)"></span>
                </div>
                <div class="flex flex-wrap" *ngFor="let image of requestNotesAttachments.images let idx = index" >
                    <div class="image-attachment">
                        <img [src]="image.src" class="image-attachment__image" *ngIf="image.src" alt="">
                        <span class="attachment__icon attachment__icon--remove icon nucleo icon-remove-fill margin-left-5" (click)="removeImage(idx)"></span>
                    </div>
                </div> 
            </ng-container>
        </div>

        <div class="flex justify-space-between align-items-center padding-16 margin-right-16">
            <div class="flex align-items-center">
                <span class="icon icon-24 icon-clickable"
                    [ngClass]="textEditorOptionsVisible ? 'icon-text-format-filled' : 'icon-text-format black-45'"
                    (click)="textEditorOptionsVisible = !textEditorOptionsVisible">
                </span>
                <span class="icon icon-24 icon-attachment black-45 icon-clickable margin-left-16" (click)="openFileSelection()"></span>
                <input #attachFile type="file" title class="upload" style="display: none;"
                    (change)="onFileSelected($event.target.files)" />
                <div *ngIf="selectedTab === REQUEST_TABS.DETAIL">
                    <span class="icon icon-24 icon-related-content black-45 icon-clickable margin-left-16" (click)="openInternalLinkSelection()"></span>
                </div>
            </div>
            <div *ngIf="selectedTab === REQUEST_TABS.DETAIL">
                <loading-button [text]="'global.form.send'" class="margin-right-10" 
                    [buttonId]="'send-message'" [iconClass]="'icon-send'" [classes]="'button--cta width-100-percent '" 
                    (action)="sendMessage()" [isValid]="isValidMessage">
                </loading-button>
            </div>
            <div *ngIf="selectedTab === REQUEST_TABS.NOTES">
                <loading-button [text]="'global.form.save'" 
                    [buttonId]="'send-note'" [classes]="'button button--secondary'" 
                    (action)="sendMessage()" [isValid]="isValidNote">
                </loading-button>
            </div>
        </div>

    </section>
</div>