import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from "@angular/upgrade/static";
import { TranslateService } from '@ngx-translate/core';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { UsersService } from '../../shared/services/users.service';
import { RemoteSignatureDocument } from '../../models/remote-signature-document';
import { Subscription } from 'rxjs';
import { DocumentsService } from '../documents/documents.service';
import { UtilsService } from '../../shared/services/utils.service';
import { RemoteSignatureService } from '../remote-signature/remote-signature.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { Constants } from '../../constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RemoteSignatureRecipientsModalComponent } from './remote-signature-recipients-modal.component';

@Component({
    selector: 'remote-signature-creation',
    templateUrl: './remote-signature-creation.component.html'
})
export class RemoteSignatureCreationComponent implements OnInit, OnDestroy{
    
    private AFTER_N_DAYS: number = 2;
    private EXACT_DATE: number = 5;

    public _rootScope: any;
    public documents: Array<RemoteSignatureDocument> = [];
    public documentFiles: Array<any> = [];
    public selectedDocumentIndex: number = -1;
    public selectedDocumentName: string;
    public selectedDocumentKind: any;
    public documentPreviewSrc: string;
    public documentNameFocus = false;
    public documentNameMaxLength = 100;

    public requiresEmployeeSignature: boolean = true;

    public SIGNERS_TYPE = Constants.SIGNERS_TYPE;    
    public signersType: number = this.SIGNERS_TYPE.SINGLE;

    public recipientUser: any;
    public countryCodesList: any[];
    public recipientUserCountryCode: any;
    public recipientUserPhoneNumber: string;
    public recipientUserNationalIdNumber: string;

    public recipientUsers: any = [];

    public hasInvalidUsers: boolean = false;

    public shortMessage: string;
    public extendedMessage: string;
    public remindersActive = false;
    public remindAfterDays: number = 2;
    public expirationDateAdvance = false;
    public expirationDateMode: number;
    public expirationDateElapsed: number = 30;
    public expirationDate: Date;
    public expirationDateMin: Date;
    public expirationDateMax: Date;
    public EXPIRATION_MODES = { AFTER_N_DAYS: this.AFTER_N_DAYS, EXACT_DATE: this.EXACT_DATE};
    public documentKindList: any[];
    public isCreationActive = false;

    public stamp: any = {
        id: null,
        addAfterEmployee: true,
    };

    public signatureAreasList: Array<any>;
    public corpStampAreasList: Array<any>;
    public corporateStampsList: Array<any>;
    public toggleCorporateSeal: boolean = false;

    private DEFAULT_COUNTRY_CODE = 34;
    private finishGetCountryCodesSubscription: Subscription;
    private finishGetDocumentKindsSubscription: Subscription;
    private finishUpdatePhoneNumberSubscription: Subscription;
    private finishBatchCreationSubscription: Subscription;

    constructor(private formUtilsService: FormUtilsService, private upgrade: UpgradeModule, private location: Location,
        private documentsService: DocumentsService, private translate: TranslateService, private usersService: UsersService,
        private utilsService: UtilsService, private remoteSignatureService: RemoteSignatureService, private duiNotificationService: DuiNotificationsService,
        private dialogService: DialogService, private Dialog: MatDialog) {}

    ngOnInit() {
        this._rootScope = this.upgrade.$injector.get('$rootScope');
        this._rootScope.isSidemenuHidden = true;
        this._rootScope.isHeaderHidden = true;
        const userSession = window['getUserSession']();
        this.shortMessage = this.translate.instant('remoteSignature.creation.shortMessagePlaceholder', { 'company': userSession?.externalLoginBrand });
        this.expirationDate = new Date();
        this.expirationDate.setDate(this.expirationDate.getDate() + 30);
        this.expirationDateMin = new Date();
        this.expirationDateMin.setDate(this.expirationDateMin.getDate() + 1);
        this.expirationDateMax = new Date();
        this.expirationDateMax.setDate(this.expirationDateMax.getDate() + 59);
        this.finishGetCountryCodesSubscription = this.usersService.getCountryCodes().subscribe(response => {
            this.countryCodesList = response;
            this.recipientUserCountryCode = response.find(countryCode => countryCode.code == this.DEFAULT_COUNTRY_CODE);
        });
        this.finishGetDocumentKindsSubscription = this.documentsService.getDocumentKinds().subscribe(response => {
            let sortedResponse = response.sort(function (a, b) {
                return ('' + a.name).localeCompare(b.name);
            });
            this.documentKindList = sortedResponse;
            this.documentKindList.push({ name: this.translate.instant('documents.documentsKind.filterTagNames.other') });
        });

        this.documentsService.getSignatureAreas(['ALL', 'SIGNATURE']).subscribe((response) => {
            this.signatureAreasList = response;
        });

        this.documentsService.getSignatureAreas(['ALL', 'CERTS_AND_STAMPS']).subscribe((response) => {
            this.corpStampAreasList = response;
        });

        this.documentsService.getCorporateStamps().subscribe((response) => {
            this.corporateStampsList = response;
        });
    }

    ngOnDestroy() {
        this._rootScope.isSidemenuHidden = false;
        this._rootScope.isHeaderHidden = false;
        this.documentPreviewSrc = null;
        if (this.finishGetCountryCodesSubscription) {
          this.finishGetCountryCodesSubscription.unsubscribe();
        }
        if (this.finishGetDocumentKindsSubscription) {
            this.finishGetDocumentKindsSubscription.unsubscribe();
        }
        if (this.finishUpdatePhoneNumberSubscription) {
          this.finishUpdatePhoneNumberSubscription.unsubscribe();
        }
        if (this.finishBatchCreationSubscription) {
            this.finishBatchCreationSubscription.unsubscribe();
        }
    }

    goBack(): void {
        const dialogValues: DialogValueOptions = {
            title: 'global.modal.changes.title',
            message: 'global.modal.changes.exitAndDiscard',
            acceptButtonText: 'global.modal.changes.onlyExit'
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe(result => {
            if(result?.accept) {
                this.location.go('/documents/remote-signature');
            } else {
                this.formUtilsService.finishSubmitAction();
            }
        });
    }

    formatDate(originalDate: Date, hours: number, minutes: number, seconds: number, milliseconds: number): Date {
        let formattedDate = new Date(originalDate.getFullYear(), originalDate.getMonth(), originalDate.getDate());
        formattedDate.setHours(hours, minutes, seconds, milliseconds);
        return formattedDate;
    }

    validateExpirationDate(): boolean {
        if (!this.expirationDateAdvance) {
            return true;
        }
        if (this.expirationDateMode === this.EXPIRATION_MODES.AFTER_N_DAYS) {
            return this.expirationDateElapsed > 0 && this.expirationDateElapsed < 60;
        } else if (this.expirationDateMode === this.EXPIRATION_MODES.EXACT_DATE && !!this.expirationDate) {
            const formattedExpirationDateTime = this.formatDate(this.expirationDate, 0, 0, 0, 0);
            const minDate = this.formatDate(this.expirationDateMin, 0, 0, 0, 0);
            const maxDate = this.formatDate(this.expirationDateMax, 23, 59, 59, 59);
            return formattedExpirationDateTime.getTime() >= minDate.getTime() && formattedExpirationDateTime.getTime() <= maxDate.getTime();
        } else {
            return false;
        }
    }

    checkEmployees(): boolean {
        return (!!this.requiresEmployeeSignature && !!this.recipientUser && (!!this.recipientUser.phoneNumber?.number || !!this.recipientUserPhoneNumber) && (!!this.recipientUser.nationalIdNumber || !!this.recipientUserNationalIdNumber)) || (!this.requiresEmployeeSignature && !!this.recipientUser) || this.recipientUsers.length > 0 ;
    }

    isFormValid(): boolean {
        return this.checkEmployees() && !!this.shortMessage && this.documentFiles.length > 0 && (!this.remindersActive || (this.remindAfterDays > 0 && this.remindAfterDays < 60)) && this.validateExpirationDate() && this.checkStampIsSelected();
    }

    private getRenamedFile(file: File, filename: string, extension: string): any {
        let newFilename = filename;
        if (!!extension && !newFilename.endsWith('.' + extension)) {
            newFilename += '.' + extension;
        }
        const renamedFile = new File([file], newFilename, { type: (file.type || 'application/pdf') });
        return { name: filename, file: renamedFile, extension: extension};
    }

    addDocuments(files: any): void {
        const previousDocumentsNumber = this.documentFiles.length;
        Array.from(files).forEach((file: any) => {
            const extension = file.name.split('.').pop();
            const filename = file.name.substring(0, this.documentNameMaxLength).replace(new RegExp('.' + extension + '$'), '');
            const renamedFile = this.getRenamedFile(file, filename, extension);
            this.documentFiles.push(renamedFile);
            this.documents.push({
                location: null,
                fileKind: null,
                stampLocation: null
            });
        });
        // Select first document of the new ones
        this.selectDocument(previousDocumentsNumber);
    }

    loadDocumentInViewer(document) {
        let reader = new FileReader();

        reader.onloadend = (e: any) => {
            this.documentPreviewSrc = e.target.result;
        };

        reader.readAsArrayBuffer(document);
    }

    selectDocument(index: number): void {
        this.selectedDocumentIndex = index;
        if (index >= 0 && this.documentFiles.length > index) {
            this.selectedDocumentName = this.documentFiles[index].name;
            this.selectedDocumentKind = this.documents[index].fileKind;
            this.loadDocumentInViewer(this.documentFiles[index].file);
        } else {
            this.selectedDocumentName = null;
            this.selectedDocumentKind = null;
            this.documentPreviewSrc = null;
        }
    }

    attachDocuments(files: FileList) {
        if (files && files.length > 0) {
            this.addDocuments(files);
        }
    }

    removeDocument(index: number): void {
        this.documentFiles.splice(index, 1);
        this.documents.splice(index, 1);
        if (index <= this.selectedDocumentIndex) {
            if (index == this.selectedDocumentIndex) {
                var newIndex = index > 0 ? (index - 1) : index;
                this.selectDocument(newIndex);
            } else {
                this.selectedDocumentIndex--;
            }
        }
    }

    setSignersType(type: number): void {
        this.signersType = type;

        if (this.signersType === this.SIGNERS_TYPE.MULTIPLE) {
            this.recipientUser = null;
            this.recipientUserPhoneNumber = null;
            this.recipientUserNationalIdNumber = null;
        } else {
            this.recipientUsers = [];
        }
    }

    onRecipientChange() {
        this.recipientUserPhoneNumber = null;
        this.recipientUserNationalIdNumber = null;
    }

    daysInputValidation(event: any): void {
        if (!isNaN(parseInt(event.target.value)) && event.target.value.length > 0) {
            if (event.target.value.length > 2) {
                event.target.value = event.target.value.slice(0, 2);
            }
            if (event.target.value > 59) {
                event.target.value = 59;
            } else if (event.target.value < 1) {
                event.target.value = 1;
            }
        }
    }

    expirationDateToggleChange(): void {
        if (this.expirationDateAdvance && !this.expirationDateMode) {
            this.expirationDateMode = this.EXPIRATION_MODES.AFTER_N_DAYS;
        }
    }

    onFilenameChange(): void {
        this.documentFiles[this.selectedDocumentIndex] = this.getRenamedFile(this.documentFiles[this.selectedDocumentIndex]?.file, this.selectedDocumentName, this.documentFiles[this.selectedDocumentIndex]?.extension);
    }

    onFilenameFocus(value: boolean): void {
        this.documentNameFocus = value;
    }

    onDocumentKindChange(value: any): void {
        if (value) {
            this.documents[this.selectedDocumentIndex].fileKind = value;
        }
    }

    onAreaSelected(area: any): void {
        if (this.selectedDocumentIndex === -1) { return; }
        this.documents[this.selectedDocumentIndex].location = area;
    }

    onStampAreaSelected(area: any): void {
        if (this.selectedDocumentIndex === -1) { return; }
        this.documents[this.selectedDocumentIndex].stampLocation = area;
    }

    checkStampIsSelected(): boolean {
        return !this.toggleCorporateSeal || (this.toggleCorporateSeal && !!this.stamp.id);
    }

    removeSignatureArea(): void {
        this.documents[this.selectedDocumentIndex].location = null;
    }

    addMultipleRecipients() :void {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.data = {
            title: !!this.requiresEmployeeSignature ? this.translate.instant('remoteSignature.creation.modal.title.signers') : this.translate.instant('remoteSignature.creation.modal.title.recipients'),
            users: [... this.recipientUsers],
            hasInvalidUsers: this.hasInvalidUsers,
            requiresEmployeeSignature: this.requiresEmployeeSignature
        };
        dialogSettings.width = '65vw';
        dialogSettings.minWidth = '976px';
        dialogSettings.position = { top: '4vh'};
        dialogSettings.panelClass = ['animated','slideInDown'];

        this.Dialog.open(RemoteSignatureRecipientsModalComponent, dialogSettings).afterClosed().subscribe(result => {
            if (result?.arePendingChanges && result?.addedUsers) {
                this.recipientUsers = result.addedUsers;
                this.hasInvalidUsers = result.hasInvalidUsers;
            }
        });
    }

    checkRequiredSignature(event: boolean): void {
        this.requiresEmployeeSignature = event;

        if (!this.requiresEmployeeSignature) {
            this.stamp.addAfterEmployee = false;
            
            if (!this.toggleCorporateSeal) {
                this.toggleCorporateSeal = true;                
            }

        } else {
            this.stamp.addAfterEmployee = true;
        }
    }

    checkRequiredStamp(event: boolean): void {
        this.toggleCorporateSeal = event;

        if (!this.toggleCorporateSeal && !this.requiresEmployeeSignature) {
            this.requiresEmployeeSignature = true;           
        } else {
            this.stamp.addAfterEmployee = true;
        }
    }

    createRemoteSignatureBatch(batch: RemoteSignatureBatch, documentFiles: Array<File>) {
        this.finishBatchCreationSubscription = this.remoteSignatureService.createRemoteSignatureBatch(batch, documentFiles).subscribe(response => {
            this.location.go('/documents/remote-signature');

            let controlMessage = this.translate.instant('remoteSignature.creation.successMessage') + '.<br>';
            controlMessage += this.translate.instant('remoteSignature.creation.partialSuccessMessage', {successBatches: response.batches.length, failedBatches: response.errors.length});

            const notificationOptions: NotificationOptions = {
                kind: response.errors.length > 0 ? 'warning' : 'success',
                message: response.errors.length > 0 ? controlMessage : this.translate.instant('remoteSignature.creation.successMessage')
            }

            this.duiNotificationService.showNotification(notificationOptions);
            this.isCreationActive = false;
        },
        error => {
            this.formUtilsService.finishSubmitAction();
            if (window['showLocalizedError']) {
                window['showLocalizedError'](error.status, error.error);
            }
            this.isCreationActive = false;
        });
    }

    sendRemoteSignatureDocuments() {
        this.isCreationActive = true;
        let batch: RemoteSignatureBatch = {
            title: this.shortMessage,
            message: this.extendedMessage,
            employees: !!this.recipientUser ? [this.recipientUser] : this.recipientUsers,
            reminder: {
                reminderEnabled: !!this.remindersActive,
                reminderKind: !!this.remindersActive ? this.AFTER_N_DAYS : null,
                reminderAmount: !!this.remindersActive ? this.remindAfterDays : null
            },
            expiration: {
                expirationEnabled: !!this.expirationDateAdvance,
                expirationKind: !!this.expirationDateAdvance ? this.expirationDateMode : null
            },
            documents: this.documents,
            requiresEmployeeSignature: this.requiresEmployeeSignature,
            stamp: (this.toggleCorporateSeal && this.stamp) ? this.stamp : null
        };
        if (!!this.expirationDateAdvance) {
            if (this.expirationDateMode === this.AFTER_N_DAYS) {
                batch.expiration['expirationAmount'] = this.expirationDateElapsed;
            } else if (this.expirationDateMode === this.EXACT_DATE && !!this.expirationDate) {
                batch.expiration['expirationDate'] = this.utilsService.formatDateToString(this.expirationDate);
            }
        }
        if (this.signersType === this.SIGNERS_TYPE.SINGLE && !!this.requiresEmployeeSignature) {
            if (!batch.employees[0].phoneNumber && this.recipientUserPhoneNumber && this.recipientUserCountryCode) {
                batch.employees[0].phoneNumber = { code: this.recipientUserCountryCode.code, number: this.recipientUserPhoneNumber };
            }
            if (!batch.employees[0].nationalIdNumber && this.recipientUserNationalIdNumber) {
                batch.employees[0].nationalIdNumber = this.recipientUserNationalIdNumber;
            }            
        }

        if (!this.toggleCorporateSeal) {
            batch.documents.map((document) => {
                document.stampLocation = null;
            });
        }

        if(!this.requiresEmployeeSignature) {
            batch.title = null;

            batch.documents.map((document) => {
                document.location = null;
            });
        }

        this.createRemoteSignatureBatch(batch, this.documentFiles);
    }
}

interface RemoteSignatureBatch {
    title: string,
    message: string,
    employees: Array<any>,
    reminder: {
        reminderEnabled: boolean,
        reminderKind: number,
        reminderAmount: number
    },
    expiration: {
        expirationEnabled: boolean,
        expirationKind: number,
        expirationAmount?: number,
        expirationDate?: string
    },
    documents: Array<RemoteSignatureDocument>,
    requiresEmployeeSignature: boolean,
    stamp: any
}
