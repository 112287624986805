<div class="dialenga-input__container dui__container" [ngClass]="{'dialenga-input__container--active': inputOnFocus}">
  <input [id]="inputId" class="dialenga-input" type="text" [(ngModel)]="inputModel" (ngModelChange)="modelValueIsChanging()" [placeholder]="(placeholder | translate)"
    [disabled]="isDisabled" [readonly]="isReadOnly" (focus)="modelValueOnFocus()" (focusout)="modelValueOnFocusOut()" (keydown.enter)="onKeyPressed($event)" (keydown.esc)="onKeyPressed($event)"
    minlength="{{minLength?.amount}}" maxlength="{{maxLength?.amount}}" [ngClass]="{'dialenga-input--active': isAllwaysActive}"
    autocomplete="off" />
    @if (isReadOnly) {
      <span class="dui__icon dui__icon--read-only icon icon-lock-fill-s"></span>
    }
    <div class="dui__actions-container" >
      <div  class="dui__message" [ngClass]="{'dui__message--error': !isValid}">
        @if (isWarningMessageActive && isValid && inputOnFocus) {
          <span class="dui__message__text dui__message__text--align-right"
                [ngClass]="{'warning' : colorWarningMessage == this.MESSAGE_COLOR.WARNING,
                'danger' : colorWarningMessage == this.MESSAGE_COLOR.DANGER}"
          [textContent]="warningMessage"></span>
        }
        @if (!isValid) {
          <span class="dui__message__text" class="warning" [textContent]="errorMessage"></span>
        }
      </div>
      @if (inputOnFocus && !isReadOnly && !isDisabled) {
        <div class="dui__action-buttons">
          <div [id]="inputId + '-dialenga-accept-button'" class="dui__action-button" (mousedown)="modelValueAccept($event)" >
            <span class="dui__action-button__icon icon icon-accept"></span>
          </div>
          <div [id]="inputId + '-dialenga-cancel-button'" class="dui__action-button" (mousedown)="modelValueCancel()" >
            <span class="dui__action-button__icon icon icon-revert"></span>
          </div>
        </div>
      }
    </div>
  </div>