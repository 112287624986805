import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormUtilsService {

  public submitActionFinishedEmitter$: EventEmitter<any>;
  public finishModulesUpdateEmitter$: EventEmitter<any>;
  public confirmCheckboxDeactivationEmitter$: EventEmitter<any>;

  constructor() {
    this.submitActionFinishedEmitter$ = new EventEmitter();
    this.finishModulesUpdateEmitter$ = new EventEmitter();
    this.confirmCheckboxDeactivationEmitter$ = new EventEmitter();
  }

  finishSubmitAction(): void {
    this.submitActionFinishedEmitter$.emit(null);
  }

  finishModulesUpdate(): void {
    this.finishModulesUpdateEmitter$.emit(null);
  }

  confirmCheckboxDeactivation(inputId: string, checkboxValue: boolean): void {
    this.confirmCheckboxDeactivationEmitter$.emit({inputId, checkboxValue});
  }
}
