import { Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
  selector: 'dui-popover',
  templateUrl: './dui-popover.component.html',
  styleUrls: ['./_dui-popover.component.scss']
})
export class DuiPopoverComponent {

    @Input() onlyOnClick? = false;

    isActive = false;

    @HostListener('document:click', ['$event'])
    clickout (event) {
        if (!this.eRef.nativeElement.contains(event.target) && this.onlyOnClick) {
            this.hidePopover();
        }
    }

    constructor(private eRef: ElementRef) { }

    showPopover() {
        this.isActive = null;
        setTimeout(() => {
            if (this.isActive === null) {
                this.isActive = true;
            }
        }, 200);
    }

    hidePopover() {
        this.isActive = false;
    }

    togglePopover() {
        this.isActive = !this.isActive;
    }

}