<button id="{{buttonId}}" class="button {{classes}}" [ngClass]="{'is-loading': isLoading === true}" [disabled]="!isValid || isLoading === true" (click)="submitForm()" [title]="title || ''">
  @if (isLoading === true) {
    <div>
      <spinner-dialenga></spinner-dialenga>
    </div>
  }
  @if (!!iconClass && !isLoading) {
    <span class="button__icon icon {{iconClass}}"></span>
  }
  @if (text && !avoidTextTranslation) {
    <span class="button__label" [translate]="text"></span>
  }
  @if (text && avoidTextTranslation) {
    <span class="button__label" [textContent]="text"></span>
  }
</button>