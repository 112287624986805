<div class="avatar-hexagon">
  @if (!color) {
    <svg width="58" height="56" viewBox="0 0 58 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1143_52)">
        <path
          d="M15.6906 12.9474C17.1197 10.4722 19.7607 8.94744 22.6188 8.94744L35.3812 8.94744C38.2393 8.94744 40.8803 10.4722 42.3094 12.9474L48.6906 24C50.1197 26.4752 50.1197 29.5248 48.6906 32L42.3094 43.0526C40.8803 45.5278 38.2393 47.0526 35.3812 47.0526H22.6188C19.7607 47.0526 17.1197 45.5278 15.6906 43.0526L9.30941 32C7.88034 29.5248 7.88034 26.4752 9.30941 24L15.6906 12.9474Z"
          fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M22.6188 8.94744C19.7607 8.94744 17.1197 10.4722 15.6906 12.9474L9.30941 24C7.88034 26.4752 7.88034 29.5248 9.30941 32L15.6906 43.0526C17.1197 45.5278 19.7607 47.0526 22.6188 47.0526H35.3812C38.2393 47.0526 40.8803 45.5278 42.3094 43.0526L48.6906 32C50.1197 29.5248 50.1197 26.4752 48.6906 24L42.3094 12.9474C40.8803 10.4722 38.2393 8.94744 35.3812 8.94744L22.6188 8.94744Z"
            fill="white" />
          </g>
          <defs>
            <filter id="filter0_d_1143_52" x="0.23761" y="0.947441" width="57.5248" height="54.1051"
              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1143_52" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1143_52" result="shape" />
              </filter>
            </defs>
          </svg>
        }
        @if (!!color) {
          <svg viewBox="0 0 59 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.6906 12.9475C17.1197 10.4722 19.7607 8.94746 22.6188 8.94746L35.3812 8.94746C38.2393 8.94746 40.8803 10.4722 42.3094 12.9475L48.6906 24C50.1197 26.4752 50.1197 29.5248 48.6906 32L42.3094 43.0526C40.8803 45.5278 38.2393 47.0526 35.3812 47.0526L22.6188 47.0526C19.7607 47.0526 17.1197 45.5278 15.6906 43.0526L9.3094 32C7.88034 29.5248 7.88034 26.4752 9.3094 24L15.6906 12.9475Z"
              [attr.fill]="(color | numberColor)" />
              <g filter="url(#filter1_d_1658_21384)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M35.3812 10.9475L22.6188 10.9475C20.4752 10.9475 18.4944 12.091 17.4227 13.9475L11.0415 25C9.96966 26.8564 9.96966 29.1436 11.0415 31L17.4227 42.0526C18.4944 43.909 20.4752 45.0526 22.6188 45.0526L35.3812 45.0526C37.5248 45.0526 39.5056 43.909 40.5774 42.0526L46.9586 31C48.0303 29.1436 48.0303 26.8564 46.9586 25L40.5774 13.9475C39.5056 12.091 37.5248 10.9475 35.3812 10.9475ZM22.6188 8.94746C19.7607 8.94746 17.1197 10.4722 15.6906 12.9475L9.3094 24C7.88034 26.4752 7.88034 29.5248 9.3094 32L15.6906 43.0526C17.1197 45.5278 19.7607 47.0526 22.6188 47.0526L35.3812 47.0526C38.2393 47.0526 40.8803 45.5278 42.3094 43.0526L48.6906 32C50.1197 29.5248 50.1197 26.4752 48.6906 24L42.3094 12.9475C40.8803 10.4722 38.2393 8.94746 35.3812 8.94746L22.6188 8.94746Z"
                  fill="white" />
                </g>
                <defs>
                  <filter id="filter0_d_1658_21384" x="0.23761" y="0.947449" width="57.5248" height="54.1051"
                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1658_21384" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1658_21384" result="shape" />
                    </filter>
                    <filter id="filter1_d_1658_21384" x="0.23761" y="0.947449" width="57.5248" height="54.1051"
                      filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="4" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1658_21384" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1658_21384" result="shape" />
                      </filter>
                    </defs>
                  </svg>
                }
                <span class="avatar-hexagon__initials" [ngClass]="{'avatar-hexagon__initials--negative': !color}" [textContent]="initials"></span>
              </div>