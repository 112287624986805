import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { Constants } from '../../constants';

@Component({
    selector: 'document-settings',
    templateUrl: './document-settings.component.html'
})
export class DocumentSettingsComponent implements OnInit {

    public DOCUMENT_TABS: any;
    public selectedTab: number;
    private _route: any;
    public userSession: any;

    public userLoggedHasAdminRole: boolean;
    public availableLanguages: Array<any>;

    constructor(private location: Location, private upgrade: UpgradeModule) { 

        this.DOCUMENT_TABS = Constants.DOCUMENT_TABS;
        this._route = this.upgrade.$injector.get('$route');
        this.selectedTab = this._route.current.locals.selectedTab;
        this.userSession = window['getUserSession']();

        this.userLoggedHasAdminRole = this.userSession.roles.indexOf(Constants.USER_ROLES.admin) !== -1;
        this.availableLanguages = this.userSession.companyLanguages;
    }

    ngOnInit() {
       
    }

    navigateTo(url: string) {
        this.location.go(url);
    }

    loadTabContent( tab: number) {
        if (this.selectedTab !== tab) {
            this.selectedTab = tab;
            switch (tab) {
                case this.DOCUMENT_TABS.GLOBAL:
                    // this.titleService.setTitle(this.translate.instant('myCompany.title'));
                    this._route.updateParams({ tabName: 'global' });
                    break;
                case this.DOCUMENT_TABS.CONVENTIONS:
                    // this.titleService.setTitle(this.translate.instant('subsidiaries.title'));
                    this._route.updateParams({ tabName: 'conventions' });
                    break;
                default:
                    break;
            }
        }
    }
}