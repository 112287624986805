import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { SegmentsService } from '../segments/segments.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './segment-create-modal.component.html'
  })
  export class SegmentCreateModalComponent implements AfterViewInit {

    public isFormValid: boolean = false;
    public segmentForm: any = {
        name: '',
        description: ''
    };
    @ViewChild('segmentName') segmentNameRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<SegmentCreateModalComponent>, private formUtilsService: FormUtilsService, private segmentsService: SegmentsService,
        private duiNotificationService: DuiNotificationsService, private _elementRef: ElementRef, private translate: TranslateService
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => this.segmentNameRef.nativeElement.focus(), 200);
    }

    onChange(): void {
        this.segmentNameRef.nativeElement.classList.remove('input--error');
        this.isFormValid = this.segmentForm.name.length > 0;
    }

    onEnterPressed(): void {
        if(this.isFormValid) {
            const createSegmentBtn: HTMLElement = (this._elementRef.nativeElement).querySelector('.button--secondary');
            createSegmentBtn.click();
        }
    }

    onAccept(): void {
        this.segmentsService.create(this.segmentForm).subscribe(
            (onSuccess: any) => {
                // Get the new room id, return it and close modal
                this.dialogRef.close({success: onSuccess});
            },
            (onError: any) => {
                const notificationOptions: NotificationOptions = {
                    kind: 'error',
                    message: onError.status === 422 ? onError.error.localizedError : this.translate.instant('500.content.title')
                }
                this.duiNotificationService.showNotification(notificationOptions);

                this.segmentNameRef.nativeElement.classList.add('input--error');
                this.segmentNameRef.nativeElement.focus();
                this.formUtilsService.finishSubmitAction();
            }
        );

    }

    onCancel(): void {
        this.dialogRef.close({closed: true});
    }

}