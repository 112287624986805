import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitItemsDisplayed'
})
export class LimitItemsDisplayedPipe  implements PipeTransform {
  transform(array: any, limit: number): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    limit = limit || 3;
    if (array.length > limit) {
        array = [array[0], {name: '...'}, array[array.length - 1]];
    }
    return array;
  }
}
