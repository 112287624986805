import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { Constants } from '../../constants';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { SegmentsManagementModalComponent } from '../../shared/components/segments-management-modal/segments-management-modal.component'
import { ChatroomAdminsModalComponent } from '../chatroom/chatroom-admins-modal.component';
import { ChatroomsService } from '../chatrooms/chatrooms.service';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'chatroom',
    templateUrl: './chatroom.component.html',
    styleUrls: ['./_chatroom.scss']
})
export class ChatroomComponent implements OnInit {

    public CHATROOM_TABS: any;
    public CHATROOM_STATES: any;
    public CHATROOM_PREVIEW: any = {
        DIRECTORY: 1,
        DETAIL: 2
    }
    public CHATROOM_USERS_SYNCHRO_KIND: any;
    public SEGMENTATION_KIND: any;
    public POPULATION: any;
    public selectedTab: number;
    private _route: any;
    private chatroomId: number;
    private userSession: any;
    public isPreviewVisible: boolean = false;
    public currentPreview: number = this.CHATROOM_PREVIEW.DIRECTORY;
    public chatroomForm: any = {
        admins: [],
        deletable: true,
        image: [],
        participants: [],
        croppedImageSrc: ''
    };
    public initialDataForm: any = {};
    public isSyncing: boolean = true;

    constructor (
        private location: Location, private upgrade: UpgradeModule, private titleService: Title, private chatroomsService: ChatroomsService, 
        private translate: TranslateService, public dialogService: DialogService, public formUtilsService: FormUtilsService, private Dialog: MatDialog,
        private notificationService: DuiNotificationsService

    ) { 
        this.CHATROOM_TABS = Constants.CHATROOM_TABS;
        this.CHATROOM_STATES = Constants.CHATROOM_STATES;
        this.CHATROOM_USERS_SYNCHRO_KIND = Constants.CHATROOM_USERS_SYNCHRO_KIND;
        this.SEGMENTATION_KIND = Constants.SEGMENTATION_KIND;
        this.POPULATION = Constants.POPULATION;
        this._route = this.upgrade.$injector.get('$route');
        this.chatroomId = this._route.current.params.id;

        this.selectedTab = this._route.current.locals.selectedTab;
        this.userSession = window['getUserSession']();
    }

    ngOnInit() {
        this.chatroomsService.findOne(this.chatroomId).subscribe(
            (response: any) => {
                this.setFormData(response);
                this.isSyncing = false;
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    setFormData( dataFromResponse: any ) :void {
        this.chatroomForm = Object.assign(this.chatroomForm, dataFromResponse);
        this.chatroomForm.description = !this.chatroomForm.description ? '' : this.chatroomForm.description;
        this.chatroomForm.croppedImageSrc = this.chatroomForm.avatarUrl;
        this.initialDataForm = cloneDeep(this.chatroomForm);
    }

    navigateTo(url: string): void {
        this.location.go(url);
    }

    isPreviewVisibleFn(visibility: boolean) :boolean {
        this.isPreviewVisible = visibility;
        return this.isPreviewVisible;
    }

    loadTabContent( tab: number): void {
        if (this.selectedTab !== tab) {
            this.selectedTab = tab;
            if (tab === this.CHATROOM_TABS.GENERAL) {
                this.titleService.setTitle(this.translate.instant('chatrooms.update.title'));
                this._route.updateParams({ tabName: 'general' });
            } else if (tab === this.CHATROOM_TABS.USERS) {
                this._route.updateParams({ tabName: 'users'});
            }
        }
    }

    archiveOrDelete(): void {
        const dialogValues: DialogValueOptions = {
            title: 'chatrooms.modal.delete.title',
            message: 'chatrooms.modal.delete.message',
            messageParam: { chatroom: this.chatroomForm.name }
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe(result => {
            if(result?.accept) {
                this.chatroomsService.deleteChat(this.chatroomForm.id).subscribe(
                    (onSuccess) => {
                        const notificationOptions: NotificationOptions = {
                            kind: 'success',
                            message: this.translate.instant('chatrooms.modal.delete.success', { chatroom: this.chatroomForm.name })
                        }
                        this.notificationService.showNotification(notificationOptions);
                        // Go back to chatrooms list
                        this.navigateTo('/settings/chatrooms');
                    },
                    (onError) => {
                        const notificationOptions: NotificationOptions = {
                            kind: 'error',
                            message: this.translate.instant('global.messages.error.unknown')
                        }
                        this.notificationService.showNotification(notificationOptions);
                    }
                )
            }
        });
    }

    showSuccessNotification(): void {
        const notificationOptions: NotificationOptions = {
            kind: 'success',
            message: this.translate.instant('global.messages.changesUpdated.success')
        };
        this.notificationService.showNotification(notificationOptions);
    }

    openAddUsersModal(): void {
        //get all the participants from chatroom for pre-charge modal
        this.chatroomsService.findAllParticipants(this.chatroomId, {page: 0, size: 20000}, null).subscribe(
            (response) => {
                this.formUtilsService.finishSubmitAction();

                const dialogSettings = new MatDialogConfig();
                dialogSettings.data = {
                    title: this.translate.instant('chatrooms.modal.users.title'),
                    users: response.content
                    };
                dialogSettings.width = '65vw';
                dialogSettings.position = { top: '4vh'};
                dialogSettings.panelClass = ['animated','slideInDown'];

                this.Dialog.open(ChatroomAdminsModalComponent, dialogSettings).afterClosed().subscribe(result => {
                    this.formUtilsService.finishSubmitAction();

                    if (result?.arePendingChanges && result?.addedUsers) {
                        this.chatroomForm.participants = result.addedUsers;
                        this.showSuccessNotification();
                    }
                });
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    openAddSegmentsModal(): void {
        this.formUtilsService.finishSubmitAction();

        const dialogSettings = new MatDialogConfig();
        dialogSettings.data = {
            title: this.translate.instant('chatrooms.modal.segments.title'),
            segments: [... this.chatroomForm.segments],
            segmentationKind: this.chatroomForm.segmentationKind !== null ? this.chatroomForm.segmentationKind : this.SEGMENTATION_KIND.UNION,
            showSegmentationSelection: true,
            population: this.POPULATION.SEGMENTS};
        dialogSettings.width = '1100px';
        dialogSettings.minWidth = '600px';
        dialogSettings.maxWidth = '90vw';
        dialogSettings.position = { top: '4vh'};
        dialogSettings.panelClass = ['animated','slideInDown'];

        this.Dialog.open(SegmentsManagementModalComponent, dialogSettings).afterClosed().subscribe(result => {
            if (result?.arePendingChanges || result?.segmentationKindChanged) {
                if (result?.arePendingChanges && result?.addedSegments) { this.chatroomForm.segments = result.addedSegments; }
                if (result?.segmentationKindChanged) { this.chatroomForm.segmentationKind = result.segmentationKind; }
                this.showSuccessNotification();
            }
        });
    }
}