<div class="main-container">
  <section class="main-container__header" style="min-width: 891px">
    <div>
      <ul class="breadcrumb">
        <li><a href="/global-settings" [translate]="'global.navbar.globalSettings'"></a></li>
        <li><a id="goToExternalLinks" [href]="externalLinksPath"
        [translate]="'myCompany.tabs.externalLinks'"></a></li>
      </ul>
      <h2 [translate]="!!externalLinkId ? 'externalLinks.form.edit' : 'externalLinks.form.new'"></h2>
    </div>
  </section>

  <language-selector id="extl-language-selector" [(selectedLanguage)]="selectedLanguage"
  [(formContent)]="extLinkForm.contents" [validationFunction]="onChange()"></language-selector>

  <section class="main-container__content">
    <div class="main-container--small-width" id="ext-link-content">
      <!-- Availability -->
      <div class="group-form group-form--label-220">
        <div class="group-form__label">
          <span class="group-form__label__text" [translate]="'externalLinks.form.availability'"></span>
        </div>
        <div class=" group-form__data">
          <div class="button-bar button-bar--small margin-right-8">
            <button class="button" type="button"
              [ngClass]="{'button--selected': extLinkForm.availabilityKind === EXT_LINK_AVAILABILITY.ALL}"
              (click)="extLinkForm.availabilityKind = EXT_LINK_AVAILABILITY.ALL; onChange()">
              <span class="button__label" [translate]="'segments.tabAll'"></span>
            </button>
            <button class="button" type="button"
              [ngClass]="{'button--selected': extLinkForm.availabilityKind === EXT_LINK_AVAILABILITY.APP}"
              (click)="extLinkForm.availabilityKind = EXT_LINK_AVAILABILITY.APP; onChange()">
              <span class="button__label" [translate]="'externalLinks.form.app'"></span>
            </button>
            <button class="button" type="button"
              [ngClass]="{'button--selected': extLinkForm.availabilityKind === EXT_LINK_AVAILABILITY.WEBAPP}"
              (click)="extLinkForm.availabilityKind = EXT_LINK_AVAILABILITY.WEBAPP; onChange()">
              <span class="button__label" [translate]="'externalLinks.form.webapp'"></span>
            </button>
          </div>
        </div>
      </div>
      <!-- External Link Name -->
      <div class="group-form group-form--label-220">
        <div class="group-form__label">
          <span class="group-form__label__text margin-right-10">{{'externalLinks.form.name' | translate}}:</span>
          @if (selectedLanguage) {
            <span class="group-form__label__text group-form__label__text--sm"
            [textContent]="'global.languageBrackets.' + selectedLanguage | translate"></span>
          }

        </div>
        <div class="group-form__data direction-column align-items-start">
          @if (extLinkForm.contents[selectedLanguage]) {
            <input id="external-link-name" class="input max-width-285" maxlength="42"
              placeholder="{{'externalLinks.form.name' | translate}}"	[(ngModel)]="extLinkForm.contents[selectedLanguage].name" (ngModelChange)="onChange()">
          }
        </div>
      </div>
      <!-- External Link URL -->
      <div class="group-form group-form--label-220">
        <div class="group-form__label">
          <span class="group-form__label__text">{{'model.audit.requestURL' | translate}}:</span>
        </div>
        <div class="group-form__data group-form__data--column relative">
          <input name="external-link-url" id="external-link-url"
            class="input width-100-percent padding-right-40"
            [ngClass]="{'input--error': !isValidURL && !!extLinkForm.url}" maxlength="256"
            placeholder="{{'model.message.relatedLinkURL' | translate}}" autocomplete="off"
            [(ngModel)]="extLinkForm.url" (ngModelChange)="validateURL(extLinkForm.url)">
            @if (!isValidURL && !!extLinkForm.url) {
              <div class="margin-top-10">
                <label class="danger" [translate]="'global.form.urlWrong'"></label>
              </div>
            }
            <span class="info-color cursor-pointer margin-top-5 margin-left-5"
            [translate]="'externalLinks.form.paramsModalCTA'" (click)="openParamsModal()"></span>
          </div>
        </div>
        <!-- Icons Gallery -->
        <div class="group-form group-form--label-220">
          <div class="group-form__label">
            <span class="group-form__label__text">{{'externalLinks.form.icon' | translate}}:</span>
          </div>
          <div class="group-form__data dui-select">
            <kendo-dropdownlist id="icon-select" name="icon-select" class="dui-dropdown dui-dropdown--grid"
              style="width: auto;" [data]="EXTERNAL_LINKS_ICONS" [(ngModel)]="extLinkForm.icon" (valueChange)="onChange()"
              [popupSettings]=" {animate: {type: 'expand' , direction: 'down' },popupClass: 'dialenga-dropdownlist__popup dialenga-dropdownlist__popup--grid',width: 'auto'}" [listHeight]="null">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span class="icon-24 black-45 margin-right-6 gallery" [ngClass]="dataItem"></span>
              </ng-template>
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                @if (dataItem === EXTERNAL_LINKS_ICONS[0]) {
                  <article class="gallery__default-icon">
                    <span class="gallery {{dataItem}}"></span>
                    <span [translate]="'externalLinks.defaultIcon'"></span>
                  </article>
                }
                @if (dataItem !== EXTERNAL_LINKS_ICONS[0]) {
                  <span class="gallery" [ngClass]="dataItem"></span>
                }
              </ng-template>
            </kendo-dropdownlist>
          </div>
        </div>

        <!-- Open in External App -->
        @if (extLinkForm.availabilityKind !== EXT_LINK_AVAILABILITY.WEBAPP) {
          <div class="group-form group-form--label-220">
            <div class="group-form__label">
              <span class="group-form__label__text margin-right-10"
              [translate]="'externalLinks.form.openOutsideApp'"></span>
            </div>
            <div class="group-form__data group-form__data--column">
              <div class="button-bar button-bar--small margin-right-8">
                <button class="button" type="button"
                  [ngClass]="{'button--selected': extLinkForm.externalApp}"
                  (click)="extLinkForm.externalApp = true; onChange()">
                  <span class="button__label" [translate]="'externalLinks.form.externalBrowser'"></span>
                </button>
                <button class="button" type="button"
                  [ngClass]="{'button--selected': !extLinkForm.externalApp}"
                  (click)="extLinkForm.externalApp = false; onChange()">
                  <span class="button__label" [translate]="'externalLinks.form.integratedBrowser'"></span>
                </button>
              </div>
              <span class="margin-top-10 font-size-12 black-45"
              [translate]="extLinkForm.externalApp ? 'externalLinks.form.externalBrowserTip' : 'externalLinks.form.integratedBrowserTip' "></span>
            </div>
          </div>
        }
        <!-- External link population kind -->
        <div class="group-form group-form--label-220">
          <div class="group-form__label">
            <span class="group-form__label__text">{{'externalLinks.form.visibility' | translate}}:</span>
          </div>

          <div class="group-form__data group-form__data--column align-items-start">
            <div class="button-bar button-bar--small margin-right-8">
              <button type="button" class="button"
                [ngClass]="{'button--selected': extLinkForm.populationKind === POPULATION.ALL}"
                (click)="setPopulation(POPULATION.ALL)">
                <span class="button__label" [translate]="'segments.tabAll'"></span>
              </button>
              <button type="button" class="button"
                [ngClass]="{'button--selected': extLinkForm.populationKind === POPULATION.SEGMENTS}"
                (click)="setPopulation(POPULATION.SEGMENTS)">
                <span class="button__label" [translate]="'segments.tabGroups'"></span>
              </button>
            </div>

            @if (extLinkForm.populationKind === POPULATION.SEGMENTS) {
              <div class="flex direction-column">
                @if (extLinkForm.segments?.length === 0) {
                  <section class="no-selected-groups">
                    <span class="icon nucleo icon-warning-circle-fill"></span>
                    <span [translate]="'externalLinks.form.noSelectedGroups'"></span>
                  </section>
                }
                <button id="add-segments" class="button button--small margin-top-16" style="padding: 0px 25px; max-width: fit-content;" (click)="addSegmentsToLink()">
                  <span class="button__label" [translate]="'externalLinks.form.selectGroups'"></span>
                </button>
                @if (extLinkForm.segments?.length > 0) {
                  <div class="flex align-items-center margin-top-16">
                    <span [translate]="'chatrooms.update.roomSynchroSettings.segmentationKind'"></span>
                    <span class="icon icon-24 margin-left-5" [ngClass]="extLinkForm.segmentationKind === SEGMENTATION_KIND.UNION ? 'icon-union' : 'icon-intersect'"></span>
                    <span class="margin-left-5 bold" [translate]="'global.segmentation.kind'" [translateParams]="{kind: extLinkForm.segmentationKind}"></span>
                  </div>
                }
                @if (extLinkForm.segments?.length > 0) {
                  <div class="margin-top-16">
                    <div class="flex flex-wrap">
                      @for (segment of extLinkForm.segments; track segment) {
                        <div class="group-form__data--tags max-width-225">
                          <span class="max-width-200 truncate" [textContent]="segment.name"></span>
                          <span class="icon icon-clear-fill-s" (click)="removeSegmentFromLink($index)"></span>
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
            }

          </div>
        </div>
        <!-- Form action buttons -->
        <div class="group-form group-form--label-220 margin-bottom-32 margin-top-32">
          <div class="group-form__label"></div>
          <div class="group-form__data group-form__data--inline">
            <feedback-action-button [buttonId]="'save-button'" [classes]="'button--secondary'"
              [text]="'global.form.save'" [tooltipText]="'formValidation.invalidSingleLanguage'"
              (action)="extLinkFormSave()" [isValid]="isFormDataChanged && isFormDataValid">
            </feedback-action-button>
            <loading-button [text]="'global.form.cancel'" class="margin-left-20" [classes]="'button'"
              (action)="goBack()" [isValid]="true">
            </loading-button>
          </div>
        </div>
      </div>
    </section>
  </div>